export const GameList = [ 
  // Toсa Boсa
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 0,
    title: "Golden Farm - playcutegames.com",
    name: ["Golden Farm", "golden-farm"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/golden-farm/250x142.png",
    path: ["/game/golden-farm", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/196268?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video: "",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Golden Farm Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `
    If you ever dreamed to escape everyday routine and spend some idle time to play farm in a quiet town or village - this farm life simulator is for you.

    <br><br>
    Imagine yourself starting the day at a beautiful fazenda: stacks of hay, fruitful fields and a blooming garden seem like a pure paradise for a city person like you.
    Your village friends are always there to help at your farmland harvest fruits and vegetables, care and feed the animals and trade on the market. Hey, by the way your family can join the game, too, to have more fun together!
    `,
    tag: ["Casual","Simulation","Economic",],
    hint: `
    <ul>
     <li>Build a village and upgrade farm buildings!</li>
     <li>Grow fruit trees and plants in fields and gardens!</li>
     <li>Breed animals: feed a chicken flock, buy a cow or two and shear the sheep!</li>
     <li>Make hay while the sun shines: take part in seasonal and daily in-game events to gain prizes and become the best farmers!</li>
     <li>Explore diamond mines below the farm! The Golden rush is contagious!</li>
     <li>Produce and trade all sorts of your fazenda goods: from dairy to jewelry!</li>
     <li>Challenge other farmers at the Fair of Achievements!</li>
     <li>Create your own farm community to achieve better results!</li>
     <li>Manage a top grade delivery service: by car, train or even airship!</li>
     <li>Color up your farm! Choose from tons of furniture, decor and flower items to make your funky mansion look trendy!</li>
     <li>Equip an expedition and travel to a tropical island: exotic animals are waiting!</li>
     <li>Run a Zoo and an Amusement park and decorate them to your taste!</li>
     <li>Bring some cute pets from the island and fill your meadows with life!</li>
     </ul>
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 0,
    title: "Drive Mad 2 - playcutegames.com",
    name: ["Drive Mad 2", "drive-mad-2"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/drive-mad-2/250x142.png",
    path: ["/game/drive-mad-2", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/254924?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video: "",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Drive Mad 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `
    Drive Mad 2 is an exciting and challenging car game that tests the player's driving skills and agility. The game features a series of levels, each with its own unique track filled with obstacles and stunts. The objective of the game is to drive your car to the finish line while avoiding obstacles and performing stunts.
    <br><br>
    Players must use their driving skills to navigate the track, avoid obstacles, and maintain control of their car. The game also includes exciting stunts such as jumps, loops, and flips, which add an extra layer of challenge and excitement to the game.
    <br><br>
    As the player progresses through the levels, the difficulty increases, and the obstacles become more challenging to avoid. Players must also balance their speed and maneuverability to avoid crashing or flipping their car.
    <br><br>
    Overall, Drive Mad is a fun and challenging game that will test your driving skills and reflexes.
    `,
    tag: ["Arcade","Boys","Car",],
    hint: `
    
    <ul>
     <li>You have to balance your speed so that your car does not overturn.</li>
     <li>Touch the screen to play and control the car.</li>
     <li>Complete the current level and then move to the next one.</li>
     </ul>
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 0,
    title: "Battle of the red and blue agents - playcutegames.com",
    name: ["Battle of the red and blue agents", "battle-of-the-red-and-blue-agents"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/battle-of-the-red-and-blue-agents/250x142.png",
    path: ["/game/battle-of-the-red-and-blue-agents", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/283309?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video: "",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Battle of the red and blue agents Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `
    Battle of the red and blue agents" is a game in which you will have to take part in a duel with your opponent. Choose from a variety of locations and weapons to fight not for life, but for death. The game is perfect to brighten up your free time, and spend it fun, while participating in a duel on scissors or sledgehammers, for example. Get a lot of positive emotions from spending time in this game.
    
    <h2 class="Sub_Heading">Game features:</h2>
    <ul>
    <li>Is a realistic fight-duel</li>
    <li>Simple but interesting game</li>
    <li>Large variety of locations in the game</li>
    <li>Has a simple and easy gameplay</li>
    <li>Great for your leisure time</li>
    </ul>
    `,
    tag: ["Arcade","Boys","Car",],
    hint: `
    The game has three game modes: single player, multiplayer and survival. In single player mode you will face other stickmen in exciting battles, here the scene changes with every fight. To win the game, just defeat your opponent..... before he defeats you! On the other hand, two players can fight on the same device in multiplayer mode. While playing in this mode, two joysticks appear on mobile devices, each on opposite sides of the screen, and players use them to attack each other and try to defeat each other. Finally, there's a survival mode where you fight hordes of other stickmen and try to defeat as many of them as possible.
    <br><br>
    To control your character on mobile devices, there is a virtual joystick on the right side of the screen. There are no special action or attack buttons, instead your big guy automatically attacks in any direction you move the joystick.
    <br><br>
    For control on PC use "WASD" or arrows.
    

    `,
    rating:[4.9,350,18],
  },{
    date: "2024-10-10",
    brand: "best",
    orientation: 0,
    title: "Halloween Merge Mania - Merge Spooky Halloween",
    name: ["Halloween Merge Mania", "halloween-merge-mania"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/halloween-merge-mania/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/halloween-merge-mania/250x142.png",
    path: ["/game/halloween-merge-mania", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/halloween-merge-mania/",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Play Halloween Merge Mania, spooky puzzle game where you can merge 11 Halloween characters like pumpkins, bat. Can you reach the ultimate merge? Try it now!",
    l_dis:
    `Halloween is the season for thrills, chills, and of course, fun games! If you’re a fan of puzzle games with a spooky twist, <a href="/game/halloween-merge-mania">Halloween Merge Mania</a> is the perfect game for you. Inspired by the popular gameplay style of Suikagame, Halloween Merge Mania lets you merge creepy Halloween characters to unlock higher-level creatures. From classic pumpkins and bats to Frankenstein and Jack Skellington, this game is packed with eerie fun and excitement.
    <h2 class="Sub_Heading">Game Overview</h2>
    In Halloween Merge Mania, the objective is simple yet addictive: merge matching Halloween-themed objects to create new ones, and continue merging to unlock more powerful characters. The game features 11 iconic Halloween figures such as pumpkins, bats, mummies, Frankenstein, and more. The challenge is to keep merging without running out of space on the board!
    <br><br>
    With each merge, you create more advanced and spooky characters, pushing the limits of how far you can go. Can you discover all 11 characters and master the art of merging in this spooky Halloween adventure?
    `,
    tag: ["Halloween","Merge","2048"],
    hint: `
    <ol>
    <li><b>Merge Identical Objects:</b> You start with basic Halloween-themed items, like pumpkins. Combine two matching items to create a higher-level object.</li>
    <li><b>Unlock New Characters:</b> As you keep merging, you’ll unlock new and exciting Halloween characters such as bats, mummies, Frankenstein, and even Jack Skellington!</li>
    <li><b>Strategize Your Moves:</b> Be mindful of your limited board space. Keep merging efficiently to prevent the board from filling up, or you’ll run out of room!</li>
    <li><b>Aim for the Ultimate Merge:</b> The more you merge, the more challenging it becomes. Can you unlock the final spooky character and become the master of Halloween merges?</li>
    </ol>
    <h2 class="Sub_Heading">Features</h2>
    <ul>
    <li><b>11 Spooky Halloween Characters:</b> Merge classic Halloween figures such as pumpkins, bats, Frankenstein, mummies, Jack Skellington, and more!</li>
    <li><b>Addictive Puzzle Gameplay:/b> Inspired by the popular Suikagame, this merge game offers easy-to-learn mechanics but keeps you hooked with its strategic depth.</li>
    <li><b>Progressive Difficulty:</b> As you advance, the challenge increases as you aim to unlock all 11 characters while managing your limited board space.</li>
    <li><b>Perfect for Halloween Lovers:</b> The Halloween theme, complete with spooky characters and eerie visuals, makes it the ideal game to enjoy during the spooky season.</li>
    <li><b>Suitable for All Ages:</b> Whether you're a casual player or a seasoned puzzle fan, Halloween Merge Mania offers fun for both kids and adults.</li>
    </ul>
    <h2 class="Sub_Heading">Why You'll Love It</h2>
    Halloween Merge Mania combines the excitement of merging games with the festive atmosphere of Halloween. The thrill of unlocking new and spooky characters keeps you coming back for more, as you try to discover all 11 unique Halloween creatures. The game's simple yet challenging mechanics make it easy to pick up, but hard to put down. Plus, the Halloween theme adds a layer of fun that’s perfect for the season!
    <br><br>
    The game’s strategic element keeps you thinking about your next move, as you carefully merge characters while managing the limited space on the board. Each successful merge feels rewarding, and the anticipation of unlocking the final spooky character keeps you on the edge of your seat.
    <h2 class="Sub_Heading">Tips for Success</h2>
    <ul>
    <li><b>Plan Your Merges:</b> Don’t rush! Think a few moves ahead to avoid filling up the board too quickly.</li>
    <li><b>Aim for High-Level Characters: The higher the character level, the more space-efficient your board will be. Focus on unlocking the higher-level spooky creatures!</b> Whenever possible, try to merge in larger chains for a higher score and quicker progress.</li>
    <li><b>Aim for High-Level Characters:</b> The higher the character level, the more space-efficient your board will be. Focus on unlocking the higher-level spooky creatures!.</li>
    </ul>
    <h2 class="Sub_Heading">The Perfect Halloween Puzzle Experience</h2>
    Whether you’re a fan of puzzle games or simply looking for something fun to play during the Halloween season, Halloween Merge Mania is sure to keep you entertained. Its simple mechanics, combined with the spooky theme and progressively challenging gameplay, make it a must-play for anyone who enjoys Halloween fun and puzzle-solving excitement.
    <br><br>
    Are you ready to merge your way through the Halloween season? Start playing Halloween Merge Mania now and see how many spooky characters you can unlock!
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-10-10",
    brand: "best",
    orientation: 0,
    title: "Spooky Halloween Hidden Pumpkin - Find 10 Hidden Pumpkins",
    name: ["Spooky Halloween Hidden Pumpkin", "spooky-halloween-hidden-pumpkin"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/spooky-halloween-hidden-pumpkin/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/spooky-halloween-hidden-pumpkin/250x142.png",
    path: ["/game/spooky-halloween-hidden-pumpkin", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/spooky-halloween-hidden-pumpkin/",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Play Spooky Halloween Hidden Pumpkin, a thrilling game with 15 eerie levels! Find 10 hidden pumpkins in each level to complete the Halloween adventure.",
    l_dis:
    `As the air turns chilly and the night grows longer, Halloween brings a season of mystery and excitement. If you’re a fan of hidden object games and the Halloween spirit, Spooky Halloween Hidden Pumpkin is the perfect treat for you! Dive into a spine-chilling adventure across 15 spooky levels, where your task is to find 10 hidden pumpkins cleverly concealed in each scene. But beware—the eerie atmosphere and tricky hiding spots will test your observation skills!
    <h2 class="Sub_Heading">Game Overview</h2>
    In Spooky Halloween Hidden Pumpkin, you’ll explore haunted locations like creepy graveyards, abandoned houses, foggy forests, and more. Each level is uniquely designed with eerie Halloween-themed settings full of tricks and illusions. Your challenge is to locate 10 pumpkins hidden in plain sight within each scene. The faster you find them, the better your score!
    <br><br>
    This game is perfect for both kids and adults who love the thrill of a good hidden object puzzle combined with the excitement of Halloween. With each level, the difficulty increases as the pumpkins become harder to find, making it a captivating experience for puzzle enthusiasts of all ages.
    `,
    tag: ["Hidden","Halloween","Skill"],
    hint: `
    <ul>
    <li><b>Explore Each Scene:</b> Every level introduces a new Halloween-themed location full of details. Use your sharp eyes to scan the scene and uncover the pumpkins.</li>
    <li><b>Find the Hidden Pumpkins:</b> There are 10 pumpkins hidden in each level. They may be cleverly disguised among other spooky elements, so pay close attention!</li>
    <li><b>Beat the Clock:</b> Each level is timed, adding an extra layer of challenge. The faster you find all the pumpkins, the higher your score will be.</li>
    <li><b>Advance Through 15 Levels:</b> As you progress, each level gets more intricate, with pumpkins hidden in more difficult spots. Can you find them all and complete the spooky challenge?</li>
    </ul>
    <h2 class="Sub_Heading">Features</h2>
    <ul>
    <li><b>15 Unique Halloween Levels:</b> Explore eerie, Halloween-inspired locations such as haunted houses, dark forests, and mysterious cemeteries.</li>
    <li><b>Increasing Difficulty:</b> Each level brings more challenging hiding spots for the pumpkins, making the game progressively harder and more fun.</li>
    <li><b>Perfect for Halloween Fans:</b> The spooky atmosphere, combined with the joy of finding hidden objects, makes this game perfect for getting into the Halloween spirit.</li>
    <li><b>For All Ages:</b> Easy enough for kids to enjoy but challenging enough to keep adults entertained, this game offers fun for the whole family.</li>
    </ul>
    <h2 class="Sub_Heading">Why You'll Love It</h2>
    <a href="/game/spooky-halloween-hidden-pumpkin">Spooky Halloween Hidden Pumpkin</a> brings together the excitement of Halloween with the satisfaction of finding hidden objects. Whether you’re looking for a relaxing game to unwind with or a fun challenge to test your observation skills, this game is sure to keep you hooked. The Halloween-themed settings and increasing difficulty make it perfect for players who enjoy hidden object games with a festive twist.
    <br><br>
    Are you ready to embrace the spooky fun and find all the hidden pumpkins? Start your Halloween adventure today with Spooky Halloween Hidden Pumpkin!
    `,
    rating:[4.4,1210,114],  
  },
  
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 0,
    title: "Battle Arena: RPG online - playcutegames.com",
    name: ["Battle Arena: RPG online", "battle-arena-rpg-online"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/battle-arena-rpg-online/250x142.png",
    path: ["/game/battle-arena-rpg-online", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/184531?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video: "",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Battle Arena: RPG online Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Battle Arena is an incredible combination of RPG and MOBA: a mobile game in the action/tactical RPG genre. Test your abilities as a commander and create an unbeatable team of heroes! Defeat opponents in online PvP battles and destroy hordes of them in captivating missions.
     <h2 class="Sub_Heading">Game features:</h2>
     <ul>
     <li>Real-time PvP battles!</li>
     <li>Challenge players around the world. Train and enter the battle Arena</li>
     <li>defeat opponents and become the best of the best</li>
     </ul>
     <h2 class="Sub_Heading">Create a team!</h2>
      <ul>
     <li>Dive into this incredible role-playing game and choose from over 80 different heroes.</li>
     <li>defeat opponents and become the best of the best</li>
     </ul>
     <h2 class="Sub_Heading">Enjoy the beautiful graphics!</h2>
     Over 60 amazing locations: Elven Forests, Burning Lands of the orcs, chasms deeper and more beautiful than the Grand Canyon, a floating Arena, and the Cold Rocks of the gnomes.
     <h2 class="Sub_Heading">Play in teams!</h2>
     Play online with friends! Create an alliance with other players or join an existing one. Trade heroes and equipment, chat with friends, share tactics, and take part in friendly fights.
    `,
    tag: ["Rpg","Midcore",],
    hint: `
    Battle Arena - test your abilities as a commander and create an unbeatable team of heroes!
     <h2 class="Sub_Heading">Real-time battles:</h2>
    <ul>
     <li>You'll love training and developing heroes to get a team that dominates in PvP battles.</li>
     <li>You must make the right decisions at the right moment.</li>
     <li>Every hero has a unique set of skills that you can perfect gradually.</li>
     <li>Make combinations of heroes by testing their mettle in the Arena and on missions to create a perfectly-balanced squad.</li>
     <li>A wide variety of attacks: snowstorms, fireballs, poison arrows or rockets, blasters, and blinding grenades.</li>
     <li>Choose different amazing hero skills from different universes and every PvP battle will be unique!</li>
     </ul>
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 1,
    title: "Ball Sort Puzzle - Color Sort - playcutegames.com",
    name: ["Ball Sort Puzzle - Color Sort", "ball-sort-puzzle-color-sort"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/ball-sort-puzzle-color-sort/250x142.png",
    path: ["/game/ball-sort-puzzle-color-sort", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/193301?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Ball Sort Puzzle - Color Sort Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Ball Sort Puzzle - Color Sort is a challenging puzzle game that will keep you entertained for hours! Sort the colored balls in the tubes until all of the same-hued balls are in the same tube. A brain-teasing game that is both tough and relaxing! 
    <h2 class=Sub_Heading>FEATURES: </h2>
    <ul>
    <li>Control with just one finger. </li>
    <li>Multiple unique levels. </li>
    <li>Completely free and easy to play. </li>
    <li>Use hints for the best move to solve the puzzle. </li>
    <li>There are no penalties or time limits, so you can play Ball Sort Puzzle </li>
    <li>Color Sort whenever you want!</li>
    </ul>
    `,
    tag: ["Sort","Puzzles","Casual"],
    hint: `
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 0,
    title: "Zombotron Re-Boot - playcutegames.com",
    name: ["Zombotron Re-Boot", "zombotron-re-boot"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/zombotron-re-boot/250x142.png",
    path: ["/game/zombotron-re-boot", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/290564?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Zombotron Re-Boot Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Fight with zombies, with evil robots and other undead creatures to survive in the crazy world of Zombotron Re-Boot. The action takes place on an unknown one day colonized planet, which over time was abandoned and forgotten by people. Find and rescue survivals to discover the mystery of the mysterious planet together.
    <br><br>
    Zombotron Re-Boot is a remaster of the original Zombotron Flash game series, with updated graphics, an improved physics engine and new, incredibly rich effects!
    <h2 class="Sub_Heading">Key Features:</h2>
    <ul>
    <li>Well-developed destructible physical world;</li>
    <li>Lots of different weapons;</li>
    <li>You can destroy enemies using the environment;</li>
    <li>Various enemies with battle-of-the-red-and-blue-agents abilities;</li>
    <li>Use the gamepad and headphones for best experience.</li>
    </ul>
    `,
    tag: ["Shooting","Action","Halloween","Boys"],
    hint: `Clear game locations from enemies, explore and find secrets to get new types of weapons.
    <ul>
    <li>Control the hero, aim and shoot at enemies;</li>
    <li>Interact with the environment to activate interactive objects;</li>
    <li>Discover the levels to find new weapons and keep track of your ammunition;</li>
    <li>Just take a break for healing - the hero is healed automatically.</li>
    </ul>
    `,
    rating:[4.9,350,18],
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 1,
    title: "Match Arena - playcutegames.com",
    name: ["Match Arena", "match-arena"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/match-arena/250x142.png",
    path: ["/game/match-arena", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/96787?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Match Arena Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Welcome to the Arena! The first "three in a row" game with real opponents! A completely new approach to three-in-a-row games. You will definitely like it! Exciting gameplay: shuffle and match chips, find new friends on an exciting and adventurous path! Show all your skills and abilities in the best game of the genre "three in a row"! Challenge your friends in real time and win through hundreds of unique levels with tricky puzzles and gorgeous effects! New exciting modes and amazing experiences. Complete exciting challenges on your way to the Golden League Match Arena! Charming piggy will accompany you on an exciting and challenging journey. The taste of victory in Match Arena is sweeter than any candy!`,
    tag: ["Puzzle","Match3","Skill","Kids","Crazy games"],
    hint: `Match 3 pieces of the same color by swapping adjacent ones!`,
    rating:[4.9,350,18],
  },
  {
    date: "2022-12-16",
    brand:"Poki games",
    orientation: 0,
    title: "Play Hilarious Monkey Mart Game Online at Playcutegames",
    name: ["Monkey Mart", "monkey-mart"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/monkey-mart/250x142.png",
    path: ["/game/monkey-mart", "https://playcutegames.com"],
    iframe:
      "https://monkey-mart.io/iframe/index.html",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/monkey-mart.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Monkey Mart is an addictive online simulation game With challenging gameplay and cute, cartoon graphics, Monkey Mart is sure to provide hours of fun.",
    l_dis:`<a href="/game/monkey-mart">Monkey Mart</a> is an idle/management game made by TinyDobbins. You control a cute monkey character that just created their supermarket. Plant fruits, move around from station to station to fill the stands with bananas or corn, or more. Clients will pick them up and wait for you at the cashier desk. You can upgrade your character, unlock new work stations, or even recruit other workers to help you manage the store !`,
    tag: ["Poki","Managment","Simulation","Shopping","Animal","Arcade"],
    hint: `Move your player monkey inside the mart - Use W A S D or Arrow keys or Drag.`,
    rating:[4.9,350,18],
    platform:"both",
  },
  {
    date: "2022-11-01",
    brand: "Yandex",
    orientation: 1,
    title: "Skydom - playcutegames.com",
    name: ["Skydom", "skydom"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/skydom/250x142.png",
    path: ["/game/skydom", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/96786?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Skydom Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    "Welcome to the magical high kingdoms! Skydom is a bright and exciting puzzle with truly unique game modes! Went through thousands of different match 3 levels and want something refreshing? Have a look inside...only in Skydom you can face off with different players to determine the best at match 3! Show off your skill against real opponents or connect with friends in live Match 3 action on hundreds of levels with unique settings, gorgeous effects and unexpected turns.",
    tag: ["Puzzle","Match3","Skill","Kids","Crazy games"],
    hint: `Match 3 pieces of the same color by swapping adjacent ones!`,
    rating:[4.9,350,18],
  },
  {
    date: "2024-03-05",
    brand: "Nes",
    orientation: 0,
    title: "Super Mario Bros - playcutegames.com",
    name: ["Super Mario Bros", "super-mario-bros"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/super-mario-bros/250x142.png",
    path: ["/game/super-mario-bros", "https://playcutegames.com"],
    iframe: "https://www.retrogames.cc/embed/21929-super-mario-bros-europe-rev-0a.html",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/super-mario-bros.mp4",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Super Mario Bros Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `
      <h2 class="Sub_Heading">Jump, Run, and Conquer!</h2>
      Super Mario Bros., a legendary Nintendo title, has captivated gamers for decades with its simple yet addictive gameplay. As the iconic plumber, Mario, your mission is to navigate through treacherous worlds, defeat enemies, and rescue Princess Peach from the clutches of Bowser.
     
      `,
      tag: ["Mario","Nes","Platformer"],
    hint: ` <h2 class="Sub_Heading">Key Controls:</h2>
      <ul>
      <li><b>Movement:</b> Use the directional pad to move left, right, up, and down.</li>
      <li><b>Jumping:</b> Press the jump button to leap over obstacles and enemies.</li>
      <li><b>Running:</b> Hold the run button to move faster.</li>
      <li><b>Crouching:</b> Press down to crouch under obstacles.</li>
      </ul>
       <h2 class="Sub_Heading">Power-Ups:</h2>
      <ul>
      <li><b>Red Mushroom:</b>Grows Mario to Super Mario size.</li>
      <li><b>Fire Flower:</b>Gives Mario the ability to throw fireballs.</li>
      <li><b>Green Mushroom:</b>Grants an extra life.</li>
      </ul>
       <h2 class="Sub_Heading">Hidden Secrets:</h2>
      <ul>
      <li>Level Skipping: Discover hidden pipes and blocks to access later worlds without completing earlier ones.</li>
      <li>Time Trials: Race against the clock to achieve faster completion times and unlock bonuses.</li>
      </ul>
       <h2 class="Sub_Heading">A Legacy of Fun</h2>
       Super Mario Bros. has left an indelible mark on the gaming industry. Its innovative level design, memorable characters, and catchy soundtrack have made it a timeless classic. Whether you're a seasoned gamer or new to the series, this iconic title offers endless hours of fun and adventure.
       <br><br>
       Ready to embark on your Mario adventure?

      `,
    rating:[4.4,1210,114],  
  }, {
    date: "2022-08-04",
    brand: "Yandex",
   
    orientation: 0,
    title: "Time Shooter 2 - playcutegames.com",
    name: ["Time Shooter 2", "time-shooter-2"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/time-shooter-2/250x142.png",
    path: ["/game/time-shooter-2", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/182594?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/time-shooter-2.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Time Shooter 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Time Shooter 2 is a first-person shooter where time stands still until you move. Plan your shots and moves carefully. Dodge enemy fire in epic slo-mo combat and make your way around the map using time to your advantage.
      <h2 class="Sub_Heading">Features:<h2>
      <ul>
      <li>unique gameplay</li>
      <li>a variety of weapons</li>
      <li>minimalistic graphics</li>
      <li>interactive items</li>
      <li>realistic physics of damage to enemies</li>
      </ul>
      `,
    tag: ["Crazy games","Shooting","Action","Adventure"],
    hint: `Walk around with the miner to collect as much gold as you can. <br><br>
    For one level only, you can also receive endless ammo or invulnerability by watching a short ad.
    
    <ul>
      <li>Left Mouse Button - pick up / shoot weapon</li>
      <li>Right Mouse Button - throw weapon</li>
      <li>WASD - movement</li>
      <li>interactive items</li>
      <li>Speed up time by moving.</li>
      </ul>
    
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-03-05",
    brand: "Miniplay",
    orientation: 0,
    title: "Plants Vs Zombies - playcutegames.com",
    name: ["Plants Vs Zombies", "plants-vs-zombies"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/plants-vs-zombies/250x142.png",
    path: ["/game/plants-vs-zombies", "https://playcutegames.com"],
    iframe: "https://ext.minijuegosgratis.com/pvz-modded/game/index.html",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Plants Vs Zombies Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Plants vs. Zombies Online is a real-time strategy game that pits a horde of undead against a botanical army. Players must strategically plant defensive plants to thwart the zombie invasion and protect their home. This popular online game offers a unique blend of tower defense and card collecting elements, making it a captivating experience for gamers of all ages.
      
      `,
      tag: ["Merge","Addictive"],
    hint: ` 
      <ul>
      <li><b>Plant Selection:</b>Players choose from a vast array of plants, each with distinct abilities and strengths. From the classic Peashooter to the powerful Chomper, there's a plant for every situation.</li>
      <li><b>Zombie Horde:</b>Zombies come in various types, each with unique characteristics and attack patterns. From the basic zombie to the elusive Gargantuar, players must be prepared to face a diverse undead threat.</li>
      <li><b>Level Progression:</b>As players advance through the game, they unlock new plants and zombies, increasing the complexity and challenge of each level.</li>
      <li><b>Multiplayer Battles:</b>Engage in competitive multiplayer matches against other players, showcasing your strategic skills and plant combinations.</li>
      <li><b>Card Collection:</b>Collect cards representing different plants and zombies to build your deck and customize your gameplay experience.</li>
      </ul>
      <div class="Empty"></div>
      <h2 class="Sub_Heading">Plants vs. Zombies</h2>
      Plants vs. Zombies is a popular tower defense video game developed by PopCap Games. The game pits a horde of undead against a botanical army, challenging players to strategically plant defensive plants to thwart the zombie invasion and protect their home. With its unique blend of humor, addictive gameplay, and colorful art style, Plants vs. Zombies has captured the hearts of gamers worldwide.
      <h2 class="Sub_Heading">A Brief History</h2>
      The original Plants vs. Zombies was released in 2009 for personal computers and consoles. Its success spawned a series of sequels and spin-offs, including Plants vs. Zombies 2: It's About Time, Plants vs. Zombies Garden Warfare, and Plants vs. Zombies Heroes. Each installment introduced new plants, zombies, and gameplay mechanics, expanding the Plants vs. Zombies universe.
     <h2 class="Sub_Heading">Why Play Plants vs. Zombies?</h2>
     <ol>
     <li><b>Addictive Gameplay:</b>The game's simple yet strategic gameplay is easy to learn but difficult to master. Players must carefully choose and place plants to defend against the relentless zombie onslaught.</li>
     <li><b>Unique Characters:</b>The game features a diverse cast of quirky plants and zombies, each with their own unique abilities and personalities.</li>
     <li><b>Humor and Charm:</b>Plants vs. Zombies is filled with humor and charm, from the witty dialogue to the comical animations.</li>
     <li><b>Endless Replayability:</b>With numerous levels, challenges, and unlockables, Plants vs. Zombies offers endless hours of entertainment.</li>
     </ol>
      <h2 class="Sub_Heading">Key Features of Plants vs. Zombies:</h2>
      <ul>
      <li><b>Strategic Gameplay:</b>Carefully plant defensive plants to block zombie advances.</li>
      <li><b>Diverse Plants:</b>Choose from a wide variety of plants with different abilities, such as shooting peas, chomping zombies, and producing sunflowers.</li>
      <li><b>Challenging Zombies:</b>Face off against a variety of zombies, including basic zombies, bucketheads, and gargantuars.</li>
      <li><b>Power-Ups:</b>Collect power-ups to boost your plants' abilities or gain temporary advantages.</li>
      </ul>

     <h2 class="Sub_Heading">Conclusion</h2>
      Plants vs. Zombies is a timeless classic that continues to captivate players of all ages. With its addictive gameplay, charming characters, and endless replayability, it's no wonder that this game has become a beloved franchise. Whether you're a casual gamer or a strategy enthusiast, Plants vs. Zombies offers a fun and engaging experience that will keep you coming back for more.
     
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "Yandex",
    orientation: 0,
    title: "Cute Monsters - playcutegames.com",
    name: ["Cute Monsters", "cute-monsters"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cute-monsters/250x142.png",
    path: ["/game/cute-monsters", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/367661?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Cute Monsters Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Immerse yourself in an exciting game world where you will find evil monsters, incredible leveling opportunities, as well as a huge number of unique and cute creatures. Show everyone your skills in creating stronger monsters in "Cute Monsters"!`,
      tag: ["Merge","Addictive"],
    hint: `Click on the monsters to earn the elixir. Combine two identical monsters to get a stronger monster that will give more elixir`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "best",
    orientation: 1,
    title: "Spooky Halloween Jigsaw Puzzle - playcutegames.com",
    name: ["Spooky Halloween Jigsaw Puzzle", "spooky-halloween-jigsaw-puzzle"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/spooky-halloween-jigsaw-puzzle/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/spooky-halloween-jigsaw-puzzle/250x142.png",
    path: ["/game/spooky-halloween-jigsaw-puzzle", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/spooky-halloween-jigsaw-puzzle/",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Spooky Halloween Jigsaw Puzzle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Spooky Halloween Jigsaw Puzzle offers a thrilling challenge for puzzle enthusiasts with a Halloween twist. Piece together hauntingly beautiful images featuring ghosts, witches, pumpkins, and eerie nightscapes. Choose from various difficulty levels to suit your skill, and enjoy the spooky atmosphere as you complete each puzzle. Perfect for getting into the Halloween spirit, this game provides hours of fun and entertainment for all ages!
    <h2 class="Sub_Heading">Spooky Halloween Jigsaw Puzzle: A Hauntingly Good Time</h2>
    Are you ready for a spooky challenge? The Spooky Halloween Jigsaw Puzzle offers a thrilling experience for puzzle enthusiasts of all ages. With its hauntingly beautiful images and varying difficulty levels, this game is perfect for getting into the Halloween spirit.
    <h2 class="Sub_Heading">What You'll Find:</h2>
    <ul>
    <li><b>Spooky Scenes:</b> Piece together images featuring ghosts, witches, pumpkins, and eerie nightscapes.</li>
    <li><b>Multiple Difficulty Levels:</b> Choose from easy, medium, and hard levels to find the perfect challenge for you.</li>
    <li><b>15 Levels of Spooky Fun:</b> Enjoy 15 unique puzzles, each with its own spooky charm.</li>
    <li><b>Hours of Entertainment:</b> The Spooky Halloween Jigsaw Puzzle offers endless hours of fun and relaxation.</li>
    </ul>
    <h2 class="Sub_Heading">Perfect for Halloween:</h2>
    Whether you're looking for a fun family activity or a solo challenge, the Spooky Halloween Jigsaw Puzzle is the perfect way to get into the Halloween spirit. Gather your loved ones, turn off the lights, and enjoy the spooky atmosphere as you piece together these haunting images.
    <br><br>
    Are you ready to take on the challenge? Grab your puzzle pieces and get ready for a frightfully good time with the Spooky Halloween Jigsaw Puzzle!
    `,
    tag: ["Halloween","Jigsaw","Puzzle"],
    hint: `
    <ul>
    <li><b>Find the Edges:</b>Begin by identifying the edge pieces. These pieces have one or more sides that are not connected to other pieces.</li>
    <li><b>Assemble the Borders:</b>Start building the borders of the puzzle by connecting the edge pieces together.</li>
    <li><b>Fill in the Center:</b>Once you have the borders in place, start filling in the center of the puzzle. Look for pieces that fit together based on their shapes and patterns.</li>
    <li><b>Use Hints:</b>If you're stuck, you can use hints to get a clue about where a particular piece might go.</li>
    <li><b>Celebrate Your Victory:</b>Once you've successfully completed the puzzle, you'll be rewarded with a spooky message and a sense of accomplishment!</li>
    </ul>
    <br>
    <b>Enjoy the Spooky Fun:</b> The Spooky Halloween Jigsaw Puzzle offers a thrilling and challenging experience. With its hauntingly beautiful images and varying difficulty levels, it's perfect for puzzle enthusiasts of all ages. So, gather your loved ones, turn off the lights, and enjoy the spooky atmosphere as you piece together these terrifying puzzles!`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "best",
    orientation: 0,
    title: "Devilish Hairdresser - playcutegames.com",
    name: ["Devilish Hairdresser", "devilish-hairdresser"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/devilish-hairdresser/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/devilish-hairdresser/250x142.png",
    path: ["/game/devilish-hairdresser", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/devilish-hairdresser/",
    Walkthrough: "https://youtu.be/",
    s_dis:
    "Devilish Hairdresser Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `In the Devilish Hairdresser game, you get to be a mischievous hairstylist and create the wildest and craziest haircuts ever! You’ll be up against an angelic hairdresser, and your goal is to cut and dye your client’s hair in your own unique devilish style. With each haircut, you’ll become more devilish, but you need to be careful not to get caught by the angel. Earn stars for your devilish haircuts and unlock new trophies to become a super devil! Start choosing a photo from the album and get to work. Just use your mouse to click and hold on the hair to cut and dye it in your devilish style. The longer you hold down the mouse button, the more devilish you become. However, you need to keep an eye on the angelic hairdresser who will be looking out for any mischievous behavior. If she catches you, you’ll be sent to Angel Prison.
    <h2 class="Sub_Heading">The Devilish Hairdresser: Unleash Your Inner Mischief</h2>
    Are you ready to embrace your inner devil? The Devilish Hairdresser game invites you to step into the shoes of a mischievous stylist, where your goal is to create the wildest, most outrageous haircuts imaginable. But beware, the angelic hairdresser is watching your every move!
    `,
    tag: ["Slacking","Hair","Skill"],
    hint: `
    <ol>
    <li><b>Choose Your Client:</b> Start by selecting a photo from your album to serve as your canvas.</li>
    <li><b>Cut and Dye with Devilish Flair:</b> Use your mouse to click and hold on the hair, then drag to cut and dye it in your desired style. The longer you hold the mouse button, the more devilish your actions become.</li>
    <li><b>Evade the Angelic Watch:</b> Keep a watchful eye on the angelic hairdresser. If she catches you being too mischievous, you'll be sent to Angel Prison!</li>
    <li><b>Aim for Devilish Perfection:</b> The longer you can hold the mouse button without getting caught, the higher your devilishness level will rise.</li>
    </ol>
    <h2 class="Sub_Heading">What You'll Discover:</h2>
    <ul>
    <li>Unlock New Levels and Trophies: As you progress, you'll unlock new levels and earn trophies for your devilish creations.</li>
    <li>Earn Stars and Become a Super Devil: The more outrageous your haircuts, the more stars you'll earn. Collect enough stars to become a super devil!</li>
    <li>Face Increasing Challenges: Each level brings new challenges and obstacles, testing your skills and creativity.</li>
    </ul>
    <h2 class="Sub_Heading">Let Your Imagination Run Wild:</h2>

    With The Devilish Hairdresser, the possibilities are endless. Experiment with different hair lengths, colors, and styles. Create intricate designs, outrageous mohawks, or even hair sculptures. The only limit is your imagination!
    <br><br>Are you ready to unleash your inner devil and become the ultimate mischievous hairdresser? Start playing today and discover the joy of creating the wildest haircuts imaginable!`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "best",
    orientation: 1,
    title: "Find The Correct Shadow - playcutegames.com",
    name: ["Find The Correct Shadow", "find-the-correct-shadow"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/find-the-correct-shadow/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/find-the-correct-shadow/250x142.png",
    path: ["/game/find-the-correct-shadow", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bestgamespot/find-the-correct-shadow/",
    Walkthrough: "https://youtu.be/MXTXN1pCwEo",
    s_dis:
    "Find The Correct Shadow Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
    `Find The Correct Shadow is a fun and educational puzzle game designed to challenge your visual skills! In each round, you'll be presented with a picture and three shadow options. Only one of the shadows matches the picture perfectly—can you find the correct one?
<br><br>
With three exciting game modes to choose from:<br>
<ul>
<li><b>Streak Mode:</b> Test how long you can maintain a perfect streak of correct answers!</li>
<li><b>Timed Mode:</b> Race against the clock and see how many correct shadows you can find in a limited time!</li>
<li><b>Learning Mode:</b> A relaxed mode designed to help you practice and improve your visual recognition skills.</li>
</ul>`,
    tag: ["Clicker","Skill","Puzzle","Endless","Exclusive"],
    hint: `
    <div class="Pad"> </div>
    <b>Start the Game:</b> Select your preferred language from the options available (English, Spanish, Turkish, Portuguese, Russian, or German).<br><br>
    <b>Choose a Mode:</b>
    <ul>
    <li>Streak Mode: Try to select the correct shadow as many times in a row as possible without making a mistake.</li>
    <li>Timed Mode: Find the correct shadows within the given time limit and score as high as you can.</li>
    <li>Learning Mode: Play at your own pace, with no pressure, perfect for practicing and learning.</li>
    </ul>
    <b>Gameplay:</b>
    <ul>
    <li>A picture will appear at the top of the screen.</li>
    <li>Below the picture, you will see three shadow options.</li>
    <li>Only one of the shadows matches the picture exactly. Tap or click on the correct shadow.</li>
    </ul>
    <b>Scoring:</b>
    <ul>
    <li>Each correct answer earns you points or adds to your streak.</li>
    <li>In timed mode, try to find as many correct shadows as you can before the timer runs out.</li>
    </ul>
    <b>Winning the Game:</b> Keep selecting the correct shadows to earn high scores, maintain streaks, or practice in learning mode to improve!
    `,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "Yandex",
    orientation: 1,
    title: "Art Salon – Make-up and Nails - playcutegames.com",
    name: ["Art Salon – Make-up and Nails", "art-salon-make-up-and-nails"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/art-salon-make-up-and-nails/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/art-salon-make-up-and-nails/250x142.png",
    path: ["/game/art-salon-make-up-and-nails", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/285558?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://youtu.be/MXTXN1pCwEo",
    s_dis:
    "Art Salon – Make-up and Nails Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to Art Salon – Make-up and Nails! Oh, no, girl, how did you even get here, you can’t just walk around with a face like this? Let’s help this poor thing out – with a skincare routine one could only dream of! Sparkly and soothing mask, deep clean for those pesky blackheads… Treat yourself to a relaxing ASMR experience with a creative exercise on the side!",
      tag: ["3D","Nail","ASMR","Manicure","Girl games","Avatar"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "Yandex",
    orientation: 0,
    title: "Gacha Club - playcutegames.com",
    name: ["Gacha Club", "gacha-club"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/gacha-club/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/gacha-club/250x142.png",
    path: ["/game/gacha-club", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/285558?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://youtu.be/MXTXN1pCwEo",
    s_dis:
    "Gacha Club Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to Gacha Club! Bring the latest anime fashion trends to life by dressing your characters in stylish outfits! You will find a huge selection of clothes, weapons, hats and much more in our gacha wardrobe. Now you have the opportunity to create up to 10 characters and give each of them a unique look! Perfect your look! Experiment with different hairstyles, eyes, mouths and more to create the most memorable and stylish look for each character.",
      tag: ["Anime","Dress up","Gocha","Girl games","Avatar"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "Yandex",
    orientation: 0,
    title: "Fish Eat Getting Big - playcutegames.com",
    name: ["Fish Eat Getting Big", "fish-eat-getting-big"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fish-eat-getting-big/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fish-eat-getting-big/250x142.png",
    path: ["/game/fish-eat-getting-big", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/196487?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "https://youtu.be/MXTXN1pCwEo",
    s_dis:
    "Fish Eat Getting Big Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to Fish Eat Getting Big. Many fishes and various fish types in the huge ocean are waiting for you. Try to hunt smaller fishes which are specified with their numbers and try to reach the biggest size. Each level will bring you some coins and you will be able to buy new fish types from the market. You can also dive into different oceans and keep your adventure in different places.",
      tag: ["Fish","Skill","Survivor"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-03-05",
    brand: "playcutegames",
    orientation: 0,
    title: "My Musical Love Story - playcutegames.com",
    name: ["My Musical Love Story", "my-musical-love-story"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-musical-love-story/230x131.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/my-musical-love-story/520x295.png",
    path: ["/game/my-musical-love-story", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/my-musical-love-story/",
    Walkthrough: "https://www.youtube.com/embed/pdiHTYE8lXA",
    s_dis:
    "My Musical Love Story Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Dive into ‘My Musical Love Story,’ an interactive romance game where melodies meet emotions. Navigate your character’s journey through love, heartbreak, and the rhythm of music. Make choices, unlock chapters, and let the music of passion guide you. Immerse yourself in a symphony of emotions as you create your own love story. Will it be a harmonious melody or a poignant tune?",
      tag: ["Love","Valentine","Dress up","Exclusive","Music","Story","Role","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2023-10-10",
    brand: "playcutegames",
   
    orientation: 0,
    title: "BFFs Unique Halloween Costumes - playcutegames.com",
    name: ["BFFs Unique Halloween Costumes", "bffs-unique-halloween-costumes"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-unique-halloween-costumes/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-unique-halloween-costumes-300.jpg",
    path: ["/game/bffs-unique-halloween-costumes", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/bffs-unique-halloween-costumes/",
    Walkthrough: "https://www.youtube.com/embed/9hQ3K5AVjpk",
    s_dis:
    "BFFs Unique Halloween Costumes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Join the BFFs Unique Halloween Costumes adventure! These best friends are on a mission to find the most creative and distinctive Halloween outfits. Get ready for a one-of-a-kind Halloween experience as they plan, choose, and showcase their unique costumes. Unleash your creativity and celebrate Halloween like never before with the trendiest and most original looks!",
      tag: ["LOL Surprise","Dress up","Halloween","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },{
    date: "2024-03-05",
    brand: "playcutegames",
    orientation: 0,
    hot:"2x2",
    title: "Nightmare Couple Halloween Party - playcutegames.com",
    name: ["Nightmare Couple Halloween Party", "nightmare-couple-halloween-party"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/nightmare-couple-halloween-party/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/nightmare-couple-halloween-party/520x295.png",
    path: ["/game/nightmare-couple-halloween-party", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/nightmare-couple-halloween-party/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Nightmare Couple Halloween Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `
      Nightmare Couple Halloween Party is a spooky and stylish dress-up game where you can help the ultimate nightmare couple prepare for their eerie Halloween bash. Select from a variety of creepy costumes, haunting accessories, and makeup styles to give the couple their perfect spine-chilling look. Unleash your creativity and fashion sense to make this Halloween party an unforgettable one for the nightmare couple!
      <br><br>
      Are you ready to dive into the spooky world of Halloweentown and join Jack Skellington on a frightful fashion adventure? Our brand-new H5 Nightmare Couple Halloween 2D Dress-Up game invites you to create terrifyingly stylish outfits for Jack and Sally, the iconic couple from Tim Burton's beloved film, The Nightmare Before Christmas.
      <br> Unleash Your Inner Nightmare
      <h2 class="Sub_Heading">Customize Your Characters</h2>
       With a vast array of costumes, accessories, and makeup options, you can customize Jack and Sally to your heart's desire. From classic Halloween looks to more creative and outlandish designs, the possibilities are endless. Experiment with different combinations to create truly unique and terrifying outfits.
      <h2 class="Sub_Heading">Key Features:</h2>
       <ul>
       <li><b>Extensive Wardrobe:</b>Choose from a wide selection of costumes, including traditional Halloween attire, spooky monsters, and even whimsical characters from other Tim Burton films.</li>
       <li><b>Accessories Galore:</b>Add the finishing touches to your outfits with a variety of accessories, such as hats, masks, weapons, and more.</li>
       <li><b>Makeup Magic:</b>Apply different makeup styles to create a variety of spooky and sinister looks.</li>
       <li><b>Save and Share:</b>Save your favorite creations and share them with friends and family on social media.</li>
       </ul>
      <h2 class="Sub_Heading">Join the Halloween Fun</h2>
       Whether you're a fan of The Nightmare Before Christmas or simply love Halloween, our dress-up game offers a fun and creative way to celebrate the spooky season. So, gather your friends and family, fire up your device, and get ready to unleash your inner nightmare!
      
      `,
      tag: ["Love","Valentine","Dress up","Exclusive","Music","Story","Role","Girl games"],
    hint: `Use your left mouse button and keyboard 
    
    <div class="Empty"></div>
    <h2 class="Sub_Heading">The Nightmare Before Christmas</h2>
    The Nightmare Before Christmas is a beloved stop-motion animated musical film directed by Henry Selick and produced by Tim Burton. The film follows Jack Skellington, the "Pumpkin King" of Halloweentown, who becomes bored with the same old Halloween routine. He stumbles upon Christmas Town and becomes fascinated with the holiday, leading him to attempt to take over Christmas.
    <br><br>
    The film is known for its unique blend of Halloween and Christmas themes, as well as its memorable characters and catchy songs. It has become a classic for both children and adults and continues to be celebrated every holiday season.`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-04-25",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Fashion Intern A Journey into Style - playcutegames.com",
    name: ["Fashion Intern A Journey into Style", "fashion-intern-a-journey-into-style"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/230x131.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
    path: ["/game/fashion-intern-a-journey-into-style", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/fashion-intern-a-journey-into-style/",
    Walkthrough: "https://www.youtube.com/embed/WipPwffGnpw",
    s_dis:
    "Fashion Intern A Journey into Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Embark on a transformative journey into the world of style with Fashion Intern! From learning the ropes to curating chic ensembles, immerse yourself in the fast-paced, glamorous world of fashion. With each task, hone your skills and unlock new opportunities to shine. Are you ready to make your mark in the industry? Join Fashion Intern and let your style journey begin!",
      tag: ["Story","Dress up","Fashion","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },

  {
    date: "2024-02-17",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Lovie Chics In Fantasy World - playcutegames.com",
    name: ["Lovie Chics In Fantasy World", "lovie-chics-in-fantasy-world"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-in-fantasy-world/230x131.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-in-fantasy-world/520x295.png",
    path: ["/game/lovie-chics-in-fantasy-world", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/lovie-chics-in-fantasy-world/",
    Walkthrough: "https://www.youtube.com/embed/c_WAphzz5FE",
    s_dis:
    "Lovie Chics In Fantasy World Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Embark on a magical journey with ‘Lovie Chics In Fantasy World’! Explore enchanted realms, mythical styles, and whimsical accessories. Join the chic adventure as Lovie Chics set trends in a world of fantasy. Elevate your style with ethereal fashion, from fairy-inspired ensembles to magical accessories. Let the fantasy world inspire your wardrobe and make every day an enchanting fashion moment. Immerse yourself in the magic of fashion!",
      tag: ["Lovie Chic","Fantasy","Dress up","Exclusive","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],  
  },
  {
    date: "2024-08-12",
    brand: "playcutegames",
   
    orientation: 0, 
    title: "American Doll In Princess Style - playcutegames.com",
    name: ["American Doll In Princess Style", "american-doll-in-princess-style"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/american-doll-in-princess-style/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/american-doll-in-princess-style/520x295.png",
    path: ["/game/american-doll-in-princess-style", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/american-doll-in-princess-style/",
    Walkthrough: "https://www.youtube.com/embed/zIEzcNSn4ZM",
    s_dis:
    "American Doll In Princess Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Embark on a royal journey with “American Doll in Princess Style”! Dress up your beloved American Doll in enchanting princess outfits, complete with sparkling tiaras, elegant gowns, and magical accessories. Create a fairy tale look that will make your doll shine at any royal event. Let your creativity soar as you explore various styles, from classic princess attire to modern regal trends. Perfect for anyone who loves fashion, fantasy, and fun!",
      tag: ["American Girl","American Doll","Girl games","Dress up","Exclusive"],
    hint: `Use your left mouse button `,
    rating:[4.4,1210,114],  
  },
    {
      date: "2024-07-25",
      brand: "Yandex",
     
      orientation: 0, 
      title: "Cross Stitch Masters - playcutegames.com",
      name: ["Cross Stitch Masters", "cross-stitch-masters"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cross-stitch-masters/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cross-stitch-masters/520x295.png",
      path: ["/game/cross-stitch-masters", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/312008?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Cross Stitch Masters Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Immerse yourself in the world of cross stitching art and feel like a master of embroidery in Cross Stitch Masters! Color by number, relax, enjoy and have fun! Create fun and colorful pictures and share with your friends, easy and stress free.
  This game is for adults and kids any age who love cross-stitching, knitting, puzzles, nonograms, jigsaws, diamond paintings, mosaics and coloring books. Join us and unleash your creativity!
  Create a handmade gift and send it to your friends, family and loved ones for their birthday, celebration or without occasion, it’s that fun and easy!`,
      tag: ["Puzzle","Girl","Yandex"],
      hint: `Immerse yourself in the world of cross stitching and feel like a master of embroidery in Cross Stitch Masters! Color by number, relax, enjoy and have fun! Create fun and colorful pictures and share with your friends, easy and stress free. This game is for adults and kids any age who loves cross-stitching, knitting, puzzles, nonograms, jigsaws and coloring books. Join us and unleash your creativity!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-26",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Count And Tracing Number Games - playcutegames.com",
      name: ["Count And Tracing Number Games", "count-and-tracing-number-games"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/count-and-tracing-number-games/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/count-and-tracing-number-games/520x295.png",
      path: ["/game/count-and-tracing-number-games", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/count-and-tracing-number-games/",
      Walkthrough: "https://www.youtube.com/embed/BkOP4GwUSSo",
      s_dis:
      "Count And Tracing Number Games Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "“Count and Tracing Number Games” offers an interactive way for kids to learn numbers. This game combines fun and education, helping children to trace numbers, count objects, and recognize numerals. With vibrant graphics and engaging gameplay, kids can practice their math skills while enjoying a playful environment. Perfect for early learners, it fosters number recognition, fine motor skills, and counting abilities.",
        tag: ["Education","Kids","Math"],
      hint: `Use your left mouse button `,
      rating:[4.4,1210,114],  
    },  {
      date: "2024-08-12",
      brand: "playcutegames",
      orientation: 0, 
      title: "Celebrity Aesthetic Challenge - playcutegames.com",
      name: ["Celebrity Aesthetic Challenge", "celebrity-aesthetic-challenge"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-aesthetic-challenge/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-aesthetic-challenge/520x295.png",
      // video:"https://cdn.cutedressup.in/games/cutedressup-video/rivals-whos-gonna-be-my-girlfriend.mp4",
      path: ["/game/celebrity-aesthetic-challenge", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-aesthetic-challenge/",
      Walkthrough: "https://www.youtube.com/embed/Nsi2lAXAKDY",
      s_dis:
      "Celebrity Aesthetic Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Celebrity Aesthetic Challenge lets you explore a range of unique celebrity-inspired styles! Compete in fun fashion challenges as you mix and match outfits, accessories, and hairstyles to recreate iconic looks. From E-girl and Soft-girl vibes to edgy streetwear and glam red carpet ensembles, showcase your creativity and win the ultimate fashion battle. Can you rise to the challenge and become the trendsetting fashion icon? Let the style showdown begin!",
        tag: ["Celebrity","Girl games","Dress up","Exclusive"],
      hint: `Use your left mouse button `,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-07-25",
      brand: "Yandex",
     
      orientation: 1, 
      title: "Face Chart – Makeup Guru - playcutegames.com",
      name: ["Face Chart – Makeup Guru", "face-chart-makeup-guru"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/face-chart-makeup-guru/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/face-chart-makeup-guru/520x295.png",
      path: ["/game/face-chart-makeup-guru", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/327632?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Face Chart – Makeup Guru Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Do you dream of creating wild, fabulous looks like your favorite artists on social media? 💞<br><br>
  
  Dive into the enchanting realm of Face Chart - Makeup Guru! 💋<br><br>
  
  Wondering what a face chart is? It's your chic canvas to plan the next jaw-dropping, glamorous look. Grab a piece of paper and let it be your pocket-sized playground—experiment with new colors you hesitated to wear, pick and mix diverse designs for an endless array of possibilities.<br><br>
  
  Ready to transform a flat sketch into a charming summer makeover? Grab your brushes, unwind, and let your creativity bloom with Face Chart - Makeup Guru – an easy, DIY coloring book. Elevate the experience with soft ASMR sounds, turning up the volume for maximum relaxation.<br><br>
  
  Turn your artistic endeavors into likes, and those likes into vibrant designs for your future salon! Infuse lively colors into your lobby, revamp the kitchen!<br><br>
  
  Eager to embark on your ultimate fashion journey? Let's build the hype together 🎀🔥`,
      tag: ["Make Up","Girl","Yandex"],
      hint: ` Don't waste time: controls are easy and intuitive 💖<br>
   Explore a variety of inclusive options: diverse skin tones, a plethora of flattering colors, and on-trend looks 💖<br>
   Revel in never-ending fun: the possibilities are limitless, in perfect harmony with your creative spirit 💖<br>
   Share your creative work: with friends, your cherished makeup artists, and fellow fashion enthusiasts 💖<br>
   Take a break: whether painting on the go or sitting, let inspiration conquer the world. 💖<br>
   Change your pace: have a blast decorating your salon for added flair 💖<br><br>
  
  Indulge in a girl's bravest dream unleash your inner diva and be authentically yourself. Step into your dazzling avatar, choose bold beauty options, and make your mark in the ultimate fashion game. Become the super stylist, creating satisfying makeovers that merge fashion, beauty, and endless choices. It's not just a game; it's a super stylist's dream come true!`,
      rating:[4.4,1210,114],  
    },{
      date: "2024-03-05",
      brand: "playcutegames",
      hot:"2x2",
      orientation: 0,
      title: "My Pet Care Salon - playcutegames.com",
      name: ["My Pet Care Salon", "my-pet-care-salon"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-pet-care-salon/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/my-pet-care-salon/520x295.png",
      path: ["/game/my-pet-care-salon", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-pet-care-salon/",
      Walkthrough: "",
      s_dis:
      "Run your own pet spa in My Pet Care Salon! Groom, style, and pamper adorable pets with fun activities, manage your salon, and become the ultimate pet stylist!",
      l_dis:
        "My Pet Care Salon invites you to run your very own pet spa! Groom, pamper, and style a variety of adorable pets with fun, interactive activities. From bath time and nail trimming to trendy haircuts and accessory styling, provide the best care for your furry clients. Manage your salon, meet different pets, and earn rewards as you create a welcoming environment for pets and their owners. Transform your pet care dreams into reality and become the ultimate pet stylist!",
        tag: ["Cleaning","Salon","Animal","Exclusive","Doctor","Story","Role","kid"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-07-25",
      brand: "Yandex",
     
      orientation: 1, 
      title: "Merge Coin - playcutegames.com",
      name: ["Merge Coin", "merge-coin"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-coin/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/merge-coin/520x295.png",
      path: ["/game/merge-coin", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/272810?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Merge Coin Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "This is a simple and relaxing puzzle game. 10 coins of the same number can be upgraded to a higher number when put together. Let’s challenge to merge the largest numbers together.",
        tag: ["Merge","Puzzle","Casual"],
      hint: `Moved coins can only be placed in empty slots or after the same number. When 10 coins with the same number are put together, remember to click the merge button first. The game has two modes, one is to challenge the highest number, and the other is to arrange the same color. The game is very relaxing, come and experience it together`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-07-25",
      brand: "Yandex",
     
      orientation: 1, 
      title: "Draw Drive - playcutegames.com",
      name: ["Draw Drive", "draw-drive"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/draw-drive/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/draw-drive/520x295.png",
      path: ["/game/draw-drive", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/223500?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Draw Drive Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "You have to compete with rivals in the skill of drawing and speed. Think about which car is best suited for the passage of the track. And be first at the finish line.",
        tag: ["Race","Casual"],
      hint: `Mouse control on the computer and finger on the phone. On the allotted panel, draw a car and it will appear in the game.`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-26",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Celebrity E-Girl vs Soft-Girl - playcutegames.com",
      name: ["Celebrity E-Girl vs Soft-Girl", "celebrity-e-girl-vs-soft-girl"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-e-girl-vs-soft-girl/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-e-girl-vs-soft-girl/520x295.png",
      path: ["/game/celebrity-e-girl-vs-soft-girl", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-e-girl-vs-soft-girl/",
      Walkthrough: "https://www.youtube.com/embed/MnKGsT6LlHw",
      s_dis:
      "Celebrity E-Girl vs Soft-Girl Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Choose your style path in Celebrity E-Girl vs Soft-Girl! Explore the contrasting worlds of edgy E-Girl fashion and soft, pastel-inspired Soft-Girl trends. Mix and match outfits, and accessories to express your unique fashion statement. Compete in fashion challenges, socialize with friends, and become a trendsetter in this dynamic virtual fashion showdown!",
      tag: ["Celebrity","E-Girl","Party","Exclusive","Dress up","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-26",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Baby Zara Outdoor Cleaning - playcutegames.com",
      name: ["Baby Zara Outdoor Cleaning", "baby-zara-outdoor-cleaning"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/baby-zara-outdoor-cleaning/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/baby-zara-outdoor-cleaning/520x295.png",
      path: ["/game/baby-zara-outdoor-cleaning", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/baby-zara-outdoor-cleaning/",
      Walkthrough: "https://www.youtube.com/embed/7OX5qj1wgec",
      s_dis:
      "Baby Zara Outdoor Cleaning Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Join Baby Zara in her Outdoor Cleaning adventure! Help her tidy up the backyard, pick up trash, and plant flowers. With your assistance, the garden will look fresh and beautiful. Enjoy a fun and educational experience as you teach Baby Zara the importance of keeping the environment clean and green.",
      tag: ["Kids","Cleaning","Decoration","Dl-Girls","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-08-12",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Ellie And Friends Art Hoe Aesthetic - playcutegames.com",
      name: ["Ellie And Friends Art Hoe Aesthetic", "ellie-and-friends-art-hoe-aesthetic"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-art-hoe-aesthetic/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-art-hoe-aesthetic/520x295.png",
      path: ["/game/ellie-and-friends-art-hoe-aesthetic", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-friends-art-hoe-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/LPXMCq2iwAg",
      s_dis:
      "Ellie And Friends Art Hoe Aesthetic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Ellie And Friends Art Hoe Aesthetic is all about exploring creativity through unique fashion and art! Dress them up in colorful outfits, mix and match funky accessories, and dive into the world of artistic expression. From painting and drawing to choosing the perfect look, this game lets you celebrate individuality and artistic flair with Ellie and her friends. Get ready to unleash your inner artist!",
        tag: ["Barbie","Ellie","Girl games","Dress up","Fashion","Exclusive"],
      hint: `Use your left mouse button `,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-16",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Incredible Princesses and Villains Puzzle - playcutegames.com",
      name: ["Incredible Princesses and Villains Puzzle", "incredible-princesses-and-villains-puzzle"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/incredible-princesses-and-villains-puzzle/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/incredible-princesses-and-villains-puzzle/520x295.png",
      path: ["/game/incredible-princesses-and-villains-puzzle", "https://playcutegames.com"],
      iframe: "https://www.dl-girls.com/content/folder_1715790017/",
      Walkthrough: "https://www.youtube.com/embed/HGm4OT-m0uY",
      s_dis:
      "Incredible Princesses and Villains Puzzle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Incredible Princesses and Villains Puzzle is an exciting game for girls where the player has to collect colorful and bright puzzles with images of princesses and villains. The game features 50 puzzles, the difficulty of which is gradually increasing. Each puzzle is unique, which contributes to the development of logical thinking and imagination among players. By collecting puzzles, you will immerse yourself in the fascinating world of fairy-tale characters, where each figure has its own role and story. The game will give you and your children hours of exciting pastime and help improve fine motor skills and attention. Lets start collecting puzzles and immerse ourselves in a world of magic and adventure with princesses and villains!",
      tag: ["Find","jigsaw","Dl-Girls","Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-07",
      brand: "playcutegames",
     
      orientation: 1, 
      title: "Find The 3 Difference - playcutegames.com",
      name: ["Find The 3 Difference", "find-the-3-difference"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/find-the-3-difference/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/find-the-3-difference/520x295.png",
      path: ["/game/find-the-3-difference", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bestgamespot/find-the-3-difference/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Find The 3 Difference Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Embark on an immersive visual challenge with “Find The 3 Difference” – a captivating puzzle game that puts your attention to detail to the test! Explore intricate images filled with subtle variations and discover three unique differences hidden within each pair of pictures across 80 exciting levels.",
      tag: ["Find","Hidden","Puzzle","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-06-18",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Celebrity Coachella Vibe Outfits - playcutegames.com",
      name: ["Celebrity Coachella Vibe Outfits", "celebrity-coachella-vibe-outfits"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-coachella-vibe-outfits/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-coachella-vibe-outfits/520x295.png",
      path: ["/game/celebrity-coachella-vibe-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-coachella-vibe-outfits/",
      Walkthrough: "https://www.youtube.com/embed/cPUe0eZeaSE",
      s_dis:
      "Celebrity Coachella Vibe Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Celebrity Coachella Vibe Outfits lets you dress up your favorite celebrities in trendy festival outfits. Explore a wide range of boho-chic styles, from flowy dresses to edgy accessories. Create stunning looks perfect for the Coachella vibe and make your celebs stand out at the festival. Get ready to unleash your inner fashionista! Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup.",
      tag: ["Celebrity","Coachella","Party","Exclusive","Dress up","Girl games"],
      hint: `Use your left mouse button`,
      rating:[4.4,1210,114],  
    },
    {
     date: "2024-08-12",
     brand: "playcutegames",
     orientation: 0, 
     title: "Glam And Glossy Summer Look - playcutegames.com",
     name: ["Glam And Glossy Summer Look", "glam-and-glossy-summer-look"],
     thumb: "https://cdn.cutedressup.in/games/cutedressup_new/glam-and-glossy-summer-look/250x142.png",
     thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/glam-and-glossy-summer-look/520x295.png",
     // video:"https://cdn.cutedressup.in/games/cutedressup-video/rivals-whos-gonna-be-my-girlfriend.mp4",
     path: ["/game/glam-and-glossy-summer-look", "https://playcutegames.com"],
     iframe: "https://games.cutedressup.net/glam-and-glossy-summer-look/",
     Walkthrough: "https://www.youtube.com/embed/lOhcTxMCn68",
     s_dis:
     "Glam And Glossy Summer Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
     l_dis:
       "Glam And Glossy Summer Look is your go-to game for creating stunning summer styles! Dive into a wardrobe filled with trendy outfits, shimmering accessories, and sun-kissed makeup. Mix and match to craft the perfect glossy summer look, whether it’s for a beach day, a pool party, or a chic city outing. Show off your fashion sense and shine bright all summer long!",
       tag: ["Black Girl","Summer","Girl games","Dress up","Exclusive"],
     hint: `Use your left mouse button `,
     rating:[4.4,1210,114],  
   },
    {
      date: "2024-05-24",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Besties Reunion Party - playcutegames.com",
      name: ["Besties Reunion Party", "besties-reunion-party"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/besties-reunion-party/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/besties-reunion-party/520x295.png",
      path: ["/game/besties-reunion-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/besties-reunion-party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Besties Reunion Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Besties Reunion Party – Join the ultimate Besties Reunion Party! Plan an unforgettable celebration with your closest friends, filled with laughter, memories, and stylish outfits. Choose the perfect attire, decorate the venue, and capture every special moment. Whether it’s a night out or a cozy get-together, this party is all about friendship and fun. Reunite with your besties and make this event a night to remember with fabulous fashion and unique party ideas!",
      tag: ["Barbie","Dress up","Party","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2024-06-06",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Baby Zara Home Cleaning - playcutegames.com",
      name: ["Baby Zara Home Cleaning", "baby-zara-home-cleaning"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/baby-zara-home-cleaning/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/baby-zara-home-cleaning/520x295.png",
      path: ["/game/baby-zara-home-cleaning", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/baby-zara-home-cleaning/",
      Walkthrough: "https://www.youtube.com/embed/Snhv7g8P478",
      s_dis:
      "Baby Zara Home Cleaning Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Baby Zara Home Cleaning. Join Baby Zara in her Home Cleaning adventure! Help Zara tidy up her home by organizing toys, sweeping floors, and wiping windows. This fun and interactive game teaches kids the importance of cleanliness while keeping them entertained. Get ready for a sparkling clean home with Baby Zara!",
      tag: ["Baby Zara","Cleaning","Party","Exclusive","Girl games"],
      hint: `Use your left mouse button`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-05-16",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Lovie Chic’s Insta Glam Squad - playcutegames.com",
      name: ["Lovie Chic’s Insta Glam Squad", "lovie-chics-insta-glam-squad"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-insta-glam-squad/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-insta-glam-squad/520x295.png",
      path: ["/game/lovie-chics-insta-glam-squad", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-insta-glam-squad/",
      Walkthrough: "https://www.youtube.com/embed/r3uDcghe8bQ",
      s_dis:
      "Lovie Chic’s Insta Glam Squad Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the world of glamour with Lovie Chic’s Insta Glam Squad! Join the squad and explore the latest trends in fashion, makeup, and styling. From chic streetwear to red carpet looks, unleash your inner fashionista and create stunning Insta-worthy outfits. With endless options for customization and photo shoots, become the ultimate style influencer and take the fashion world by storm with your Insta Glam Squad!",
      tag: ["Lovie Chic","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-05-06",
      brand: "playcutegames",
      orientation: 0,
      title: "Celebrity Last Fling Before The Ring - playcutegames.com",
      name: ["Celebrity Last Fling Before The Ring", "celebrity-last-fling-before-the-ring"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-last-fling-before-the-ring/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-last-fling-before-the-ring/520x295.png",
      path: ["/game/celebrity-last-fling-before-the-ring", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-last-fling-before-the-ring/",
      Walkthrough: "https://www.youtube.com/embed/YXvdhpOqVfI",
      s_dis:
      "Celebrity Last Fling Before The Ring Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the spotlight with Celebrity Last Fling Before The Ring! Explore a dazzling array of dresses, accessories, and hairstyles to create your perfect prom look. From elegant ball gowns to chic cocktail dresses, we have everything you need to shine on your special night. Get ready to dance the night away in style and make memories that will last a lifetime!",
      tag: ["Celebrity","Dress up","Wedding","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-08-02",
      brand: "Yandex",
     
      orientation: 0, 
      title: "Secret Agents Mission to Save Earth - playcutegames.com",
      name: ["Secret Agents Mission to Save Earth", "secret-agents-mission-to-save-earth"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/secret-agents-mission-to-save-earth/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/secret-agents-mission-to-save-earth/520x295.png",
      path: ["/game/secret-agents-mission-to-save-earth", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/secret-agents-mission-to-save-earth/",
      Walkthrough: "https://www.youtube.com/embed/FIYRQOu95Hc",
      s_dis:
      "Secret Agents Mission to Save Earth Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `“Secret Agents Mission to Save Earth” is an action-packed adventure game where players take on the role of elite secret agents tasked with protecting the planet. Navigate through intense missions, solve challenging puzzles, and battle formidable foes to thwart a global threat. Utilize high-tech gadgets, stealth tactics, and your wits to uncover conspiracies and save Earth from imminent danger. Embark on this thrilling journey and prove you have what it takes to be a top secret agent!`,
      tag: ["Story Games","Dress up","Girl","Exclusive"],
      hint: `Immerse yourself in the world of cross stitching and feel like a master of embroidery in Cross Stitch Masters! Color by number, relax, enjoy and have fun! Create fun and colorful pictures and share with your friends, easy and stress free. This game is for adults and kids any age who loves cross-stitching, knitting, puzzles, nonograms, jigsaws and coloring books. Join us and unleash your creativity!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-05-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Glamorous Prom Party - playcutegames.com",
      name: ["Lovie Chic’s Glamorous Prom Party", "lovie-chics-glamorous-prom-party"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-glamorous-prom-party/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-glamorous-prom-party/520x295.png",
      path: ["/game/lovie-chics-glamorous-prom-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-glamorous-prom-party/",
      Walkthrough: "https://www.youtube.com/embed/9QVo6j0Z-Bo",
      s_dis:
      "Step into the spotlight with Lovie Chic's glamorous prom party. Explore a dazzling array of dresses and accessories to make your prom night unforgettable.",
      l_dis:
        "Step into the spotlight with Lovie Chic’s Glamorous Prom Party! Explore a dazzling array of dresses, accessories, and hairstyles to create your perfect prom look. From elegant ball gowns to chic cocktail dresses, we have everything you need to shine on your special night. Get ready to dance the night away in style and make memories that will last a lifetime!",
      tag: ["Lovie Chic","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2024-04-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFF’s Hot Summer Style - playcutegames.com",
      name: ["BFF’s Hot Summer Style", "bffs-hot-summer-style"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-hot-summer-style/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-hot-summer-style/520x295.png",
      path: ["/game/bffs-hot-summer-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-hot-summer-style/",
      Walkthrough: "https://www.youtube.com/embed/bMP66CtLGcg",
      s_dis:
      "BFF’s Hot Summer Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Get ready to sizzle in the sun with BFF’s Hot Summer Style! Dive into a world of vibrant colors, breezy fabrics, and trendy silhouettes, curated to keep you cool and stylish all season long. From chic sundresses to playful rompers, our collection offers endless options to slay your summer look. Whether you’re lounging by the pool or hitting the beach, make a splash with BFF’s Hot Summer Style!",
        tag: ["Dress up","Summer","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-10",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Holographic Trends - playcutegames.com",
      name: ["Lovie Chic’s Holographic Trends", "lovie-chics-holographic-trends"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-holographic-trends/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-holographic-trends/520x295.png",
      path: ["/game/lovie-chics-holographic-trends", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-holographic-trends/",
      Walkthrough: "https://www.youtube.com/embed/WipPwffGnpw",
      s_dis:
      "Lovie Chic’s Holographic Trends Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the future of fashion with Lovie Chic’s Holographic Trends! Embrace the mesmerizing allure of holographic fabrics and accessories, where every outfit radiates a futuristic glow. From shimmering dresses to iridescent accessories, our collection offers a dazzling array of options to elevate your style game. Stand out from the crowd and make a bold fashion statement with Lovie Chic’s Holographic Trends!",
        tag: ["Lovie Chic","Dress up","Holographic","Trends","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-10",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Casual Trendy Look - playcutegames.com",
      name: ["Celebrity Casual Trendy Look", "celebrity-casual-trendy-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-casual-trendy-look/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-casual-trendy-look/520x295.png",
      path: ["/game/celebrity-casual-trendy-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-casual-trendy-look/",
      Walkthrough: "https://www.youtube.com/embed/WipPwffGnpw",
      s_dis:
      "Celebrity Casual Trendy Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Explore the world of casual chic with our “Celebrity Casual Trendy Look” collection! Discover effortless yet stylish outfits curated for a relaxed yet fashionable vibe. Embrace the latest trends, mix and match pieces, and elevate your everyday style with ease. Whether you’re running errands or grabbing coffee with friends, rock your look like a celebrity!",
        tag: ["Celebrity","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-10",
      brand: "playcutegames",
     
      orientation: 0,
      title: "From Nerd To School Popular - playcutegames.com",
      name: ["From Nerd To School Popular", "from-nerd-to-school-popular"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/from-nerd-to-school-popular/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/from-nerd-to-school-popular/520x295.png",
      path: ["/game/from-nerd-to-school-popular", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/from-nerd-to-school-popular/",
      Walkthrough: "https://www.youtube.com/embed/-wk5sCz9vRc",
      s_dis:
      "From Nerd To School Popular Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Embark on a transformation journey “From Nerd To School Popular”! Help our protagonist navigate high school life, overcome challenges, and discover their true potential. With your guidance, they’ll undergo a stylish makeover, gain confidence, and rise to the top of the social ladder. Experience the ultimate high school makeover story and watch as our hero transforms from a shy outsider to a beloved school icon!",
        tag: ["Make Up","Nerd","Dress up","Exclusive","Decoration","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-03-27",
      brand: "Yandex",
     
      orientation: 1,
      title: "Jump in Rhythm to the Hit! Cat Disco! - playcutegames.com",
      name: ["Jump in Rhythm to the Hit! Cat Disco!", "jump-in-rhythm-to-the-hit-cat-disco"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/jump-in-rhythm-to-the-hit-cat-disco/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/jump-in-rhythm-to-the-hit-cat-disco/520x295.png",
      path: ["/game/jump-in-rhythm-to-the-hit-cat-disco", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/272485?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Jump in Rhythm to the Hit! Cat Disco! Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `🎵 The game "Jump in Rhythm to the Hit! Cat Disco!" offers a unique combination of cute cat meows and exciting pop music rhythms. Play along to a variety of popular tunes, including worldwide hits, and enjoy gorgeous visuals and kawaii design! ⭐ Main Features ⭐ - A large selection of popular hits! - Remixes of popular tracks with a fun "meow"! - Easy one-touch screen control! - Bright colors and fantastic design! - A variety of kawaii cats ready for adventure! 🎁 Don't miss out on the surprises we have prepared for you! Start the game "Jump in Rhythm to the Hit! Cat Disco!" and plunge into the fun world of musical adventures right now! 🌈🐾🎮 💕Don't forget to support us by leaving a review and comment if you liked the game! If you have any comments about the game, please describe the problem in as much detail as possible and we will try to fix it quickly!`,
        tag: ["kids","Rhythm","Music","Girl games"],
      hint: `🕹️The game controls are very simple. To control a character: On a smartphone📱 - move your finger across the screen left and right. On a computer💻 - move the mouse with the left button pressed. 🎯The main goal of the game is to reach the end of the level by jumping on the tiles without falling. - Guide your cat by helping him jump on the tiles to the beat of the music. - Beware of missing tiles - your task is to complete as many levels as possible and unlock all the songs. - Earn coins to unlock new cute cats and new popular tracks!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-08",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Bonnie Fitness Frenzy - playcutegames.com",
      name: ["Bonnie Fitness Frenzy", "bonnie-fitness-frenzy"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bonnie-fitness-frenzy/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bonnie-fitness-frenzy/520x295.png",
      path: ["/game/bonnie-fitness-frenzy", "https://playcutegames.com"],
      iframe: "https://www.dl-girls.com/content/folder_1710606259/",
      Walkthrough: "https://www.youtube.com/embed/5sg-J7fi_cU",
      s_dis:
      "Bonnie Fitness Frenzy Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Bonnie: Fitness Frenzy - an exhilarating game for girls, where Bonnie decides to sculpt her figure into perfect form for the summer season. Join Bonnie on her adventures in the gym, where you will perform a variety of exercises by clicking the mouse to help her burn off those extra calories. After intense workouts, Bonnie will focus on applying makeup. Help her create a vibrant and stylish look for her upcoming beach outing. Choose from a plethora of cosmetics and create a unique appearance for Bonnie. And finally, it's time to dress up! Assist Bonnie in selecting fashionable and elegant sportswear to accentuate her fitness style. Choose from a variety of t-shirts, shorts, and accessories to craft a distinctive look for Bonnie. Join Bonnie on her exciting journey to achieve the ideal figure and style in the game «Bonnie Fitness Frenzy",
        tag: ["Barbie","Easter","Dress up","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-08",
      brand: "cgspot",
     
      orientation: 0,
      title: "From Zombie To Glam A Spooky - playcutegames.com",
      name: ["From Zombie To Glam A Spooky", "from-zombie-to-glam-a-spooky"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/from-zombie-to-glam-a-spooky/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/from-zombie-to-glam-a-spooky/520x295.png",
      path: ["/game/from-zombie-to-glam-a-spooky", "https://playcutegames.com"],
      iframe: "https://html5.gamemonetize.co/f8i7q0hmeirg49gv2mjhfcqgw1l0vs6f/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "From Zombie To Glam A Spooky Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the game “From Zombie To Glam A Spooky”. Embark on a spooktacular journey with Ellie in From Zombie to Glam: A Spooky Transformation! Help her shed her undead appearance and embrace a new, ordinary life. Dive into levels of makeover, hand cleaning, makeup, nail decor, and dress-up for an epic Halloween makeover!",
        tag: ["Zombie","halloween","Dress up","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-04-08",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Celebrity Face Dance - playcutegames.com",
      name: ["Celebrity Face Dance", "celebrity-face-dance"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-face-dance/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-face-dance/520x295.png",
      path: ["/game/celebrity-face-dance", "https://playcutegames.com"],
      iframe: "https://www.dl-girls.com/content/folder_1709396015/",
      Walkthrough: "https://www.youtube.com/embed/SMlulpm_O64",
      s_dis:
      "Celebrity Face Dance Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Celebrity Face Dance - an exciting game for girls that combines makeup and dancing. In this game, you can transform into real stars and experience being a makeup artist and dancer at the same time. Three celebrities await you - Ariana Grande, Taylor Swift, and Billie Eilish, each with their unique style and image. Choose your favorite star and start creating a unique look for her. Use various cosmetic products to enhance beauty and individuality girls. Then, when the makeup is ready, move on to the most exciting part - the dance! Follow the instructions on the screen and mimic the celebrity's moves to become a true dance floor star. Improve your skills and become the best in Celebrity Face Dance",
        tag: ["Celebrity","Dance","Dress up","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
   
    {
      date: "2024-03-27",
      brand: "playcutegames",
     
      orientation: 1,
      title: "BFF Easter Photobooth Party - playcutegames.com",
      name: ["BFF Easter Photobooth Party", "bff-easter-photobooth-party"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bff-easter-photobooth-party/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bff-easter-photobooth-party/520x295.png",
      path: ["/game/bff-easter-photobooth-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff-easter-photobooth-party/",
      Walkthrough: "https://www.youtube.com/embed/SPLZdUVIGQI",
      s_dis:
      "BFF Easter Photobooth Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the whimsical world of the “BFF Easter Photobooth Party” and celebrate the joyous holiday with your best friends! Join in on the festive fun as you dress up in vibrant Easter-themed outfits, pose for adorable photos in the photobooth, and create lasting memories together. With colorful props, festive decorations, and endless laughter, this is the perfect way to capture the spirit of Easter and enjoy a delightful day with your BFFs. ",
        tag: ["Barbie","Easter","Dress up","Exclusive","Craft","Decoration","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-03-23",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chick’s Preppy Look - playcutegames.com",
      name: ["Lovie Chick’s Preppy Look", "lovie-chicks-preppy-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chicks-preppy-look/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chicks-preppy-look/520x295.png",
      path: ["/game/lovie-chicks-preppy-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chicks-preppy-look/",
      Walkthrough: "https://www.youtube.com/embed/i9Z8eO93HlQ",
      s_dis:
      "Lovie Chick’s Preppy Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Explore the enchanting fusion of nostalgia and celebrity flair with Lovie Chick’s Preppy Look’. Dive into a whimsical realm where childhood memories inspire vibrant, playful fashion choices. Discover quirky outfits and accessories that blend retro charm with modern celebrity style. Embrace your inner child and express yourself in a fun, creative way! ",
        tag: ["Lovie Chic","Preppy","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-03-14",
      brand: "playcutegames",
     
      orientation: 1,
      title: "St Patrick’s Day Tic-Tac-Toe - Play at playcutegames!",
      name: ["St Patrick’s Day Tic-Tac-Toe", "st-patricks-day-tic-tac-toe"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/st-patricks-day-tic-tac-toe/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/st-patricks-day-tic-tac-toe/520x295.png",
      path: ["/game/st-patricks-day-tic-tac-toe", "https://playcutegames.com"],
      iframe:"https://games.cutedressup.net/bestgamespot/st-patricks-day-tic-tac-toe/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "St Patrick’s Day Tic-Tac-Toe Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Bring luck to your game with ‘St. Patrick’s Day Tic-Tac-Toe’! Play on vibrant themed boards, featuring shamrocks and pots of gold. Challenge friends or AI, and enjoy the festive twist on a classic. Whether you’re an X or an O, may the luck of the Irish be with you. Elevate your St. Patrick’s Day celebration with this charming, strategy-filled game. Play now and let the fun, luck, and greenery unfold in every move!`,
        tag: ["Puzzle","Action","Patricks","Tic Tac Toe","Exclusive"],
      hint: `Click or Tap`,
      rating:[4.4,1210,114],
    },
    {
      date: "2024-03-18",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Kidcore Aesthetic - playcutegames.com",
      name: ["Celebrity Kidcore Aesthetic", "celebrity-kidcore-aesthetic"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-kidcore-aesthetic/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-kidcore-aesthetic/520x295.png",
      path: ["/game/celebrity-kidcore-aesthetic", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-kidcore-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/riL8mMHsoc0",
      s_dis:
      "Celebrity Kidcore Aesthetic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Explore the enchanting fusion of nostalgia and celebrity flair with ‘Celebrity Kidcore Aesthetic’. Dive into a whimsical realm where childhood memories inspire vibrant, playful fashion choices. Discover quirky outfits and accessories that blend retro charm with modern celebrity style. Embrace your inner child and express yourself in a fun, creative way! ",
        tag: ["Lovie Chic","Kidcore","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2024-03-14",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chics Spring Break Fashion - Play at playcutegames!",
      name: ["Lovie Chics Spring Break Fashion", "lovie-chics-spring-break-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-spring-break-fashion/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-spring-break-fashion/520x295.png",
      path: ["/game/lovie-chics-spring-break-fashion", "https://playcutegames.com"],
      iframe:"https://games.cutedressup.net/lovie-chics-spring-break-fashion/",
      Walkthrough: "https://www.youtube.com/embed/T1YYciNmyu0",
      s_dis:
      "Lovie Chics Spring Break Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Lovie Chics Spring Break Fashion” is your ultimate destination for chic and trendy outfits perfect for the spring break season! Explore our curated collection of vibrant dresses, stylish swimwear, and must-have accessories designed to elevate your style and make a statement wherever you go. Whether you’re hitting the beach, lounging by the pool, or exploring new destinations, we have everything you need to look and feel fabulous all spring break long. Embrace the sunny vibes and shop our collection now`,
        tag: ["Puzzle","Action","Spring","Exclusive"],
      hint: `Click or Tap`,
      rating:[4.4,1210,114],
    },
    {
      date: "2024-03-07",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Mr Hacker: The Museum Hunt - Play free at playcutegames!",
      name: ["Mr Hacker: The Museum Hunt", "mr-hacker-the-museum-hunt"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mr-hacker-the-museum-hunt/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mr-hacker-the-museum-hunt/520x295.png",
      path: ["/game/mr-hacker-the-museum-hunt", "https://playcutegames.com"],
      iframe:"https://games.cutedressup.net/bestgamespot/mr-hacker-the-museum-hunt/",
      Walkthrough: "https://www.youtube.com/embed/xhIu5t0EaZo",
      s_dis:
      "Mr Hacker: The Museum Hunt Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Join the thrilling heist in ‘Mr Hacker: The Museum Hunt.’ As a hired hacker, your mission is to crack 150 vaults in a high-stakes museum break-in. Unleash your skills, avoid security, and uncover precious diamonds. Are you up for the challenge? Play now and immerse yourself in the world of cunning schemes and high-tech heists.`,
        tag: ["Puzzle","Action","Hacker","Exclusive"],
      hint: `Click `,
      rating:[4.4,1210,114],
    },
    {
      date: "2024-02-28",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Lovie Chic’s St Patricks Day Costumes - playcutegames.com",
      name: ["Lovie Chic’s St Patricks Day Costumes", "lovie-chics-st-patricks-day-costumes"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-st-patricks-day-costumes/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-st-patricks-day-costumes/520x295.png",
      path: ["/game/lovie-chics-st-patricks-day-costumes", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-st-patricks-day-costumes/",
      Walkthrough: "https://www.youtube.com/embed/14fQ5xgHd88",
      s_dis:
      "Lovie Chic’s St Patricks Day Costumes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Join the festive fun with Lovie Chic’s St Patricks Day Costumes! Explore a collection of vibrant green ensembles, lucky accessories, and Irish-inspired styles. Elevate your St. Patrick’s Day look with chic outfits that capture the spirit of the celebration. Join Lovie Chic in setting trends for this lively holiday. From parades to parties, embrace the charm of St. Patrick’s Day with fashion that brings luck and style to your wardrobe. Let the green magic unfold in every outfit with Lovie Chic’s expert inspiration!",
        tag: ["Lovie Chic","Festivale","Dress up","Patricks","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-02-14",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Kendel 7 Days 7 Styles - playcutegames.com",
      name: ["Kendel 7 Days 7 Styles", "kendel-7-days-7-styles"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/kendel-7-days-7-styles/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/kendel-7-days-7-styles/520x295.png",
      path: ["/game/kendel-7-days-7-styles", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/kendel-7-days-7-styles/",
      Walkthrough: "https://www.youtube.com/embed/bIivHyNJbVg",
      s_dis:
      "Kendel 7 Days 7 Styles Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into a week-long fashion adventure with ‘Kendel 7 Days 7 Styles’! Explore a daily wardrobe transformation, from casual chic to elegant evenings. Join Kendel in setting trends and elevating your style each day. Immerse yourself in the excitement of diverse fashion, unlock new looks, and make every day a stylish statement. From Monday to Sunday, embrace the thrill of reinventing your style with Kendel’s expert inspiration. Elevate your wardrobe and step into a week of endless fashion possibilities!",
        tag: ["Puzzle","Board","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-02-02",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Cupid Valentine Tic-Tac-Toe - playcutegames.com",
      name: ["Cupid Valentine Tic-Tac-Toe", "cupid-valentine-tic-tac-toe"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cupid-valentine-tic-tac-toe/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cupid-valentine-tic-tac-toe/520x295.png",
      path: ["/game/cupid-valentine-tic-tac-toe", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/cupid-valentine-tic-tac-toe/",
      Walkthrough: "https://www.youtube.com/embed/mUMDiViGM90",
      s_dis:
      "Cupid Valentine Tic-Tac-Toe Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Cupid Valentine Tic-Tac-Toe. Dive into the Island Princess’s world of style! Explore tropical ensembles, beachy accessories, and exotic looks. Join the fashion adventure as she sets trends in paradise. Elevate your style and embrace the magic of Island Princess’s wardrobe. From casual strolls to beach parties, let the island inspire your fashion journey!",
        tag: ["Puzzle","Board","Dress up","Exclusive","Valentine","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },{
      date: "2024-02-17",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Spring Manicure Design - playcutegames.com",
      name: ["Celebrity Spring Manicure Design", "celebrity-spring-manicure-design"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-spring-manicure-design/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-spring-manicure-design/520x295.png",
      path: ["/game/celebrity-spring-manicure-design", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-spring-manicure-design/",
      Walkthrough: "https://www.youtube.com/embed/DpdeySxpyt4",
      s_dis:
      "Celebrity Spring Manicure Design Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the glamour of ‘Celebrity Spring Manicure Design’! Explore a world of trendy nail art, vibrant colors, and chic designs. Join celebrities in setting the trend for spring nails. Elevate your style with creative manicures, from floral elegance to modern motifs. Immerse yourself in the art of nail fashion and transform your fingertips into a canvas of spring-inspired beauty. Express your unique style, celebrate the season, and make your nails a fashion statement with Celebrity Spring Manicure Design!",
        tag: ["Celebrity","Fantasy","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-02-02",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Island Princess All Around The Fashion - playcutegames.com",
      name: ["Island Princess All Around The Fashion", "island-princess-all-around-the-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/island-princess-all-around-the-fashion/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/island-princess-all-around-the-fashion/520x295.png",
      path: ["/game/island-princess-all-around-the-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/island-princess-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/mUMDiViGM90",
      s_dis:
      "Island Princess All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Island Princess All Around The Fashion. Dive into the Island Princess’s world of style! Explore tropical ensembles, beachy accessories, and exotic looks. Join the fashion adventure as she sets trends in paradise. Elevate your style and embrace the magic of Island Princess’s wardrobe. From casual strolls to beach parties, let the island inspire your fashion journey!",
        tag: ["Princess","Dress up","Exclusive","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-02-07",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Valentine Nail Salon - playcutegames.com",
      name: ["Valentine Nail Salon", "valentine-nail-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/valentine-nail-salon/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/valentine-nail-salon-300.jpg",
      path: ["/game/valentine-nail-salon", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/valentine-nail-salon/",
      Walkthrough: "https://www.youtube.com/embed/ibv37Ce1T98",
      s_dis:
      "Valentine Nail Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Valentine Nail Salon. You own this chic nail salon where many girls come to have their manicures done. As today is Valentine’s day, you have to create a sweet romantic manicure for your client who wants to look wonderful on such an important day. So, prove what a good nail artist you are by making her hands look glamorous. Choose the length and the shape of the nails you want and then begin painting them with style. Choose the nail polish color you want to use and color the nails as fancy as you can. You can give her a very trendy french manicure or a classic one, you can use a single color or multiple ones. There are also some cool nail accessories and fancy rings you can choose from to make her hands look perfect.",
      tag: ["Nail","Girl games","Valentine","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2024-02-09",
      brand: "yandex",
     
      orientation: 0,
      title: "Jungle Bubble Shooter - playcutegames.com",
      name: ["Jungle Bubble Shooter", "jungle-bubble-shooter"],
      thumb: "https://avatars.mds.yandex.net/get-games/1881371/2a0000018630e1ad4ea7994006afd18d0a83/icon1",
      thumb_big: "https://avatars.mds.yandex.net/get-games/1881371/2a0000018630e1ad4ea7994006afd18d0a83/icon1",
      path: ["/game/jungle-bubble-shooter", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/214728?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Jungle Bubble Shooter Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Aim and match bubbles and collect 3 or more identical bubbles.<br>
         Use the props in the game.<br>
        Its use makes it easy for you to pass the game.<br>
        The less bubbles used to complete the game, the higher the score.
        `,
        tag: ["Bubble shooter","kids"],
      hint: `A client will come into your salon and ask for a dream haircut.
      Fulfill her wishes and make the girl happy!<br>
      And for this, carefully choose the shape of the haircut, hair color, jewelry and create a stylish image.<br>
      Accumulate money and do not forget to improve your salon and make cool repairs in it!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-02-09",
      brand: "yandex",
     
      orientation: 0,
      title: "Bubble Shooter Classic - playcutegames.com",
      name: ["Bubble Shooter Classic", "bubble-shooter-classic"],
      thumb: "https://avatars.mds.yandex.net/get-games/1881371/2a0000017c288b4ae5bcfcae80d8d4cc1b30/icon1",
      thumb_big: "https://avatars.mds.yandex.net/get-games/1881371/2a0000017c288b4ae5bcfcae80d8d4cc1b30/icon1",
      path: ["/game/bubble-shooter-classic", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/177601?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "All you need is to shoot bubbles across the colored field to make combinations of 3 or more bubbles of the same color. The more bubbles you shoot down in one shot, the more points you get. For the dropped bubbles, you get twice as many points! The playing field will go down one row if you fail to collect a combination.",
      l_dis:
        `"- Use the Sight to hit the target clearly!<br>
        - Use the Bomb to shoot down all the bubbles around!<br>
        - Drop bubbles to get twice as many points.<br>
        - Collect as many points as possible and become the best player on bestgame spot!"
        `,
        tag: ["Bubble shooter","kids"],
      hint: `A client will come into your salon and ask for a dream haircut.
      Fulfill her wishes and make the girl happy!<br>
      And for this, carefully choose the shape of the haircut, hair color, jewelry and create a stylish image.<br>
      Accumulate money and do not forget to improve your salon and make cool repairs in it!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-06-17",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Panda Tropical Wedding Story - playcutegames.com",
      name: ["Panda Tropical Wedding Story", "panda-tropical-wedding-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/panda-tropical-wedding-story/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/panda-tropical-wedding-story-300.jpg",
      path: ["/game/panda-tropical-wedding-story", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/panda-tropical-wedding-story/",
      Walkthrough: "https://www.youtube.com/embed/yTCfJXZFzm0",
      s_dis:
      " Panda Tropical Wedding Story Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the vibrant world of the Panda Tropical Wedding Story! Unleash your creativity as you style the adorable pandas in stunning tropical wedding outfits. Mix and match glamorous dresses, stylish accessories, and beautiful hairstyles to make the pandas look their best on their special day. Get ready for a fashion-filled adventure!",
      tag: ["Rainbow Girls","Dress up","Girl games","Pastel","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-02-07",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Avatar The Way Of Love - playcutegames.com",
      name: ["Avatar The Way Of Love", "avatar-the-way-of-love"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/avatar-the-way-of-love/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/avatar-the-way-of-love-300.jpg",
      path: ["/game/avatar-the-way-of-love", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/avatar-the-way-of-love/",
      Walkthrough: "https://www.youtube.com/embed/EMu97EhaAz0",
      s_dis:
      "Avatar The Way Of Love Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Avatar The Way Of Love. Can you guess what day it is? Okay, maybe we can give you a clue. Love is in the air tonight. Yeah, it’s Valentine’s Day alright. These Avatar couples love to spend time together this valentine’s in the city. They plan to wear new trendy clothes.",
      tag: ["Avatar","Dress up","Girl games","Fashion","Valentine","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "TB World - playcutegames.com",
      name: ["TB World", "tb-world"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/tb-world/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/tb-world/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/tb-world.mp4",
      path: ["/game/tb-world", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/364679?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play TB World, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Discover the fascinating world of TB World, where creativity and joy await you! Here, you can bring your fantasies to life by designing stylish looks for characters and creating cozy interiors. The design process becomes a true pleasure: choose from a wide range of clothing, accessories, and decor items to make each look and space unique. Transform your characters and their surroundings, crafting a distinctive atmosphere. This game is your personal creativity workshop where you create your own stories. Start your exciting journey into the design world today and let your imagination run free, creating unique looks and cozy interiors!
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca Build a 5-Star Hotel - playcutegames.com",
      name: ["Toca Boca Build a 5-Star Hotel", "toca-boca-build-a-5-star-hotel"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-build-a-5-star-hotel/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-build-a-5-star-hotel/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-build-a-5-star-hotel.mp4",
      path: ["/game/toca-boca-build-a-5-star-hotel", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/291344?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/zyqfolATmFk",
      s_dis:
      "Toca Boca Build a 5-Star Hotel is a popular construction game where players can create their own world and control various characters.",
      l_dis:
        `“Toca Boca Build a 5-Star Hotel” is a popular construction game where players can create their own world and control various characters. In this game, characters visit a 5-star hotel, which has many interesting places and activities. One of the main places in a 5-star hotel is the 3-story hotel. The player can furnish the three floors of the hotel with furniture according to their taste. Control the characters of the Toca Boca world.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Miga World Hospital - playcutegames.com",
      name: ["Miga World Hospital", "miga-world-hospital"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/miga-world-hospital/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/miga-world-hospital/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/miga-world-hospital.mp4",
      path: ["/game/miga-world-hospital", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/260751?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Miga World Hospital is a popular construction game where players can create their own world and control various characters.",
      l_dis:
        `Miga World Hospital is a fun and informative adventure that introduces players to the world of health care and emphasizes the importance of caring for their own health. The game was created especially for children and teenagers who wish to learn more about the various aspects of a hospital and learn first aid skills.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toka Boca World: Night City - playcutegames.com",
      name: ["Toka Boca World: Night City", "toka-boca-world-night-city"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toka-boca-world-night-city/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toka-boca-world-night-city/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toka-boca-world-night-city.mp4",
      path: ["/game/toka-boca-world-night-city", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/329680?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toka Boca World: Night City lets kids explore, create characters, and design spaces in a virtual city with locations like restaurants, hospitals, and shops.",
      l_dis:
        `Toka Boca World: Night City is a digital puppet game in which players can explore a virtual night city and create their own stories. The game has various locations to choose from, including a restaurant, a hospital, an aquarium, a courtroom, a prison, a bank, an office and shops. Players can also create their own characters and change the interior design. The game is designed to encourage children’s imagination and creativity. It allows them to create their own stories and play the way they want.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toсa Boсa clothing store - playcutegames.com",
      name: ["Toсa Boсa clothing store", "toсa-boсa-clothing-store"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toсa-boсa-clothing-store/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toсa-boсa-clothing-store/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toсa-boсa-clothing-store.mp4",
      path: ["/game/toсa-boсa-clothing-store", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/350569?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Welcome to Toca Boca clothing store! Move characters, change outfits, and create your own fashion avatar. Take selfies and explore fun, unique costumes!",
      l_dis:
        `Welcome to Toсa Boсa clothing store. Move the characters around the location of the clothing store with a mouse or finger on the phone screen. Take a selfie by pressing the camera button. The characters can change their clothes. Try unique costumes for each character and make your own fashion avatar.
      <br><br>
        In the game you will be waiting for:<br><br>
        - Creating unique looks;<br>
        - Hair coloring, creating hairstyles;<br>
        - A large closet with a unique selection of clothes;<br>
        - Choosing emotions for the character.<br>
        - Currency Earning;<br>
        - Upgrade currency per click;<br>
        - Cool mini-game “Left or Right?”<br> 
      
      `,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca Cooking - playcutegames.com",
      name: ["Toca Boca Cooking", "toca-boca-cooking"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-cooking/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-cooking/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-cooking.mp4",
      path: ["/game/toca-boca-cooking", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/257197?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Welcome to Toca Boca Cooking! Serve food from your truck, earn money, and grow into a cafe. Enjoy 50+ levels, kitchen upgrades, and exciting game modes!",
      l_dis:
        `Welcome to Toca Boca Cooking. The goal of the game is to serve food to all the customers visiting your food truck. Earn more money and build your small food truck to a big cafe in the center of the airport. What awaits you: – More than 50 large levels – Various kitchen equipment upgrades – Several game modes
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Avatar Life: Hacking - playcutegames.com",
      name: ["Avatar Life: Hacking", "avatar-life-hacking"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/avatar-life-hacking/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/avatar-life-hacking/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/avatar-life-hacking.mp4",
      path: ["/game/avatar-life-hacking", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/306718?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Avatar Life: Hacking is a fun simulation game set in a vibrant world. Explore unique locations, interact with characters, customize interiors, and control your space!",
      l_dis:
        `“Avatar Life: Hacking” is an entertaining simulation game. A colorful and amazing Avatar world awaits you.  There are several unique locations available in the game. Each of them contains different items and characters. You can move between locations using the main map in the game. There are many characters and items available to you. Each zone has its own content. Interact with him and control the space. You can create your own unique interior. All characters interact with objects and surroundings.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca and the Circus: mix - playcutegames.com",
      name: ["Toca Boca and the Circus: mix", "toca-boca-and-the-circus-mix"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-and-the-circus-mix/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-and-the-circus-mix/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-and-the-circus-mix.mp4",
      path: ["/game/toca-boca-and-the-circus-mix", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/278329?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toca Boca and the Circus: Mix is a fun simulation game where you explore two worlds, transform characters and objects, and create unique photos using portals!",
      l_dis:
        `“Toca Boca and the Circus: mix” is an exciting simulation game. You will discover two amazing worlds: the Current Side and the amazing digital circus. The game is divided into three zones. Each of the zones includes two different worlds. What kind of world you build depends on you! Incredible transformations of the main characters and objects are available. Just transfer objects from one world to another through the portal. Control characters, objects and space! All characters change, move and interact with objects. There will be a portal waiting for you in each location that will help you change the world. Have fun and create unique photos.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca and Avatar Mix - playcutegames.com",
      name: ["Toca Boca and Avatar Mix", "toca-boca-and-avatar-mix"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-and-avatar-mix/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-and-avatar-mix/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-and-avatar-mix.mp4",
      path: ["/game/toca-boca-and-avatar-mix", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/339461?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toca Boca and Avatar Mix is a fun simulation game where you explore two worlds, design interiors, control characters, and capture unique photos using a portal!",
      l_dis:
        `‘Toca Boca and Avatar Mix’ is an exciting simulation game where you will encounter two amazing worlds: Avatar and Toca Boca. The game offers six unique locations with different content and mechanics. You can design your own interior, compare the two worlds through a portal or modify existing interiors. The choice is yours! A variety of items and characters will captivate you. Control them as you wish. All of them interact with each other and move around. You can take a snapshot of your achievements at any time during the game. Manage space and characters to create unique photos!
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca High School - playcutegames.com",
      name: ["Toca Boca High School", "toca-boca-high-school"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-high-school/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-high-school/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-high-school.mp4",
      path: ["/game/toca-boca-high-school", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/307054?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toca Boca High School is a fun simulation game where you explore two worlds, design interiors, control characters, and capture unique photos using a portal!",
      l_dis:
        `‘Toca Boca High School’ is an exciting simulation game where you will encounter two amazing worlds: Avatar and Toca Boca. The game offers six unique locations with different content and mechanics. You can design your own interior, compare the two worlds through a portal or modify existing interiors. The choice is yours! A variety of items and characters will captivate you. Control them as you wish. All of them interact with each other and move around. You can take a snapshot of your achievements at any time during the game. Manage space and characters to create unique photos!
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca Everything Unlocked - playcutegames.com",
      name: ["Toca Boca Everything Unlocked", "toca-boca-everything-unlocked"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-everything-unlocked/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toca-boca-everything-unlocked/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toca-boca-everything-unlocked.mp4",
      path: ["/game/toca-boca-everything-unlocked", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/335526?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toca Boca Everything Unlocked lets you create and decorate homes with furniture and accessories. No hacks needed—just launch, design, and enjoy arranging characters!",
      l_dis:
        `Toca Boca Everything Unlocked – is a game in which you can create a new house and decorate it with furniture, decorations, and accessories. Hacking is no longer required. Launch the latest version and start creating! Arrange furniture and characters, arrange a photo shoot, and find friendly neighbors for your characters.
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Toca Boca Housewarming - playcutegames.com",
      name: ["Toca Boca Housewarming", "toka-boka-housewarming"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/toka-boka-housewarming/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/toka-boka-housewarming/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/toka-boka-housewarming.mp4",
      path: ["/game/toka-boka-housewarming", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/278077?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://youtu.be/",
      s_dis:
      "Toca Boca Housewarming lets you design homes for friends, dress up characters, choose emotions, and care for cute pets. Host the most fun housewarming party!",
      l_dis:
        `Toka Boka Housewarming is your own story, in which you can comfortably arrange new homes for friends. Features of the game: – a variety of clothing and accessories options for the characters; – the possibility of a unique design using furniture and decor; – options for emotions in your characters;- cute pets that can be fed. Arrange the most fun housewarming party with your friends!
`,
        tag: ["Toca boca"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Mania - playcutegames.com",
      name: ["Mahjong Mania", "mahjong-mania"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-mania/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-mania/250x142.png",
      path: ["/game/mahjong-mania", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-mania.mp4",
      iframe: "https://yandex.com/games/app/96479?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Mania Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Mania! With three difficulty levels, dynamic graphics and increasingly tricky levels to enjoy, you can pick up a game whenever you fancy, but don’t forget that not all matches are what the immediately appear to be. In mahjong, seasonal tiles can be matched with each other, even if the pictures do not match. A fun alternative to solitaire, tile-matching games can’t always be completed, but you can use the shuffle option to maximize your chances.
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 1,
      title: "Mahjong Russian - playcutegames.com",
      name: ["Mahjong Russian", "mahjong-russian"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-russian/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-russian/250x142.png",
      path: ["/game/mahjong-russian", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-russian.mp4",
      iframe: "https://yandex.com/games/app/258375?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Russian Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Russian! The rules are classic: you need to remove identical pairs of tiles (pictures). To do this, you need to find and select two identical pictures. Tiles are laid out in a certain way, and some of the upper tiles cover the lower ones. Therefore, you first need to find and remove the top tiles, and then access to the bottom tiles will open.
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Daddy - playcutegames.com",
      name: ["Mahjong Daddy", "mahjong-daddy"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-daddy/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-daddy/250x142.png",
      path: ["/game/mahjong-daddy", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-daddy.mp4",
      iframe: "https://yandex.com/games/app/241515?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Daddy Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Daddy! Beautiful, colorful mahjong, created for true connoisseurs of this game. There are no restrictions here: neither by time, nor by levels, nor by meaningless tasks. Only the game itself, only mahjong! A fun alternative to solitaire, tile-matching games can’t always be completed, but you can use the shuffle option to maximize your chances.
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Butterflies - playcutegames.com",
      name: ["Mahjong Butterflies", "mahjong-butterflies"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-butterflies/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-butterflies/250x142.png",
      path: ["/game/mahjong-butterflies", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-butterflies.mp4",
      iframe: "https://yandex.com/games/app/303288?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Butterflies Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Butterflies! Enjoy the captivating gameplay, known to all fans of the Mahjong series. Play in full screen and collect all the tiles by matching their pairs according to the rules of classic Mahjong. Imerse yourself in the unique world of Mahjong in full screen and dive into the depths of this captivating puzzle!
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Tiles - playcutegames.com",
      name: ["Mahjong Tiles", "mahjong-tiles"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-tiles/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-tiles/250x142.png",
      path: ["/game/mahjong-tiles", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-tiles.mp4",
      iframe: "https://yandex.com/games/app/328023?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Tiles Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Tiles! Remove identical mahjong tiles from the field. You can only remove identical tiles that do not border others with at least one side. You can choose the type of tiles in the game. The playing field can be moved around the screen, enlarged or reduced. Shuffling and hint options are available.
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Connect - playcutegames.com",
      name: ["Mahjong Connect", "mahjong-connect"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-connect/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-connect/250x142.png",
      path: ["/game/mahjong-connect", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-connect.mp4",
      iframe: "https://yandex.com/games/app/328023?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mahjong Connect Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Mahjong Connect – an exciting puzzle game where you can play for free, without registration, and without time limits. Enjoy the captivating gameplay, known to all fans of the Mahjong series. Play in full screen and collect all the tiles by matching their pairs according to the rules of classic Mahjong. Imerse yourself in the unique world of Mahjong in full screen and dive into the depths of this captivating puzzle!
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 0,
      title: "Mahjong Competitions - playcutegames.com",
      name: ["Mahjong Competitions", "mahjong-competitions"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-competitions/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mahjong-competitions/250x142.png",
      path: ["/game/mahjong-competitions", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mahjong-competitions.mp4",
      iframe: "https://yandex.com/games/app/260145?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Play Mahjong Competitions, a fun tile-matching game with three difficulty levels. Match seasonal tiles, use shuffle for new chances, and enjoy dynamic, challenging gameplay!",
      l_dis:
        `Mahjong Competitions is one of the most popular board games in the world. With three difficulty levels, dynamic graphics and increasingly tricky levels to enjoy, you can pick up a game whenever you fancy, but don’t forget that not all matches are what the immediately appear to be. In mahjong, seasonal tiles can be matched with each other, even if the pictures do not match. A fun alternative to solitaire, tile-matching games can’t always be completed, but you can use the shuffle option to maximize your chances.
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 1,
      title: "Fluffy Mahjong - playcutegames.com",
      name: ["Fluffy Mahjong", "fluffy-mahjong"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fluffy-mahjong/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fluffy-mahjong/250x142.png",
      path: ["/game/fluffy-mahjong", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/fluffy-mahjong.mp4",
      iframe: "https://yandex.com/games/app/361067?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Play Fluffy Mahjong, a fun tile-matching game with three difficulty levels. Match seasonal tiles, use shuffle for new chances, and enjoy dynamic, challenging gameplay!",
      l_dis:
        `Fluffy Mahjong is a puzzle game where you will need to find pairs of fuzzy chips. With three difficulty levels, dynamic graphics and increasingly tricky levels to enjoy, you can pick up a game whenever you fancy, but don’t forget that not all matches are what the immediately appear to be. In mahjong, seasonal tiles can be matched with each other, even if the pictures do not match. A fun alternative to solitaire, tile-matching games can’t always be completed, but you can use the shuffle option to maximize your chances
        `,
        tag: ["Mahjong"],
      hint: ``,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
      orientation: 1,
      title: "Magic Mahjong - playcutegames.com",
      name: ["Magic Mahjong", "magic-mahjong"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/magic-mahjong/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/magic-mahjong/250x142.png",
      path: ["/game/magic-mahjong", "https://playcutegames.com"],
      video:"https://cdn.cutedressup.in/games/cutedressup-video/magic-mahjong.mp4",
      iframe: "https://yandex.com/games/app/311912?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Play Magic Mahjong, a fun tile-matching game with three difficulty levels. Match seasonal tiles, use shuffle for new chances, and enjoy dynamic, challenging gameplay!",
      l_dis:
        `Magic Mahjong is an enchanting twist on the classic tile-matching game that offers both relaxation and challenge. With three difficulty levels, stunning graphics, and increasingly tricky levels, you'll find hours of entertainment. But beware—not all tile matches are as they seem! In this magical version of Mahjong, seasonal tiles can be paired even if their images don’t match, adding a unique strategy to the game. Whether you're looking for a quick game or a longer session, Magic Mahjong is a fun and rewarding way to spend your time. Use the shuffle feature to enhance your chances and master the board in this engaging alternative to solitaire!
        `,
        tag: ["Mahjong"],
      hint: `
      <b>Start the Game:</b>
      <ul>
      <li>Select from three difficulty levels: Easy, Medium, or Hard depending on your experience and mood.</li>
      </ul>
      <b>Gameplay:</b>
      <ul>
      <li>The objective is to remove all the tiles from the board by matching pairs of identical tiles.</li>
      <li>Seasonal tiles can be matched even if their pictures differ, but they must belong to the same season or group.</li>
      <li>Only free tiles, which are not covered and have at least one side open, can be matched.</li>
      </ul>
      <b>Strategy:</b>
      <ul>
      <li>Look ahead and plan your moves carefully to avoid getting stuck.</li>
      <li>If no matches are available, use the Shuffle option to reshuffle the remaining tiles and create new opportunities.</li>
      </ul>
      <b>Winning:</b>
      <ul>
      <li>Successfully match all the tiles to clear the board and complete the level.</li>
      <li>Keep improving your skills and aim to complete harder difficulty levels for an extra challenge!</li>
      </ul>
      <b>Additional Features:</b>
      <ul>
      <li>Enjoy dynamic visuals and tile designs that enhance the magical atmosphere of the game.</li>
      <li>Replay levels to improve your time and score, or simply relax and enjoy the process.</li>
      </ul>
      `,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Aesthetic World of Obby - The Ultimate Snake Game Experience",
      name: ["Aesthetic World of Obby", "aesthetic-world-of-obby"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/aesthetic-world-of-obby/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/aesthetic-world-of-obby/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/aesthetic-world-of-obby.mp4",
      path: ["/game/aesthetic-world-of-obby", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/360454?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Aesthetic World of Obby, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Aesthetic World of Obby is a beautiful adventure game similar to the popular parkour game Roblox. Enjoy the nice aesthetic environment, jump on colorful platforms and go through parkour maps. 72 options for customizing the obby-avatar, Open world with 10 locations with parkour, Beautiful aesthetic graphics of the levels and Convenient controls.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Pink City - The Ultimate Snake Game Experience",
      name: ["Pink City", "pink-city"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/pink-city/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/pink-city/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/pink-city.mp4",
      path: ["/game/pink-city", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/350228?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Pink City, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Pink City is a vibrant, open-world adventure where exciting tasks and endless opportunities await. Explore the colorful city, make new friends, and take on their unique challenges. Earn rewards as you complete tasks, unlock new areas, and uncover hidden secrets. Dive into a world full of surprises and build lasting friendships along the way. Ready to explore Pink City? Adventure and fun are just around the corner!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Obby: Tower of Hell - The Ultimate Snake Game Experience",
      name: ["Obby: Tower of Hell", "obby-tower-of-hell"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/obby-tower-of-hell/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/obby-tower-of-hell/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/obby-tower-of-hell.mp4",
      path: ["/game/obby-tower-of-hell", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/290656?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Obby: Tower of Hell, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Obby: Tower of Hell is a parody of the obby mode from Roblox, where you need to escape from prison. Go through difficult challenges, climb through the ventilation and try to get out of the sewer! Great variety of maps! Go through many unique rainbow obstacles. Your cellmate will always be with you! Play and have fun!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Obby Online With Friends: Draw And Jump - The Ultimate Snake Game Experience",
      name: ["Obby Online With Friends: Draw And Jump", "obby-online-with-friends-draw-and-jump"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/obby-online-with-friends-draw-and-jump/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/obby-online-with-friends-draw-and-jump/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/obby-online-with-friends-draw-and-jump.mp4",
      path: ["/game/obby-online-with-friends-draw-and-jump", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/289722?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Obby Online With Friends: Draw And Jump, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Obby online with friends: Draw and Jump! Dive into the exciting world of parkour platforming with Roblox’s Obby in this online multiplayer game! Incredible adventures await you, full of breathtaking jumps and logical challenges. In this game, you can not only show off your parkour skills but also solve various puzzles with your friends, creating your own platforms and overcoming traps. Collect Robux and take part in exciting adventures with friends online!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Obby: Mini-Games - The Ultimate Snake Game Experience",
      name: ["Obby: Mini-Games", "obby-mini-games"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/obby-mini-games/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/obby-mini-games/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/obby-mini-games.mp4",
      path: ["/game/obby-mini-games", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/335573?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Obby: Mini-Games, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `“Obby: Mini-Games” is a dynamic and fun-packed experience where you compete in a variety of mini-games and obstacle courses. Each mini-game presents a unique challenge, from navigating tricky parkour levels to solving puzzles under pressure. Perfect for players of all ages, this game offers endless entertainment and the chance to show off your skills. Can you master all the mini-games and emerge victorious? Dive in and find out!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Obby: Easy Parkour - The Ultimate Snake Game Experience",
      name: ["Obby: Easy Parkour", "obby-easy-parkour"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/obby-easy-parkour/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/obby-easy-parkour/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/obby-easy-parkour.mp4",
      path: ["/game/obby-easy-parkour", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/240398?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Obby: Easy Parkour, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `“Obby: Easy Parkour” is a fun and beginner-friendly parkour adventure where you jump, run, and climb through a series of exciting obstacle courses. With simple controls and vibrant levels, it’s perfect for both new players and parkour enthusiasts. Challenge yourself to complete each stage and improve your skills as you navigate various obstacles. Ready to master the art of parkour? Start your adventure now in this easy yet thrilling obby!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Obby Blox: Leela and the Tower of Hell - The Ultimate Snake Game Experience",
      name: ["Obby Blox: Leela and the Tower of Hell", "obby-blox-leela-and-the-tower-of-hell"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/obby-blox-leela-and-the-tower-of-hell/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/obby-blox-leela-and-the-tower-of-hell/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/obby-blox-leela-and-the-tower-of-hell.mp4",
      path: ["/game/obby-blox-leela-and-the-tower-of-hell", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/350687?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Obby Blox: Leela and the Tower of Hell, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Obby Blox: Leela and the Tower of Hell. Navigate the obstacle course to climb to the top of the Tower of Hell. Help Leela collect all the coins on the way to the top. Solve puzzles to advance further! The grand prize awaits you at the end! Jump across platforms, trying not to fall. Overcome various obstacles. Sometimes you need to think a bit to solve a puzzle and reach the top of the Tower of Hell.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Noob: Obby On A Bike - The Ultimate Snake Game Experience",
      name: ["Noob: Obby On A Bike", "noob-obby-on-a-bike"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/noob-obby-on-a-bike/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/noob-obby-on-a-bike/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/noob-obby-on-a-bike.mp4",
      path: ["/game/noob-obby-on-a-bike", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/265196?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Noob: Obby On A Bike, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Noob: Obby On A Bike. Immerse yourself in the racing world, where you will have to test your parkour skills. In this game you have to jump on colorful objects, overcoming difficulties and obstacles on your way. Your goal is to reach the finish line and become a winner!
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "My Sandbox - The Ultimate Snake Game Experience",
      name: ["My Sandbox", "my-sandbox"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-sandbox/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/my-sandbox/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/my-sandbox.mp4",
      path: ["/game/my-sandbox", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/279366?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play My Sandbox, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to My Sandbox! Immerse yourself in our exciting role-playing game My Sandbox, where life is literally in your hands. Control your virtual phone: create stylish worlds, elegant cars and start your adventure! Get a cozy home, host friends and enjoy the simple joys of life. Drive a car, explore the surroundings.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Katnap Survival And Morphs - The Ultimate Snake Game Experience",
      name: ["Katnap Survival And Morphs", "katnap-survival-and-morphs"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/katnap-survival-and-morphs/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/katnap-survival-and-morphs/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/katnap-survival-and-morphs.mp4",
      path: ["/game/katnap-survival-and-morphs", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/288891?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Katnap Survival And Morphs, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Katnap Survival And Morphs. If you like the Poppy Play Time universe, then be sure to enter the new adventure. Find the most batteries and become a leader, all players will see your nickname in the top. Earn coins and buy new smile skins. Find animals and try on the Catnap, Nightmare Huggy, Miss Delight or Dog Day morphs.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Escape the Lava: Obby - The Ultimate Snake Game Experience",
      name: ["Escape the Lava: Obby", "escape-the-lava-obby"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/escape-the-lava-obby/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/escape-the-lava-obby/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/escape-the-lava-obby.mp4",
      path: ["/game/escape-the-lava-obby", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/264786?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Escape the Lava: Obby, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `“Escape the Lava: Obby” is a thrilling arcade game where brave players embark on an ordeal filled with exciting challenges and incredible obstacles. In this exciting world where every step can be decisive, participants fight for survival by climbing towering platforms, avoiding the stream of red-hot lava that rises mercilessly from below. Players will have to show their agility, stamina and strategic thinking to overcome challenging obstacles.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Easy Robby Parkour Online - The Ultimate Snake Game Experience",
      name: ["Easy Robby Parkour Online", "easy-robby-parkour-online"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/easy-robby-parkour-online/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/easy-robby-parkour-online/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/easy-robby-parkour-online.mp4",
      path: ["/game/easy-robby-parkour-online", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/313692?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Easy Robby Parkour Online, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Easy Robby Parkour Online. The game is similar to Roblox. You have to go through a long streak of challenges, but the game has Checkpoints (saves), so you don’t have to start all over again if you fall. Play with your friends! Communicate with others through emotes! This game has special features like Colorful skins and different character models. If the game seems difficult, activate a double jump or any other boost to complete the difficult stage.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Blox Obby: Tower of Hell - The Ultimate Snake Game Experience",
      name: ["Blox Obby: Tower of Hell", "blox-obby-tower-of-hell"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/blox-obby-tower-of-hell/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/blox-obby-tower-of-hell/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/blox-obby-tower-of-hell.mp4",
      path: ["/game/blox-obby-tower-of-hell", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/232356?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Blox Obby: Tower of Hell, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Blox Obby: Tower of Hell is a parody of the Obby mode where you have to navigate through obstacle courses. Reach the end and be the first to obtain the golden crown! Huge maps! Conquer all the challenges and overcome obstacles. Collect coins to unlock all the skins. Create a unique look! Complete the obstacle course faster than anyone else and make it onto the leaderboard.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Aesthetic Obby - The Ultimate Snake Game Experience",
      name: ["Aesthetic Obby", "aesthetic-obby"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/aesthetic-obby/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/aesthetic-obby/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/aesthetic-obby.mp4",
      path: ["/game/aesthetic-obby", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/251031?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Aesthetic Obby, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `Welcome to Aesthetic Obby is a game about a small beautiful adventure, similar to the popular parkour games from Roblox. Complete the game the fastest or just enjoy the pleasant environment! In the game you will find: Interesting and challenging tasks, Beautiful, well-designed graphics, and Rest area at the end of your journey.
`,
        tag: ["Roblox"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-01-27",
      brand: "yandex",
     
      orientation: 1,
      title: "Hair Coloring - playcutegames.com",
      name: ["Hair Coloring", "hair-coloring"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/hair-coloring/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/hair-coloring/250x142.png",
      path: ["/game/hair-coloring", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/263610?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Hair Coloring Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Change the image, style, color and length of your hair. Make everyone beautiful!
        Open the most fashionable beauty salon in the city and make cool repairs in it.<br>
        
        - Make awesome haircuts and hairstyles<br>
        - We'll have to wash the clients' hair. Gently apply shampoo and conditioner.<br>
        - Don't forget to blow dry your hair, straighten and curl it.
        `,
        tag: ["Girl games","kids",],
      hint: `A client will come into your salon and ask for a dream haircut.
      Fulfill her wishes and make the girl happy!<br>
      And for this, carefully choose the shape of the haircut, hair color, jewelry and create a stylish image.<br>
      Accumulate money and do not forget to improve your salon and make cool repairs in it!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 1,
      title: "Color Snake - Slithering Train Game in a 3D World",
      name: ["Color Snake", "color-snake"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/color-snake/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/color-snake/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/color-snake.mp4",
      path: ["/game/color-snake", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/365228?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Guide your snake through a colorful forest, eating fruits and avoiding creatures in Color Snake. Fun music and playful challenges for kids!",
      l_dis:
        `If you like retro games, reflex-based games and fast-paced action, Color Snake will take you down memory lane and allow you to experience what prime mobile gaming used to look like about 27 years ago! The arcade-style sound effects and pixelated graphics are quite effective, and the gameplay is gorgeously simple yet unexpectedly challenging.
        As the snake eats the cubes across the box, it gets bigger, and the game's objective is to grow as big as possible. You can slither through the map’s dashed boundaries, but if you crash into the snake’s body or crash into the border, the game is over, and you’re forced to retry. How long do you think you can survive?<br>
`,
        tag: ["Snake"],
      hint: `The game is quite easy in the beginning, but as the snake feeds, its very length gradually becomes an ever-growing issue. Practice passing through the walls to devour cubes more efficiently, stopping on a dime, and making quick U-turns. These skills are however very important especially at the later stages of the game when the room for movement begins to shrink.
Once the snake’s body occupies a large portion of the playing field, it’s important to always have a preplanned escape route ready. Do not surround the head of the snake because you may often find yourself in situations that you cannot get out of.<br>
 <h2 class="Sub_Heading">What are the controls for Color Snake?</h2>
 <ul> 
 <li>You can play Color Snake on PC and mobile devices alike.</li>
 <li>Use <b>WASD</b> or arrow keys to move if playing on a PC</li>
 <li><b>swipe</b> to turn in the desired direction if you prefer playing on mobile.</li>
 </ul>
 <h2 class="Sub_Heading">Features:</h2>
 <ul> 
 <li>An immersive 2D retro color snake game</li>
 <li>Arcade-styled sound effects and pixelated graphics</li>
 <li>Gorgeously simple yet highly challenging gameplay</li>
 </ul>
`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "Cute Animals - Slithering Train Game in a 3D World",
      name: ["Cute Animals", "cute-animals"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cute-animals/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cute-animals/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/cute-animals.mp4",
      path: ["/game/cute-animals", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/205223?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Guide your snake through a colorful forest, eating fruits and avoiding creatures in Cute Animals. Fun music and playful challenges for kids!",
      l_dis:
        `Meet Cute Animals – a mesmerizing snake IO game that brings you to the world of cute animals and real competition. In this colourful environment, you are a cute snake that has to swallow different objects on the map to become bigger than the opponent. It doesn’t matter if you’re a pro or if this is your first time playing a game in this genre – this game will be very interesting!
`,
        tag: ["Snake"],
      hint: `To start your journey, all you need to do is to move your snake through the green field using your mouse pointer or your finger if you are using a touch screen device. The more food you take, the longer and stronger your snake becomes which increases the chances of you ruling the leaderboard. Nevertheless, pay attention to other participants—running into bigger snakes will result in your loss! This game requires good response time and strategy to beat the enemies.
       <h2 class="Sub_Heading">Features:</h2>
       <ul>
       <li><b>Charming Customization:</b> Personalize your snake with adorable animal heads like bunnies, kittens, and more for a unique gameplay experience.</li>
       <li><b>Multiplayer Mayhem:</b> Compete against players worldwide in real-time battles, climbing the leaderboard to become the biggest snake!</li>
       <li><b>Engaging Environments:</b> Explore a variety of colorful landscapes filled with delicious food items and playful animations that enhance your gaming experience.</li>
       <li><b>Exciting Gameplay:</b> Enjoy the fast-paced action with quick reflexes and strategic movement, including speed boosts for those critical moments.</li>
       </ul>
       <h2 class="Sub_Heading">Why Play Cute Animals?:</h2>
       This game is perfect for animal lovers and fans of the snake IO genre alike! With its blend of competitive gameplay, cute visuals, and easy-to-learn mechanics, Cute Animals promises hours of fun and excitement. Gather your friends and challenge each other in this delightful game, where every moment is packed with action and charm.<br><br>
       Cute Animals is waiting for you right now, so get ready to slither to the top while adorable animals are watching you! If you are interested in spending some time with your friends, playing games with no stress or if you are interested in an intense competition this game is designed for you. Sign up to the fun now and find out how long your snake will become!`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 1,
      title: "Merge Snake War - Slithering Train Game in a 3D World",
      name: ["Merge Snake War", "merge-snake-war"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-snake-war/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/merge-snake-war/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/merge-snake-war.mp4",
      path: ["/game/merge-snake-war", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/266004?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Guide your snake through a colorful forest, eating fruits and avoiding creatures in Merge Snake War. Fun music and playful challenges for kids!",
      l_dis:
        `Welcome to the new game called Merge Snake War – where tactics and shooting are combined in a great game! In this fascinating game, you lead a team of snakes and combine them to create strong partners while moving through bright territories with both obstacles and opportunities. Do you think that you are up to the task of fusing and merging and take your snakes to the top?
          <br><br>
          In Merge Snake War, the objective is simple yet engaging: combine two snakes of the same kind to get a more powerful kind. However, it is not only about merging—sophisticated planning is important! In the next stages, you will be competing with players who are equally as willing to take over. Will you be able to outcompete them and be the winner?`,
        tag: ["Snake"],
      hint: `Lead your snakes in this warzone where you have to gather resources and combine them to create your dream team. Each time you merge you get specific abilities that can help you win the battle in your favor. Strategy and wit will be your two greatest friends when it comes to choosing which snakes should be combined and when they should attack competitors.
      <h2 class="Sub_Heading">Why Choose Merge Snake War?</h2>
      <ul>
      <li><b>Strategic Merging:</b> Get the excitement of putting snakes together to make incredible beasts, which possess their own abilities. What new ability will you gain after the merges?</li>
      <li><b>Dynamic Battles:</b> Experience instant fast paced real-time combat where every action matters. On this game, can you be smart enough to beat all your competitors?</li>
      <li><b>Variety of Snakes:</b> Meet a great number of snakes of different characteristics. Which of these skills will complement the other and become your winning strategy against stronger enemies?</li>
      <li><b>Exciting Upgrades:</b> Receive in-game currency and advance your snakes, which will cause them to gain new abilities that will help you during the game. How good can your team get?</li>
      <li><b>Captivating Graphics:</b> There are nice graphics that make each combat exciting, and you can plunge into the colorful world of Merge Snake War.</li>
      </ul>
      <br>
      Are you prepared for this merging kind of journey? Merge Snake War is suitable for anybody who enjoys strategy with a touch of action all in one. Sign up today and show us your merging skills in the battle! Is it possible to gain the position of the supreme commander of snakes and become the real boss of a snake organization? The stage is set for your management—integrate, plan, and triumph!
      `,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "Forest Slither Snake - Slithering Train Game in a 3D World",
      name: ["Forest Slither Snake", "forest-slither-snake"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/forest-slither-snake/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/forest-slither-snake/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/forest-slither-snake.mp4",
      path: ["/game/forest-slither-snake", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/177171?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Guide your snake through a colorful forest, eating fruits and avoiding creatures in Forest Slither Snake. Fun music and playful challenges for kids!",
      l_dis:
        `Forest Slither Snake is a new version of the regular snake game that takes place in a forest and your snake is out on the mission of making it bigger. While navigating through the forest, you are to collect fruits and food to make your snake bigger and score better. Just remember though, hitting larger snakes or other objects will cause your run to be over so skill and timing are important.
`,
        tag: ["Snake"],
      hint: `First, choose an animal for your snake, then, using the mouse or finger, guide your snake through the forest. To grow bigger, eat the scattered fruits and food items and to avoid other snakes. It is also possible to run at a high speed with a simple double click or swipe, which can be useful for avoiding threats or reaching prey. The longer the snake becomes, the more points you receive, but if you run into a longer snake, the game is over.

<h2 class="Sub_Heading">Game Modes and Graphics:</h2>
Among the two positive aspects of Forest Slither Snake, the first one is the presence of game modes. You can play in single player and in this a person has to complete the levels in a fixed time as well as with a particular score targets. Or you can go into the multiplayer mode where you are a player against other players from all over the world in a real and very fierce battle for survival and supremacy. The general appearance of the game is colorful and cartoonish and this makes it more attractive to children and anyone else who loves games with bright graphics.
<h2 class="Sub_Heading">Customization Options:</h2>
You can even give your snake a more of an individual look by selecting from a variety of cute animal heads such as a cat, dog, fox, rabbit, or even a lion. These customization options make the games unique in a way as it lets you play as your favorite animal as you slither through the forest.
<h2 class="Sub_Heading">Other Features:</h2>
One standout feature of Forest Slither Snake is the ability to increase your snake’s speed, helping you outmaneuver opponents or quickly collect food. The dynamic gameplay, combined with the bright, engaging graphics, ensures players remain entertained, whether they’re completing challenges in single-player or dominating in multiplayer.
With its exciting game modes, customizable snakes, and captivating forest setting, Forest Slither Snake offers a fresh and fun twist on the traditional snake game, making it perfect for players of all ages.


`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "SlitherCraft.io - Slithering Train Game in a 3D World",
      name: ["SlitherCraft.io", "slithercraft-io"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/slithercraft-io/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/slithercraft-io/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/slithercraft-io.mp4",
      path: ["/game/slithercraft-io", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/248811?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Experience the thrilling world of SlitherCraft.io, a slithering train game set in a 3D environment. Eat other trains, collect screws and toolboxes, and grow longer to become the grand train!",
      l_dis:
        `Welcome to SlitherCraft.io! This is where the excitement of Slither.io collides with the creative world of Minecraft, offering a one-of-a-kind gaming experience that will keep you hooked for hours. Picture this: a world full of colorful Minecraft blocks just waiting to be consumed, all while you maneuver your snake through intense competition with other players. Are you ready to dive in?
        <h2 class="Sub_Heading">What Makes SlitherCraft.io Special?</h2> 
        In SlitherCraft.io, you control a lively snake that grows by munching on various Minecraft blocks scattered across a vibrant map. Your journey starts by gobbling up everything in sight, but beware—other snakes are on the hunt too! The bigger you grow, the more you’ll have to watch your back.
        `,
        tag: ["Snake"],
      hint: `<ul>
      <li><b>Collect and Grow:</b> Start your adventure by eating different Minecraft blocks. Each block adds length to your snake, making it stronger and more formidable in battles. Watch out for those diamond blocks—they're your key to scoring big!</li>
      <li><b>Defeat Opponents:</b> Use strategy to outsmart other players. Throw TNT at rival snakes to eliminate them and claim their blocks as your own. Remember, it’s not just about growing; it’s about surviving!</li>
      <li><b>Stay Safe:</b> Protect yourself by curling your tail around your body. This clever move can keep you safe from enemy attacks while you plot your next move.</li>
      </ul>
       <h2 class="Sub_Heading">Customize Your Snake</h2> 
      What’s better than just playing? Personalizing your snake! As you advance in the game, you can unlock various skins to make your snake truly unique. Whether you prefer a fiery design or a cool, calm look, the choice is yours.
      <h2 class="Sub_Heading">Why You’ll Love SlitherCraft.io</h2> 
      <ul>
      <li><b>Endless Fun:</b> The dynamic gameplay means there’s always something new to experience, whether you're playing solo or challenging friends.</li>
      <li><b>Engaging Community:</b>  Join a vibrant community of players from around the world and show off your skills on the leaderboard.</li>
      <li><b>Charming Aesthetics:</b>  Enjoy the playful blend of Minecraft graphics and snake gameplay, making each session visually appealing and fun</li>
      </ul>

      `,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "Trains.io 3D - Slithering Train Game in a 3D World",
      name: ["Trains.io 3D", "trains-io-3d"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/trains-io-3d/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/trains-io-3d/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/trains-io-3d.mp4",
      path: ["/game/trains-io-3d", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/170221?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Experience the thrilling world of Trains.io 3D, a slithering train game set in a 3D environment. Eat other trains, collect screws and toolboxes, and grow longer to become the grand train!",
      l_dis:
        `Do you like snake games? Do you like train games? Trains.io 3D brings these two worlds together in a fast-paced, action-packed game where you grow your train and dominate the tracks. This game is perfect for fans of multiplayer snake games and anyone who loves the thrill of train simulators with a competitive twist. Every second counts as you navigate tight spaces, collect resources, and avoid crashing into other players' trains.
        <h2 class="Sub_Heading">Why Trains.io 3D Stands Out</h2> 
        <h3 class="Sub_Heading2">1. Snake Game Mechanics with a Train Theme</h3><br>
        <p>Just like classic snake games, you grow as you eat, but here, you control a train. As you collect resources scattered across the map, your train becomes longer and more challenging to maneuver. Your mission is simple: keep growing while avoiding collisions. One wrong move, and it’s game over!</p>
        <br><h3 class="Sub_Heading2">2. Competitive Multiplayer Mode</h3><br>
        <p>Play with players from around the globe in real-time multiplayer mode. Outsmart your opponents by using clever tactics to trap them with your ever-growing train. The larger your train, the more control you have over the tracks, but remember, other players are aiming to do the same! Show off your skills and become the ultimate champion.</p>
        <br><h3 class="Sub_Heading2">3. Stunning 3D Graphics and Smooth Gameplay</h3><br>
        <p>Trains.io 3D offers impressive 3D visuals that make the gameplay feel immersive. The colorful, dynamic environment is not only visually appealing but also adds to the intensity of the game. With intuitive controls and smooth animation, every move feels responsive and exciting, perfect for players who enjoy a seamless experience.</p>
        <h2 class="Sub_Heading">Top Tips for Winning in Trains.io 3D</h2> 
        <ul>
        <li><b>Strategize Your Moves:</b> As your train grows longer, space becomes tight. Plan your moves ahead to avoid getting stuck.</li>
        <li><b>Use Your Train's Length:</b> The bigger your train, the more you can block opponents. Use your length to control the game.</li>
        <li><b>Stay on Your Toes:</b> This game is fast-paced, and opponents can strike at any moment. Stay alert and adapt quickly to the changing environment.</li>
        </ul>
        <h2 class="Sub_Heading">Why Trains.io 3D is a Must-Play</h2> 
If you love snake games, train games, or both, this game brings an entirely new experience to the table. With its unique combination of growth mechanics, strategy, and multiplayer action, Trains.io 3D offers endless fun for players looking for a challenging yet entertaining game.<br>
Are you ready to compete and grow the longest train on the tracks? Join Trains.io 3D now and start your journey to victory!
        `,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "Worm.io - Slithering io Snake Game with a Paper Theme",
      name: ["Worm.io", "worm-io"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/worm-io/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/worm-io/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/worm-io.mp4",
      path: ["/game/worm-io", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/167116?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Worm.io, an exciting slithering io snake game with a paper theme. Grow your snake by eating others and become the biggest on the map. Join the battle now!",
      l_dis:
        `Welcome to the world of Worm.io – where the population is constantly growing and one can become a prey literally in several minutes. As a small worm you have to eat as much as you can and become the big worm in the arena. But do not let the simplicity impress you because the show is filled with dangerous moments at every turn. Are you able to slide your way to the win or will you be devoured by a bigger opponent?
        <h2 class="Sub_Heading">Game Modes</h2>
        <ul>
        <li><b>Endless Mode:</b>Play until you can no longer survive. There’s no time limit—just focus on growing and surviving as long as possible.</li>
        <li><b>Timed Mode:</b>This fast-paced mode puts you against the clock. Race through a 7-minute game, where every second counts as you gather food and score points.</li>
        <li><b>Treasure Hunter Mode:</b>Explore the arena for hidden treasures while competing against other worms for the ultimate rewards.</li>
        </ul>
        <h2 class="Sub_Heading">Exciting Power-Ups</h2>
        In Worm.io, it's not just about getting big—using the right strategy is key to dominating the arena. Look for power-ups that will give you an advantage:
        <br>
        <br>
        <ul>
        <li><b>Speed Boost:</b>Get an edge in tight situations by speeding past enemies or escaping tricky traps.</li>
        <li><b>Zoom Out:</b>Gain a broader view of the arena, spotting food and avoiding dangerous worms from afar.</li>
        <li><b>Food Magnet:</b>Collect food from a greater distance, making it easier to grow without putting yourself at risk.</li>
        </ul>
        <br>
        These power-ups bring an exciting layer of strategy to the game, ensuring every match is different.
        <h2 class="Sub_Heading">Key Features</h2>
        <ul>
        <li><b>Addictive Gameplay:</b>Easy to learn, hard to master, Woerm.io offers simple controls but intense competition.</li>
        <li><b>Multiplayer Snake Game:</b>Play against real players from around the world in this massive online battle arena.</li>
        <li><b>Dynamic Power-Ups:</b>Find and use power-ups to outsmart your opponents and gain an advantage.</li>
        <li><b>Real-Time Leaderboard:</b>Compete to rank on the global leaderboard and prove you’re the ultimate worm!</li>
        </ul>
        <br>
        Ready to take on the competition? Join the battle in Worm.io, where only the smartest and quickest worms survive. Grow, strategize, and become the biggest worm in this fast-paced multiplayer snake game. Challenge yourself and climb the ranks today!
        `,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 1,
      title: "Bullet Snake - A Unique and Explosive Snake Game Experience",
      name: ["Bullet Snake", "bullet-snake"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bullet-snake/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bullet-snake/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/bullet-snake.mp4",
      path: ["/game/bullet-snake", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/282379?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Bullet Snake, a unique and exciting twist on snake games where you control a snake made of bullets. Destroy obstacles, enemies, and merge bullets for powerful combos!",
      l_dis:
        `The game offers a unique gameplay in which the player has to control a snake consisting of bullets. The main goal of the game is to destroy obstacles and enemies on the way using the power of your bullets. The trick of the game is the possibility of mixing bullets. The player can connect their bullets with each other, creating stronger and more effective bullets. The unique margin mechanics allow the player to create a variety of combos and strategies to win.
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 1,
      title: "Snake Evolution - A Unique Twist on Snake Games",
      name: ["Snake Evolution", "snake-evolution"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-evolution/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snake-evolution/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-evolution.mp4",
      path: ["/game/snake-evolution", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/274872?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Snake Evolution, an exciting twist on classic snake games. Control snakes and animals, eat others to grow stronger, and become the ultimate predator!",
      l_dis:
        `Snake Evolution, is an exciting game that combines the mechanics of the well-known snake with cute and diverse animals and snakes, offering a unique and fun experience. Simple and Fun Mechanics: Use your skills to eat other animals and become stronger. Becoming the strongest predator is your goal!.
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 1,
      title: "Snake: Modern - An Exciting Take on Classic Snake Games",
      name: ["Snake: Modern", "snake-modern"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-modern/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snake-modern/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-modern.mp4",
      path: ["/game/snake-modern", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/195667?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Snake: Modern, an evolved version of the classic snake game with enhanced graphics and sophisticated gameplay. Control the snake, consume items, and avoid colliding with your body!",
      l_dis:
        `Snake Modern brings a fresh and exciting take on the classic snake game. In this modern version, you control a sleek snake in a dynamic, fast-paced arena, where your goal is to grow longer by eating scattered food and outmaneuvering other snakes. Simple to play, but with new challenges and stunning visuals, Snake Modern redefines the timeless gameplay of snake games with a stylish twist.

           
`,
        tag: ["Snake"],
      hint: `
      Playing Snake Modern is all about mastering your movement and planning ahead. You start small, but with every piece of food you eat, your snake grows longer and faster. But be careful—the more you grow, the harder it becomes to avoid obstacles and other snakes in the arena.
      <br>
      <b>Key Tip:</b> Avoid colliding with your own tail or other snakes! The last snake standing wins, so stay sharp and react quickly to the ever-changing challenges around you.
      
      <h2 class="Sub_Heading">Power-Ups and Boosts</h2>
      Snake Modern takes things up a notch with strategic power-ups scattered throughout the arena. These power-ups provide your snake with special abilities:
      <br>
      <br>
      <ul>
      <li><b>Speed Boost:</b> Zoom ahead of opponents to grab food before they do or escape tricky situations.</li>
      <li><b>Shield:</b> Protect your snake from crashing into obstacles or other players for a limited time.</li>
      <li><b>Growth Boost:</b> Collect this and instantly grow longer, putting you ahead of the competition.</li>
      </ul>
      <br>

      Mastering these power-ups is the key to dominating the game.
      
      <h2 class="Sub_Heading">Compete and Climb the Leaderboards</h2>
      Every match in Snake Modern is a fierce battle for survival. With real-time global leaderboards, you can compete against players worldwide. Will you rise to the top and claim the title of the longest snake? Each game offers new opportunities to outmaneuver opponents and secure your place as a true snake master.

      <h2 class="Sub_Heading">Exciting Features of Snake Modern</h2>
      <ul>
      <li><b>Futuristic Visuals:</b></b> Experience a sleek and modern design that sets it apart from other snake games.</li>
      <li><b>Simple but Addictive Gameplay:</b> Easy to pick up but hard to put down—classic snake mechanics with added excitement.</li>
      <li><b>Power-Ups for Strategic Play:</b> Use special abilities to gain an advantage over your rivals.</li>
      <li><b>Competitive Multiplayer:</b> Face off against players from around the globe in thrilling snake battles.</li>
      <li><b>Global Leaderboards:</b> See how you stack up against other players and climb the ranks.</li>
      </ul>
      <br>
      <b>Are you ready to slither your way to victory?</b> Play Snake Modern now and experience the next evolution of the classic snake game. With modern graphics, exciting power-ups, and intense multiplayer action, this is the ultimate game for snake fans everywhere!
      `,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 1,
      title: "Red Snake 3D: Fast-Paced Fun in Challenging Snake Games",
      name: ["Red Snake 3D", "red-snake-3d"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/red-snake-3d/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/red-snake-3d/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/red-snake-3d.mp4",
      path: ["/game/red-snake-3d", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/367742?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Red Snake 3D, a fast-paced free snake game where you navigate icy obstacles and dodge barricades in 20 challenging levels.",
      l_dis:
        `Red Snake 3D - is a fast-paced free game where you control a snake that slithers through icy obstacles. Your goal is to reach the finish line in each of the 20 challenging levels while dodging barricades and walls. The game requires quick reflexes and determination, offering an addictive gameplay experience that you will definitely enjoy.
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "Cube Snake 2048: Combine, Grow, and Dominate in Snake Games",
      name: ["Cube Snake 2048", "cube-snake-2048"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cube-snake-2048/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cube-snake-2048/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/cube-snake-2048.mp4",
      path: ["/game/cube-snake-2048", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/304104?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Cube Snake 2048, an exciting twist on classic snake games! Collect cubes, combine numbers, and grow your snake in a massive arena.",
      l_dis:
        `A great Cube Snake 2048 game begins with cube snakes and cubes in a huge arena. You will start with a low number of snakes, collect lower numbered cubes around the map, and try to make your snake bigger by combining numbers. Collect power-ups and give your snake more advantages.
        <br><br>
        Get ready for a fresh take on the beloved Snake game with Cube Snake 2048! This unique blend of Snake and 2048 challenges you to grow your snake by collecting and merging numbered cubes in a lively 3D arena. Outplay your opponents, avoid the bigger snakes, and rise to the top by creating the biggest snake in the game.
        
        `,
        tag: ["Snake"],
      hint: `
        In this fast-paced game, you’ll collect cubes scattered across the arena. When two identical cubes collide, they merge into a single, larger number. As your snake grows longer, you’ll gain more power to swallow smaller snakes—but be careful! Bigger opponents are lurking around every corner, waiting to take your cubes.
        <h2 class="Sub_Heading">Boost Your Strategy with Power-Ups</h2>
        Scattered across the arena are power-ups that can give your cube snake the edge it needs. Whether it’s boosting your speed or making you more maneuverable, these abilities can turn the tide of battle. Use them wisely to outpace your opponents or to escape a dangerous situation!
        <h2 class="Sub_Heading">Stay Ahead of the Competition</h2>
        In Cube Snake 2048, survival is key. You’ll need to avoid bigger snakes while gobbling up smaller ones to grow stronger. Collect power-ups, merge your cubes, and climb the leaderboard to become the biggest snake on the field. The competition is fierce, and only the smartest snakes will survive!
        <h2 class="Sub_Heading">Tips for Victory</h2>
        <ul>
        <li>Merge Cubes Quickly: Combining cubes of the same number makes you grow, so keep collecting and merging for a size advantage.</li>
        <li>Target Smaller Snakes: Take down your competition by absorbing snakes with lower-numbered cubes, but avoid larger ones.</li>
        <li>Use Power-Ups to Gain an Edge: Keep an eye out for speed boosts and other power-ups that can help you escape or chase down foes.</li>
        <li>Watch Out for Halving Blocks: Some blocks will reduce your snake’s length—so dodge them to keep growing!</li>
        </ul>
        <h2 class="Sub_Heading">Features That Set Cube Snake 2048 Apart</h2>
        <ul>
        <li>Real-Time Multiplayer Arena: Compete with players from around the world in a massive arena.</li>
        <li>Addictive Merging Gameplay: The fusion of Snake and 2048 makes for a unique and engaging experience.</li>
        <li>Strategic Power-Ups: Collect special abilities to help you on your journey to becoming the largest snake.</li>
        <li>Leaderboard Challenges: Stay on top of the leaderboard by consistently growing your snake and outsmarting opponents.</li>
        </ul>
        <h2 class="Sub_Heading">Dominate the Cube Snake 2048 Arena!</h2>
        Every match in <b>Cube Snake 2048</b> is an opportunity to grow bigger and challenge players from across the globe. Whether you’re speeding through the arena or merging cubes for maximum points, this game will keep you hooked. Become the biggest cube snake and see your name rise to the top of the leaderboard!
`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-13",
      brand: "Yandex",
      orientation: 0,
      title: "Clash of Snakes: Battle, Customize, and Conquer the Arena!",
      name: ["Clash of Snakes", "clash-of-snakes"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/clash-of-snakes/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/clash-of-snakes/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/clash-of-snakes.mp4",
      path: ["/game/clash-of-snakes", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/199208?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Join the battle in Clash of Snakes! Customize your snake, conquer challenging levels, and compete against players in the arena. Can you take the top spot on the leaderboard?",
      l_dis:
        `An exciting game where you have to play as a snake! In this game you are waiting for Campaign of interesting levels with increasing difficulty! Battle against other players in the arena! Ability to choose the look of your snake from many different options! Compete with other players and try to take first place on the leaderboard!.
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 1,
      title: "Snake Run 2048 - Overcome Obstacles and Grow Your Snake!",
      name: ["Snake Run 2048", "snake-run-2048"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-run-2048/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snake-run-2048/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-run-2048.mp4",
      path: ["/game/snake-run-2048", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/210954?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Snake Run 2048, an engaging game where you control a constantly growing snake, collect 2048 cubes to increase your size and earn rewards. Suitable for all ages!",
      l_dis:
        `You need to overcome obstacles and collect 2048 cubes in order to increase your size and get more rewards at the end of the level. You control a snake that is constantly growing in size, but be careful, you may accidentally lose all the accumulated cubes.  You are waiting for a huge number of different obstacles. Each obstacle is unique in its own way. This game is suitable for people of any age and gender.
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Cute Snake io - The Ultimate Snake Game Experience",
      name: ["Cute Snake io", "cute-snake-io"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cute-snake-io/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cute-snake-io/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/cute-snake-io.mp4",
      path: ["/game/cute-snake-io", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/153846?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Cute Snake io, a fun and addictive snake game where you eat treats to grow and outmaneuver opponents. Become the top snake in the world of snake games.",
      l_dis:
        `In Cute Snake IO, you take control of an adorable snake with a unique twist—the head of a cat that loves snacking on sweets. Your goal is to help it grow larger by eating food scattered across the map, while avoiding collisions with other players and obstacles. The game’s charming graphics, featuring cute animals and adorable animations make it stand out from typical snake games, creating a fun and competitive multiplayer experience. Despite the presence of adorable faces in snakes, don’t let it fool you, competition in this multiplayer game is fierce, and you’ll need to stay sharp to survive and grow. The multiplayer format adds to the thrill, as you'll be battling players from around the world in real time. Each match is unpredictable, making every move count as you strive to become the biggest snake in the arena.
`,
        tag: ["Snake"],
      hint: `Navigate your snake using your mouse or finger, guiding it across the grass to collect fruits. Grow bigger, but be careful—colliding with other snakes or obstacles can end your run.
        <h2 class="Sub_Heading">How to Increase Your Snake’s Size:</h2>
        The key to success in Cute Snake IO is simple: eat as much food as possible to grow. Start small by collecting fruits scattered throughout the map, and as your snake grows, you can begin to outmaneuver and bump into other snakes to eliminate them. The larger you become, the more powerful and dominant you’ll be in battles, but remember, there’s always a bigger snake lurking around! Keep moving and stay cautious, as one wrong move could send you back to the start.
        <h2 class="Sub_Heading">Customization Options:</h2>
        Make your snake even cuter by choosing from a variety of animal heads, including a cat, dog, fox, bunny, tiger, or even a panda. Each customization adds a unique personality to your snake, making the gameplay experience even more fun and personal.
With its engaging multiplayer mode and endless customization, Cute Snake IO offers an exciting and competitive snake game experience with a cute twist.`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Snake Zone - The Ultimate Snake Game Experience",
      name: ["Snake Zone", "snake-zone"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-zone/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snake-zone/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-zone.mp4",
      path: ["/game/snake-zone", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/265700?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Play Snake Zone, an addictive snake game where you control a snake, eat food to grow, and customize your snake with unique skins.",
      l_dis:
        `Snake Zone is an addictive game where the player controls a snake while trying to eat as much food as possible and become the biggest snake on the field. Players can choose different skins for their snake to personalize their gaming experience. Each skin has a unique design and style, allowing players to express their personality.
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Apple Snake: Reach the Target Gate",
      name: ["Apple Snake", "apple-snake"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/apple-snake/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/apple-snake/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/apple-snake.mp4",
      path: ["/game/apple-snake", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/226916?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Guide the worm to the safe target gate by using arrow keys and feeding it apples to make it longer and reach higher and farther places.",
      l_dis:
        `<p class="P_tag">Your mission in <strong>Worm Adventure</strong> is simple yet exciting: bring the worm to the safe target gate by navigating through various obstacles and collecting apples along the way. The more apples you feed your worm, the longer its body grows, allowing it to reach higher and farther places as you progress through the game.</p>

    <h2 class="Sub_Heading2">Control the Worm</h2>
    <p class="P_tag">Use the virtual arrow keys to guide the worm through different levels filled with challenges. Whether it’s dodging obstacles or climbing to new heights, your precise movements will determine the success of your journey. The longer the worm, the more difficult the task becomes, so stay sharp and plan your moves carefully!</p>

    <h2 class="Sub_Heading2">Feed the Worm</h2>
    <p class="P_tag">Throughout each level, you’ll come across delicious apples. Feeding your worm these apples will increase its length, allowing it to reach higher platforms and navigate more complex environments. Be strategic about when and where you choose to feed the worm to ensure you can reach the target gate safely.</p>

    <h2 class="Sub_Heading2">Reach New Heights</h2>
    <p class="P_tag">With a longer body, the worm can access previously unreachable areas, which adds new dimensions to each level. By mastering the movement and growth of the worm, you'll be able to explore hidden pathways and find shortcuts to the target gate.</p>

    <h2 class="Sub_Heading2">Features of Worm Adventure</h2>
    <ul>
        <li><strong>Exciting Puzzle Adventure:</strong> Navigate your worm through various levels, growing longer and more powerful as you collect apples.</li>
        <li><strong>Simple Controls:</strong> Use virtual arrow keys to move your worm through challenging environments.</li>
        <li><strong>Length Matters:</strong> The more apples you collect, the longer your worm becomes, helping you access higher and farther areas.</li>
        <li><strong>Strategic Gameplay:</strong> Plan your moves to avoid obstacles and guide your worm safely to the target gate.</li>
        <li><strong>Multiple Levels:</strong> Each level presents new challenges and exciting ways to test your worm-control skills.</li>
    </ul>

    <p class="P_tag">Ready to guide the worm to victory? Play <strong>Worm Adventure</strong> now, collect apples, and reach the target gate in this fun and challenging puzzle game!</p>
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Mine: Snake - A Thrilling Block Snake Adventure",
      name: ["Mine: Snake", "mine-snake"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mine-snake/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/mine-snake.mp4",
      path: ["/game/mine-snake", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/308217?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Mine: Snake - the latest snake game where the goal is to become the largest creature by carefully eating food while avoiding collisions with yourself and other snakes.",
      l_dis:
        `
    <p class="P_tag">Welcome to <strong>Mine: Snake</strong>, an exciting arcade game that brings a fresh twist to the classic snake game genre. Get ready to plunge into a world full of obstacles, traps, and adventure as you control a unique block snake through various challenging levels. Your mission is to collect coins, avoid collisions, and unlock new levels while trying to survive as long as possible!</p>

    <h2 class="Sub_Heading2">Classic Mode: Go the Distance</h2>
    <p class="P_tag">Mine: Snake features a thrilling <strong>Classic Mode</strong>, where your primary goal is to travel as far as possible without hitting obstacles. As you move forward, your snake increases in length, adding more challenge and excitement to the gameplay. The farther you go, the more points you score, and the higher you can climb on the leaderboard. But watch out—each new level brings trickier paths and more obstacles to overcome!</p>

    <h2 class="Sub_Heading2">Break Blocks and Collect Coins</h2>
    <p class="P_tag">One of the unique elements in Mine: Snake is the ability to destroy blocks along your path. As you slither through the levels, you'll encounter blocks that can be broken for extra experience points. The more blocks you break, the more experience you gain, and the higher your score. Don't forget to collect coins along the way, as they are key to unlocking new levels and upgrading your snake for even more fun.</p>

    <h2 class="Sub_Heading2">Unlock New Levels</h2>
    <p class="P_tag">As you progress through Mine: Snake, new levels will be unlocked, each filled with its own set of challenges, obstacles, and rewards. With every level you complete, the difficulty increases, requiring quick reflexes and strategic planning to keep your snake safe from traps and obstacles. Can you unlock all the levels and become the ultimate Mine: Snake master?</p>

    <h2 class="Sub_Heading2">Compete on the Leaderboard</h2>
    <p class="P_tag">Do you have what it takes to reach the top? In Mine: Snake, you can compete with players from around the world for the highest score. With each block destroyed and coin collected, you’ll earn valuable points that can push you to the top of the leaderboard. The more you play, the better your chances of claiming the number one spot!</p>

    <h2 class="Sub_Heading2">Features of Mine: Snake</h2>
    <ul>
        <li><strong>Classic Arcade Adventure:</strong> Control your block snake through a series of increasingly challenging levels.</li>
        <li><strong>Break Blocks for Experience:</strong> Destroy blocks along the way to gain experience points and increase your score.</li>
        <li><strong>Collect Coins:</strong> Gather coins to unlock new levels and upgrades.</li>
        <li><strong>Leaderboards:</strong> Compete with friends and other players worldwide to become the top scorer.</li>
        <li><strong>Exciting Challenges:</strong> Each level brings new obstacles and challenges to overcome.</li>
    </ul>

    <p class="P_tag">Are you ready to dive into the thrilling adventure of <strong>Mine: Snake</strong>? Control your snake, break blocks, collect coins, and race to the top of the leaderboard. Play now and see how far you can go!</p>
`,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 0,
      title: "Snake Blast: The Ultimate Snake Game Experience",
      name: ["Snake Blast", "snake-blast"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snake-blast/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/snake-blast.mp4",
      path: ["/game/snake-blast", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/103596?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Snake Blast - the latest snake game where the goal is to become the largest creature by carefully eating food while avoiding collisions with yourself and other snakes.",
      l_dis:
        ` 
    <p>Welcome to <strong>Snake Blast</strong>, the latest and most thrilling addition to the classic snake game genre! If you're a fan of simple yet highly addictive games, then Snake Blast is perfect for you. With its intuitive controls and exciting gameplay, you’ll find yourself immersed in a world where your primary objective is to grow bigger, become stronger, and avoid obstacles at every turn.</p>

    <h2 class="Sub_Heading">Objective: Become the Largest Creature</h2>
    <p>In Snake Blast, your main goal is to become the largest creature in the game. Starting as a tiny snake, you’ll traverse the game arena, gobbling up food scattered throughout the environment. Each piece of food you consume will make your snake grow longer and stronger. But beware—growing bigger comes with its own set of challenges! The larger you get, the more difficult it becomes to navigate through tight spaces.</p>

    <h2 class="Sub_Heading">Eat Your Food Carefully</h2>
    <p>As simple as the game may sound, Snake Blast requires careful strategy and quick reflexes. You’ll need to think on your feet to navigate the arena while avoiding traps and collisions. Every move counts. Eating food increases your size, but positioning yourself in the right place to consume food without bumping into obstacles can be tricky. This adds a layer of excitement and challenge as you advance through the game.</p>

    <h2 class="Sub_Heading">Avoid Self-Collisions and Other Snakes</h2>
    <p>The key to survival in Snake Blast is avoiding collisions. If you crash into yourself or other snakes, your game ends. As your snake grows longer, maneuvering without getting tangled up becomes increasingly difficult. Similarly, the arena is filled with other snakes competing for space and food. Keep your distance from them, and make sure you don’t accidentally touch another snake, or it’s game over!</p>

    <h2 class="Sub_Heading">Tips for Success</h2>
    <ul>
        <li><strong>Plan Your Moves:</strong> As your snake grows, think ahead before making sharp turns or attempting to grab food.</li>
        <li><strong>Use Space Wisely:</strong> Try to keep to the open areas when possible to avoid running into yourself or other snakes.</li>
        <li><strong>Stay Patient:</strong> Don’t rush for every piece of food—sometimes, waiting for the right moment to grab it is the smarter move.</li>
        <li><strong>Outmaneuver Opponents:</strong> If other snakes are in your way, use your agility to outsmart and trap them while staying safe.</li>
    </ul>

    <h2 class="Sub_Heading">A Modern Twist on a Classic Genre</h2>
    <p>Snake Blast brings a fresh and modern twist to the classic snake game we all know and love. With updated graphics, more complex challenges, and a competitive edge, this game is sure to keep players hooked for hours. Whether you're a seasoned snake game veteran or a newcomer looking for a fun and engaging experience, Snake Blast offers something for everyone.</p>

    <p>Are you ready to take on the challenge? Navigate, grow, and prove that you have what it takes to become the largest creature in the arena. Play Snake Blast now and test your skills!</p>

        `,
        tag: ["Snake"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
    {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 1,
      title: "Dinosaurs Merge Master - playcutegames.com",
      name: ["Dinosaurs Merge Master", "dinosaurs-merge-master"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaurs-merge-master/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaurs-merge-master.mp4",
      path: ["/game/dinosaurs-merge-master", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/195590?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
      "Dinosaurs Merge Master blends chess-like tactics with auto-battles. Command dinosaurs and warriors, merge units to unlock powerful monsters, and defeat foes!",
      l_dis:
        "Dinosaurs Merge Master is a thrilling strategy experience that mixes chess-like tactics and fast-paced auto-battling combat. In this game, you will command troops of pre-historic dinosaurs and warriors, battling waves of menacing adversaries, including dragons, monsters, T-rex, and other kinds of dangerous dinosaurs. Your finesse and quick thinking skill will be the deciding factor that leads to victory. Unlock new powerful monsters by merging existing units. Use smart strategy to mow through enemies and reach higher challenges.",
        tag: ["Dino"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],  
    },
     {
      date: "2024-09-11",
      brand: "Yandex",
      orientation: 1,
      title: "Merge Master: Dinosaurs War 🕹️ Play on Playcutegames",
      name: ["Merge Master: Dinosaurs War", "merge-master-dinosaurs-war"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-master-dinosaurs-war/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/merge-master-dinosaurs-war/520x295.png",
      video:"https://cdn.cutedressup.in/games/cutedressup-video/merge-master-dinosaurs-war.mp4",
      path: ["/game/merge-master-dinosaurs-war", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/264296?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "",
      s_dis:
        "Merge Master: Dinosaurs War is a real-time strategy game where you merge dragons, monsters, and archers to battle dinosaurs. Enjoy epic dino clashes and win the fight!",
      l_dis:
        "Merge Master: Dinosaurs War is a cool real-time strategy, dinosaur epic battle simulator like monster legends and merge dragons, for everyone that want to merge dragons and monsters, join and clash dinosaurs, merge archers and enjoy the dino bash and win the fight.",
        tag: ["Dino"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.8,15102,1014],
    },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 1,
    title: "Dinosaur Attack 🕹️ Play on Playcutegames",
    name: ["Dinosaur Attack", "dinosaur-attack"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-attack/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-attack/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaur-attack.mp4",
    path: ["/game/dinosaur-attack", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/230518?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Dive into the world of dinosaurs! Collect eggs to transform into powerful warriors and battle rivals. Choose your fighter wisely and dominate the arena with epic battles!",
    l_dis:
      "Welcome to the exciting world of dinosaurs! In this thrilling arcade game, you will find yourself in the times of dinosaurs, where you have to collect as many dinosaur eggs as possible! After you gather a sufficient amount of eggs, your dinosaurs will transform into mighty warriors, and you will battle against other dinosaurs. Choose your fighter wisely, as each of them possesses unique abilities and characteristics. Become the bravest dinosaur, collect all types of eggs, and prove your dominance in epic battles in the arena!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 1,
    title: "Merge: Dinosaur History 🕹️ Play on Playcutegames",
    name: ["Merge: Dinosaur History", "merge-dinosaur-history"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-dinosaur-history/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/merge-dinosaur-history/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/merge-dinosaur-history.mp4",
    path: ["/game/merge-dinosaur-history", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/366201?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Play Merge: Dinosaur History! Merge fossils to collect dinosaur species, unlock the T-Rex, and top the leaderboard. Score with combos and compete for the highest rank!",
    l_dis:
      "Immerse yourself in the captivating world of ancient dinosaurs! In our app, fossils drop into the game area and merge when they match! Collect all dinosaur species and unlock the legendary T-Rex. Compete with other players on the leaderboard! Each new fossil earns you more points, and combos give bonus points. Score the most points and claim the top spot on the leaderboard!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },{
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 0,
    title: "Jurassic Park: Dino Island Idle Tycoon 🕹️ Play on Playcutegames",
    name: ["Jurassic Park: Dino Island Idle Tycoon 3D", "jurassic-park-dino-island-idle-tycoon-3d"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/jurassic-park-dino-island-idle-tycoon-3d/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/jurassic-park-dino-island-idle-tycoon-3d.mp4",
    path: ["/game/jurassic-park-dino-island-idle-tycoon-3d", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/232987?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Manage your dino-park, grow dinosaurs from eggs to giants, and expand to attract visitors. Improve your park, create hybrids, and surprise the world!",
    l_dis:
      "You are the new manager of the dino-park, congratulations! Your task will be to grow dinosaurs from a small egg to huge reptiles! Of course you will have assistants, but no one will work for you for free, so you need to expand the park and attract more visitors who will give everything just to see these big dinosaurs up close! You will also be able to improve your park to earn more money and grow new hybrids that will help you to surprise the world!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "Little Archaeologists: Search for Dinosaurs 🕹️ Play on Playcutegames",
    name: ["Little Archaeologists: Search for Dinosaurs", "little-archaeologists-search-for-dinosaurs"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/little-archaeologists-search-for-dinosaurs/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/little-archaeologists-search-for-dinosaurs/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/little-archaeologists-search-for-dinosaurs.mp4",
    path: ["/game/little-archaeologists-search-for-dinosaurs", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/367680?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Play Little Archaeologists: Search for Dinosaurs! Explore deserts as a fearless archaeologist and uncover the remains of the oldest dinosaurs. Discover hidden secrets!",
    l_dis:
      "There are all kinds of secrets hidden underground in the deserts, and we need fearless explorers to help us uncover the past. Become a real archaeologist and go on an expedition with us in search of the remains of the oldest dinosaurs.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "Colored Dino: Run and Jump! 🕹️ Play on Playcutegames",
    name: ["Colored Dino: Run and Jump!", "colored-dino-run-and-jump"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/colored-dino-run-and-jump/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/colored-dino-run-and-jump/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/colored-dino-run-and-jump.mp4",
    path: ["/game/colored-dino-run-and-jump", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/251781?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Play Colored Dino: Run and Jump! Guide a dinosaur through the desert, jump over cacti, and duck pterodactyls. Score high to climb the leaderboard and beat your friends!",
    l_dis:
      "Colored Dino: Run and Jump! is an interesting and addictive browser arcade game in which you have to play as a dinosaur running through the desert. Jump over cacti and crouch under pterodactyls to survive! Score as many points as you can to climb the leaderboard and overtake your friends!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "CR Dino Run 🕹️ Play on Playcutegames",
    name: ["CR Dino Run", "cr-dino-run"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/cr-dino-run/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/cr-dino-run/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/cr-dino-run.mp4",
    path: ["/game/cr-dino-run", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/193944?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Play CR Dino Run, a classic dino run game! Score 10 points to level up and increase speed. Dodge cacti and flying dinosaurs, unlock 5 dino characters, and use the dino menu!",
    l_dis:
      "Each time you score 10 points, the level is increased by one and the game goes faster. There are cacti and flying dinosaurs between the obstacles. There are five dinosaur characters you can play within the game. You can unlock these characters by scoring what you need to unlock. You can go to the dinosaur menu by the dinosaur button on the main menu.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "Dinosaur evolution simulator Online 🕹️ Play on Playcutegames",
    name: ["Dinosaur evolution simulator Online", "dinosaur-evolution-simulator-online"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-evolution-simulator-online/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-evolution-simulator-online/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaur-evolution-simulator-online.mp4",
    path: ["/game/dinosaur-evolution-simulator-online", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/370735?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Hunt dinosaurs to evolve and upgrade your skills. Grow into a powerful predator, but beware of other players and their ferocious dinosaurs in this competitive world!",
    l_dis:
      "Hunt other dinosaurs to grow and develop. Each successful hunt is a step in your evolution. Upgrade your characteristics and turn into a stronger and more ferocious predator. But do not forget, you are not alone! This world is also inhabited by other players who control their own dinosaurs, battles with them are dangerous, so be careful.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 1,
    title: "Dinosaur Eggs Pop 🕹️ Play on Playcutegames",
    name: ["Dinosaur Eggs Pop", "dinosaur-eggs-pop"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-eggs-pop/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-eggs-pop/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaur-eggs-pop.mp4",
    path: ["/game/dinosaur-eggs-pop", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/191040?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Travel back to the Jurassic era with Dinosaur Eggs Pop! Join the cutest dinosaur babysitter on a relaxing bubble shooting adventure. No downloads needed—just jump in and play!",
    l_dis:
      "Get ready to travel back to the Jurassic era with Dinosaur Eggs Pop! Join Dinosaur, the cutest dinosaur babysitter of the Bubble Jungle, on an endless and relaxing bubble shooting adventure. With no downloads necessary, it's easy to jump right into the fun.Get ready to travel back to the Jurassic era with Dinosaur Eggs Pop! Join Dinosaur, the cutest dinosaur babysitter of the Bubble Jungle, on an endless and relaxing bubble shooting adventure. With no downloads necessary, it's easy to jump right into the fun.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 1,
    title: "Dragons 2048 🕹️ Play on Playcutegames",
    name: ["Dragons 2048", "dragons-2048"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dragons-2048/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dragons-2048/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dragons-2048.mp4",
    path: ["/game/dragons-2048", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/351804?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Immerse in a magical dragon world with a game like 2048 but featuring colorful dragons. Unlock all dragons for a fun and intriguing collecting experience!",
    l_dis:
      "Immerse yourself in the magical world of dragons with a game that resembles 2048, but instead of numbers there are cute and colorful dragons.The main goal is to unlock all the dragons, which adds an element of collecting and intrigue.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 1,
    title: "Dinopark: survive in the maze 🕹️ Play on Playcutegames",
    name: ["Dinopark: survive in the maze", "dinopark-survive-in-the-maze"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinopark-survive-in-the-maze/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinopark-survive-in-the-maze/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinopark-survive-in-the-maze.mp4",
    path: ["/game/dinopark-survive-in-the-maze", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/311447?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Dinopark: Survive in the Maze is a fun puzzle game that challenges your wits. Explore a labyrinth, defeat dinosaurs, and navigate rooms to escape the maze!",
    l_dis:
      "Dinopark: survive in the maze is a fun puzzle game that will help develop the battle of wits. In this educational game you will explore a labyrinth while defeating dinosaurs. To get out of the maze, you need to choose rooms with a lower number than yours and avoid dinosaurs of a higher level.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "Dinosaur Shifting Run 🕹️ Play on Playcutegames",
    name: ["Dinosaur Shifting Run", "dinosaur-shifting-run"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-shifting-run/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaur-shifting-run/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaur-shifting-run.mp4",
    path: ["/game/dinosaur-shifting-run", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/361174?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Sprint across deserts and transform into a dinosaur to smash through obstacles in lush jungles. Dinosaur Shifting Run offers unique challenges and fresh gameplay in every level!",
    l_dis:
      "Do you love mini dinos? Dream of becoming a veterinarian? Save the dinos in the new game Save pet Dino vet clinic! Develop your veterinary clinic, hire assistants, buy new equipment and become the best vet in the dino world! Treat cute mini dinos: herbivores and predators, cheerful and funny. Diagnose, give medicine, catch patients and much more. Enjoy colorful graphics and addictive gameplay.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-09-11",
    brand: "Yandex",
    orientation: 0,
    title: "Save Pet Dino Vet Clinic 🕹️ Play on Playcutegames",
    name: ["Save Pet Dino Vet Clinic", "save-pet-dino-vet-clinic"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/save-pet-dino-vet-clinic/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/save-pet-dino-vet-clinic/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/save-pet-dino-vet-clinic.mp4",
    path: ["/game/save-pet-dino-vet-clinic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/310611?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Love mini dinos? Become a vet in Save Pet Dino Vet Clinic! Develop your clinic, hire staff, and treat cute dinos. Diagnose, medicate, and enjoy colorful graphics!",
    l_dis:
      "Do you love mini dinos? Dream of becoming a veterinarian? Save the dinos in the new game Save pet Dino vet clinic! Develop your veterinary clinic, hire assistants, buy new equipment and become the best vet in the dino world! Treat cute mini dinos: herbivores and predators, cheerful and funny. Diagnose, give medicine, catch patients and much more. Enjoy colorful graphics and addictive gameplay.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 1,
    title: "Dinosaurs.io 🕹️ Play on Playcutegames",
    name: ["Dinosaurs.io", "dinosaurs-io"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaurs-io/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dinosaurs-io/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dinosaurs-io.mp4",
    path: ["/game/dinosaurs-io", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/240032?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Grow the biggest Tyrannosaurus! Eat humans, gain mass, and attack other dinosaurs to become the ultimate winner. Dominate the prehistoric world!",
    l_dis:
      "Grow the biggest Tyrannosaurus! Eat humans, gain mass, attack other dinosaurs and become the winner!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 0,
    title: "Dino Survival: 3D Simulator 🕹️ Play on Playcutegames",
    name: ["Dino Survival: 3D Simulator", "dino-survival-3d-simulator"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dino-survival-3d-simulator/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/dino-survival-3d-simulator/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dino-survival-3d-simulator.mp4",
    path: ["/game/dino-survival-3d-simulator", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/350747?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Survive Jurassic Park! Build your base, collect resources, fight dinosaurs, raid enemy bases, and explore mysterious locations. Raft down rivers and become stronger!",
    l_dis:
      "Survive Jurassic Park! Build your base! Go on a journey to different locations. Collect resources, fight dinosaurs. Go on raids on enemy bases. Infiltrate, become stronger, build buildings and transport. Raft down the river! Explore this world full of mysteries!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 1,
    title: "Mini Dino Park 🕹️ Play on Playcutegames",
    name: ["Mini Dino Park", "mini-dino-park"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mini-dino-park/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mini-dino-park/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/mini-dino-park.mp4",
    path: ["/game/mini-dino-park", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/241508?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Mini Dino Park is a business simulation game where you expand your island, build dinosaur nests, and attract visitors. Upgrade facilities, guide tourists, and cruise for more income!",
    l_dis:
      "Mini Dino Park is a business simulation game. You need to earn revenue by entertaining customers and selling tickets. In the game, you need to expand the area of the island, build dinosaur nests, build rides and unlock new dinosaurs to attract more visitors. Upgrading park facilities and visitor levels can help you earn more income. Guide lost tourists and return lost wallets to get unexpected gifts. Cruise on the sea to get more income. Come and build your dinosaur park!",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],
  },
     {
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 1,
    title: "Merge Dinosaur: Jurassic World - playcutegames.com",
    name: ["Merge Dinosaur: Jurassic World", "merge-dinosaur-jurassic-world"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-dinosaur-jurassic-world/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/merge-dinosaur-jurassic-world.mp4",
    path: ["/game/merge-dinosaur-jurassic-world", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/228988?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Merge Dinosaur: Jurassic World is a real-time strategy game. Combine dinosaurs and warriors to defeat dragons and monsters. Use strategy to win battles!",
    l_dis:
      "Merge Dinosaur: Jurassic World is a cool real—time strategy game that is suitable for everyone. The main goal of the game is to defeat all enemies by combining your warriors or dinosaurs. Your enemies will be dragons, monsters, tyrannosaurs or other dinosaurs, so it won't be easy. React and think fast. Use your strategy and tactics to win the battle and advance to the next level. Fight the final boss by combining all your creatures! At each stage of this test, an increasingly powerful monster will be waiting for you! Apply your strategy in real time and determine the correct drawing order. ",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],  
  },
  {
    date: "2024-04-25",
    brand: "Yandex",
    orientation: 0,
    title: "Dino Dodge - playcutegames.com",
    name: ["Dino Dodge", "dino-dodge"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/dino-dodge/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/dino-dodge.mp4",
    path: ["/game/dino-dodge", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/354567?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
      "Dino Dodge is an exciting 2D endless runner. Sprint as fast as you can while dodging deadly monsters, ugly creatures, flying goblins, and dragons!",
    l_dis:
      "Dino Dodge is an exciting 2D endless running game where have to run continuously as fast as you can while dodging through the deadly monsters, ugly creatures, flying goblins, and dragons.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],  
  },
  {
    date: "2024-04-25",
    brand: "Yandex",
   
    orientation: 1,
    title: "Merge Dinosaur Fight - playcutegames.com",
    name: ["Merge Dinosaur Fight", "merge-dinosaur-fight"],
    thumb: "https://cdn.cutedressup.in/games/cutedressup_new/merge-dinosaur-fight/250x142.png",
    thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashion-intern-a-journey-into-style/520x295.png",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/merge-dinosaur-fight.mp4",
    path: ["/game/merge-dinosaur-fight", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/343585?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis:
    "Strategize and fuse dinosaurs and warriors in epic battles! Combine troops to defeat enemies in this thrilling Jurassic dinosaur game. Win with skill!",
    l_dis:
      "Your quest is simple...use your strategy to combine the dinosaurs or warriors on your battlefield and fight against the enemies! You must make a fusion of your troops and merge animals quickly if you want to win in this jurassic dinosaur epic battle simulator in one of coolest dinosaur games.",
      tag: ["Dino"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.8,15102,1014],  
  },
  
    {
      date: "2024-01-27",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Cat Puzzle: Draw to Kitten - playcutegames.com",
      name: ["Cat Puzzle: Draw to Kitten", "cat-puzzle-draw-to-kitten"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/cat-puzzle-draw-to-kitten/250x142.png",
      thumb_big:"https://cdn.cutedressup.in/games/cutedressup_new/cat-puzzle-draw-to-kitten-300.jpg",
      path: ["/game/cat-puzzle-draw-to-kitten", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/278373?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cat Puzzle: Draw to Kitten Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `The game is controlled using the mouse or your finger (if playing on a mobile device). Press the right mouse button or use your finger to drag and drop items to their desired locations.
  
        The game offers various locations and levels, each of which may present different challenges. You'll need to sort items of the same type, find matching items, and try to fit the required quantity into a box.
        
        Hints will accompany you throughout the game to ensure you don't get stuck and always know what to do!
        `,
        tag: ["Arcade","Puzzles","Girl games"],
      hint: `1. Drag your finger over the cat and start drawing a line towards the kittens;<br>
      2. Be careful, avoid enemies and obstacles on the way;<br>
      3. Make sure all cats take their kittens home safely!`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Organizer master - playcutegames.com",
      name: ["Organizer master", "organizer-master"],
      thumb: "https://avatars.mds.yandex.net/get-games/2977039/2a000001896479d16029063c1603dd7bc4e3/icon1",
      thumb_big: "https://avatars.mds.yandex.net/get-games/2977039/2a000001896479d16029063c1603dd7bc4e3/icon1",
      path: ["/game/organizer-master", "https://playcutegames.com"],
      iframe: "https://yandex.com/games/app/235877?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Organizer master Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "An engaging 3D puzzle with diverse levels. Find a pair, arrange objects so that everything fits, and sort items by their type. Develop spatial thinking and solve challenges. Enjoy high-quality 3D graphics in an interesting game. A set of levels of varying difficulty will provide you with hours of captivating entertainment. Immerse yourself in the exciting world of puzzles and develop your skills!",
        tag: ["Arcade","Puzzles","Girl games"],
      hint: `The game is controlled using the mouse or your finger (if playing on a mobile device). Press the right mouse button or use your finger to drag and drop items to their desired locations.
  
      The game offers various locations and levels, each of which may present different challenges. You'll need to sort items of the same type, find matching items, and try to fit the required quantity into a box.
      
      Hints will accompany you throughout the game to ensure you don't get stuck and always know what to do!`,
      rating:[4.4,1210,114],  
    },{
      date: "2024-02-20",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie And Friends Floral Outfits - playcutegames.com",
      name: ["Ellie And Friends Floral Outfits", "ellie-and-friends-floral-outfits"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-floral-outfits/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-floral-outfits/520x295.png",
      path: ["/game/ellie-and-friends-floral-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-friends-floral-outfits/",
      Walkthrough: "https://www.youtube.com/embed/T5xfaOEuitc",
      s_dis:
      "Ellie And Friends Floral Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dress in the beauty of nature with ‘Ellie and Friends Floral Outfits’! Explore a blossoming collection of stylish ensembles and vibrant accessories. Elevate your look with the freshness of floral fashion in this exciting adventure. Join Ellie and Friends as they set trends with petals and patterns, making every outfit a garden of style. From casual strolls to garden parties, embrace the allure of floral elegance in your wardrobe. Let the fashion bloom!",
        tag: ["Barbie","Spring","Floral","Dress up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Trendy Off Shoulder Look - playcutegames.com",
      name: ["Lovie Chic’s Trendy Off Shoulder Look", "lovie-chics-trendy-off-shoulder-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-trendy-off-shoulder-look/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-trendy-off-shoulder-look/520x295.png",
      path: ["/game/lovie-chics-trendy-off-shoulder-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-trendy-off-shoulder-look/",
      Walkthrough: "https://www.youtube.com/embed/tqcQnr8B31Q",
      s_dis:
      "Lovie Chic’s Trendy Off Shoulder Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the chic world of Lovie Chic’s Trendy Off Shoulder Look! Explore fashionable ensembles, stylish accessories, and the allure of the off-shoulder trend. Join Lovie Chic on a fashion journey where every look exudes sophistication. Elevate your style with off-shoulder elegance and let Lovie Chic be your guide to the ultimate trendy wardrobe. From casual outings to glamorous evenings, discover the charm of the off-shoulder look with Lovie Chic’s expert inspiration!",
        tag: ["Lovie Chic","Dress up","Exclusive","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-31",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Lunar New Year - playcutegames.com",
      name: ["Celebrity Lunar New Year", "celebrity-lunar-new-year"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-lunar-new-year/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-lunar-new-year/520x295.png",
      path: ["/game/celebrity-lunar-new-year", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-lunar-new-year/",
      Walkthrough: "https://www.youtube.com/embed/9wubQ4LgpFI",
      s_dis:
      "Celebrity Lunar New Year Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the star-studded glamour of ‘Celebrity Lunar New Year’! Explore festive ensembles, elegant accessories, and the magic of the lunar celebration. Join celebrities as they set the trend for the perfect Lunar New Year look. Elevate your style and embrace the cultural richness with the ultimate celebrity-inspired fashion. From traditional elegance to modern chic, let the stars guide you in creating a memorable and stylish Lunar New Year wardrobe. Celebrate in glamour with Celebrity Lunar New Year!",
        tag: ["Celebrity","Dress up","Exclusive","New year","Chinese","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-01-22",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Biker Vogue - playcutegames.com",
      name: ["Celebrity Biker Vogue", "celebrity-biker-vogue"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-biker-vogue/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-biker-vogue/520x295.png",
      path: ["/game/celebrity-biker-vogue", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-biker-vogue/",
      Walkthrough: "https://www.youtube.com/embed/2YemTZgukf8",
      s_dis:
      "Celebrity Biker Vogue Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Rev up your style with ‘Celebrity Biker Vogue’! Explore the fusion of edgy and chic as celebrities redefine biker fashion. From leather jackets to sleek helmets, witness the clash of high fashion on the open road. Join the ride, elevate your style, and embrace the rebellious glamour of celebrity biker vogue. Let the stars guide you on a fashionable journey where the road becomes a runway, and every outfit is a statement. Gear up for a stylish adventure in the world of Celebrity Biker Vogue!",
        tag: ["Celebrity","Dress up","Exclusive","Travel","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2024-01-24",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s #CandyLand Fashion - playcutegames.com",
      name: ["Lovie Chic’s #CandyLand Fashion", "lovie-chics-candyland-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-candyland-fashion/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-candyland-fashion/520x295.png",
      path: ["/game/lovie-chics-candyland-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-candyland-fashion/",
      Walkthrough: "https://www.youtube.com/embed/jDgvI6RqPcg",
      s_dis:
      "Lovie Chic’s #CandyLand Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the sweet world of Lovie Chic’s #CandyLand Fashion! Explore sugary-sweet ensembles, vibrant accessories, and candy-inspired styles. Join the chic adventure as Lovie Chic sets trends in the colorful Candy Land. Elevate your style with whimsical fashion, from candy-coated hues to playful patterns. Let the sugary magic inspire your wardrobe and make a bold, sweet statement in every look. Indulge in the delightful journey of fashion with Lovie Chic in #CandyLand!",
        tag: ["Lovie Chic","Dress up","Exclusive","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-01-12",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Snow White All Around The Fashion - playcutegames.com",
      name: ["Snow White All Around The Fashion", "snow-white-all-around-the-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/snow-white-all-around-the-fashion/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/snow-white-all-around-the-fashion/520x295.png",
      path: ["/game/snow-white-all-around-the-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/snow-white-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/yYQZy68u5KU",
      s_dis:
      "Snow White All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the enchanting world of ‘Snow White All Around The Fashion’! Explore a magical wardrobe with styles ranging from princess elegance to modern chic. Join Snow White on a fashion adventure, discover the latest trends, and elevate your style with a touch of fairy-tale glamour. With a mix of classic and contemporary ensembles, embrace the magic of fashion in the fairy-tale realm. From the forest to the ballroom, let Snow White inspire your wardrobe with timeless charm!",
        tag: ["Princess","Dress up","Exclusive","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-01-04",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Mermaid All Around The Fashion - playcutegames.com",
      name: ["Mermaid All Around The Fashion", "mermaid-all-around-the-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/mermaid-all-around-the-fashion/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/mermaid-all-around-the-fashion/520x295.png",
      path: ["/game/mermaid-all-around-the-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/mermaid-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/jflKIGiVgQc",
      s_dis:
      "Mermaid All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the diverse world of ‘Mermaid All Around The Fashion! Explore a myriad of styles, from casual chic to elegant evening looks. Join the Mermaid Princess fashion journey, discover the latest trends, and elevate your style game. With an array of outfits and accessories, the possibilities are endless. Unleash your creativity and make a statement in every ensemble. From day to night, Mermaid Princess has you covered in the ever-evolving world of fashion!",
        tag: ["Princess","Dress up","Exclusive","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-01-19",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Valentine Hidden Heart - playcutegames.com",
      name: ["Valentine Hidden Heart", "valentine-hidden-heart"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/valentine-hidden-heart/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/valentine-hidden-heart/520x295.png",
      path: ["/game/valentine-hidden-heart", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bestgamespot/valentine-hidden-heart/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Valentine Hidden Heart Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Dive into a world of love and discovery with "Valentine Hidden Heart" - the ultimate romantic hidden object adventure! Unleash your inner cupid as you embark on a delightful quest to find the 10 hidden hearts in each of the 15 enchanting levels. `,
        tag: ["Hidden","Valentine","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-11",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Fruit Merge Reloaded - playcutegames.com",
      name: ["Fruit Merge Reloaded", "fruit-merge-reloaded"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fruit-merge-reloaded/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fruit-merge-reloaded/520x295.png",
      path: ["/game/fruit-merge-reloaded", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bestgamespot/fruit-merge-reloaded/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Fruit Merge Reloaded Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the juicy and colorful world of Fruit Merge Reloaded, a captivating merging game that combines the addictive gameplay of 2048 with the sweetness of your favorite fruits! Your task is to merge identical fruits to create new and exotic varieties, aiming to reach the ultimate fruit fusion.",
        tag: ["Marge","Matching","Puzzle"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2024-01-04",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity In Venice Carnival - playcutegames.com",
      name: ["Celebrity In Venice Carnival", "celebrity-in-venice-carnival"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-in-venice-carnival/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-in-venice-carnival/520x295.png",
      path: ["/game/celebrity-in-venice-carnival", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-in-venice-carnival/",
      Walkthrough: "https://www.youtube.com/embed/p78SmfCqh5k",
      s_dis:
      "Celebrity In Venice Carnival Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Experience the enchantment of the ‘Celebrity in Venice Carnival’! Immerse yourself in the allure of iconic masks, glamorous costumes, and star-studded celebrations. Join celebrities as they navigate the romantic canals in style. From elegant gowns to mysterious masks, witness the clash of high fashion in the heart of Venice. Elevate your fashion game, embrace the carnival spirit, and let the stars guide you through this glamorous Venetian adventure!",
        tag: ["Celebrity","Dress up","Exclusive","Venice Carnival","Festival","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-12-16",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie NYE Sequin Party - playcutegames.com",
      name: ["Ellie NYE Sequin Party", "ellie-nye-sequin-party"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-nye-sequin-party/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-nye-sequin-party/520x295.png",
      path: ["/game/ellie-nye-sequin-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-nye-sequin-party/",
      Walkthrough: "https://www.youtube.com/embed/_vq69fIIoyA",
      s_dis:
      "Ellie NYE Sequin Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the glamour of Ellie NYE Sequin Party! Explore dazzling sequined ensembles, sparkling accessories, and celebrate the countdown in style. Join the glitzy festivities as Ellie sets the trend for the perfect New Year’s Eve look. Elevate your party style and shine into the new year with Ellie’s dazzling inspiration. Unleash the magic of sequins and make your NYE celebration a shimmering sensation!",
        tag: ["Barbie","Dress up","Exclusive","Newyear","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-12-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bffs Fabulous Winter Look - playcutegames.com",
      name: ["Bffs Fabulous Winter Look", "bffs-fabulous-winter-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-fabulous-winter-look/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-fabulous-winter-look/520x295.png",
      path: ["/game/bffs-fabulous-winter-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-fabulous-winter-look/",
      Walkthrough: "https://www.youtube.com/embed/LCfiBeabXXg",
      s_dis:
      "Bffs Fabulous Winter Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into winter glamour with BFFs Fabulous Winter Look! Explore chic ensembles, cozy layers, and trendy accessories. Join the BFFs as they set the trend for the perfect winter look. Elevate your style and embrace the magic of the season with the ultimate BFFs winter fashion inspiration. From festive gatherings to snowy adventures, let the BFFs guide you to a fabulously fashionable winter!",
        tag: ["Barbie","Dress up","Exclusive","Winter","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },{
      date: "2023-01-17",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Ellie And Friends Get Ready For First Date - playcutegames.com",
      name: ["Ellie And Friends Get Ready For First Date", "ellie-and-friends-get-ready-for-first-date"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-get-ready-for-first-date/230x131.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-get-ready-for-first-date/520x295.png",
      path: ["/game/ellie-and-friends-get-ready-for-first-date", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-friends-get-ready-for-first-date/",
      Walkthrough: "https://www.youtube.com/embed/qWZ5BW50u8I",
      s_dis:
      "Ellie And Friends Get Ready For First Date Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Join Ellie And Friends Get Ready For First Date! Explore chic ensembles, trendy accessories, and glamorous styles to make a lasting impression. Dive into the excitement of the first date as these fashion-forward friends set the trend for the perfect look. Elevate your style game, embrace the thrill of romance, and let Ellie and Friends guide you through a fashionable journey to a memorable first date. Make every moment stylish and unforgettable!",
        tag: ["Barbie","Dress up","Exclusive","Valentine","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-12-20",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Winter Ice Skating Look - playcutegames.com",
      name: ["BFFs Winter Ice Skating Look", "bffs-winter-ice-skating-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-winter-ice-skating-look/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-winter-ice-skating-look/520x295.png",
      path: ["/game/bffs-winter-ice-skating-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-winter-ice-skating-look/",
      Walkthrough: "https://www.youtube.com/embed/ExpM8AzpD4U",
      s_dis:
      "BFFs Winter Ice Skating Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the frosty enchantment of BFFs Winter Ice Skating Look! Explore chic winter ensembles, cozy layers, and stylish accessories for the perfect ice-skating adventure. Join the BFFs as they glide through the season in fashion-forward attire. Elevate your winter style and embrace the magic of frosty fashion with the ultimate Ice Skating Look inspiration. Let the chilly breeze be your runway!",
        tag: ["Lovie Chic","Dress up","Exclusive","Winter","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },{
      date: "2023-02-01",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Angela valentine Story Deep Water - playcutegames.com",
      name: ["Angela valentine Story Deep Water", "angela-valentine-story-deep-water"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-valentine-story-deep-water/230x131.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-valentine-story-deep-water-300.jpg",
      path: ["/game/angela-valentine-story-deep-water", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/angela-valentine-story-deep-water/",
      Walkthrough: "https://www.youtube.com/embed/hgg4vM9cKu4",
      s_dis:
      "Angela valentine Story Deep Water Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Angela valentine Story Deep Water. Angela feels lonely after her BF hates her so much in recent days. She asked help to her friend. Her friend gave her the gain his love back using a love potion. But she needs to go on an adventure hunt to get the love potion. Help her to join back to her BF.",
      tag: ["Angela","Dress up","Girl games","Fashion","Valentine","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-12-12",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Warm And Chic Look - playcutegames.com",
      name: ["Celebrity Warm And Chic Look", "celebrity-warm-and-chic-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-warm-and-chic-look/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-warm-and-chic-look/520x295.png",
      path: ["/game/celebrity-warm-and-chic-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-warm-and-chic-look/",
      Walkthrough: "https://www.youtube.com/embed/tefsFgEh1Vo",
      s_dis:
      "Celebrity Warm And Chic Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Experience Celebrity Warm and Chic Look! Immerse yourself in the cozy elegance of star-inspired winter fashion. From luxurious coats to trendy accessories, discover the secrets behind celebrities’ stylish warmth. Join the fashion journey and elevate your cold-weather wardrobe with the perfect blend of comfort and glamour. Embrace winter in celebrity style!",
        tag: ["Celebrity","Dress up","Exclusive","Winter","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    
    {
      date: "2023-12-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrities Night Out Outfits - playcutegames.com",
      name: ["Celebrities Night Out Outfits", "celebrities-night-out-outfits"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrities-night-out-outfits/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrities-night-out-outfits/520x295.png",
      path: ["/game/celebrities-night-out-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrities-night-out-outfits/",
      Walkthrough: "https://www.youtube.com/embed/ET7ZZotyQzc",
      s_dis:
      "Celebrities Night Out Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Explore the glitz and glamour of Celebrities Night Out Outfits! Step into the world of high fashion as your favorite stars showcase their iconic styles. From red carpet elegance to chic street looks, discover the secrets behind their stunning night-out ensembles. Join the fashion parade and get inspired to elevate your own evening wardrobe. Uncover the allure of celebrity fashion and make every night out a memorable style statement!",
        tag: ["Celebrity","Dress up","Exclusive","Holiday","Christmas","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2022-12-07",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Perfect Christmas Costumes - playcutegames.com",
      name: ["My Perfect Christmas Costumes", "my-perfect-christmas-costumes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-christmas-costumes/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-christmas-costumes/250x142.png",
      path: ["/game/my-perfect-christmas-costumes", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-perfect-christmas-costumes/",
      Walkthrough: "https://www.youtube.com/embed/9n0PamZ81IA",
      s_dis:
      "My Perfect Christmas Costumes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Perfect Christmas Costumes. Ellie and her friends are excited for Christmas celebrations. They want to try cute Christmas outfits with crazy makeovers. Browse their wardrobe for cute outfits and pick some matching accessories. Join these girls and enjoy this Christmas a most memorable one. Have fun!",
      tag: ["Barbie","Dress up","Christmas","Exclusive","Girl games","Holiday","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },{
      date: "2023-12-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Retro Time Travel Fashion - playcutegames.com",
      name: ["BFFs Retro Time Travel Fashion", "bffs-retro-time-travel-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-retro-time-travel-fashion/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-retro-time-travel-fashion/520x295.png",
      path: ["/game/bffs-retro-time-travel-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-retro-time-travel-fashion/",
      Walkthrough: "https://www.youtube.com/embed/i93tAKNBYDg",
      s_dis:
      "BFFs Retro Time Travel Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Take a stylish journey through time with BFFs Retro Time Travel Fashion! Explore vintage ensembles, iconic trends, and timeless accessories. Join the BFFs as they navigate the fashion eras, setting trends in every decade. Elevate your style with a retro twist and let the BFFs be your guides in the ultimate time-traveling fashion adventure. Rediscover the charm of bygone styles and create a timeless wardrobe with the BFFs!",
        tag: ["Barbie","Dress up","Exclusive","Retro","Fashion","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-12-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Fashionista Christmas Eve Party - playcutegames.com",
      name: ["Fashionista Christmas Eve Party", "fashionista-christmas-eve-party"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fashionista-christmas-eve-party/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashionista-christmas-eve-party/520x295.png",
      path: ["/game/fashionista-christmas-eve-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/fashionista-christmas-eve-party/",
      Walkthrough: "https://www.youtube.com/embed/AN2M6L95z2c",
      s_dis:
      "Fashionista Christmas Eve Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Indulge in the Fashionista Christmas Eve Party extravaganza! Discover glamorous holiday ensembles, festive accessories, and the perfect blend of chic for a stylish celebration. Join the fashion-forward festivities and elevate your Christmas Eve style to new heights. Unwrap the magic of the season with Fashionista, where every outfit is a statement and every moment is a fashion triumph. Let the glamour of Christmas Eve unfold.",
        tag: ["Fashionista","Dress up","Exclusive","Holiday","Christmas","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-28",
      brand: "Gamezop",
     
      orientation: 1,
      title: "Fruity Fiesta - playcutegames.com",
      name: ["Fruity Fiesta", "fruity-fiesta"],
      thumb: "https://static.gamezop.com/mKZZW9NXW/square.png",
      thumb_big: "https://static.gamezop.com/mKZZW9NXW/square.png",
      path: ["/game/fruity-fiesta", "https://playcutegames.com"],
      iframe: "https://8033.play.gamezop.com/g/mKZZW9NXW",
      Walkthrough: "https://www.youtube.com/embed/Z1PT6M-E-6w",
      s_dis:
      "It's time for a juicy, jivy Fiesta of Fruits. Merge the fruits to get the elusive Melons. Collect them all to become the fruit baron!",
      l_dis:
        `Get ready for a fruity adventure on Play Cute Games! In this colorful puzzle game you’ll enter a world where juicy fruits are waiting to be matched and collected. Each level brings new challenges as you connect fruits, create sweet combos to complete your goals. Whether it’s strawberries, oranges or apples every move counts in this puzzle game.
As you progress the puzzles get harder, fun obstacles will be introduced to keep you on your toes. But don’t worry along the way you’ll find tasty rewards and bonuses to help you through those extra tough levels. With bright graphics and happy music Fruity Fiesta is a game that’s as fun to look at as it is to play!
Whether you're a puzzle enthusiast or just looking to unwind, Fruity Fiesta guarantees a delightful experience. Jump in and see how far you can go in this juicy adventure!`,
        tag: ["Gamezop","Matching","Match-3","Girl games"],
      hint: `Use your left mouse or touch`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2022-12-07",
      brand: "playcutegames",
     
      orientation: 0,
      title: "New Years Eve Cruise Party - playcutegames.com",
      name: ["New Years Eve Cruise Party", "new-years-eve-cruise-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/new-years-eve-cruise-party/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/new-years-eve-cruise-party/250x142.png",
      path: ["/game/new-years-eve-cruise-party", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/new-years-eve-cruise-party/",
      Walkthrough: "https://www.youtube.com/embed/6nHqN3Bud7w",
      s_dis:
      "New Years Eve Cruise Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to New Year’s Eve Cruise Party. New Year is a great time to get together with your closest friends and party the night away to blow off steam and welcome the new year. Here are celebrity plans to celebrate this new year’s eve on the cruise. They are so excited to travel on New year’s eve. Help them to pick a perfect party costume and make this New year more memorable.",
      tag: ["Celebrity","Dress up","New Year","Exclusive","Girl games","Holiday","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-11-28",
      brand: "Gamezop",
     
      orientation: 1,
      title: "Pop Soap - playcutegames.com",
      name: ["Pop Soap", "pop-soap"],
      thumb: "https://static.gamezop.com/SJX7TGkDq1X/square.png",
      thumb_big: "https://static.gamezop.com/SJX7TGkDq1X/square.png",
      path: ["/game/pop-soap", "https://playcutegames.com"],
      iframe: "https://8033.play.gamezop.com/g/SJX7TGkDq1X",
      Walkthrough: "https://www.youtube.com/embed/Z1PT6M-E-6w",
      s_dis:
      "Classic Match-3 game in Endless Mode and an Achievements mode! Remember, if there's 2 or more of the same kind, you can burst them!",
      l_dis:
        `Get ready for a burst of fun with Pop Soap games on Play Cute Games! Have a blast popping bright bubbles and cracking fun puzzles in a lively, colorful world. Match sparkling soaps and use your strategy to clear levels while enjoying dynamic gameplay. Each stage presents a new challenge that will keep you hooked as you progress through an array of cheerful, soapy adventures.
Uncover special power-ups that add an exciting twist to your game and help you achieve high scores. Whether you’re looking to beat your own record or just have a good time, Pop Soap has something for everyone. Share your soapy achievements with friends and see who can master the most challenging levels. The fun never stops in Pop Soap—jump in and start popping today!`,
        tag: ["Gamezop","Matching","Match-3","Girl games"],
      hint: `Use your left mouse or touch`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-01-06",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Winter Wonderland Clash - playcutegames.com",
      name: ["Celebrity Winter Wonderland Clash", "celebrity-winter-wonderland-clash"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-winter-wonderland-clash/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-winter-wonderland-clash-300.jpg",
      path: ["/game/celebrity-winter-wonderland-clash", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-winter-wonderland-clash/",
      Walkthrough: "https://www.youtube.com/embed/jflKIGiVgQc",
      s_dis:
      "Celebrity Winter Wonderland Clash Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the Celebrity Winter Wonderland Clash! Explore the glamorous clash of styles as celebrities navigate the snowy season. From cozy layers to high-end fashion, witness the stars setting trends in a winter wonderland. Join the clash, elevate your winter style, and immerse yourself in the magic of celebrity fashion. Let the snowy backdrop be the canvas for a clash of elegance and glamour. Bundle up and embrace the chic side of winter with your favorite celebrities!",
        tag: ["Celebrity","Dress up","Exclusive","Winter","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-29",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrities Get Ready For Christmas - playcutegames.com",
      name: ["Celebrities Get Ready For Christmas", "celebrities-get-ready-for-christmas"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrities-get-ready-for-christmas/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrities-get-ready-for-christmas/520x295.png",
      path: ["/game/celebrities-get-ready-for-christmas", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrities-get-ready-for-christmas/",
      Walkthrough: "https://www.youtube.com/embed/DmeOmLJOjnE",
      s_dis:
      "Celebrities Get Ready For Christmas Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the world of ‘Celebrities Get Ready for Christmas’! Join your favorite stars as they prepare for the festive season. Explore dazzling holiday outfits, glamorous decorations, and witness the magic of Christmas through the eyes of celebrities. Get ready for a star-studded holiday celebration! Play and have fun!",
        tag: ["Celebrity","Dress up","Exclusive","Holiday","Christmas","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-10-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Thanksgiving Squad Style - playcutegames.com",
      name: ["Thanksgiving Squad Style", "thanksgiving-squad-style"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/thanksgiving-squad-style/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/thanksgiving-squad-style-300.jpg",
      path: ["/game/thanksgiving-squad-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/thanksgiving-squad-style/",
      Walkthrough: "https://www.youtube.com/embed/_WfHISbh1II",
      s_dis:
      "Thanksgiving Squad Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the world of Thanksgiving Squad Style! Explore a fusion of cozy elegance and festive fashion as the squad celebrates the season. From warm layers to stylish accents, discover the perfect ensemble to make this Thanksgiving unforgettable. Join the squad and embrace the spirit of gratitude and style!",
        tag: ["Winx","Dress up","Thanksgiving","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-18",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Fashionista Rainy Day Edition - playcutegames.com",
      name: ["Fashionista Rainy Day Edition", "fashionista-rainy-day-edition"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/fashionista-rainy-day-edition/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/fashionista-rainy-day-edition/520x295.png",
      path: ["/game/fashionista-rainy-day-edition", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/fashionista-rainy-day-edition/",
      Walkthrough: "https://www.youtube.com/embed/lVs_lIc1bTk",
      s_dis:
      "Fashionista Rainy Day Edition Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the world of Fashionista Rainy Day Edition! Embrace the charm of rainy days with our curated collection of chic ensembles, stylish rainwear, and trendy accessories. Join the fashion journey as we redefine rainy day fashion, proving that even in drizzles, your style can shine. Elevate your wardrobe with weather-appropriate yet fabulous choices. Stay dry, stay fabulous, and make a splash with your rainy day looks!",
        tag: ["Fashionista","Dress up","Fashion","Fall","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-11-21",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Stylish Maxi Look - playcutegames.com",
      name: ["Lovie Chic’s Stylish Maxi Look", "lovie-chics-stylish-maxi-look"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-stylish-maxi-look/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-stylish-maxi-look/520x295.png",
      path: ["/game/lovie-chics-stylish-maxi-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-stylish-maxi-look/",
      Walkthrough: "https://www.youtube.com/embed/Z8RTINQ9qqY",
      s_dis:
      "Lovie Chic’s Stylish Maxi Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into Lovie Chic’s Stylish Maxi Look! Elevate your fashion game with chic maxi dresses, trendy accessories, and expert styling tips. Embrace comfort and glamour in every step. Help the chic to pick up a perfect maxi outfit. Join the style revolution and discover your perfect maxi ensemble with Lovie Chic!",
        tag: ["Lovie Chic","Dress up","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-10",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Cold Weather Style - playcutegames.com",
      name: ["Celebrity Cold Weather Style", "celebrity-cold-weather-style"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-cold-weather-style/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-cold-weather-style/520x295.png",
      path: ["/game/celebrity-cold-weather-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-cold-weather-style/",
      Walkthrough: "https://www.youtube.com/embed/rJDSz1xzp8M",
      s_dis:
      "Celebrity Cold Weather Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into Celebrity Cold Weather Style! Discover how your favorite stars rock winter fashion with cozy layers, chic outerwear, and trendy accessories. Get inspired to elevate your cold-weather wardrobe and stay fashion-forward all season. Join us in exploring the glamour of celebrity winter fashion! Elevate your seasonal wardrobe with inspiration from the glamorous world of celebrities.",
        tag: ["Lovie Chic","Dress up","Winter","Style","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-15",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Lisa Fashion Club #Style Challenge - playcutegames.com",
      name: ["Lisa Fashion Club #Style Challenge", "lisa-fashion-club-style-challenge"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lisa-fashion-club-style-challenge/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lisa-fashion-club-style-challenge/520x295.png",
      path: ["/game/lisa-fashion-club-style-challenge", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lisa-fashion-club-style-challenge/",
      Walkthrough: "https://www.youtube.com/embed/fqRGY__OIg8",
      s_dis:
      "Lisa Fashion Club #Style Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Join the Lisa Fashion Club #Style Challenge! Unleash your creativity and explore the latest trends. Share your unique looks, discover fashion inspiration, and be part of a vibrant community that celebrates individual style. Elevate your fashion game with Lisa and make every outfit a statement! Unleash your creativity, and explore new trends.",
        tag: ["Lisa","Dress up","Fashion","Style","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-10-13",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Lucy All Season Fashionista - playcutegames.com",
      name: ["Lucy All Season Fashionista", "lucy-all-season-fashionista"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lucy-all-season-fashionista/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lucy-all-season-fashionista-300.jpg",
      path: ["/game/lucy-all-season-fashionista", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lucy-all-season-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/RycnDXDHsnA",
      s_dis:
      "Lucy All Season Fashionista Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Discover Lucy All-Season Fashionista, who effortlessly masters every seasonal trend. From cozy winter chic to breezy summer styles, follow her fashion journey through all seasons. Get inspired to elevate your wardrobe with Lucy’s expert tips and versatile fashion choices. Embrace a style that transcends every season!",
        tag: ["Lucy","Dress up","Season","Exclusive","Autumn","Winter","Summer","Fall","Spring","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Fall Dress Up - playcutegames.com",
      name: ["Lovie Chic’s Fall Dress Up", "lovie-chics-fall-dress-up"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-fall-dress-up/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-fall-dress-up/520x295.png",
      path: ["/game/lovie-chics-fall-dress-up", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-fall-dress-up/",
      Walkthrough: "https://www.youtube.com/embed/9FnPeY7-Fx4",
      s_dis:
      "Lovie Chic’s Fall Dress Up Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into Lovie Chic’s Fall Dress Up, a world of autumnal elegance and trendy ensembles. Explore cozy layers, stylish textures, and chic accessories, embracing the spirit of the season. Join the fashion journey and elevate your fall style with Lovie Chic’s expert guidance. Discover your perfect autumn look today!",
        tag: ["Lovie Chic","Dress up","Fall", "Autumn","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-10-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFF’s Day Of Dead Celebration - playcutegames.com",
      name: ["BFF’s Day Of Dead Celebration", "bffs-day-of-dead-celebration"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-day-of-dead-celebration/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/bffs-day-of-dead-celebration-300.jpg",
      path: ["/game/bffs-day-of-dead-celebration", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-day-of-dead-celebration/",
      Walkthrough: "https://www.youtube.com/embed/9Lhvd0bFlVY",
      s_dis:
      "BFF’s Day Of Dead Celebration Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the vibrant BFF’s Day of the Dead Celebration! Experience the rich cultural festivities, colorful traditions, and lively celebrations. Join the BFFs as they honor loved ones with intricate altars, face paintings, and traditional rituals. Immerse yourself in the spirit of togetherness and remembrance on this special occasion!",
        tag: ["Angela","Dress up","Festivel","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-10-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chics Black Friday Shopping - playcutegames.com",
      name: ["Lovie Chics Black Friday Shopping", "lovie-chics-black-friday-shopping"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-black-friday-shopping/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-black-friday-shopping-300.jpg",
      path: ["/game/lovie-chics-black-friday-shopping", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-black-friday-shopping/",
      Walkthrough: "https://www.youtube.com/embed/0q-wcsoLb-k",
      s_dis:
      "Lovie Chics Black Friday Shopping Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Indulge in the ultimate Lovie Chics Black Friday Shopping spree! BFF girls are excited to go out for shopping on Black Friday. Explore exclusive deals, trendy fashion finds, and must-have accessories. Elevate your style and make the most of this exciting shopping extravaganza. Join us for a day of style and savings!",
        tag: ["Lovie Chic","Dress up","Black Friday","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-12-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie And Friends Ski Fashion - playcutegames.com",
      name: ["Ellie And Friends Ski Fashion", "ellie-and-friends-ski-fashion"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-ski-fashion/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-ski-fashion/520x295.png",
      path: ["/game/ellie-and-friends-ski-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-friends-ski-fashion/",
      Walkthrough: "https://www.youtube.com/embed/hGS9EVvz0k4",
      s_dis:
      "Ellie And Friends Ski Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Embark on a chic alpine journey with Ellie and Friends Ski Fashion! Explore trendy winter ensembles, cozy layers, and stylish accessories. Join the fashionable slope adventure as Ellie and friends set the trend for the perfect ski look. Elevate your winter style and hit the slopes in glamour. Unleash the magic of ski fashion with Ellie and friends as your stylish guides. Glide through the season in fashion-forward attire!",
        tag: ["Barbie","Dress up","Exclusive","Winter","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-10-18",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Trip To International Balloon Fiesta - playcutegames.com",
      name: ["Trip To International Balloon Fiesta", "trip-to-international-balloon-fiesta"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/trip-to-international-balloon-fiesta/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/trip-to-international-balloon-fiesta-300.jpg",
      path: ["/game/trip-to-international-balloon-fiesta", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/trip-to-international-balloon-fiesta/",
      Walkthrough: "https://www.youtube.com/embed/ZNxVR0uktk8",
      s_dis:
      "Trip To International Balloon Fiesta Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Trip to International Balloon Fiesta! Join the BFFs as they plan a trip to embrace the spirit of adventure. Get ready to experience vibrant festivities and magical hot air balloons in style. Elevate your travel wardrobe and create picture-perfect memories with your best friends! Play and have fun!",
        tag: ["Rainbow High","Dress up","Festival","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-10-04",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Monster Girls Back To School - playcutegames.com",
      name: ["Monster Girls Back To School", "monster-girls-back-to-school"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-back-to-school/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-back-to-school-300.jpg",
      path: ["/game/monster-girls-back-to-school", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/monster-girls-back-to-school/",
      Walkthrough: "https://www.youtube.com/embed/0PQeSr1bD6o",
      s_dis:
      "Monster Girls Back To School Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Monster Girls Back To School. Join the Monster Girls as they prep for school! Assist them in selecting the ideal outfits for a stylish return. Dive into their supernatural world, full of fashion choices and exciting back-to-school adventures. Get ready to be their fashion guide and have fun!",
        tag: ["Monster High","Dress up","School","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-11-21",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Lovie Chic’s Black Vs Pink Style - playcutegames.com",
      name: ["Lovie Chic’s Black Vs Pink Style", "lovie-chics-black-vs-pink-style"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-black-vs-pink-style/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/lovie-chics-black-vs-pink-style/520x295.png",
      path: ["/game/lovie-chics-black-vs-pink-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovie-chics-black-vs-pink-style/",
      Walkthrough: "https://www.youtube.com/embed/H0mD-N4keBw",
      s_dis:
      "Lovie Chic’s Black Vs Pink Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into the world of Lovie Chic’s Black Vs Pink Style! Explore a fusion of cozy elegance and festive fashion as the squad celebrates the season. From warm layers to stylish accents, discover the perfect ensemble to make this Thanksgiving unforgettable. Join the squad and embrace the spirit of gratitude and style!",
        tag: ["Lovie Chic","Dress up","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
    {
      id: 202203001,
      brand: "lagged",
     
      orientation: 1,
      title: "Dr. Animal Teeth - playcutegames.com",
      name: ["Dr. Animal Teeth", "dr-animal-teeth"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/dr-animal-teeth/250x142.png",
      path: ["/game/dr-animal-teeth", "https://playcutegames.com"],
      iframe:
        "https://lagged.com/games/dr-animal-teeth/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Dr. Animal Teeth Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Time to sit down in the dentist's chair and clean some dirty mouths. You have 3 different animals who have not kept up with good teeth hygiene. Use your tools to remove cavities, brush the teeth and clean all of the mouths in this fun online dentist game.",
        tag: ["Doctor","Animal","Girl Games"],
      hint: 0,
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Mr and Mrs Santa Christmas Adventure - playcutegames.com",
      name: ["Mr and Mrs Santa Christmas Adventure", "mr-and-mrs-santa-christmas-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/mr-and-mrs-santa-christmas-adventure/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/mr-and-mrs-santa-christmas-adventure/250x142.png",
      path: ["/game/mr-and-mrs-santa-christmas-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/mr-and-mrs-santa-christmas-adventure/",
      Walkthrough: "https://www.youtube.com/embed/LQi43kI3iKc",
      s_dis:
      "Mr and Mrs Santa Christmas Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Mr. and Mrs. Santa Christmas Adventure.  Christmas is around the corner. Mr. and Mrs. Santa preparing for the celebration. Unfortunately, Mr. Santa met an accident. Santa and his pet got severe injuries and also his sleigh was damaged totally. Help Santa and his pet for a quick recovery so that he can tour around the world to share his Christmas gift with the children.",
      tag: ["Santa","Dress up","Winter","Girl games","Christmas","Holiday","Decoration","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-10-18",
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Perfect Halloween Costume - playcutegames.com",
      name: ["My Perfect Halloween Costume", "my-perfect-halloween-costume"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-halloween-costume/250x142.png",
      path: ["/game/my-perfect-halloween-costume", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-perfect-halloween-costume/",
      Walkthrough: "https://www.youtube.com/embed/era5WKrPocI",
      s_dis:
      "My Perfect Halloween Costume Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Perfect Halloween Costume. Bff Girls decided to celebrate this spooky holiday, just like when they were kids! This means that these princesses will go trick-or-treating dressed up in great costumes! This means that you can help them find the best costumes out there, which is not easy! As you will see, there are so many choices. Open the wardrobe and see it yourself.",
        tag: ["Winx","Dress up","Halloween","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-10-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Cute Melon My Dream Profession - playcutegames.com",
      name: ["Cute Melon My Dream Profession", "cute-melon-my-dream-profession"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-melon-my-dream-profession/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-melon-my-dream-profession-300.jpg",
      path: ["/game/cute-melon-my-dream-profession", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/cute-melon-my-dream-profession/",
      Walkthrough: "https://www.youtube.com/embed/vNulEfnyKDg",
      s_dis:
      "Cute Melon My Dream Profession Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the enchanting world of ‘Cute Melon My Dream Profession,’ where kids embark on a journey to uncover their dream careers. Dress up kids in exciting professions and have fun while sparking their ambition!” Join us in fostering the next generation’s dreams and aspirations through educational and delightful experiences!",
        tag: ["Melon","Kids","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-11-28",
      brand: "Gamezop",
     
      orientation: 1,
      title: "Don't Touch the Walls - playcutegames.com",
      name: ["Don't Touch the Walls", "dont-touch-the-walls"],
      thumb: "https://static.gamezop.com/rybx12amqCB/square.png",
      thumb_big: "https://static.gamezop.com/rybx12amqCB/square.png",
      path: ["/game/dont-touch-the-walls", "https://playcutegames.com"],
      iframe: "https://8033.play.gamezop.com/g/rybx12amqCB",
      Walkthrough: "https://www.youtube.com/embed/KmNYWJ09d3k",
      s_dis:
      "Be quick with your moves and navigate the maze without touching the walls! The longer you play without touching any of the walls, the more you score. Watch out for the turns!",
      l_dis:
        "Be quick with your moves and navigate the maze without touching the walls! The longer you play without touching any of the walls, the more you score. Watch out for the turns!",
        tag: ["Gamezop","Puzzle","Adventure","Logic","Girl games"],
      hint: `Use your left mouse or touch`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-11-28",
      brand: "Gamezop",
     
      orientation: 1,
      title: "Juicy Dash - playcutegames.com",
      name: ["Juicy Dash", "juicy-dash"],
      thumb: "https://static.gamezop.com/H1lZem8hq/square.png",
      thumb_big: "https://static.gamezop.com/H1lZem8hq/square.png",
      path: ["/game/juicy-dash", "https://playcutegames.com"],
      iframe: "https://8033.play.gamezop.com/g/H1lZem8hq",
      Walkthrough: "https://www.youtube.com/embed/i4G5ncMgpJI",
      s_dis:
      "Juicy Dash is a classic match3 game. Prove your skills and match as many fruits as possible. Create combos of 4 or 5 fruits to get boni and collect as many points as you can. Hurry up! Time is limited!",
      l_dis:
        `Get set for a fruity adventure with Juicy Dash on Play Cute Games! In this colourful match-3 puzzle game you’ll be swapping and matching bananas, strawberries and oranges to solve the puzzles. The aim is to connect at least three of the same fruit to clear them from the board and score big. As you progress through the levels clever moves will help you make delicious combinations and unlock special treats like extra time, juice and jam.
Every level is a new challenge so every game is a new experience. With bright graphics and fun sound effects Juicy Dash is for fruit puzzle fans and colour lovers. Share your scores and achievements with friends and see who can get to the most levels. Get started now!`,
        tag: ["Gamezop","Puzzle","Match-3","Logic","Girl games"],
      hint: `Use your left mouse or touch`,
      rating:[4.4,1210,114],  
    },
    {
      date: "2023-09-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Luma The Fashion Stylist - playcutegames.com",
      name: ["Luma The Fashion Stylist", "luma-the-fashion-stylist"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/luma-the-fashion-stylist/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/luma-the-fashion-stylist-300.jpg",
      path: ["/game/luma-the-fashion-stylist", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/luma-the-fashion-stylist/",
      Walkthrough: "https://www.youtube.com/embed/N2T1j5xU2EE",
      s_dis:
      "Luma The Fashion Stylist Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Luma The Fashion Stylist Game. Introducing Luma, the fashion stylist extraordinaire. With a keen eye for trends, Luma effortlessly navigates styles like casual chic, minimalist, feminine, urban, tomboy, and hip-hop. Elevate your fashion skills and discover your unique style for Luma with your expert guidance. Unleash the fashionista in you!",
        tag: ["Luma","Fashion","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-09-19",
      brand: "cutedressup",
     
      orientation: 1,
      title: "Ice Princess All Around The Fashion - playcutegames.com",
      name: ["Ice Princess All Around The Fashion", "ice-princess-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ice-princess-all-around-the-fashion/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/ice-princess-all-around-the-fashion-300.jpg",
      path: ["/game/ice-princess-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ice-princess-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Ice Princess All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Ice Princess All Around The Fashion. Enter the enchanting world of the Ice Princess, where fashion knows no bounds! Explore a realm of icy elegance and timeless style. From regal ball gowns to chic modern looks, discover a wardrobe fit for a princess. Join her on a journey through the world of fashion, where every outfit tells a story. Unleash your inner fashionista and embrace the magic of the Ice Princess’s style!",
        tag: ["Princess","Fashion","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },{
      date: "2023-10-04",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Halloween Costumes - playcutegames.com",
      name: ["Celebrity Halloween Costumes", "celebrity-halloween-costumes"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-halloween-costumes/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-halloween-costumes-300.jpg",
      path: ["/game/celebrity-halloween-costumes", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-halloween-costumes/",
      Walkthrough: "https://www.youtube.com/embed/BoS07BubpUA",
      s_dis:
      "Celebrity Halloween Costumes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Halloween Costume Adventure! Your favorite celebrities are planning the perfect Halloween outfits together. Get ready for a star-studded Halloween experience as they choose and showcase their ultimate costumes. Unleash your inner celebrity with the hottest Halloween trends!",
        tag: ["Celebrity","Dress up","Halloween","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-18",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Royal Couple Halloween Party - playcutegames.com",
      name: ["Royal Couple Halloween Party", "royal-couple-halloween-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/royal-couple-halloween-party/250x142.png",
      path: ["/game/royal-couple-halloween-party", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/royal-couple-halloween-party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Royal Couple Halloween Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Royal Couple Halloween Party. Halloween is behind the corner and your favorite Royal couples are getting ready to go trick-or-treating in their great fantasy world. Browse their wardrobe for perfect costumes for each couple. Come and join the girls and see what highly original Halloween costumes you can create for each of them. Have fun, ladies!",
        tag: ["Princess","Dress up","Halloween","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
  
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Happy Halloween Party - playcutegames.com",
      name: ["Princess Happy Halloween Party", "princess-happy-halloween-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-happy-halloween-party/250x142.png",
      path: [
        "/game/princess-happy-halloween-party",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess-happy-halloween-party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Happy Halloween Party. Princess Anna and Elsa arrange a Halloween party at Arendelle.",
      l_dis:
        `Welcome to the Princess Happy Halloween Party. Princess Anna and Elsa arrange a Halloween party at Arendelle. They invite their friends Jasmine and Moana to the party. Before the party started, they need to be in traditional halloween costumes. Join and help the girls to pick a perfect party costumes. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Princess",
        "Halloween",
        "Anna",
        "Elsa",
        "Jasmine",
        "Moana",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Halloween Makeup Trend - playcutegames.com",
      name: ["Halloween Makeup Trends", "halloween-makeup-trends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/halloween-makeup-trends/250x142.png",
      path: ["/game/halloween-makeup-trends", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/75c443a1-5540-5bcf-996e-948d68272909/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Halloween Makeup Trends. If you’re still seeking inspiration for your Halloween look, then stop right here.",
      l_dis:
        "Welcome to the Halloween Makeup Trends. If you’re still seeking inspiration for your Halloween look, then stop right here. Our social media influencers bring three jaw-dropping makeup looks that are going to help you steal the spotlights during the spookiest night of the year. In the Halloween Makeup Trends game for girls, you can learn from Kiki how to create a unicorn makeup look to complete your Halloween look with, from gorgeous Riri how to obtain a colorful Dia de Los Muertes inspired makeup look, and from Regina the secrets of a sophisticated fantasy makeup. Play today and have fun!",
      tag: ["Dress up", "Make Up", "Halloween", "Decoration", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Halloween Salon - playcutegames.com",
      name: ["Rainbow Girls Halloween Salon", "rainbow-girls-halloween-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-halloween-salon/250x142.png",
      path: ["/game/rainbow-girls-halloween-salon", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/rainbow-girls-halloween-salon/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Rainbow Girls Halloween Salon game. Rainbow High school girls Skyler, Sunny, Ruby, and Violet are planning to celebrate Halloween",
      l_dis:
        `Welcome to the Rainbow Girls Halloween Salon game. Rainbow High school girls Skyler, Sunny, Ruby, and Violet are planning to celebrate Halloween in the traditional way. Join as a costume expert and browse their wardrobe to choose a cute scary outfit with a beautiful mask. Play and have fun with this cute Halloween game
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "Rainbow High", "Halloween", "Salon"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Pumpkin Carving - playcutegames.com",
      name: ["Pumpkin Carving", "pumpkin-carving"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pumpkin-carving/250x142.png",
      path: ["/game/pumpkin-carving", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1634745709/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Dry pumpkin carving is a common Halloween craft. This fall, together with the Harlequin Princess, we will decorate our yard with pumpkins using the carving technique.",
      l_dis:
        "Dry pumpkin carving is a common Halloween craft. This fall, together with the Harlequin Princess, we will decorate our yard with pumpkins using the carving technique. With a knife and a spoon, take out the pulp of the pumpkin, trying to make the walls thinner, no more than 2 cm. Now we cut out the outline of the large details of the picture. It remains to install the pumpkin and put a candle inside. Just a fabulous glow and incredibly spectacular decoration!",
      tag: ["Dress up", "Decoration", "Dl-Girls", "Kids", "Halloween"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      
      date: "2023-09-12",
      brand: "Vi games",
     
      orientation: 1,
      title: "Winter Holiday Puzzles - playcutegames.com",
      name: ["Winter Holiday Puzzles", "winter-holiday-puzzles"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/winter-holiday-puzzles/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/winter-holiday-puzzles-300.jpg",
      path: ["/game/winter-holiday-puzzles", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/29ba56c74fd24143af0af946187c57c1/?gd_sdk_referrer_url=https://playcutegames.com/game/winter-holiday-puzzles",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Winter Holiday Puzzles Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "A gorgeous selection of snow scenes, landscapes, snowmen and all things winter in a game called Winter Holiday Puzzles. Winter Holiday Puzzles is perfect online jigsaw game for those people that love winter and those who just want to pass the time to get through it! Start playing and have fun.",
        tag: ["Puzzle","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-09-12",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Angela Multiverse Fashionista - playcutegames.com",
      name: ["Angela Multiverse Fashionista", "angela-multiverse-fashionista"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-multiverse-fashionista/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-multiverse-fashionista-300.jpg",
      path: ["/game/angela-multiverse-fashionista", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/angela-multiverse-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/buGjMuGUKNQ",
      s_dis:
      "Angela Multiverse Fashionista Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into Angela Multiverse Fashionista world, where style transcends dimensions! Explore her diverse wardrobe spanning galaxies, eras, and realms. From futuristic space chic to timeless historical elegance, unlock fashion inspiration that knows no bounds. Join Angela on a journey through the multiverse of couture and express your unique style across the cosmos. Your fashion adventure begins here!",
        tag: ["Angela","Multiverse","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      
      date: "2023-09-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Insta Celebrity Hashtag Goals - playcutegames.com",
      name: ["Insta Celebrity Hashtag Goals", "insta-celebrity-hashtag-goals"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/insta-celebrity-hashtag-goals/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/insta-celebrity-hashtag-goals-300.jpg",
      path: ["/game/insta-celebrity-hashtag-goals", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/insta-celebrity-hashtag-goals/",
      Walkthrough: "https://www.youtube.com/embed/A3gndSkZBog",
      s_dis:
      "Insta Celebrity Hashtag Goals Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Unlock your inner star with Insta Celebrity Hashtag Goals! Celebrities plan to elevate their fashionista in social media. Join and help them to complete their goal at each level. Mix and match the outfit on each level. Take a picture and post it on social media to earn more points. Have fun!",
        tag: ["Celebrity","Instagram","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      
      date: "2023-09-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Summer Firecamp Adventure - playcutegames.com",
      name: ["Summer Firecamp Adventure", "summer-firecamp-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/summer-firecamp-adventure/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/summer-firecamp-adventure-300.jpg",
      path: ["/game/summer-firecamp-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/summer-firecamp-adventure/",
      Walkthrough: "https://www.youtube.com/embed/A3gndSkZBog",
      s_dis:
      "Summer Firecamp Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Summer Firecamp Adventure. A little girl planned to have a summer fire camp near the lake. Help her prepare for the trip, to reach the destination, and also help her in fishing and preparing s’mores. Sounds fun right?? Join and make this summer more unforgettable for her. Have fun!",
        tag: ["Decoration","Summer","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2022-10-29",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Little Lily Halloween Prep - playcutegames.com",
      name: ["Little Lily Halloween Prep", "little-lily-halloween-prep"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/little-lily-halloween-prep/250x142.png",
        thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/little-lily-halloween-prep-300.jpg",
      path: ["/game/little-lily-halloween-prep", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/little-lily-halloween-prep/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Little Lily Halloween Prep Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Little Lily Halloween Prep. It’s time to celebrate Halloween with Little Lily! Enjoy a memorable Halloween night with Lily and play fun-packed Halloween games and activities. Help the lily to complete her makeover, then help her with Halloween face painting. Browse her wardrobe and pick cute outfits and costumes. Finally, help her with cake decoration. A lot more to enjoy and explore this Halloween season with Little Lily. Happy Halloween!",
        tag: ["Little Lily","Dress up","Halloween","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-07-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Monster Girls High School Squad - playcutegames.com",
      name: ["Monster Girls High School Squad", "monster-girls-high-school-squad"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-high-school-squad/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-high-school-squad-300.jpg",
      path: ["/game/monster-girls-high-school-squad", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/monster-girls-high-school-squad/",
      Walkthrough: "https://www.youtube.com/embed/tAGWj5xle3o",
      s_dis:
      " Monster Girls High School Squad Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Unleash your inner fashionista with the Monster Girls High School Squad dress-up game! Mix and match captivating outfits, accessorize with magical charms, and create spellbinding looks for supernatural students. Dive into a world of style, friendship, and enchantment as you explore the fantastic wardrobe options and dress up the alluring characters. Join the squad and let your creativity run wild in this thrilling fashion adventure!",
        tag: ["Monster High","Dress up","Girl games","Fashion","Wednesday","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },  
    
    {
      date: "2023-07-28",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Trendy Prom Look - playcutegames.com",
      name: ["Celebrity Trendy Prom Look", "celebrity-trendy-prom-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-trendy-prom-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-trendy-prom-look-300.jpg",
      path: ["/game/celebrity-trendy-prom-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-trendy-prom-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      " Celebrity Trendy Prom Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Steal the spotlight at prom with our Celebrity Trendy Prom Look! Embrace A-list elegance with chic dresses, glamorous hairstyles, and stunning accessories. Get inspired by the red carpet fashion and create a show-stopping ensemble that will leave everyone in awe. Make your prom night a celebrity-worthy affair and feel like a star on your special evening!",
        tag: ["Celebrity","Dress up","Girl games","Fashion","Prom","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },{
      date: "2023-10-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Angela Halloween Preparation - playcutegames.com",
      name: ["Angela Halloween Preparation", "angela-halloween-preparation"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/angela-halloween-preparation/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/angela-halloween-preparation-300.jpg",
      path: ["/game/angela-halloween-preparation", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/angela-halloween-preparation/",
      Walkthrough: "https://www.youtube.com/embed/_WfHISbh1II",
      s_dis:
      "Angela Halloween Preparation Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Angela Halloween Preparation. Join Angela in her Halloween Preparation journey! From spooky decorations to creative costumes, get inspired for the spookiest night of the year. Discover Angela’s tips and tricks for the ultimate Halloween celebration and make this year’s festivities unforgettable! Play and have fun with this new halloween game!",
        tag: ["Angela","Dress up","Halloween","Exclusive","Holiday","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],  
    },
  {
    date: "2023-07-15",
    brand: "VI",
   
    orientation: 0,
    title: "Bratz Hidden Stars - playcutegames.com",
    name: ["Bratz Hidden Stars", "bratz-hidden-stars"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/bratz-hidden-stars/250x142.png",
    thumb_big: 
      "https://cdn.cutedressup.in/games/cutedressup_new/bratz-hidden-stars-300.jpg",
    path: ["/game/bratz-hidden-stars", "https://playcutegames.com"],
    iframe:
      "https://html5.gamemonetize.co/47xp4h7w45yz0uv7dc3kz72tcoz58oe4/",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    " Bratz Hidden Stars Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Bratz Hidden Stars is super fun and free to play online game for kids. Bratz Hidden Stars where you need to find all hidden stars on the stage to enter new level. Grab the loop and start looking for the stars hiiden on the Bratz images. Have fun playing.",
      tag: ["Bratz","Puzzle","Girl games","Hidden"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.7,1510,1455],
    platform:"both",
  },
  
  {
      
    date: "2023-08-16",
    brand: "playcutegames",
   
    orientation: 1,
    title: "Ellie All Around The Fashion - playcutegames.com",
    name: ["Ellie All Around The Fashion", "ellie-all-around-the-fashion"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/ellie-all-around-the-fashion/250x142.png",
    thumb_big: 
      "https://cdn.cutedressup.in/games/cutedressup_new/ellie-all-around-the-fashion-300.jpg",
    path: ["/game/ellie-all-around-the-fashion", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/ellie-all-around-the-fashion/",
    Walkthrough: "https://www.youtube.com/embed/lCjzE5YGNhI",
    s_dis:
    "Ellie All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Immerse yourself in the world of Ellie All Around The Fashion! From casual chic to red-carpet glam, join Ellie’s style journey. Discover versatile outfits, trendy accessories, and expert fashion tips. Whether it’s a day out or a special event, find inspiration for every occasion. Unleash your inner fashionista and elevate your style with Ellie as your guide. Explore, experiment, and embrace a world of endless fashion possibilities.",
      tag: ["Barbie","Dress up","Girl games","Fashion","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.7,1510,1455],
    platform:"both",
  }, 
  {
    date: "2023-07-15",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Celebrity AfroPunk Street Style - playcutegames.com",
    name: ["Celebrity AfroPunk Street Style", "celebrity-afropunk-street-style"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-afropunk-street-style/250x142.png",
    thumb_big: 
      "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-afropunk-street-style-300.jpg",
    path: ["/game/celebrity-afropunk-street-style", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/celebrity-afropunk-street-style/",
    Walkthrough: "https://www.youtube.com/embed/n3y9Oi3uJ0s",
    s_dis:
    " Celebrity AfroPunk Street Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Step into the world of Celebrity AfroPunk Street Style and discover a vibrant fusion of fashion, music, and self-expression. Explore captivating looks, bold outfits, and cutting-edge trends as celebrities showcase their unique personal style. Immerse yourself in this electrifying fashion movement and get inspired to make a statement of your own.",
      tag: ["Celebrity","Dress up","Girl games","Fashion","Afro","Core","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.7,1510,1455],
    platform:"both",
  },
    {
      date: "2023-07-11",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie Bachelorette Party - playcutegames.com",
      name: ["Ellie Bachelorette Party", "ellie-bachelorette-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-bachelorette-party/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-bachelorette-party-300.jpg",
      path: ["/game/ellie-bachelorette-party", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-bachelorette-party/",
      Walkthrough: "https://www.youtube.com/embed/wmp_ZhL1dBE",
      s_dis:
      " Ellie Bachelorette Party Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Ellie Bachelorette Party! It’s time to celebrate with her closest friends before the big day. Get ready for a night of laughter, dancing, and unforgettable memories. From fun games to fabulous surprises, this party is all about showering Ellie with love and creating an evening she’ll treasure forever. Join us and let’s make this celebration truly extraordinary!",
        tag: ["Barbie","Dress up","Girl games","Fashion","Party","Bachelor","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-07-28",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Holographic Futuristic Fashion - playcutegames.com",
      name: ["BFFs Holographic Futuristic Fashion", "bffs-holographic-futuristic-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-holographic-futuristic-fashion/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-holographic-futuristic-fashion-300.jpg",
      path: ["/game/bffs-holographic-futuristic-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-holographic-futuristic-fashion/",
      Walkthrough: "https://www.youtube.com/embed/Cng2bSteVWQ",
      s_dis:
      " BFFs Holographic Futuristic Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Step into a dazzling world of BFFs Holographic Futuristic Fashion! Explore a realm of shimmering outfits, holographic accessories, and futuristic styles. Join these best friends on their fashion journey, blending technology and trend to create mesmerizing looks. Get inspired, mix and match, and embrace the cutting-edge allure of holographic fashion!",
        tag: ["LOL Surprise","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-07-07",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity BarbieCore Aesthetic Look - playcutegames.com",
      name: ["Celebrity BarbieCore Aesthetic Look", "celebrity-barbiecore-aesthetic-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-barbiecore-aesthetic-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-barbiecore-aesthetic-look-300.jpg",
      path: ["/game/celebrity-barbiecore-aesthetic-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-barbiecore-aesthetic-look/",
      Walkthrough: "https://www.youtube.com/embed/VBkmMcijSOA",
      s_dis:
      " Celebrity BarbieCore Aesthetic Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity BarbieCore Aesthetic Look. Step into the glamorous world of celebrity fashion with this game! Six famous stars are inspired by BarbieCore’s iconic style and are eager to try her trends. From dazzling red carpet gowns to chic streetwear, they’ll explore the diverse range of BarbieCore aesthetics. Experiment with stunning hairstyles, flawless makeup, and luxurious accessories. Become their personal stylist and guide them to embody the essence of BarbieCore’s fashion-forward allure. Unleash your creativity and make them shine like true style icons!",
        tag: ["Celebrity","Barbie","Dress up","Girl games","Fashion","Core","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
  
    {
      date: "2023-07-04",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs High School First Date Look - playcutegames.com",
      name: ["BFFs High School First Date Look", "bffs-high-school-first-date-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-high-school-first-date-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-high-school-first-date-look-300.jpg",
      path: ["/game/bffs-high-school-first-date-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-high-school-first-date-look/",
      Walkthrough: "https://www.youtube.com/embed/IzmLYMPI94E",
      s_dis:
      " BFFs High School First Date Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs High School First Date Look. Get ready to style BFFs for their exciting high school first date! Mix and match trendy outfits, hairstyles, and accessories to create the perfect look that will make them feel confident and impress their date. Can you help them to find the ultimate outfit that captures their unique style and personality? Let the fashion fun begin!",
        tag: ["Barbie","Dress up","Girl games","Fashion","School","Date","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-07-05",
      brand: "GD",
     
      orientation: 0,
      title: "Jewels Kyodai Mahjong - playcutegames.com",
      name: ["Jewels Kyodai Mahjong", "jewels-kyodai-mahjong"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/jewels-kyodai-mahjong/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/jewels-kyodai-mahjong-300.jpg",
      path: ["/game/jewels-kyodai-mahjong", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/6fcd1f12261346e5bb6302fa08b1de8d/?gd_sdk_referrer_url=https://playcutegames.com/game/jewels-kyodai-mahjong",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      " Jewels Kyodai Mahjong Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Jewels Kyodai Mahjong is one of the most anticipated mahjong tile match game releases of this year! It combines the popular mahjong tile match gameplay with the mystical theme of the legendary Jewels Blitz Match 3 Game. Jewels Kyodai Mahjong features a library of more than 60 beautiful puzzles together with a a puzzle selector which lets you choose any puzzle you like or explore new puzzles with the 'Shuffle mode'! Play Jewels Kyodai Mahjong every day to sharpen your mind and focus!`,
        tag: ["Skill","Puzzle"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-07-05",
      brand: "GD",
     
      orientation: 1,
      title: "DRAW 2 SAVE - STICKMAN RESCUE - playcutegames.com",
      name: ["DRAW 2 SAVE - STICKMAN RESCUE", "draw-2-save-stickman-rescue"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/draw-2-save-stickman-rescue/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/draw-2-save-stickman-rescue-300.jpg",
      path: ["/game/draw-2-save-stickman-rescue", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/32f46c72af894eafbe5ef1400c0b066a/?gd_sdk_referrer_url=https://playcutegames.com/game/draw-2-save-stickman-rescue",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      " DRAW 2 SAVE - STICKMAN RESCUE Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Draw 2 Save - Stickman Rescue - draw one line to save the Stickman from the threat! Are you a good draftsman or Do you want to test your creativity? Are you a puzzle lover? Now you have a good chance! Draw a line to help the little man and protect him from swords, bullets, bombs... and many other life-threatening attacks! You are free to draw any shelters and defenses to help the little man survive. Learn to draw lines creatively, improve your logic and develop your brain!s",
        tag: ["Skill","Puzzle"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2022-12-07",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Angela Christmas Dress up Game - playcutegames.com",
      name: ["Angela Christmas Dress up Game", "angela-christmas-dress-up-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-christmas-dress-up-game/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-christmas-dress-up-game/250x142.png",
      path: ["/game/angela-christmas-dress-up-game", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/angela-christmas-dress-up-game/",
      Walkthrough: "https://www.youtube.com/embed/D5r0O4e3YLg",
      s_dis:
      "Angela Christmas Dress up Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Angela Christmas Dress Up Game. Christmas is around the corner. Angela is more excited than us to enjoy this Christmas with her friends. She has some crazy ideas to make this Christmas more fun! Join and help her to bake and decorate cakes, a Christmas tree, Cookies, and her house.",
      tag: ["Angela","Dress up","Christmas","Decoration","Cooking","Cake","Girl games","Holiday","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2024-08-12",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Ellie And Friends Art Hoe Aesthetic - playcutegames.com",
      name: ["Ellie And Friends Art Hoe Aesthetic", "ellie-and-friends-art-hoe-aesthetic"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-art-hoe-aesthetic/250x142.png",
      thumb_big: "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-art-hoe-aesthetic/520x295.png",
      path: ["/game/ellie-and-friends-art-hoe-aesthetic", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-friends-art-hoe-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/LPXMCq2iwAg",
      s_dis:
      "Ellie And Friends Art Hoe Aesthetic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Ellie And Friends Art Hoe Aesthetic is all about exploring creativity through unique fashion and art! Dress them up in colorful outfits, mix and match funky accessories, and dive into the world of artistic expression. From painting and drawing to choosing the perfect look, this game lets you celebrate individuality and artistic flair with Ellie and her friends. Get ready to unleash your inner artist!",
        tag: ["Barbie","Ellie","Girl games","Dress up","Fashion","Exclusive"],
      hint: `Use your left mouse button `,
      rating:[4.4,1210,114],  
    },{
      date: "2023-07-28",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Monster Girls Glam Goth Style - playcutegames.com",
      name: ["Monster Girls Glam Goth Style", "monster-girls-glam-goth-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-glam-goth-style/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-girls-glam-goth-style-300.jpg",
      path: ["/game/monster-girls-glam-goth-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/monster-girls-glam-goth-style/",
      Walkthrough: "https://www.youtube.com/embed/4cc9a6Ou9Aw",
      s_dis:
      "Monster Girls Glam Goth Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Discover the dark allure of Monster Girls Glam Goth Style! Embrace gothic charm, mystical fashion, and edgy elegance as enchanting characters flaunt their unique looks. Dive into a world of captivating outfits, hauntingly beautiful accessories, and mesmerizing aesthetics. Unleash your inner goth and explore a fashion realm like no other, where the mystical meets the glamorous. Join the bewitching squad and express your dark and stylish side with this new game.",
        tag: ["Monster High","Dress up","Girl games","Fashion","Prom","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      
      date: "2023-09-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BTS Signature Fashion Style - playcutegames.com",
      name: ["BTS Signature Fashion Style", "bts-signature-fashion-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bts-signature-fashion-style/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bts-signature-fashion-style-300.jpg",
      path: ["/game/bts-signature-fashion-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bts-signature-fashion-style/",
      Walkthrough: "https://www.youtube.com/embed/jr3PcLAgCvY",
      s_dis:
      "BTS Signature Fashion Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dive into the world of BTS Signature Fashion Style. Explore the iconic looks of the global superstars and discover how to incorporate their trendsetting fashion into your own wardrobe. From stage outfits to everyday streetwear, get inspired and elevate your style with a touch of BTS’s unparalleled flair. Join us on a fashion journey that transcends borders and celebrates individuality with the influence of these K-pop icons.",
        tag: ["BTS","Girl games","Exclusive",],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
     {
      date: "2022-10-11",
      brand: "GD",
     
      orientation: 1,
      title: "Tasty Drop - playcutegames.com ",
      name: ["Tasty Drop", "tasty-drop"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/tasty-drop/250x142.png",
      path: ["/game/tasty-drop", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/89f190bce5de4a6f9831f6a16d721b74/?gd_sdk_referrer_url=https://playcutegames.com/game/tasty-drop",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Tasty Drop Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Tasty Drop is a fun casual game where all you have to do is to drop the last element of a delicious dish. The game concept might look simple, but it is actually quite challenging. Experience the unique physics system that provides the unique look and feel of the game.`,
      tag: ["Puzzle","Skill","Girl","Simulation"],
      hint: `
      <ul class="gamelist">
      <li class="para description">Tasty Drop is a simulation game that's great for players of all ages. Help two mice construct an outdoor oven before they bake a few pizzas in it.</li>
      </ul>
      `,
      rating:[4.4,1210,114],
    },
     {
      date: "2022-10-11",
      brand: "Babygames",
     
      orientation: 1,
      title: "Makeup Slime Cooking Master 2 - playcutegames.com ",
      name: ["Makeup Slime Cooking Master 2", "makeup-slime-cooking-master-2"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/makeup-slime-cooking-master-2/250x142.png",
      path: ["/game/makeup-slime-cooking-master-2", "https://playcutegames.com"],
      iframe:
        "https://www.babygames.com/games/Makeup-Slime-Cooking-Master-2/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Makeup Slime Cooking Master 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Makeup Slime Cooking Master 2 is an online game that you can play on Yiv for free. Hello, young chefs! Are you craving delicious fast food? Are you craving delicious fast food? Come and play Happy Kids Burger Maker. Make your own burgers, fries chips, and cold drinks in the kitchen. Practice your cooking skills, learn new cooking techniques and become the best junior head chef in this fun kitchen cooking game.`,
      tag: ["Cooking","Camp","Skill","Girl","Simulation"],
      hint:  `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
     {
      date: "2022-10-11",
      brand: "Babygames",
     
      orientation: 1,
      title: "Happy Kids Burger Maker - playcutegames.com ",
      name: ["Happy Kids Burger Maker", "happy-kids-burger-maker"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/happy-kids-burger-maker/250x142.png",
      path: ["/game/happy-kids-burger-maker", "https://playcutegames.com"],
      iframe:
        "https://www.babygames.com/games/Happy-Kids-Burger-Maker/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Happy Kids Burger Maker Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Happy Kids Burger Maker is an online game that you can play on Yiv for free. Hello, young chefs! Are you craving delicious fast food? Are you craving delicious fast food? Come and play Happy Kids Burger Maker. Make your own burgers, fries chips, and cold drinks in the kitchen. Practice your cooking skills, learn new cooking techniques and become the best junior head chef in this fun kitchen cooking game.`,
      tag: ["Cooking","Camp","Skill","Girl","Simulation"],
      hint:  `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },{
      
      date: "2023-08-30",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Ellie And Friends Pre Fall Outfit - playcutegames.com",
      name: ["Ellie And Friends Pre Fall Outfit", "ellie-and-friends-pre-fall-outfit"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-pre-fall-outfit/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-friends-pre-fall-outfit-300.jpg",
      path: ["/game/ellie-and-friends-pre-fall-outfit", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-and-friends-pre-fall-outfit/",
      Walkthrough: "https://www.youtube.com/embed/k4DMuC3rw4o",
      s_dis:
      "Ellie And Friends Pre Fall Outfit Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Transition into fall with the Ellie and Friends Pre-Fall Outfit collection. Explore a fusion of style and comfort as they curate trendy ensembles featuring cozy layers, versatile accessories, and seasonal hues. Embrace the upcoming season with fashion-forward choices inspired by the experts themselves! Join and have fun!",
        tag: ["Barbie","Fall","Autumn","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-07-15",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Monster High Signature Style - playcutegames.com",
      name: ["Monster High Signature Style", "monster-high-signature-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-high-signature-style/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-high-signature-style-300.jpg",
      path: ["/game/monster-high-signature-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/monster-high-signature-style/",
      Walkthrough: "https://www.youtube.com/embed/hQK2XzacAeQ",
      s_dis:
      " Monster High Signature Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Embrace the supernatural allure of Monster High Signature Style! Dive into a world where ghoulish fashion takes center stage. Discover unique outfits, hauntingly beautiful accessories, and the signature style of your favorite monster characters. Unleash your inner monster and join the stylish haunting fun!",
        tag: ["Monster High","Dress up","Girl games","Fashion","Signature","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-06-29",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Ellie Summer Spa And Beauty Salon - playcutegames.com",
      name: ["Ellie Summer Spa And Beauty Salon", "ellie-summer-spa-and-beauty-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-summer-spa-and-beauty-salon/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-summer-spa-and-beauty-salon-300.jpg",
      path: ["/game/ellie-summer-spa-and-beauty-salon", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-summer-spa-and-beauty-salon/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      " Ellie Summer Spa And Beauty Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Ellie Summer Spa & Beauty Salon: Dive into the glamorous world of beauty and fashion as you run your own spa and salon empire! Treat clients to soothing massages, refreshing facials, and stunning makeovers. Create unique styles and become the ultimate beauty guru in this addictive and fun game.",
        tag: ["Barbie","Dress up","Girl games","Fashion","Salon","Hair","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2022-08-05",
      brand: "Crazy games",
     
      orientation: 0,
      title: "UNO Online - playcutegames.com",
      name: ["UNO Online", "uno-online"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/uno-online/250x142.png",
      path: ["/game/uno-online", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/uno-online/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "UNO Online Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Uno Online lets you play the popular Uno card game for free in your web browser. The aim of the game is to be the first player with no cards, similar to other Crazy Eights style card games. Play Uno Online with 2, 3, or 4 players.",
      tag: ["Crazy games","Multiplayer","Card","Puzzle","Challenge","Online games"],
      hint: `
      There are various strategies you can use to maximize your chances of winning at Uno. The main principle is to get rid of all your cards before everyone else. You can do that by matching the color or number of the card placed down before yours. If you don't have a playable card, you must draw cards from the pile until you do.
      <br><br>When you have one card left, press the "1" button, otherwise you will be punished with two penalty cards!`,
      rating:[4.2,110,14],
    },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Durak classic - playcutegames.com",
    name: ["Durak classic", "durak-classic"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/durak-classic/250x142.png",
    path: ["/game/durak-classic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/174460?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/durak-classic.mp4",
    Walkthrough: "",
    s_dis: "Play the most popular card game against real opponents, challenge other players and prove that you are the real winner!",
    l_dis:`The most popular card game in the world, classic Klondike Solitaire, by Conifer is the new best way to play the game you know and love for free！Is a great way to relax and train your brain. With beautiful graphics, fun animations, and offline play, Solitaire is the best game to pass the time.`,
    tag: ["Card","Board"],
    hint: `
    The goal of the game is to be the first to get rid of all your cards. The last player who does not get rid of the cards remains in the \"fools\".\n\nThe player with the lowest trump card goes first, then clockwise. You can move any number of cards of the same rank.\n​✔️​The first \"hang up\" is 5 cards.\n​✔️​The first round cannot be transferred.\n\nThe player under whom they go can cover the cards either with the highest card of the same suit, or with a card of the trump suit.\n\nThe walking player can flip cards that match the value of any card on the table. You cannot throw more cards than the batting player has left and more than six cards, even if the player has more cards in his hand.\n\nIf the player under whom they go fought back, then he goes next, but first all players get up to 6 cards, starting with the player who went first and ending with the player who fought back.\n\nIf the player could not fight back, then he accepts all the cards, and the player on the left goes next.
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Solitaire - playcutegames.com",
    name: ["Solitaire", "solitaire"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/solitaire/250x142.png",
    path: ["/game/solitaire", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/182305?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    Walkthrough: "",
    s_dis: "Solitaire Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`The most popular card game in the world, classic Klondike Solitaire, by Conifer is the new best way to play the game you know and love for free！Is a great way to relax and train your brain. With beautiful graphics, fun animations, and offline play, Solitaire is the best game to pass the time.`,
    tag: ["Card","Board"],
    hint: `
    FEATURES:\r\n\r\nOriginal Classic Solitaire: \r\n♥️ Play Klondike solitaire draw 1 & draw 3 modes\r\n♥️ Choose scoring mode: Standard or Vegas solitaire scoring\r\n\r\nDaily Challenge:\r\n♠️ Challenge yourself with the hard mode\r\n♠️ Receive special mark everyday\r\n♣️ Record every achievement you made\r\n♣️ Stay Sharp and see every improvement\r\n\r\nUser Friendly Experience:\r\n♦️ Enjoy clean and comfortable visual design\r\n♦️ Use new props to make your gameplay more easy\r\n♦️ Play Offline: Play random deals everywhere\r\n♦️ Supports left-handed play
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Kings & Queens - playcutegames.com",
    name: ["Kings & Queens", "kings-and-queens"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/kings-and-queens/250x142.png",
    path: ["/game/kings-and-queens", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/173184?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/kings-and-queens.mp4",
    Walkthrough: "",
    s_dis: "Kings & Queens Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`It’s a grand time to harvest some solitaire fun! If you enjoy card games and the words Pyramid, Klondike, Spider mean something to you, or even if you’re just on the verge of discovering solitaire puzzles, Kings & Queens welcome you on your journey! This beautiful tripeaks saga has a lot to offer.
<br><br>
Become an honorable member of Kings & Queens club and enjoy its never-ending perks: – Savor picturesque landscapes while solving challenging puzzles! – Complete countless tri peaks levels to become the solitaire master! – Learn the sophisticated art of playing the wildcards and use it to your advantage! – Try to keep up with mischievous magic cards and to pick enchanted locks! – Uncover hidden golden treasures and collect priceless rubies on your journey!
<br><br>
It’s easy to learn and hard to let go of! Get ready for your journey into the world of tripeaks solitaire puzzles together with Kings & Queens!`,
    tag: ["Card","Board"],
    hint: `
    Beat levels to collect cards and open new locations!<br>
To complete a level collect a card one higher or lower than the one in the deck.<br>
Receive stars for each completed level and use them to unlock different picturesque sights.<br>
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Durak - playcutegames.com",
    name: ["Durak", "durak"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/durak/250x142.png",
    path: ["/game/durak", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/99290?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/durak.mp4",
    Walkthrough: "",
    s_dis: "Durak Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:`Durak means fool and it is the most popular card game in Russia and in many post-Soviet states. The object of the game is to avoid being the last player with cards.`,
    tag: ["Card","Board"],
    hint: `
    Begin by playing any card from hand.\nTo beat off the attack the defender will have to beat this and all subsequent attacking cards. A card which is not a trump can be beaten by playing a higher card of the same suit, or by any trump. A trump card can only be beaten by playing a higher trump.\nIn the Transfer Durak while no cards are beaten the defender can transfer defender role to the next player by playing a card with the same rank as the cards on the table.\nCard under deck determines trump suit.\nThe attacker can continue the attack by playing another card, which is of the same rank as some card already played during the current bout – either an attack card or a card played by the defender.\nWhen an attack is beaten off, the defender becomes the attacker for the next bout, and the player to the new attacker's left is the new defender. Otherwise defender's left is the new defender.\nThe last player left holding cards is the loser.
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "4 Colors World Tour - playcutegames.com",
    name: ["4 Colors World Tour", "4-colors-world-tour"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/4-colors-world-tour/250x142.png",
    path: ["/game/4-colors-world-tour", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/269910?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/4-colors-world-tour.mp4",
    video:"",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "4 Colors World Tour Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Pack your bags and go in search of cute mascots around the world in this special version of the game Uno!\n\nPlay alone or challenge up other players from around the world!
  
      `,
    tag: ["Card","Board"],
    hint: `
    Match cards by color or number and get rid of them first. \n\nRemember, when you only have one card left, otherwise you will have to draw two penalty cards.
    
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Spider Solitaire 2024 - playcutegames.com",
    name: ["Spider Solitaire 2024", "spider-solitaire-2024"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/spider-solitaire-2024/250x142.png",
    path: ["/game/spider-solitaire-2024", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/269910?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/spider-solitaire-2024.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Spider Solitaire 2024 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Spider Solitaire 2024 is a well-known card game played by one person. You need to arrange all the cards in a 52-card deck in a certain way. The first should be the king, then the queen, etc. to reduce the card's rank. The last to be placed is the ace.
      <br><br>
      Rules of the game: The game uses a deck of 52 cards. These cards must be laid out in a certain sequence. The king is placed first, then the queen, etc. to reduce the card's rank. The last to be placed is the ace. In this case, the cards must be of the same suit, so that the collected stack of cards is considered collected and moved to the base. But in the process of collecting cards, they can be placed on other suits, the main thing is to respect the seniority of card values. Those. A 9 can only be placed on a ten, and not vice versa.
      <br><br>
      In the game you can customize the background color, card back, card type and some other functions.
  
      `,
    tag: ["Card","Board"],
    hint: `
    Select the difficulty level you want, it's best to start with a simple difficulty level. Especially if you haven't played this game before to understand the essence. Next, to move the map, double-click on it with the mouse button or tap on the smartphone screen. You can also move a card by holding a tap on this card and moving it around the screen.
    
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Solitaire: classic - playcutegames.com",
    name: ["Solitaire: classic", "solitaire-classic"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/solitaire-classic/250x142.png",
    path: ["/game/solitaire-classic", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/191706?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/solitaire-classic.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Solitaire: classic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Solitaire is a great opportunity to train your brain, improve your problem-solving skills, relax and have fun! Return to the timeless PC classic as it comes to your phone so that you can play it anytime, anywhere!
      <br>
      Play card game anyway at anytime
      <h2 class="Sub_Heading">Featuring:</h2>
      <ul>
      <li>Detailed player statistics</li>
      <li>Random or Winnable solitaire decks</li>
      <li>Tablet support</li>
      <li>Beautiful HD Graphics</li>
      </ul>
      `,
    tag: ["Card","Board"],
    hint: `
    <ul>
    <li>To solve the Klondike deal, you need to tap, drag or drop to sort cards of 4 suits in ascending order, Ace 2, ... Jack, Queen, King.</li>
    <li>You will have 7 card column options where you can move one card or consecutive cards from one column to another.</li>
    <li>King or any consecutive cards starting with K can be moved to the empty columns.</li>
    <li>Click the draw pile and find the card you want if you get stuck.</li>
    </ul>
    
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Solitaire Classic Klondike - playcutegames.com",
    name: ["Solitaire Classic Klondike", "solitaire-classic-klondike"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/solitaire-classic-klondike/250x142.png",
    path: ["/game/solitaire-classic-klondike", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/180471?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/solitaire-classic-klondike.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Solitaire Classic Klondike Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Solitaire Classic Klondike is a game most of us have played at some time - here we have versions of this game made with beautiful game boards and eye-catching pieces. We've carefully designed the game to have a smooth and modern feel to it and threw in some classic, Solitaire Classic Klondike game elements we all love. FEATURE- Undo moves - Track your moves and time - Enjoy the clean design, animations, and sounds - Enjoy nice & smooth gameplay that'll keep you coming for more - Enjoy special challenges & surprises - Play on your mobile phone, tablet or desktop - And many more! Enjoy a game by yourself, or save your score to play card games with friends!
      `,
    tag: ["Card","Board"],
    hint: `
    Remove all cards from the field.<br>
Decreasing sequences of the same suit from king to ace are removed from the field.<br>
On the card, you can put a card on a unit of lower value, without taking into account the suit, along with the cards lying on it.<br>
The descending sequence of cards of the same suit can be dragged.<br>
You can put any card in an empty cell.<br>
A click on the deck lays out another row of cards.
If at least one game cell is free, a new row of cards cannot be laid out. 
    `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Pastaria - playcutegames.com",
    name: ["Papa’s Pastaria", "papas-pastaria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-pastaria/250x142.png",
    path: ["/game/papas-pastaria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-pastaria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-pastaria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Pastaria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Pastaria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own pasta restaurant. Create delicious pasta dishes by cooking pasta, adding sauces, and customizing them with various toppings. Serve your customers quickly to earn tips and upgrade your restaurant with new equipment and ingredients. Can you become the ultimate pasta master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Cupcakeria - playcutegames.com",
    name: ["Papa’s Cupcakeria", "papas-cupcakeria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-cupcakeria/250x142.png",
    path: ["/game/papas-cupcakeria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-cupcakeria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-cupcakeria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Cupcakeria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Cupcakeria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own cupcake bakery. Create delicious cupcakes by mixing batter, baking them in the oven, frosting them with various flavors, and decorating them with toppings. Serve your customers quickly to earn tips and upgrade your bakery with new equipment and ingredients. Can you become the ultimate cupcake master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Hot Doggeria - playcutegames.com",
    name: ["Papa’s Hot Doggeria", "papas-hot-doggeria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-hotdoggeria/250x142.png",
    path: ["/game/papas-hot-doggeria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-hotdoggeria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-hot-doggeria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Hot Doggeria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Hot Doggeria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own hot dog stand. Create delicious hot dogs by grilling the sausages, adding toppings, and customizing them with various condiments. Serve your customers quickly to earn tips and upgrade your stand with new equipment and ingredients. Can you become the ultimate hot dog master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Wingeria - playcutegames.com",
    name: ["Papa’s Wingeria", "papas-wingeria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-wingeria/250x142.png",
    path: ["/game/papas-wingeria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-wingeria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-wingeria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Wingeria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Wingeria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own chicken wing restaurant. Create delicious chicken wings by frying them to perfection, adding sauces, and customizing them with various toppings. Serve your customers quickly to earn tips and upgrade your restaurant with new equipment and ingredients. Can you become the ultimate chicken wing master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Pancakeria - playcutegames.com",
    name: ["Papa’s Pancakeria", "papas-pancakeria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-pancakeria/250x142.png",
    path: ["/game/papas-pancakeria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-pancakeria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-pancakeria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Pancakeria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Pancakeria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own pancake restaurant. Create delicious pancakes by flipping batter on the griddle, adding toppings, and drizzling syrups. Serve your customers quickly to earn tips and upgrade your restaurant with new equipment and ingredients. Can you become the ultimate pancake master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Scooperia - playcutegames.com",
    name: ["Papa’s Scooperia", "papas-scooperia"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-scooperia/250x142.png",
    path: ["/game/papas-scooperia", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-scooperia/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-scooperia.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Scooperia Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Scooperia is a fun and addictive time management game where you take on the role of a young entrepreneur running your own ice cream shop. Create delicious ice cream sundaes by scooping ice cream, adding toppings, and drizzling sauces. Serve your customers quickly to earn tips and upgrade your shop with new equipment and ingredients. Can you become the ultimate ice cream master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Donuteria - playcutegames.com",
    name: ["Papa’s Donuteria", "papas-donuteria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-donuteria/250x142.png",
    path: ["/game/papas-donuteria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-donuteria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-donuteria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Donuteria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Donuteria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own donut shop. Create delicious donuts by frying dough, adding fillings, and frosting them with various glazes and toppings. Serve your customers quickly to earn tips and upgrade your shop with new equipment and ingredients. Can you become the ultimate donut master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Cheeseria - playcutegames.com",
    name: ["Papa’s Cheeseria", "papas-cheeseria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-cheeseria/250x142.png",
    path: ["/game/papas-cheeseria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-cheeseria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-cheeseria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Cheeseria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Cheeseria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own cheese shop. Create delicious cheese sandwiches, grilled cheese, and cheese platters by selecting different types of cheese, adding toppings, and customizing them with various condiments. Serve your customers quickly to earn tips and upgrade your shop with new equipment and ingredients. Can you become the ultimate cheese master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Bakeria - playcutegames.com",
    name: ["Papa’s Bakeria", "papas-bakeria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-bakeria/250x142.png",
    path: ["/game/papas-bakeria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-bakeria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-bakeria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Bakeria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Bakeria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own bakery. Create delicious baked goods like bread, pastries, and cakes by mixing dough, shaping it, baking it in the oven, and frosting or decorating them with various toppings. Serve your customers quickly to earn tips and upgrade your bakery with new equipment and ingredients. Can you become the ultimate baker master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 0,
    title: "Papa’s Sushiria - playcutegames.com",
    name: ["Papa’s Sushiria", "papas-sushiria"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/papas-sushiria/250x142.png",
    path: ["/game/papas-sushiria", "https://playcutegames.com"],
    iframe: "https://games.cutedressup.net/ruffle/papas-sushiria/",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/papas-sushiria.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Papa’s Sushiria Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `Papa’s Sushiria is a fun and addictive time management game where you take on the role of a young entrepreneur running your own sushi restaurant. Create delicious sushi rolls by selecting different types of fish, adding rice and seaweed, and customizing them with various toppings and sauces. Serve your customers quickly to earn tips and upgrade your restaurant with new equipment and ingredients. Can you become the ultimate sushi master?
      `,
    tag: ["Papas","Cooking"],
    hint: `
     `,
    rating:[4.2,110,14],
  },
  {
    date: "2024-10-04",
    brand: "Yandex",
    orientation: 1,
    title: "Spider Solitaire (1, 2, and 4 suits) - playcutegames.com",
    name: ["Spider Solitaire (1, 2, and 4 suits)", "spider-solitaire-1-2-and-4-suits"],
    thumb:"https://cdn.cutedressup.in/games/cutedressup_new/spider-solitaire-1-2-and-4-suits/250x142.png",
    path: ["/game/spider-solitaire-1-2-and-4-suits", "https://playcutegames.com"],
    iframe: "https://yandex.com/games/app/96447?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
    video:"https://cdn.cutedressup.in/games/cutedressup-video/spider-solitaire-1-2-and-4-suits.mp4",
    Walkthrough: "https://www.youtube.com/embed/",
    s_dis:
    "Spider Solitaire (1, 2, and 4 suits) Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      `The game contains a choice of three Spider variants with 1, 2 and 4 suits. Win and increase your grade in every variant.
      `,
    tag: ["Card"],
    hint: `
    Remove all cards from the field.<br>
Decreasing sequences of the same suit from king to ace are removed from the field.<br>
On the card, you can put a card on a unit of lower value, without taking into account the suit, along with the cards lying on it.<br>
The descending sequence of cards of the same suit can be dragged.<br>
You can put any card in an empty cell.<br>
A click on the deck lays out another row of cards.
If at least one game cell is free, a new row of cards cannot be laid out. 
    `,
    rating:[4.2,110,14],
  }, 
     {
      date: "2022-10-11",
      brand: "Yiv",
     
      orientation: 0,
      title: "Princess Dream Bakery - playcutegames.com ",
      name: ["Princess Dream Bakery", "princess-dream-bakery"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/princess-dream-bakery/250x142.png",
      path: ["/game/princess-dream-bakery", "https://playcutegames.com"],
      iframe:
        "https://www.yiv.com/games/Princess-Dream-Bakery/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Princess Dream Bakery Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Princess Dream Bakery is an online game that you can play on Yiv for free. Little princess loves cooking desserts. One day her parents are going to travel. The little princess decides to run the cake bakery shop on her own. As her friend, come on and help her cooking delicious desserts. You will experience the cooking processes of the cakes, swiss rolls and fruit glutinous rice balls in this game. Don't forget to decorate the desserts with cute accessories. Enjoy!`,
      tag: ["Cooking","Camp","Skill","Girl","Simulation"],
      hint:  `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
     {
      date: "2022-10-11",
      brand: "GD",
     
      orientation: 0,
      title: "Funny Cooking Camp - playcutegames.com ",
      name: ["Funny Cooking Camp", "funny-cooking-camp"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/funny-cooking-camp/250x142.png",
      path: ["/game/funny-cooking-camp", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/492bf68e9e054b1da34b25e5cbc24a40/?gd_sdk_referrer_url=https://playcutegames.com/game/funny-cooking-camp",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Looking for a fun and interesting fishing game? Try Funny Cooking Camp Game and experience the excitement of catching a variety of fish species in different locations.",
      l_dis:
        `The adorable mice in Funny Cooking Camp love pizza almost as much as they love the great outdoors! Tag along with them in this cute camping game.
  
        The mice will be building an outdoor oven out of bricks at their campsite in this cooking game. They'll definitely need your help while they put it together. Once that's done, it'll be time to get started on the pizzas!`,
      tag: ["Cooking","Camp","Skill","Girl","Simulation"],
      hint: `
      <ul class="gamelist">
      <li class="para description">Funny Cooking Camp is a simulation game that's great for players of all ages. Help two mice construct an outdoor oven before they bake a few pizzas in it.</li>
      <li class="para description">LEFT CLICK to select tools, prepare the pizzas, and more.</li>
      </ul>
      `,
      rating:[4.4,1210,114],
    },
     {
      date: "2022-10-11",
      brand: "GD",
     
      orientation: 0,
      title: "Pizza Real Life Cooking - playcutegames.com ",
      name: ["Pizza Real Life Cooking", "pizza-real-life-cooking"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/pizza-real-life-cooking/250x142.png",
      path: ["/game/pizza-real-life-cooking", "https://playcutegames.com"],
      iframe:
        "https://html5.GameDistribution.com/120092e1e29c48d8ba1c7d6fc74d277a/?gd_sdk_referrer_url=https://playcutegames.com/game/pizza-real-life-cooking",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Looking for a fun and interesting fishing game? Try Pizza Real Life Cooking Game and experience the excitement of catching a variety of fish species in different locations.",
      l_dis:
        `You can bake a virtual pizza in this realisitic cooking game.All of the ingredients are waiting for you in the kitchen. Chop up toppings like olives, peppers and tomatoes before you put them on the pizza and place it in the oven.`,
      tag: ["Cooking","Pizza","Skill","Girl"],
      hint: `
      <ul class="gamelist">
      <li class="para description">Use the mouse to play</li>
      </ul>
      `,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-07-22",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Wednesday Addams Style - playcutegames.com",
      name: ["Celebrity Wednesday Addams Style", "celebrity-wednesday-addams-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-wednesday-addams-style/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-wednesday-addams-style-300.jpg",
      path: ["/game/celebrity-wednesday-addams-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-wednesday-addams-style/",
      Walkthrough: "https://www.youtube.com/embed/Sbp6Xhy3qqs",
      s_dis:
      " Celebrity Wednesday Addams Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Indulge in the iconic and bewitching Celebrity Wednesday Addams Style! Embrace the darkly chic aesthetics, explore mesmerizing outfits, and channel your inner gothic elegance. Discover how celebrities rock the macabre charm of Wednesday Addams with a modern twist. Unleash your inner darkness and redefine elegance with a touch of the mysterious.",
        tag: ["Celebrity","Dress up","Girl games","Fashion","Wednesday","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
     {
      date: "2022-10-11",
      brand: "GD",
     
      orientation: 0,
      title: "Grandma Recipe Nigiri Sushi - playcutegames.com",
      name: ["Grandma Recipe Nigiri Sushi", "grandma-recipe-nigiri-sushi"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/grandma-recipe-nigiri-sushi/250x142.png",
      path: ["/game/grandma-recipe-nigiri-sushi", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/4d3061a523dc4753b7cc45b9d1e68be3/?gd_sdk_referrer_url=https://playcutegames.com/game/grandma-recipe-nigiri-sushi",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Looking for a fun and interesting fishing game? Try Grandma Recipe Nigiri Sushi Game and experience the excitement of catching a variety of fish species in different locations.",
      l_dis:
        `Grandma Recipe: Nigiri Sushi is a fun cooking game in which you get to prepare some delicious sushi using grandma’s expert recipes.
  
        The main character has invited some of her friends over for dinner. She wants to prepare a meal that will really wow them. What better dish to choose than Grandma’s best nigiri sushi?`,
      tag: ["Cooking","Recipe","Girl"],
      hint: `
      <ul class="gamelist">
      <li class="para description">Use the mouse to play</li>
      <li class="para description">Follow the directions and trace the arrows on the screen</li>
      <li class="para description">This game is divided into six levels. Grandma is on the phone to guide you through the process of making nigiri sushi.</li>
      <li class="para description">Nigiri sushi is the type of sushi that consists of a ball of rice pressed into an oval shape, with raw fish, grilled eel, or another topping arranged on top. You’ll also be making some gunkan maki (boat-shaped sushi) with sea urchin on top.</li>
      <li class="para description">First, you’ll need to find all the ingredients and utensils in the kitchen. Cut the seafood and scorch the eel with your blowtorch. Preparing and slicing all your ingredients before you start cooking is what chefs call ‘mise en place’.</li>
      <li class="para description">Now you can start assembling and decorating the sushi plates with soy sauce, wasabi, mayonaise, salmon roe, ginger, and other toppings. Don’t forget to choose a cute outfit before your guests arrive!</li>
      </ul>
      `,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-06-24",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Fashion Sequin Addict - playcutegames.com",
      name: ["BFFs Fashion Sequin Addict", "bffs-fashion-sequin-addict"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-fashion-sequin-addict/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-fashion-sequin-addict-300.jpg",
      path: ["/game/bffs-fashion-sequin-addict", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-fashion-sequin-addict/",
      Walkthrough: "https://www.youtube.com/embed/mm0Jy-3PkzE",
      s_dis:
      " BFFs Fashion Sequin Addict Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Fashion Sequin Addict. BFFs want to try cute sparkling sequin outfits! Mix and match glamorous dresses, tops, bottoms, and accessories from their wardrobe, and create stunning looks for the runway. Show off your fashion sense and become the ultimate sequin addict! Join and help the girls.",
        tag: ["Winx","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-06-17",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Black And Pink Fashionista - playcutegames.com",
      name: ["BFFs Black And Pink Fashionista", "bffs-black-and-pink-fashionista"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-black-and-pink-fashionista/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-black-and-pink-fashionista-300.jpg",
      path: ["/game/bffs-black-and-pink-fashionista", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-black-and-pink-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/ZFtXJuXCa5Y",
      s_dis:
      " BFFs Black And Pink Fashionista Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "In BFFs Black and Pink Fashionista, BFFs are planning a style extravaganza, embracing the power of black and pink. They aim to create head-turning outfits by combining sleek black pieces with vibrant pink accents. With their impeccable taste and creative flair, they’re set to redefine fashion boundaries and inspire others to embrace the boldness of this stunning color combination. Get ready for a fashion revolution that celebrates the perfect blend of edgy and feminine styles.",
        tag: ["Winx","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-06-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Perfect Pastel Looks - playcutegames.com",
      name: ["My Perfect Pastel Looks", "my-perfect-pastel-looks"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-pastel-looks/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-pastel-looks-300.jpg",
      path: ["/game/my-perfect-pastel-looks", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-perfect-pastel-looks/",
      Walkthrough: "https://www.youtube.com/embed/647kNKeDeyU",
      s_dis:
      " My Perfect Pastel Looks Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Perfect Pastel Looks. Bffs excited to try pastel fashion. Join and help them to create stunning pastel outfits and explore a world of soft hues, delicate textures, and dreamy aesthetics. Mix and match trendy clothing, accessories, and hairstyles for the perfect pastel ensemble. Unleash your creativity in this fashion-forward game!",
      tag: ["Rainbow Girls","Dress up","Girl games","Pastel","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    }, {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Perfect Prom Night Look - playcutegames.com",
      name: ["Perfect Prom Night Look", "perfect-prom-night-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/perfect-prom-night-look/250x142.png",
      path: ["/game/perfect-prom-night-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/perfect_prom_night_look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Perfect Prom Night Look game. Princess Anna, Elsa, Ariel, Moana, Jasmine, and SnowWhite are getting ready for their dream day.",
      l_dis:
        `Welcome to the Perfect Prom Night Look game. Princess Anna, Elsa, Ariel, Moana, Jasmine, and SnowWhite are getting ready for their dream day. Yes! It’s a Prom Night!. They are so excited and have confused to pick a perfect costume.  Join and help the girls to choose perfect outfits and accessories.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Prom",
        "Anna",
        "Elsa",
        "Elsa",
        "Jasmine",
        "Moana",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      date: "2023-06-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Candy Fashion Look - playcutegames.com",
      name: ["BFFs Candy Fashion Look", "bffs-candy-fashion-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-candy-fashion-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-candy-fashion-look-300.jpg",
      path: ["/game/bffs-candy-fashion-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-candy-fashion-look/",
      Walkthrough: "https://www.youtube.com/embed/tGlSDfIPbV8",
      s_dis:
      " BFFs Candy Fashion Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Candy Fashion Look. Bffs want to try sweet and vibrant candy-inspired outfits. Mix and match colorful clothes, accessories, and hairstyles to create the most fabulous and delicious fashion looks. Let your creativity shine in this sugary fashion adventure! Unleash your creativity and create the most fabulous looks in this sweet and stylish game. Get ready for a mouthwatering fashion experience!",
      tag: ["LOL Surprise","Dress up","Girl games","Beach","Festival","Summer","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-24",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Biker Fashion - playcutegames.com",
      name: ["BFFs Biker Fashion", "bffs-biker-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-biker-fashion/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-biker-fashion-300.jpg",
      path: ["/game/bffs-biker-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-biker-fashion/",
      Walkthrough: "https://www.youtube.com/embed/YUxXRA6C6dg",
      s_dis:
      " BFFs Biker Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Get ready to hit the road in style with BFFs Biker Fashion! Choose from a wide range of trendy outfits, leather jackets, boots, and accessories to create the perfect biker look. Show off your fashion skills and transform these best friends into the ultimate biker babes! Play and have fun!",
        tag: ["Barbie","Dress up","Fashion","Girl games","Exclusive","Ellie"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-24",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Kendel All Around The Fashion - playcutegames.com",
      name: ["Celebrity Kendel All Around The Fashion", "celebrity-kendel-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-kendel-all-around-the-fashion/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-kendel-all-around-the-fashion-300.jpg",
      path: ["/game/celebrity-kendel-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-kendel-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/kpF6FbJK6wQ",
      s_dis:
      "Celebrity Kendel All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Kendel All Around The Fashion. Kendel All, the sensational celebrity, takes the fashion world by storm in this exciting game! Join her on a glamorous journey as she conquers the runway, designs stunning outfits, and transforms into a style icon. Explore luxurious locations, attend exclusive events, and compete against fashion elites to become the ultimate trendsetter. With endless customization options and a captivating storyline, Kendel All Around The Fashion is a must-play for fashion enthusiasts!",
        tag: ["Celebrity","Dress up","Fashion","Girl games","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-15",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Sunflower Shine Looks - playcutegames.com",
      name: ["Celebrity Sunflower Shine Looks", "celebrity-sunflower-shine-looks"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-sunflower-shine-looks/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-sunflower-shine-looks-300.jpg",
      path: ["/game/celebrity-sunflower-shine-looks", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-sunflower-shine-looks/",
      Walkthrough: "https://www.youtube.com/embed/rNT08ap5Zq8",
      s_dis:
      "Celebrity Sunflower Shine Looks Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Sunflower Shine Looks. These celebrities are more fond of sunflower pattern outfits. They already filled the latest collection in their wardrobe. Explore a variety of dresses, accessories, and more, in their wardrobe. Mix and match different pieces to create the perfect ensemble that captures the essence of these beautiful flowers.",
        tag: ["Celebrity","Dress up","Flower","Girl games","Summer","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-18",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Wild Animal Care And Salon - playcutegames.com",
      name: ["Wild Animal Care And Salon", "wild-animal-care-and-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/wild-animal-care-and-saloon/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/wild-animal-care-and-salon-300.jpg",
      path: ["/game/wild-animal-care-and-salon", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/wild-animal-care-and-saloon/",
      Walkthrough: "https://www.youtube.com/embed/PwlohL2Fxlo",
      s_dis:
      "Wild Animal Care And Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "“Wild Animal Care And Salon” is an interactive simulation game where players take on the role of caretaker and stylist for a variety of wild animals. Players will be responsible for giving treatment, feeding, grooming, and playing with the animals to keep them healthy and happy. As they progress through the game, players will unlock new animals to care for, each with their own unique needs and personalities.",
      tag: ["Docter","Animal","Treatment","Dress up","Mobile","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      
      date: "2023-08-22",
      brand: "playcutegames",
     
      orientation: 0,
      title: "V And N Pizza Cooking Game - playcutegames.com",
      name: ["V And N Pizza Cooking Game", "v-and-n-pizza-cooking-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/v-and-n-pizza-cooking-game/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/v-and-n-pizza-cooking-game-300.jpg",
      path: ["/game/v-and-n-pizza-cooking-game", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/v-and-n-pizza-cooking-game/",
      Walkthrough: "https://www.youtube.com/embed/HvO3LEDT8RI",
      s_dis:
      "V And N Pizza Cooking Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Experience the ultimate virtual pizza-making adventure with the V And N Pizza Cooking Game! Craft delicious pizzas from scratch, choose mouthwatering toppings, and master the art of baking. From classic favorites to innovative creations, unleash your inner chef and create culinary masterpieces. With immersive gameplay and endless combinations, satisfy your pizza cravings and become a virtual pizzaiolo extraordinaire. Get ready to cook, create, and enjoy the taste of success in this exciting cooking game!",
        tag: ["Cooking","V&N","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-15",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Swifty All Around The Fashion - playcutegames.com",
      name: ["Celebrity Swifty All Around The Fashion", "celebrity-swifty-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-swifty-all-around-the-fashion/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-swifty-all-around-the-fashion-300.jpg",
      path: ["/game/celebrity-swifty-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-swifty-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/2LkRE1h9Wh0",
      s_dis:
      "Celebrity Swifty All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Swifty All Around The Fashion. Celebrity Swifty plans to try different fashion trends. She needs some help to choose the perfect costumes and outfits. Browse her wardrobe and choose the perfect outfits for each fashion trend. Play and have fun with this new Celebrity game for girls!",
        tag: ["Celebrity","Dress up","Girl games","Fashion","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-09",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Classic Summer Stripes - playcutegames.com",
      name: ["Celebrity Classic Summer Stripes", "celebrity-classic-summer-stripes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-classic-summer-stripes/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-classic-summer-stripes-300.jpg",
      path: ["/game/celebrity-classic-summer-stripes", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-classic-summer-stripes/",
      Walkthrough: "https://www.youtube.com/embed/CmPYK0yR4vA",
      s_dis:
      "Celebrity Classic Summer Stripes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Classic Summer Stripes. Let’s style your favorite celebrities in summer stripes fashion. Browse their wardrobe and choose from a selection of striped tops, bottoms, dresses, and accessories to create the perfect summer look for them. You can mix and match different pieces to create a one-of-a-kind look. So, let your imagination run wild and create the ultimate summer stripes look for your favorite celebrity!",
        tag: ["LOL Surprise","Dress up","Girl games","Beach","Festival","Summer","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-08",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Movie Star Daily Routine - playcutegames.com",
      name: ["Movie Star Daily Routine", "movie-star-daily-routine"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/movie-star-daily-routine/250x142.png",
      path: ["/game/movie-star-daily-routine", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1680542863/",
      Walkthrough: "https://www.youtube.com/embed/vAcIbxcKiNA",
      s_dis:
      "Movie Star Daily Routine Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Movie Star Daily Routine. Let’s join the day in the Life of a movie star stream. We will spend the whole day with a famous actress from the series. The morning begins with cosmetic procedures and skin care. See how the skin of a movie star shines!",
      tag: ["Dl-Girls","Make up","Kids","Girl","Dress up","Wednesday"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-05-06",
      brand: "colorifyme",
     
      orientation: 1,
      title: "Cat Coloring - playcutegames.com",
      name: ["Cat Coloring", "cat-coloring-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cat-coloring-game/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/cat-coloring-game/520x295.png",
      path: ["/game/cat-coloring-game", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/colorifyme/cat-coloring-game/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cat Coloring Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Purrfect your coloring skills with our Cat Coloring Game! Choose from a variety of cute cat images and use your artistic flair to color them in with bright colors. Great for all ages, this game is a fun way to unwind and express your creativity. With easy-to-use controls and endless color combinations, you’ll never get bored. You can also experiment with different shades of the same color to add depth and texture to your design. If you make a mistake or want to change a color, you can simply select the eraser tool or undo the button to remove the color and start again. Once you have finished coloring, you can save the finished design or print it out to display.",
      tag: ["Coloring","Education","Cat","Pet","Animal"],
      hint: `Click and apply`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Summer Beach Vibes - playcutegames.com",
      name: ["BFFs Summer Beach Vibes", "bffs-summer-beach-vibes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-summer-beach-vibes/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-summer-beach-vibes-300.jpg",
      path: ["/game/bffs-summer-beach-vibes", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-summer-beach-vibes/",
      Walkthrough: "https://www.youtube.com/embed/XyK06GpZ59I",
      s_dis:
      "BFFs Summer Beach Vibes Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "BFFs Summer Beach Vibes is a fun and exciting game that lets you dress up four best friends for a day at the beach. You get to choose from a variety of trendy outfits and accessories to create the perfect beach look. They are excited to spend the day soaking up the sun and having fun at the beach. Join and help them pick out their outfits for the day. You can choose from a variety of beach outfits, sunglasses, and hats to create a stylish beach look for each friend.",
        tag: ["LOL Surprise","Dress up","Girl games","Beach","Festival","Summer","Exclusive","Season"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-27",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Boho Summer Style - playcutegames.com",
      name: ["Celebrity Boho Summer Style", "celebrity-boho-summer-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-boho-summer-style/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-boho-summer-style-300.jpg",
      path: ["/game/celebrity-boho-summer-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-boho-summer-style/",
      Walkthrough: "https://www.youtube.com/embed/StPWgVoc8uY",
      s_dis:
      "Celebrity Boho Summer Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Celebrity Boho Summer Style is a summer fashion dress-up game. In this game, Celebrities plan to have a party on the beach with bohemian-inspired outfits. Browse their wardrobe to mix and match different clothing items, accessories, and hairstyles to create a unique look. Join to play with your favorite celebrities and have fun!",
        tag: ["Celebrity","Dress up","Girl games","Fashion","Summer","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-127",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bffs Cherry Blossom Festival Look - playcutegames.com",
      name: ["Bffs Cherry Blossom Festival Look", "bffs-cherry-blossom-festival-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-cherry-blossom-festival-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-cherry-blossom-festival-look-300.jpg",
      path: ["/game/bffs-cherry-blossom-festival-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-cherry-blossom-festival-look/",
      Walkthrough: "https://www.youtube.com/embed/t1z5b8FTFR0",
      s_dis:
      "Bffs Cherry Blossom Festival Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "“The BFFs Cherry Blossom Festival Look dress-up game is a fun and interactive way to plan your perfect outfit for a springtime festival with your best friend. In this game, Bffs plan to get ready for the cherry blossom festival. You have to help them to choose clothing options, including pastel dresses, floral prints, and other spring-inspired pieces to create an ideal look.",
      tag: ["Winx","Dress up","Girl games","Fashion","Festival","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-05-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Coachella Festival Outfits - playcutegames.com",
      name: ["My Coachella Festival Outfits", "my-coachella-festival-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-coachella-festival-outfits/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/my-coachella-festival-outfits-300.jpg",
      path: ["/game/my-coachella-festival-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-coachella-festival-outfits/",
      Walkthrough: "https://www.youtube.com/embed/RaGnPgnJ2h4",
      s_dis:
      "My Coachella Festival Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Coachella Festival Outfits. Ellie and her friends are more excited to attend the Coachella Festival this year. Browse their wardrobe to mix and match different clothing items, accessories, hairstyles, and makeup to create unique and trendy outfits for them. With a wide variety of options to choose from, you can experiment with different styles and fashion trends, including bohemian, grunge, retro, and more.",
        tag: ["Barbie","Dress up","Girl games","Festival","Summer","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-19",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Trendy Oversized Outfits Street Style - playcutegames.com",
      name: ["My Trendy Oversized Outfits Street Style", "my-trendy-oversized-outfits-street-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-trendy-oversized-outfits-street-style/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/my-trendy-oversized-outfits-street-style-300.jpg",
      path: ["/game/my-trendy-oversized-outfits-street-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-trendy-oversized-outfits-street-style/",
      Walkthrough: "https://www.youtube.com/embed/lwjxtxhyIzE",
      s_dis:
      "My Trendy Oversized Outfits Street Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Trendy Oversized Outfits Street Style. Oversized Outfits… so trendy these days! But it’s not easy building up a suitable outfit! This BFF will wear one today, and they need a bit of help to pick a perfect one.  Help them out! They have plenty of cool Oversized Outfits in their wardrobe which you can mix and match however you like, and check out the accessories too; those are amazing! ",
      tag: ["Winx","Girl games","Dress up","Oversized","Fashion","Dress up","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-17",
      brand: "colorifyme",
     
      orientation: 1,
      title: "Seahorse Coloring - playcutegames.com",
      name: ["Seahorse Coloring", "seahorse-coloring-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/seahorse-coloring-game/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/seahorse-coloring-game/520x295.png",
      path: ["/game/seahorse-coloring-game", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/colorifyme/seahorse-coloring-game/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Seahorse Coloring Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Do you like coloring games that let your creativity run wild? Check out the free online Seahorse Coloring Game on Play Cute Games! This fun and engaging game lets you bring adorable seahorses to life in a vibrant, underwater world. With a wide selection of colors and creative tools at your fingertips, you can design unique patterns and make each seahorse stand out in its own special way.
Enjoy a relaxing and artistic experience as you craft beautiful sea scenes and add your personal touch to each design. Whether you’re creating calming ocean views or bold, colorful designs, this game provides endless opportunities for imaginative fun. Share your colorful masterpieces with friends and see who can come up with the most stunning sea creature creations. Dive in and let your artistic talents shine with hours of playful and creative coloring fun!`,
      tag: ["Coloring","Education","Exclusive"],
      hint: `Click and apply`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2022-10-11",
      brand: "GD",
     
      orientation: 1,
      title: "Enjoy the Thrill of the Catch with Tiny Fishing Game",
      name: ["Tiny Fishing", "tiny-fishing"],
      thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/tiny-fishing/250x142.png",
      path: ["/game/tiny-fishing", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/5ee4b0222a474e44880b6c6d9f96754d/?gd_sdk_referrer_url=https://playcutegames.com/game/tiny-fishing",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Looking for a fun and interesting fishing game? Try Tiny Fishing Game and experience the excitement of catching a variety of fish species in different locations.",
      l_dis:
        `Welcome to the world of the <a href="/game/tiny-fishing">Tiny Fishing</a> Game, where you can experience the excitement of fishing without leaving your home! Imagine a miniature world filled with clear blue waters, tiny boats, and fishing rods that fit in the palm of your hand.`,
      tag: ["Puzzle","Skill","Kids","Arcade"],
      hint: `
      <ol class="gamelist">
      <li class="para description">Start by clicking on the "play" button to begin the game.</li>
      <li class="para description">Once the game starts, you will see a lake with fish swimming around in it.</li>
      <li class="para description">Use your mouse to move your fishing rod around the lake until you find a spot with fish.</li>
      <li class="para description">Click and hold the left mouse button to cast your line into the water.</li>
      <li class="para description">Wait for a fish to bite and then quickly click the left mouse button to reel it in.</li>
      <li class="para description">Keep reeling in the fish by clicking the left mouse button until it reaches the surface of the water.</li>
      <li class="para description">Once the fish is on the surface, click the left mouse button again to catch it.</li>
      <li class="para description">steps 3-7 to catch as many fish as possible within the time limit.</li>
      <li class="para description">At the end of the game, your score will be displayed based on the number and size of fish you caught.</li>
      </ol>
      `,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-04-17",
      brand: "colorifyme",
     
      orientation: 1,
      title: "Bee Coloring - playcutegames.com",
      name: ["Bee Coloring", "bee-coloring-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bee-coloring-game/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bee-coloring-game/520x295.png",
      path: ["/game/bee-coloring-game", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/colorifyme/bee-coloring-game/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Bee Coloring Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Buzz into creativity with the Bee Coloring Game on Play Cute Games! It is a beautiful game in which you are allowed to choose the colors that will create the life of cute bees and the environments they live in  the gardens. And since these paints are bright and cheerful it is up to you what you paint these buzzing bees and the environment in which they live. Whether it is dazzling patterns or different hues of color each stroke transforms your creation into an exciting work of art.
Take a pleasant and aesthetic journey as you paint beautiful pictures with wonderful elements such as bees and blooming flowers. You are welcome to come up with beautiful and creative bee filled environments as you play the game. After that, make your own picture perfect and show it to friends and observe how they will react. Join the Bee Coloring Game and find a way to have fun and be relaxed coloring bees!`,
      tag: ["Coloring","Education","Exclusive"],
      hint: `Click and apply`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-14",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Spring Fashion Trends - playcutegames.com",
      name: ["Celebrity Spring Fashion Trends", "celebrity-spring-fashion-trends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-spring-fashion-trends/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-spring-fashion-trends-300.jpg",
      path: ["/game/celebrity-spring-fashion-trends", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-spring-fashion-trends/",
      Walkthrough: "https://www.youtube.com/embed/8d17wp6UDlA",
      s_dis:
      "Celebrity Spring Fashion Trends Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Spring Fashion Trends. Hello little girls. Are you ready for the latest spring fashion collection? Follow the latest fashion trends with these celebrities and create your own fashion clothes for next spring season. Browse their wardrobe to pick suitable outfits and costumes. Join and help them to welcome their favorite season.",
      tag: ["Celebrity","Girl games","Dress up","Spring","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-07",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Dreamy Flora Fashion Look - playcutegames.com",
      name: ["My Dreamy Flora Fashion Look", "my-dreamy-flora-fashion-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-dreamy-flora-fashion-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/my-dreamy-flora-fashion-look-300.jpg",
      path: ["/game/my-dreamy-flora-fashion-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-dreamy-flora-fashion-look/",
      Walkthrough: "https://www.youtube.com/embed/rnJMcyk0HdQ",
      s_dis:
      "My Dreamy Flora Fashion Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Dreamy Flora Fashion Look. Girls! You know that spring is on its way when you see the trees starting to bud and bloom, and the bulbs you planted last fall start to sprout out of the ground. Join with Ellie and their friends to celebrate the most beautiful and colorful season.",
      tag: ["Barbie","Girl games","Dress up","Spring","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-04-07",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Weird Dance on Wednesday - playcutegames.com",
      name: ["Weird Dance on Wednesday", "weird-dance-on-wednesday"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/weird-dance-on-wednesday/250x142.png",
      path: ["/game/weird-dance-on-wednesday", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1680542863/",
      Walkthrough: "https://www.youtube.com/embed/kyspCtnvGnQ",
      s_dis:
      "Weird Dance on Wednesday Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Weird Dance on Wednesday. A goth girl is going to a school prom. She wants to make a splash with her new dance. Rogue rehearsed this dance for a whole month. She came up with it herself, inspired by classic films. The gothic girl has prepared a gorgeous black dress, but something is missing. Certainly! How can you go to a ball without makeup? Help the goth girl to choose make-up that reflects her inner world. Use bold and dark shades to emphasize the eyes and lips. A few freckles will make the look a little more romantic. Go to the ball – have fun and dance a weird dance on Wednesday!",
      tag: ["Make up","Kids","Girl","Dress up","Dl-Girls","Wednesday"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2023-03-28",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Spring Haute Couture Season 1 - playcutegames.com",
      name: ["Spring Haute Couture Season 1", "spring-haute-couture-season-1"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/spring-haute-couture-season-1/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/spring-haute-couture-season-1-300.jpg",
      path: ["/game/spring-haute-couture-season-1", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/spring-haute-couture-season-1/",
      Walkthrough: "https://www.youtube.com/embed/3yBQ8THHzNU",
      s_dis:
      "Spring Haute Couture Season 1 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Spring Haute Couture Season 1. These celebrities get more excited to be a part of Spring Haute Couture. Join and browse their wardrobe to pick the perfect outfits and costumes. Don’t forget to pick a unique hairstyle. Are you ready to try the perfect one for the girls? Play and have fun!",
      tag: ["Celebrity","Girl games","Dress up","Spring","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-03-03",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Casual Winter Look - playcutegames.com",
      name: ["Celebrity Casual Winter Look", "celebrity-casual-winter-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-casual-winter-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-casual-winter-look-300.jpg",
      path: ["/game/celebrity-casual-winter-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-casual-winter-look/",
      Walkthrough: "https://www.youtube.com/embed/5Xi6N4cL-y4",
      s_dis:
      "Celebrity Casual Winter Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Casual Winter Look. Winter is usually the coldest season of the year, but this doesn’t mean this celebrity can’t be stylish during these less sunny days of the year. Browse the wardrobe and pick suitable outfits and costumes to welcome this winter! Play and have fun!",
      tag: ["Celebrity","Dress up","Girl games","Fashion","Winter","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-02-24",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Super Girls Ripped Jeans Outfits - playcutegames.com",
      name: ["Super Girls Ripped Jeans Outfits", "super-girls-ripped-jeans-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-ripped-jeans-outfits/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-ripped-jeans-outfits-300.jpg",
      path: ["/game/super-girls-ripped-jeans-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/super-girls-ripped-jeans-outfits/",
      Walkthrough: "https://www.youtube.com/embed/5DAF1zHjls8",
      s_dis:
      "Super Girls Ripped Jeans Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Super Girls Ripped Jeans Outfits. Hello Girls! Are you feeling crafty today? Let’s try ripped-pattern jeans with different cool colors. Help the girls to browse and pick suitable jeans from their wardrobe. Try some matching accessories and different hairstyles for a perfect look.",
      tag: ["Winx","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-02-17",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Ari All Around The Fashion - playcutegames.com",
      name: ["Celebrity Ari All Around The Fashion", "celebrity-ari-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-ari-all-around-the-fashion/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-ari-all-around-the-fashion-300.jpg",
      path: ["/game/celebrity-ari-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-ari-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/veoylS8faT0",
      s_dis:
      "Celebrity Ari All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Ari All Around The Fashion. Celebrity Ari plans to try different fashion trends. She needs some help to choose the perfect costumes and outfits. Browse her wardrobe and choose the perfect outfits for each fashion trend. Play and have fun with this new game!",
      tag: ["Celebrity","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-03-28",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Easter Fashionista - playcutegames.com",
      name: ["Celebrity Easter Fashionista", "celebrity-easter-fashionista"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-easter-fashionista/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-easter-fashionista-300.jpg",
      path: ["/game/celebrity-easter-fashionista", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-easter-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/R5tpiMQFxUA",
      s_dis:
      "Celebrity Easter Fashionista Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Easter Fashionista. Do you celebrate Easter? These celebrities are going to celebrate Easter together doing all kinds of fun and creative activities. They are going to get ready for the Easter celebration! Browse their wardrobe and pick the perfect outfit for them. Come and join your favorite celebrities.",
      tag: ["Celebrity","Easter","Girl games","Festival","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2023-03-13",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bff St Patrick’s day Look - playcutegames.com",
      name: ["Bff St Patrick’s day Look", "bff-st-patricks-day-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-st-patricks-day-look/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-st-patricks-day-look-300.jpg",
      path: ["/game/bff-st-patricks-day-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bff-st-patricks-day-look/",
      Walkthrough: "https://www.youtube.com/embed/QLNPD3IJ5XI",
      s_dis:
      "Bff St Patrick’s day Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Bff St Patrick’s day Look. Have you ever heard about St. Patrick’s Day? Prepare your girlfriends for the holiday party in the game BFF St. Patricks Day game. The main color of the holiday is green, so the outfits are mainly made in this color. First, select a unique outfit, hat, hairstyle, and stylish accessories.",
      tag: ["LOL Surprise","Dress up","Girl games","Festival","Patricks","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-02-17",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bff Stylish Off Shoulder Outfits - playcutegames.com",
      name: ["Bff Stylish Off Shoulder Outfits", "bff-stylish-off-shoulder-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-stylish-off-shoulder-outfits/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-stylish-off-shoulder-outfits-300.jpg",
      path: ["/game/bff-stylish-off-shoulder-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bff-stylish-off-shoulder-outfits/",
      Walkthrough: "https://www.youtube.com/embed/2lrTcYfdTAA",
      s_dis:
      "Bff Stylish Off Shoulder Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Bff Stylish Off Shoulder Outfits. The off-shoulder top never gets out of style! This clothing piece is so cute and feminine, perfect for a day! These BFFs can’t wait to fill their closets with as many dresses as possible. This can mean only one thing, they need to go shopping and you could help them find the perfect one.",
      tag: ["Nail","Girl games","Valentine","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-02-13",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bffs Venice Carnival Celebrations - playcutegames.com",
      name: ["Bffs Venice Carnival Celebrations", "bffs-venice-carnival-celebration"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-venice-carnival-celebration/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-venice-carnival-celebration-300.jpg",
      path: ["/game/bffs-venice-carnival-celebration", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-venice-carnival-celebrations/",
      Walkthrough: "https://www.youtube.com/embed/B-gNz5blTAM",
      s_dis:
      "Bffs Venice Carnival Celebrations Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Venice Carnival Celebrations. Bffs are planning a trip to Italy to join the Venice carnival. Sounds fun right? Help the girls with the quick preparation. Browse their wardrobe and pick suitable costumes and hairstyles. Don’t forget to choose the mask for the party. Join these girls and have fun!",
      tag: ["Dress up","Barbie","Girl games","Venice","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-01-27",
      brand: "bestgamespot",
     
      orientation: 1,
      title: "Help the couple - playcutegames.com",
      name: ["Help the couple", "help-the-couple"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/help-the-couple/250x142.png",
      path: ["/game/help-the-couple", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/help-the-couples/",
      Walkthrough: "https://www.youtube.com/embed/XIl5uw0o22k",
      s_dis:
      "Help the couple Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Help the couple. Help the couple is a slide puzzle game. And which contains 30 tricky puzzles level. Here your task is to slide pieces around to get them in the right spot and create a way to meet the couple. Play and have fun.",
      tag: ["Puzzle","Arcade","Girl","Slide","Kids","Exclusive"],
      hint: `Click and Drag to slide the pieces`,
      rating:[4.4,1210,114],
    },
    
    {
      date: "2023-03-16",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Little Lily St.Patrick’s Day Photo Shoot - playcutegames.com",
      name: ["Little Lily St.Patrick’s Day Photo Shoot", "little-lily-st-patricks-day-photo-shoot"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/little-lily-st-patricks-day-photo-shoot/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/little-lily-st-patricks-day-photo-shoot-300.jpg",
      path: ["/game/little-lily-st-patricks-day-photo-shoot", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/little-lily-st-patricks-day-photo-shoot/",
      Walkthrough: "https://www.youtube.com/embed/9aDdPpImRSI",
      s_dis:
      "Little Lily St.Patrick’s Day Photo Shoot Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Little Lily St.Patrick’s Day Photo Shoot. St. Patrick’s Day is just around the corner, it’s time to celebrate the rich culture and heritage of Ireland! This festive day is a time to wear green! Help Little Lily to get ready for this wonderful day! Browse her wardrobe and pick a cute outfit with matching accessories.",
      tag: ["Little Lily","Dress up","Girl games","Festival","Patricks","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-01-19",
      brand: "playcutegames",
     
      orientation: 1,
      title: "All Seasons Nail Salon - playcutegames.com",
      name: ["All Seasons Nail Salon", "all-seasons-nail-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/all-seasons-nail-salon/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/all-seasons-nail-salon-300.jpg",
      path: ["/game/all-seasons-nail-salon", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/all-seasons-nail-salon/",
      Walkthrough: "https://www.youtube.com/embed/13dLbahfazA",
      s_dis:
      "All Seasons Nail Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to All Seasons Nail Salon. It is manicure madness over here and it’s your time to become the greatest Nail Salon of the year! All you need to do is choose the shape, paint, polish, and also help to pick beautiful accessories and tattoos. Play and have fun!",
      tag: ["Nail","Winter","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-01-19",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Selen All Around The Fashion - playcutegames.com",
      name: ["Celebrity Selen All Around The Fashion", "celebrity-selen-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-selena-all-around-the-fashion/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-selen-all-around-the-fashion-300.jpg",
      path: ["/game/celebrity-selen-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-selena-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Celebrity Selen All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Selen All Around The Fashion. Celebrity Selen plans to try different fashion trends. She needs some help to choose the perfect costumes and outfits. Browse her wardrobe and choose the perfect outfits for each fashion trend. Play and have fun with this new game!",
      tag: ["Celebrity","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-01-13",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Sundance Film Festival - playcutegames.com",
      name: ["Celebrity Sundance Film Festival", "celebrity-sundance-film-festival"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-sundance-film-festival/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-sundance-film-festival-300.jpg",
      path: ["/game/celebrity-sundance-film-festival", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-sundance-film-festival/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Celebrity Sundance Film Festival Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Celebrity Sundance Film Festival. Celebrity invited to the Sundance event. They are excited to participate in this event. Browse her wardrobe and pick cute outfits and costumes for the event. Help them and have fun!",
      tag: ["Celebrity","Dress up","Festival","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Dark Academia Winter Outfits - playcutegames.com",
      name: ["BFFs Dark Academia Winter Outfits", "bffs-dark-academia-winter-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-dark-academia-winter-outfits/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-dark-academia-winter-outfits/250x142.png",
      path: ["/game/bffs-dark-academia-winter-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/bffs-dark-academia-winter-outfits/",
      Walkthrough: "https://www.youtube.com/embed/qt8HwRZsnuM",
      s_dis:
      "BFFs Dark Academia Winter Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Dark Academia Winter Outfits. BFFs are getting ready for school. There’s a new cool fashion style they want to try on; dark academia. Mix and match the clothes to create unique outfits and complete the look with amazing makeup. Help the girls to pick cute outfits.",
      tag: ["LOL Surprise","Dress up","Winter","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity RiRi All Around The Fashion - playcutegames.com",
      name: ["Celebrity RiRi All Around The Fashion", "celebrity-riri-all-around-the-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-riri-all-around-the-fashion/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-riri-all-around-the-fashion/250x142.png",
      path: ["/game/celebrity-riri-all-around-the-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/celebrity-riri-all-around-the-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Celebrity RiRi All Around The Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity RiRi All Around The Fashion. Celebrity RiRi plans to try different fashion trends. She needs some help to choose the perfect costumes and outfits. Browse her wardrobe and choose the perfect outfits for each fashion trend. Play and have fun with this new game!",
      tag: ["LOL Surprise","Dress up","Winter","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "GD",
     
      orientation: 0,
      title: "Uphill Rush 11 - playcutegames.com",
      name: ["Uphill Rush 11", "uphill-rush-11"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/uphill-rush-11/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/uphill-rush-11/250x142.png",
      path: ["/game/uphill-rush-11", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/4705e37793174bcc82972bd2871e08a4/?gd_sdk_referrer_url=https://playcutegames.com/game/uphill-rush-11",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Uphill Rush 11 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Explore a series of wild and wacky waterslides in Uphill Rush 11, a new action game in the Uphill Rush series. After surviving the dangerous online car racing game of the previous episode, it’s time for a vacation on a cruise ship… A cruise ship crammed with crazy waterslides, that is! Choose an inflatable ring or even a waterscooter and speed through the tube slides. Perform incredible jumps before plunging into the pool. There are other swimmers in the pool, but they’ll just have to make way!",
      tag: ["Racing","3D","Winter","Hypercasual","Io"],
      hint: `Use the arrow keys to keep your balance. If you capsize, you’ll have to start over! When the lightning icon is loaded, you can activate a speed boost by tapping the space bar. Collect coins to upgrade your swimmers and your float’s power stats.`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-03-19",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Aqua Fish Dental Care - playcutegames.com",
      name: ["Aqua Fish Dental Care", "aqua-fish-dental-care"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/aqua-fish-dental-care/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/aqua-fish-dental-care-300.jpg",
      path: ["/game/aqua-fish-dental-care", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/aqua-fish-dental-care/",
      Walkthrough: "https://www.youtube.com/embed/4Exz1LUzB2I",
      s_dis:
      "Aqua Fish Dental Care Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Aqua Fish Dental Care. Aquatic organisms need a dentist! Come and join as a dental expert and treat all the species with care. It’s time to explore the amazing underwater world and give dental treatment to all the cute species! Each level has different species and you have to use all the equipment to complete the process.",
      tag: ["Docter","Dental","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "GD",
     
      orientation: 1,
      title: "DinoMatch: Mahjong Pairs - playcutegames.com",
      name: ["DinoMatch: Mahjong Pairs", "dinomatch-mahjong-pairs"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dinomatch-mahjong-pairs/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/dinomatch-mahjong-pairs/250x142.png",
      path: ["/game/dinomatch-mahjong-pairs", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/0d033a94592845d8bbe814039cba3e57/?gd_sdk_referrer_url=https://playcutegames.com/game/dinomatch-mahjong-pairs",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "DinoMatch: Mahjong Pairs Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "The goal of the game is to connect pairs of identical tiles.? ? You need to have time to clear the entire field of how the time runs out.⏰ Connect more distant tiles to get more stars.⭐ For a certain number of stars, a reward is given - a chest.? Over time, new beautiful tiles are unlocked and level configurations become more complicated.?",
      tag: ["Racing","3D","Winter","Hypercasual","Io"],
      hint: `The goal of the game is to connect pairs of identical tiles You need to have time to clear the entire field of how the time runs out. Connect more distant tiles to get more stars.`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-31",
      brand: "GD",
     
      orientation: 1,
      title: "Snowball Racing - playcutegames.com",
      name: ["Snowball Racing", "snow-ball-racing"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/snow-ball-racing/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/snow-ball-racing/250x142.png",
      path: ["/game/snow-ball-racing", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/85d40b1334dd42fb8c9416e42fb46bb4/?gd_sdk_referrer_url=https://playcutegames.com/game/snow-ball-racing",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Snowball Racing Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Grow your snowball as big as possible! Try one of the most entertaining winter race games. Roll the snowball bigger and bump the rivals. Snowball Racing is a super fun race game. Become a real Snowball Master!",
      tag: ["Racing","3D","Winter","Hypercasual","Io"],
      hint: `Swerve to control the movements and grow the snowball.`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-02-24",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Super Girls Magical Fairy land - playcutegames.com",
      name: ["Super Girls Magical Fairy land", "super-girls-magical-fairy-land"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-magical-fairy-land/250x142.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-magical-fairy-land-300.jpg",
      path: ["/game/super-girls-magical-fairy-land", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/super-girls-magical-fairy-land/",
      Walkthrough: "https://www.youtube.com/embed/aiKd8LbN--0",
      s_dis:
      "Super Girls Magical Fairy land Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Super Girls Magical Fairy land. These cute Bffs are looking for some fairytale-inspired outfit ideas for the upcoming fairyland ball of the season! To create a perfect look, you will first need to browse through the wardrobe to check out the dresses and costumes we put together! There are many different looks you can go for, from a delicate fairy princess, a powerful warrior princess, a flower or season fairy to an oriental empress look. You will find amazing hairdo ideas and accessories with cute fairy wings.",
      tag: ["Winx","Dress up","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2023-03-28",
      brand: "playcutegames",
     
      orientation: 1,
      title: "Ellie Easter Adventure - playcutegames.com",
      name: ["Ellie Easter Adventure", "ellie-easter-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-easter-adventure/230x131.png",
      thumb_big: 
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-easter-adventure-300.jpg",
      path: ["/game/ellie-easter-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-easter-adventure/",
      Walkthrough: "https://www.youtube.com/embed/AgFc8O4o424",
      s_dis:
      "Ellie Easter Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Ellie Easter Adventure. Ellie wants to celebrate in style! But she needs your help to get everything prepared! But first things first, you have to help her to find the easter egg and paint it with cute colors. Next, give treatment to the bunny to heal his wound. Finally, help Ellie to pick cute makeup and outfits to celebrate Easter.",
      tag: ["Barbie","Easter","Girl games","Festival","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.7,1510,1455],
      platform:"both",
    },
    {
      date: "2022-12-30",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Winter Cozy Outfits - playcutegames.com",
      name: ["My Winter Cozy Outfits", "my-winter-cozy-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-winter-cozy-outfits/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-winter-cozy-outfits/250x142.png",
      path: ["/game/my-winter-cozy-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-winter-cozy-outfits/",
      Walkthrough: "https://www.youtube.com/embed/CbOTH5duCPU",
      s_dis:
      "My Winter Cozy Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Winter Cozy Outfits. Winter is not a season, It’s a celebration. Enjoy this winter with these cute besties. BFF wants to try something special this winter. Join and help the girls to pick cute outfits and accessories. Don’t forget to choose a matching hairstyle! Play and have fun!",
      tag: ["Barbie","Dress up","Winter","Girl games","Season","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-30",
      brand: "playcutegames",
     
      orientation: 1,
      title: "99 Balls - playcutegames.com",
      name: ["99 Balls", "99-balls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/99-balls/250x142.png",
      thumb_big:
        "",
      path: ["/game/99-balls", "https://playcutegames.com"],
      iframe:
        "https://yandex.com/games/app/207515?header=no&skip-guard=1&utm_source=distrib&utm_medium=cutedressup&clid=4890700",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "99 Balls Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Are you tired of ordinary bubble shooter games? Try 99 Balls, an innovate game that mixes gameplay from Bubble Shooter and Breakout! In this game, you need to destroy numbered balls before they reach the bottom of the screen. Each ball contains a number which indicates the number of hits it requires to be destroyed. Collect circles to increase the number of weapons you can throw at once. Collect stars to unlock new weapons! The further you proceed into the game, the higher the numbers on the balls become.",
      tag: ["Casual","Puzzle","Balls","Skill","Bubble","Yiv"],
      hint: `Drag the left mouse button backwards to aim, release to shoo`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-30",
      brand: "playcutegames",
     
      orientation: 0,
      title: "LOLBeans.io - playcutegames.com",
      name: ["LOLBeans.io", "lolbeans-io"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lolbeans-io/250x142.png",
      thumb_big:
        "",
      path: ["/game/lolbeans-io", "https://playcutegames.com"],
      iframe:
        "https://lolbeans.io/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "LOLBeans.io Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "LOLBeans.io is a multiplayer racing game where you have to avoid obstacles. Race to the end of the obstacle course in battle-royale-style elimination gameplay. Move through the levels until you’re the only bean left standing.",
      tag: ["Arcade","Io","Boys","Multiplayer","Survival"],
      hint: `WASD or arrow keys to move`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-16",
      brand:"Poki games",
     
      orientation: 1,
      title: "Egg Wars - playcutegames.com",
      name: ["Egg Wars", "egg-wars"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/egg-wars/250x142.png",
      path: ["/game/egg-wars", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/851bce8bb62243eb92dd8b8f7f7b774a/?gd_sdk_referrer_url=https://playcutegames.com/game/egg-wars",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Egg wars shooting. Love first-person shooters? Craving a fun-filled gaming experience? Enjoy the new multiplayer FPS game. The Team PvP and Battle Royale modes are available for your choice. Your duty is to lead the battle, become the best shooter, and be last to survive.",
      l_dis:"Monkey Mart is an idle/management game made by TinyDobbins. You control a cute monkey character that just created their supermarket. Plant fruits, move around from station to station to fill the stands with bananas or corn, or more. Clients will pick them up and wait for you at the cashier desk. You can upgrade your character, unlock new work stations, or even recruit other workers to help you manage the store !",
      tag: ["Poki","Fighting","Action","Shooting","Io","Arcade"],
      hint: `ASWD - Moves R - Reload Left mouse click - Shooting`,
      rating:[4.9,350,18],
      platform:"both",
    },
   
    {
      date: "2022-12-16",
      brand:"GD",
     
      orientation: 1,
      title: "Play Idle Fashion Shop Game Online at Playcutegames",
      name: ["Shopping Mall Tycoon", "shopping-mall-tycoon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/shopping-mall-tycoon/250x142.png",
      path: ["/game/shopping-mall-tycoon", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/63d57726031a4a8b9a666b27b2a0d323/?gd_sdk_referrer_url=https://playcutegames.com/game/shopping-mall-tycoon",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Shopping Mall Tycoon is an addictive online simulation game With challenging gameplay and cute, cartoon graphics, Shopping Mall Tycoon is sure to provide hours of fun.",
      l_dis:`Try to be the owner of all the shopping mall here, find the first bucket of gold and you can finally become the world-famous business tycoon!Buy a small plot of land, build your first store, manage and try to expand it. As long as you hold enough money,
      `,
      tag: ["Shopaholic","Simulation","Dressup","Arcade"],
      hint: `Use mouse`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-16",
      brand:"GD",
     
      orientation: 0,
      title: "Play Rachel Shopping Day Game Online at Playcutegames",
      name: ["Rachel Shopping Day", "rachel-shopping-day"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rachel-shopping-day/250x142.png",
      path: ["/game/rachel-shopping-day", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/1be69b1b98734c1fb1c4b03a639cb30a/?gd_sdk_referrer_url=https://playcutegames.com/game/rachel-shopping-day",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Rachel Shopping Day is an addictive online simulation game With challenging gameplay and cute, cartoon graphics, Rachel Shopping Day is sure to provide hours of fun.",
      l_dis:`Rachel wants to take advantage of the season sales and buy a new outfit. She wants get dressed in new clothes from head to toe! The cute girl could use some expert fashion tips in order to make the best choices. Join her on a fun shopping spree right away! The staff of the store will show you the most stunning clothes and you can decide what looks best on Rachel. Complete her look with some stylish high heels and some elegant accessories. Show off your creative and unique fashion ideas in this new dress up game!Rewarded
      `,
      tag: ["Shopaholic","Simulation","Dressup","Arcade"],
      hint: `Use mouse`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Crazy games",
     
      orientation: 0,
      title: "Mega Factory - playcutegames.com",
      name: ["Mega Factory", "mega-factory"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/mega-factory/250x142.png",
      path: ["/game/mega-factory", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/mega-factory/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Mega Factory Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Mega Factory is a business simulation game where you run a factory and process these raw materials to create products. Process and pack the products, and send them using delivery vans to fulfill orders. Build more machines, unlock new items, upgrade your workers, and expand your delivery team. Go Big! Go Huge!",
      tag: ["Crazy games","Managment","Simulation","3D"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Cookie Crush Christmas 2 - playcutegames.com",
      name: ["Cookie Crush Christmas 2", "cookie-crush-christmas-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cookie-crush-christmas-2/250x142.png",
      path: ["/game/cookie-crush-christmas-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/3a9f80702dc74fa5a947e1de5e37c24b/?gd_sdk_referrer_url=https://playcutegames.com/game/cookie-crush-christmas-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cookie Crush Christmas 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Cookie Crush Christmas 2 is the latest and the greatest Cookie Crush match-3 game ever released. Come and join this incredible journey through a dream-like Christmas land where you will enjoy 3,000+ levels packed with cookies, cakes, donuts, chests full of coins and more Christmas fun!",
      tag: ["Match3","Girl games","Kids","Cute"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2023-01-06",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity’s Chinese New Year Look - playcutegames.com",
      name: ["Celebrity’s Chinese New Year Look", "celebrity-chinese-new-year-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-chinese-new-year-look/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-chinese-new-year-look/250x142.png",
      path: ["/game/celebrity-chinese-new-year-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/celebrity-chinese-new-year-look/",
      Walkthrough: "https://www.youtube.com/embed/OljV-UGqhfI",
      s_dis:
      "Celebrity’s Chinese New Year Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity’s Chinese New Year Look. Celebrities are getting ready to celebrate the Chinese New Year in style. Care to help them prepare for it? In this online for girls, you can help your favorite Celebrity, choose new outfits and statement makeup looks for the upcoming festive days. What do you think they should wear on this occasion?",
      tag: ["Celebrity","Dress up","Rihanna","Chinese","New year","Chinese New year","Girl games","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Cake Master Shop - playcutegames.com",
      name: ["Cake Master Shop", "cake-master-shop"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cake-master-shop/250x142.png",
      path: ["/game/cake-master-shop", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/34c4f80214474ee988ec493a563562bd/?gd_sdk_referrer_url=https://playcutegames.com/game/cake-master-shop",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cake Master Shop Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"We all like sweets and delicious cakes, which cake do you want to make today? Challenge levels and unlock more sweet cakes! Many ingredients for cake cooking. Put them into bowls, and blend it, the next step is bake. Pick some fresh fruits. Over 15 kitchen tools, help you to make sweet and yummy cakes",
      tag: ["Cooking","Cake","Yiv","Decoration","Girl games"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-25",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My New Year’s Sparkling Outfits - playcutegames.com",
      name: ["My New Year’s Sparkling Outfits", "my-new-years-sparkling-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-new-years-sparkling-outfits/250x142.png",
      thumb_big:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-new-years-sparkling-outfits/250x142.png",
      path: ["/game/my-new-years-sparkling-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-new-years-sparkling-outfits/",
      Walkthrough: "https://www.youtube.com/embed/pex6YWXMeic",
      s_dis:
      "My New Year’s Sparkling Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My New Year’s Sparkling Outfits. BFF girls plan for a new year surprise party. They are so excited to try new fashion trends for this new year. Help the girls to pick a cute sparkling outfit and pick cute matching accessories for each girl. Don’t forget to choose a unique hairstyle.",
      tag: ["Princess","Dress up","New year","Girl games","Holiday","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Hatch Your Unicorn Idol - playcutegames.com",
      name: ["Hatch Your Unicorn Idol", "hatch-your-unicorn-idol"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/hatch-your-unicorn-idol/250x142.png",
      path: ["/game/hatch-your-unicorn-idol", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/07c7b5e533114aa0966789e630a03be8/?gd_sdk_referrer_url=https://playcutegames.com/game/hatch-your-unicorn-idol",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Hatch Your Unicorn Idol Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"A sparkling mix of kids' favorite things: kawaii unicorns, virtual pet care, of course, tons of rainbows! Hatch pony to form the cutest music band ever! Each little pony has a unique style, but all of them love to sing and dance.",
      tag: ["Pony","Kids","Unicorn","Yiv","Cute","Girl games","Simulation"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Crazy games",
     
      orientation: 0,
      title: "Pet Healer: Vet Hospital - playcutegames.com",
      name: ["Pet Healer: Vet Hospital", "pet-healer-vet-hospital"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pet-healer-vet-hospital/250x142.png",
      path: ["/game/pet-healer-vet-hospital", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/pet-healer---vet-hospital/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Pet Healer - Vet Hospital Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Pet Healer - Vet Hospital is a casual game where you help treat animals and become the best pet doctor. Collect coins, buy new equipment, unlock new medicine and learn to cure different diseases in your new pet clinic!",
      tag: ["Crazy games","Managment","Simulation","Doctor","3D"],
      hint: `WASD or arrow keys or hold left mouse button = move<br>( Tap on mobile)`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Save the Panda - playcutegames.com",
      name: ["Save the Panda", "save-the-panda"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/save-the-panda/250x142.png",
      path: ["/game/save-the-panda", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/53263014d861457dadefa13e5bde6dcb/?gd_sdk_referrer_url=https://playcutegames.com/game/safe-the-panda",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Save the Panda Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Puzzle Box includes three leisure games: Save Panda, Joy Draw and Puzzle game. All three games are very fun to kill time and exercise your brain. It will be more fun to play with your family!",
      tag: ["Arcade","Kids","Boys","Puzzle","Poki"],
      hint: ` Click or drag with the mouse to complete`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Archery - playcutegames.com",
      name: ["Archery", "archery-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/archery-2/250x142.png",
      path: ["/game/archery-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/0cfb140bc86741528fd8e6a5cc96f8a1/?gd_sdk_referrer_url=https://playcutegames.com/game/archery-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Archery Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "This is a super fun archery shooting experience Archery Tournament completely simulates this ancient to modern sports. Different target distances and still/moving targets will add difficulties to the different bow games. Practice make it perfect Just like archery in the real world, it is a sport that is easy to learn, and hard to master. But you will receive endless fun!",
      tag: ["Arcade","Sports","Kids","Boys","Puzzle","Archery","Shooting","Crazy games"],
      hint: `Hold and aim, and release to shoot.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Cook and Match: Sara's Adventure - playcutegames.com",
      name: ["Cook and Match: Sara's Adventure", "cook-and-match-saras-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cook-and-match-saras-adventure/250x142.png",
        thumb_big:
          "https://cdn.cutedressup.in/games/cutedressup_new/cook-and-match-saras-adventure-323.png",
      path: ["/game/cook-and-match-saras-adventure", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/a853fd25dae64d8f8536b577ac08042b/?gd_sdk_referrer_url=https://playcutegames.com/game/cook-and-match-sara's-adventure",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cook and Match: Sara's Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Chef Sara needs your help to complete the tile-matching puzzles and collect the ingredients she wants. Make the onions, bell peppers, and cheese wedges swap places, and line up three or more of the same icons to create matches. Bigger combinations will unlock powerful bonus items such as chef’s knives, blenders, and pressure cookers. The objectives for each level will change, bringing a variety of new challenges as you progress through the game.",
      tag: ["Match3","Girl games","Kids","Puzzle"],
      hint: `Tap or click on an icon and drag it to move it to a new tile. You can make two adjacent items swap places, but every move must result in a valid combination of three or more of the same ones. Keep an eye on Sara’s shopping list to see which items you need.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    
    {
      date: "2022-12-12",
      brand:"Crazy games",
     
      orientation: 0,
      title: "Winter Clash 3D - playcutegames.com",
      name: ["Winter Clash 3D", "winter-clash-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/winter-clash-3d/250x142.png",
      path: ["/game/winter-clash-3d", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/winter-clash-3d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Winter Clash 3D Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Winter Clash 3D is a business simulation game where you run a factory and process these raw materials to create products. Process and pack the products, and send them using delivery vans to fulfill orders. Build more machines, unlock new items, upgrade your workers, and expand your delivery team. Go Big! Go Huge!",
      tag: ["Crazy games","Fighting","Shooting","Action","Santa"],
      hint: `
       <ul class="gamelist">
       <li class="para description">WASD or arrow keys = move</li>
       <li class="para description">C = crouch</li>
       <li class="para description">Space = jump</li>
       <li class="para description">Tab = score table</li>
       <li class="para description">Tab = score table</li>
       <li class="para description">Right-click = aim</li>
       <li class="para description">Left-click = shoot</li>
     </ul>`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Others",
     
      orientation: 1,
      title: "Children Doctor Dentist 2 - playcutegames.com",
      name: ["Children Doctor Dentist 2", "children-doctor-dentist-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/children-doctor-dentist-2/250x142.png",
      path: ["/game/children-doctor-dentist-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/225102bed76847d99248bfe52a2fc17d/?gd_sdk_referrer_url=https://playcutegames.com/game/children-doctor-dentist-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Children Doctor Dentist 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"In our new exciting game, you will manage a real dental hospital. And every day you will have to see many patients - funny animals. All of them have the only problem - their teeth hurt. And all of them need to accept and provide medical care. And actually you have such a unique opportunity. Using modern medical tools, you can help your little patients.",
      tag: ["Doctor","Kids","Dentist","Yiv"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-07",
      brand: "cutedressup",
     
      orientation: 0,
      title: "Super Girls My Rainy Day Outfits - playcutegames.com",
      name: ["Super Girls My Rainy Day Outfits", "super-girls-my-rainy-day-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-my-rainy-day-outfits/250x142.png",
      path: ["/game/super-girls-my-rainy-day-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/super-girls-my-rainy-day-outfits/",
      Walkthrough: "https://www.youtube.com/embed/hb8jHgGvrJ8",
      s_dis:
      "Super Girls My Rainy Day Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Super Girls My Rainy Day Outfits. Who said that you can’t look perfect on a rainy day? A bit of rain shouldn’t stop us from looking fabulous! And don’t even worry about the hair, some curls under a hat look absolutely perfect! How would your perfect rainy day outfit look like? Try creating it while helping these princesses dress up in warmer clothes and rain boots. Accessorize their look with a hat, scarf, and a cute umbrella.",
      tag: ["Winx","Dress up","Atumn","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand:"GD",
     
      orientation: 1,
      title: "Instadiva Nikke Dress Up Tutorial - playcutegames.com",
      name: ["Instadiva Nikke Dress Up Tutorial", "instadiva-nikke-dress-up-tutorial"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/instadiva-nikke-dress-up-tutorial/250x142.png",
      path: ["/game/instadiva-nikke-dress-up-tutorial", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/9a0800df78ba450a92f77b3cbd0ac998/?gd_sdk_referrer_url=https://playcutegames.com/game/instadiva-nikke-dress-up-tutorial",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Instadiva Nikke Dress Up Tutorial Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "It's time to dress up Nikki in this fun dress up game. What're you feeling today? Pick a hairstyle that exhibits Nikki's style. Choose a set of beautiful eyes with thick lashes. Pick a bold top to show how you're feeling. Pants, jeans, skirts, we have them all. And of course, SHOES! We love shoes. Once you're done, it's time to move to the photo studio. Execute a variety of chic poses for the camera and start capturing insta worthy photos!",
      tag: ["Dress up","Instagram","Girl games"],
      hint: `Lights, camera, action! Dress up Nikki on her way to stardom.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand:"GD",
     
      orientation: 0,
      title: "Instagirls: Christmas Dress Up - playcutegames.com",
      name: ["Instagirls: Christmas Dress Up", "instagirls-christmas-dress-up"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/instagirls-christmas-dress-up/250x142.png",
      path: ["/game/instagirls-christmas-dress-up", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/6adbde2ee60748d388c98d41dceef9af/?gd_sdk_referrer_url=https://playcutegames.com/game/instagirls-christmas-dress-up",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Instagirls: Christmas Dress Up Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Instagirls: Christmas Dress Up is a fun fashion game with a cool Christmas theme. In this game, you must help your lovely Instagram Girls dress up for Christmas! You must choose outfits carefully to match with the current trending fashion styles for this holiday season.",
      tag: ["Dress up","Christmas","Girl games","Crazy games"],
      hint: `Press left mouse button.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Fairyland Merge & Magic - playcutegames.com",
      name: ["Fairyland Merge & Magic", "fairyland-merge-and-magic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fairyland-merge-and-magic/250x142.png",
      path: ["/game/fairyland-merge-and-magic", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/2deee771bf534191bc4071fa571a53a3/?gd_sdk_referrer_url=https://playcutegames.com/game/fairyland-merge-and-magic",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Fairyland Merge & Magic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the merge island full of the most amazing pieces that you will ever merge! It looks nothing like your ordinary fairytales but is a unique collection of merge fables spiced up with true merge magic. Always dreamed about having a dragon? You can merge dragons, unicorns, leprechauns, or other fantastic beasts! It's your merge world and your rules. Make your dragon merge now and have fun with your dragon farm! What merger is it going to be next? Key Features: - Familiar characters in new and exciting circumstances. - Intriguing plot with an amazing main story and thrilling side quests. - Countless new lands to reveal and discoveries to make. - Fantastic merge puzzle quests to complete and rewards to collect. Wonder what it's like to have such a mergical experience? Stop questioning yourself and jump right into this fantastic merge game full of merge tales. If you love free merge games and discoveries, then Once upon a merge is a perfect fit for you!",
      tag: ["Puzzle","Farming","Matching","Farming","Santa","Girl games"],
      hint: `Merge items to pass the fairy exam! - Take a grape sprout and merge it with the other two - Do the same with other beds so that the grapes are ripen - Harvest other and use them to produce the magic key - Open the fog to discover the rest of the island Unlock new items and new heroes to explore Fairyland!`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Crazy games",
     
      orientation: 0,
      title: "Crazy Flips 3D - playcutegames.com",
      name: ["Crazy Flips 3D", "crazy-flips-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/crazy-flips-3d/250x142.png",
      path: ["/game/crazy-flips-3d", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/crazy-flips-3d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Crazy Flips 3D Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`Crazy Flips 3D is an awesome sports game to do perfect flips, such as frontflips, backflips, and gainer tricks from high cliffs.<br><br>
      Launch into the excitement of Crazy Flips 3D on Play Cute Games! This awesome game lets you do flips and stunts as you launch off big cliffs and try to land on tiny targets. With real 3D physics and dynamic character movement, every flip and somersault is a timing and skill challenge.
As you progress through the levels, you’ll face obstacles and tricky landings that will test your abilities. Master the art of timing your jumps and flips to score big and tackle everything in your path. Show off your flying skills and compare scores with friends for bragging rights. Go wild and let your flipping begin in Crazy Flips 3D!`,
      tag: ["Crazy games","Skill","Kids","3D"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Dream Life - playcutegames.com",
      name: ["Dream Life", "dream-life"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dream-life/250x142.png",
      path: ["/game/dream-life", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/2d67f0ecb6a84c5cbe1c37a9364843b8/?gd_sdk_referrer_url=https://playcutegames.com/game/dream-life",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dream Life Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dream Life is a brand new and fun match 3 game. In the game, you can change the positions of the objects to match them and remove these objects, beat the match 3 levels to win the rewards. The game has rich and varied match-3 levels and cute and vivid level elements, allowing you to experience an interesting match-3 adventure journey.",
      tag: ["Doctor","Surgery","Dress up","Christmas","Santa"],
      hint: `Match 3 same colored items to collect them! Match 4 same colored items in a line to create a ROCKET! Match the same colored items in L-shape to create a BOMB! Match 4 same colored items in a square to create a MISSILE! Match 5 same colored items in a line to create a RAINBOW BALL! You can select these boosters to start the level with them! Beat the level, participate in tournament challenge, and win great rewards`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Lagged",
     
      orientation: 0,
      title: "Funny Throat Surgery - playcutegames.com",
      name: ["Funny Throat Surgery", "funny-throat-surgery"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/funny-throat-surgery/250x142.png",
      path: ["/game/funny-throat-surgery", "https://playcutegames.com"],
      iframe:
        "https://lagged.com/api/play2/funny-throat-surgery/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Funny Throat Surgery Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Perform surgery on your patients in this super funny game. Use a variety of different tools to clean up the throats of each kid who goes into your doctors office. Play as a Doctor as you help out patients who are in serious needs of your services.",
      tag: ["Doctor","Kids","Dentist","Lagged"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 0,
      title: "Doc Darling Santa Surgery - playcutegames.com",
      name: ["Doc Darling Santa Surgery", "doc-darling-santa-surgery"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/doc-darling-santa-surgery/250x142.png",
      path: ["/game/doc-darling-santa-surgery", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/86243dc5144b4782a08a3cc6771e17e5/?gd_sdk_referrer_url=https://playcutegames.com/game/doc-darling-santa-surgery",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Doc Darling Santa Surgery Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Christmas is coming but Santa had an accident on his way! He needs your help to fix Santa's sleight and find his deer. Also bring him to Doc Darling to take care of all injuries and prepare for an emergency surgery. Complete all treatments and dress him up till he gets ready to deliver the gifts for Christmas night!",
      tag: ["Doctor","Surgery","Dress up","Christmas","Santa"],
      hint: `
      Use MOUSE to play the game.`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Others",
     
      orientation: 1,
      title: "Dental Care Game - playcutegames.com",
      name: ["Dental Care Game", "dental-care-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dental-care-game/250x142.png",
      path: ["/game/dental-care-game", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/bac933bb80014eca8b5ec99b0f3e0d3e/?gd_sdk_referrer_url=https://playcutegames.com/game/dental-care-game",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dental Care Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Boys and girls welcome to the Dentist, Here you can not only turn yourself into a professional dentist but also diagnose and cure different patients, make it you're dreaming one! In the game, patients are not models who just open their mouths with a poker face, they are so vivid with laughers and tears!",
      tag: ["Doctor","Kids","Dentist","Yiv"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Jewel Christmas Story - playcutegames.com",
      name: ["Jewel Christmas Story", "jewel-christmas-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/jewel-christmas-story/250x142.png",
      path: ["/game/jewel-christmas-story", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/c3ce9bfdcdba45248f0267adbb65c6de/?gd_sdk_referrer_url=https://playcutegames.com/game/jewel-christmas-story",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Jewel Christmas Story Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "The most anticipated holiday of the year is here! Christmas is just around the corner and lots of holiday fun full of gifts and sweets is waiting for you in this new match3 game. Collect game currency, achieve combos, and use boosters in various missions.",
      tag: ["Matching","Puzzle","Christmas","Santa","Jewel"],
      hint: `Use your quick-thinking mind and boosters to beat the levels. Match at least three identical items and reach the goal of the missions. Collect game currency, which you can use to buy boosters in the shop and in loot box. Unlock your path to move forward.`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 0,
      title: "Dog Puzzle Story 2 - playcutegames.com",
      name: ["Dog Puzzle Story 2", "dog-puzzle-story-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dog-puzzle-story-2/250x142.png",
      path: ["/game/dog-puzzle-story-2", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/9921e655b971470fbfa730f30866aae8/?gd_sdk_referrer_url=https://playcutegames.com/game/dog-puzzle-story-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dog Puzzle Story 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Dog Puzzle Story 2 is back together with your much beloved friend, the cute puppy Charlie. Join him on another adventurous journey through colourful Match 3 puzzles full of toys, dog pillows and treats scattered all over the garden! Help Charlie to put all the items in the right place and dig up all the bones! This is going to be so much fun!",
      tag: ["Matching","Puzzle","Girl games"],
      hint: `
      Combine three items of the same kind to collect them or combine four or more to create mighty power-ups. Reach the level goals to earn stars and to advance to the next level. Complete Daily Missions and Challenges to earn additional rewards that will help Charlie solve tricky puzzles. Join Charlie on this magical adventure and enjoy countless hours of Match 3 puzzle fun!`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-12-03",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Trendy Ball Gown - playcutegames.com",
      name: ["My Trendy Ball Gown", "my-trendy-ball-gown"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-trendy-ball-gown/250x142.png",
      path: ["/game/my-trendy-ball-gown", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-trendy-ball-gown/",
      Walkthrough: "https://www.youtube.com/embed/c6cLz_X4Sg8",
      s_dis:
      "My Trendy Ball Gown Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Trendy Ball Gown. Rainbow Girls are going to be styled by you for the prom, but before that help them to choose the best outfit, also accessorize it using earrings, necklaces, and purses, and you can pick the hairstyle you think will suit the girl the best, simple as that. Enjoy!",
      tag: ["Rainbow Girls","Dress up","Black Friday","Holiday","Exclusive","Girl games", "Thanksgiving","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
      platform:"both",
    },
    {
      date: "2022-11-30",
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Black Friday Collection - playcutegames.com",
      name: ["BFFs Black Friday Collection", "bffs-black-friday-collection"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-black-friday-collection/250x142.png",
      path: ["/game/bffs-black-friday-collection", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/bffs-black-friday-collection/",
      Walkthrough: "https://www.youtube.com/embed/XKnZGw9Ux-M",
      s_dis:
      "BFFs Black Friday Collection Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Black Friday Collection. Bffs already filled the closet with a new Black Friday collection. Help them to pick the perfect costume. Don’t forget to pick a fabulous hairstyle. They are all excited like us for this day. Join and help the girls with your skill. Play and have fun!",
      tag: ["Winx","Dress up","Black Friday","Holiday","Exclusive","Girl games", "Thanksgiving","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-11-26",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie Thanksgiving Day - playcutegames.com",
      name: ["Ellie Thanksgiving Day", "ellie-thanksgiving-day"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-thanksgiving-day/250x142.png",
      path: ["/game/ellie-thanksgiving-day", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/ellie-thanksgiving-day/",
      Walkthrough: "https://www.youtube.com/embed/axGgjVHwHOY",
      s_dis:
      "Ellie Thanksgiving Day Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Ellie Thanksgiving Day. It’s Thanksgiving Day, and like every year, young Ellie is in charge of the Thanksgiving dinner. Her friends will start arriving soon, and there are still a few things that need her attention. Will you help her with the last preparations? Join and help beautiful Ellie to surprise her guests with a perfect dinner!",
      tag: ["Barbie","Dress up","Cooking","Holiday","Exclusive","Girl games", "Thanksgiving","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      id: 20211125,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Ellie And Ben Christmas Preparation - playcutegames.com",
      name: [
        "Ellie And Ben Christmas Preparation",
        "ellie-and-ben-christmas-preparation",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-ben-christmas-preparation/250x142.png",
      path: [
        "/game/ellie-and-ben-christmas-preparation",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/ellie-and-ben-christmas-preparation/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Ellie And Ben Christmas Preparation. Ellie and Ben are the new couples going to celebrate the upcoming Christmas together.",
      l_dis:
        `Welcome to the Ellie And Ben Christmas Preparation. Ellie and Ben are the new couples going to celebrate the upcoming Christmas together. They have some plans to do!. Like decorate the interior with beautiful Christmas things and decorate their Christmas Tree. Join and Help the couples to make this Christmas the most memorable one!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Barbie",
        "Dress up",
        "Make Up",
        "Christmas",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Lagged",
     
      orientation: 1,
      title: "Doctor Teeth 3 - playcutegames.com",
      name: ["Doctor Teeth 3", "doctor-teeth-3"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/doctor-teeth-3/250x142.png",
      path: ["/game/doctor-teeth-3", "https://playcutegames.com"],
      iframe:
        "https://lagged.com/games/play2/doctorteeth34/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Doctor Teeth 3 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Brush, clean, pull and drill teeth in this funny hygiene game! Use the plethora of tools available to remove plaque, tartar and even sometimes teeth! Be sure to read the patient's checklist so that you are not performing any unnecessary procedures on innocent paying customers!",
      tag: ["Doctor","Kids","Dentist","Lagged"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "playcutegames",
     
      orientation: 0,
      title: "3D Christmas Island Tree Decor - playcutegames.com",
      name: ["3D Christmas Island Tree Decor", "3d-christmas-island-tree-decor"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/3d-christmas-island-tree-decor/250x142.png",
      path: [
        "/game/3d-christmas-island-tree-decor",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/3d-christmas-island-tree-decor/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the 3D Christmas Island Tree Decor game. Hey kids! Happy Christmas to everyone! Enjoy playing this new 3d Christmas game and have wonderful Christmas! This game consists of two levels.",
      l_dis:
        "Welcome to the 3D Christmas Island Tree Decor game. Hey kids! Happy Christmas to everyone! Enjoy playing this new 3d Christmas game and have wonderful Christmas! This game consists of two levels. First, start with Christmas tree Decoration and then you have to decor the room for Christmas Celebration. Both decor level has a variety of items to decorate. Have fun!",
      tag: ["Christmas", "3D Games", "Winter","Decoration", "playcutegames", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211125,
  
      brand: "Playcutegames",
     
      orientation: 0,
      title: "Bff Christmas Cookie Challenge - playcutegames.com",
      name: ["Bff Christmas Cookie Challenge", "bff-christmas-cookie-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-christmas-cookie-challenge/250x142.png",
      path: [
        "/game/bff-christmas-cookie-challenge",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/bff-christmas-cookie-challenge/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Bff Christmas Cookie Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home.",
      l_dis:
        "Welcome to the Rainbow Girls Christmas Outfits. Rainbow girls Skyler, Sunny, Ruby, and Violet get ready for Christmas celebrations. They want to try cute Christmas outfits with crazy makeovers. Browse their wardrobe for cute outfits and pick some matching accessories. Join with these girls and enjoy this Christmas a most memorable one. Have fun!",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Christmas",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-11-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Unique Prom Look - playcutegames.com",
      name: ["My Unique Prom Look", "my-unique-prom-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-unique-prom-look/250x142.png",
      path: ["/game/my-unique-prom-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/my-unique-prom-look/",
      Walkthrough: "https://www.youtube.com/embed/mX-gSB16lJs",
      s_dis:
      "My Unique Prom Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Unique Prom Look. The prom night is tomorrow, and these girls are so excited! They planned to meet up and prepare for the prom together. They also decided to call up a stylist to get help with their makeup and hair. Therefore, here is your chance to be their stylist! Prove your fashion and makeup skills by creating their lovely prom look. Select which dress each girl is going to wear and accessorize it. The wardrobe is filled with the most breathtaking gowns!",
      tag: ["LOL Surprise","Dress up","Fashion","Prom","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-11-10",
      brand: "bestgamespot",
     
      orientation: 0,
      title: "Angry Flocks - playcutegames.com",
      name: ["Angry Flocks", "angry-flocks"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angry-flocks/250x142.png",
      path: ["/game/angry-flocks", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/angry-flocks/",
      Walkthrough: "https://www.youtube.com/embed/w9J9yEaE64E",
      s_dis:
      "Angry Flocks Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Angry Flocks. Join the Angry Flocks to destroy all aliens and save the world. Master the slingshot to take on the aliens. Use the special powers to destroy every shelter and complete all levels. Defeat Bosses and help the little heroes to unlock new power-ups in this amazing fantasy journey. Enjoy these tricky 40 levels and have fun !",
      tag: ["Action","Skill","Puzzle","Kids","Arcade","Exclusive","Girl games"],
      hint: ``,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-12-12",
      brand:"Lagged",
     
      orientation: 1,
      title: "Doctor Teeth - playcutegames.com",
      name: ["Doctor Teeth", "doctor-teeth"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/doctor-teeth/250x142.png",
      path: ["/game/doctor-teeth", "https://playcutegames.com"],
      iframe:
        "https://play.famobi.com/doctor-teeth/A-0D1OG",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Doctor Teeth Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:`Put on your dental gloves and get ready to have some fun with Doctor Teeth Game on Play Cute Games! In this game you’ll do all sorts of dental procedures like cleaning teeth, filling cavities and using real tools to give your patients the perfect smile. Each task from polishing to filling cavities will test your skills as you fix your patients’ dental health. With colorful graphics and interactive gameplay you’ll learn about dental care while having fun. Try different treatments, solve dental problems and watch your patients leave with brighter healthier smiles. It’s a mix of creativity and problem solving wrapped up in a fun dental adventure!`,
      tag: ["Doctor","Kids","Dentist","Lagged"],
      hint: `Mouse or tap to play.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-11-08",
      brand: "playcutegames",
     
      orientation: 0,
      title: "PFW Fall Ready To Wear Season 1 - playcutegames.com",
      name: ["PFW Fall Ready To Wear Season 1", "pfw-fall-ready-to-wear-season-1"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pfw-fall-ready-to-wear-season-1/250x142.png",
      path: ["/game/pfw-fall-ready-to-wear-season-1", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/pfw-fall-ready-to-wear-season-1/",
      Walkthrough: "https://www.youtube.com/embed/jll4JIO39AY",
      s_dis:
      "PFW Fall Ready To Wear Season 1 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to PFW Fall Ready To Wear Season 1. Celebrity girls are ready for their next fashion adventure. Yes! It’s Paris Fashion Week. Help them to be more unique in their look for this wonderful event. Browse their wardrobe and select the fabulous outfits for this event. Don’t forget to choose the matching accessories. Play and have fun!",
        tag: ["PFW","Dress up","Fashion","Celebrity","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-08-05",
      brand: "GD",
     
      orientation: 1,
      title: "Garden Tales - playcutegames.com",
      name: ["Garden Tales", "garden-tales"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/garden-tales/250x142.png",
      path: ["/game/garden-tales", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/fa29efc197d04cb1bb3074b8255f817f/?gd_sdk_referrer_url=https://playcutegames.com/games/garden-tales",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Garden Tales Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "The colorful match 3 game Garden Tales takes you to a beautifully landscaped garden. Combine and collect flowers, fruit, and mushrooms in over 700 exciting levels. The more flowers and fruits of the same kind you combine, the cooler the extras you get, making the game easier for you.Help the garden gnome Willy keep the beds accurate and free from weeds. Remove ice, puddles, stones and other debris, collect fruit baskets or go treasure hunting with your spade. But beware of the moles, they will ruin your whole garden! Complete the daily missions or the daily challenge to earn additional coins and other gifts. Enjoy the catchy soundtrack and challenging levels. Play Garden Tales now for free.",
      tag: ["Match3","Brain","Puzzle","Challenge","Kids","Girl games"],
      hint: `
      Travel around the map by completing the levels.<br><br> Use your mouse or the touch controls to swap the fruits and vegetables.<br><br> Match at least three same items to clear the board and match 4 or more to get boosters.`,
      rating:[4.2,110,14],
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 0,
      title: "My Pony Designer - playcutegames.com",
      name: ["My Pony Designer", "my-pony-designer"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-pony-designer/250x142.png",
      path: ["/game/my-pony-designer", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/41708b4a3a864c5a85505cc6d59f0d23/?gd_sdk_referrer_url=https://playcutegames.com/game/my-pony-designer",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Pony Designer Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Pony creator game where you can create your magic pony. The design is all yours, choose head shape, tail, body, wings and a nice garment. Color your pony as you wish, and add some stickers for final touch. At the end of the game you can take a photo of your pony and download it. Show your creation to your friends and family.",
      tag: ["Pony","Unicorn","Kids","Girl games","Pet"],
      hint: `Use your mouse to play, or tap the screen.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Pony Pet Salon - playcutegames.com",
      name: ["Pony Pet Salon", "pony-pet-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pony-pet-salon/250x142.png",
      path: ["/game/pony-pet-salon", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/7076a9f1d7d94c729b56f648fcbad84f/?gd_sdk_referrer_url=https://playcutegames.com/game/pony-pet-salon",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Pony Pet Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Even ponies like to look their best! Can you help these stylish ones get all cleaned up at the awesome pet salon in this online game? You can give them a bath, comb their hair, and more!",
      tag: ["Pony","Unicorn","Kids","Salon","Beauty","Girl games","Pet"],
      hint: `Tap to play`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 0,
      title: "Unicorn Beauty Salon - playcutegames.com",
      name: ["Unicorn Beauty Salon", "unicorn-beauty-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/unicorn-beauty-salon/250x142.png",
      path: ["/game/unicorn-beauty-salon", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/ce629d0a5cb8483aafe599539511be50/?gd_sdk_referrer_url=https://playcutegames.com/game/unicorn-beauty-salon",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Unicorn Beauty Salon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Unicorn Beauty Salon is a fun makeover for unicorn game with amazing graphics and customizations. Customize your unicorn for his best look! Play Unicorn Beauty Salon for great fun!",
      tag: ["Pony","Unicorn","Kids","Salon","Beauty","Girl games","Cute"],
      hint: `Tap to play`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 0,
      title: "Unicorn Beauty Salon 1 - playcutegames.com",
      name: ["Unicorn Beauty Salon 1", "unicorn-beauty-salon-1"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/unicorn-beauty-salon-1/250x142.png",
      path: ["/game/unicorn-beauty-salon-1", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/rvvASMiM/f68b4b4d812f4aa888c949c2d22426cc/index.html?",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Unicorn Beauty Salon 1 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Adventures can get rough sometimes and this magical unicorn just returned from one that scrapped her up a bit. Can you help her feel better while she gets a makeover at the enchanted salon in this online game for girls?",
      tag: ["Pony","Unicorn","Kids","Salon","Beauty","Girl games","Cute"],
      hint: ` Click or drag with the mouse to complete`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 0,
      title: "Pony Friendship - playcutegames.com",
      name: ["Pony Friendship", "pony-friendship"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pony-friendship/250x142.png",
      path: ["/game/pony-friendship", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/09400b9f9e704dc88b4878777f1faa2a/?gd_sdk_referrer_url=https://playcutegames.com/game/pony-friendship",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Pony Friendship Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Pony Friendship is a logic game where little ponies get lost in the forest. To help them, it is necessary, observing the rules of mirror reflection, to meet in the center of the location. -Easy logic game -You can improve the result every time",
      tag: ["Pony","Unicorn","Kids","Girl games","Cute","3D"],
      hint: ` `,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"GD",
     
      orientation: 1,
      title: "Unicorn Dress Up: Girls Games - playcutegames.com",
      name: ["Unicorn Dress Up: Girls Games", "unicorn-dress-up-girls-games"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/unicorn-dress-up-girls-games/250x142.png",
      path: ["/game/unicorn-dress-up-girls-games", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/68cd9d769660434db66b1600e4a1a173/?gd_sdk_referrer_url=https://playcutegames.com/game/unicorn-dress-up-girls-games",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Unicorn Dress Up: Girls Games Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"Girl games with unicorns - magical horses with a horn on their head. In dress up unicorn games for little girls you can create a huge number of pony outfits from over 180 fantasy items. A real feast to all cute horses, glitter fashion clothing, animal outfits and fairy tales lovers! It’s your perfect time to be a fashion designer. Enter the wonderful pony dress-up world full of super clothes. Children love dressing up such mythical animals as dragons, elves, fairies, mermaid dolls and, of course, colorful unicorns! Perhaps before going to bed you listened to fairy tales about them or read books about how beautiful these creatures are? In fact, if you dress up little ponies and attach a long stylish horn to their forehead, you can make your own unicorn! All you need is adorable ? games: make as many fantasy characters and decorations as you want, create your unique rainbow pony, become the cutest unicorn makeover artist.",
      tag: ["Pony","Unicorn","Kids","Dress up","Girl games","Race","Cute"],
      hint: `Use left mouse to change outfit.`,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-12-12",
      brand:"Crazy games",
     
      orientation: 0,
      title: "My Pony: My Little Race - playcutegames.com",
      name: ["My Pony: My Little Race", "my-pony-my-little-race"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-pony-my-little-race/250x142.png",
      path: ["/game/my-pony-my-little-race", "https://playcutegames.com"],
      iframe:
        "https://playzool.com/games/myPonyMLR/My-Pony-My-Little-Race",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Pony: My Little Race Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:"My Pony: My Little Race is a fun pony racing game for kids. Ride your pony and avoid obstacles to reach the finish line as fast as possible.",
      tag: ["Pony","Unicorn","Kids","Girl games","Race","Cute","Crazy games"],
      hint: ` <ul class="gamelist">
      <li class="para description"> Right arrow to start the race/speed up</li>
      <li class="para description">Up arrow to jump</li>
       </ul>
      `,
      rating:[4.9,350,18],
      platform:"both",
    },
    {
      date: "2022-11-04",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Paris Fashion Week Fall Couture - playcutegames.com",
      name: ["Paris Fashion Week Fall Couture", "paris-fashion-week-fall-couture"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/paris-fashion-week-fall-couture/250x142.png",
      path: ["/game/paris-fashion-week-fall-couture", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/paris-fashion-week-fall-couture/",
      Walkthrough: "https://www.youtube.com/embed/jll4JIO39AY",
      s_dis:
      "Paris Fashion Week Fall Couture Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Paris Fashion Week Fall Couture. Join with Bffs in their awesome journey in Paris Fashion Week and prepare yourself to be amazed by the designers, models, and trends gracing the runways. Help the girls to choose the perfect fashion costumes. Have fun!",
        tag: ["LOL Surprise","Dress up","Fashion","Autumn","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-11-03",
      brand: "playcutegames",
     
      orientation: 0,
      title: "NYFW Street Style - playcutegames.com",
      name: ["NYFW Street Style", "nyfw-street-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/nyfw-street-style/250x142.png",
      path: ["/game/nyfw-street-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/nyfw-street-style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "NYFW Street Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to NYFW Street Style. Rainbow girls are excited to try some really amazing street-style fashion outfits and accessories! So, play it and get inspired for your own wardrobe! Check out all those fabulous items and choose your favorites to dress the beautiful model up with style! She should look amazing in the end, so make sure you pick up the perfect outfit for her!",
      tag: ["Rainbow High","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    // Poki Ga
    // Yad Games
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Helicopter Rescue - playcutegames.com",
      name: ["Helicopter Rescue", "helicopter-rescue"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/helicopter-rescue/250x142.png",
      path: ["/game/helicopter-rescue", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/35cd27c173ba4d31b3845365a65e891e/?gd_sdk_referrer_url=https://playcutegames.com/game/helicopter-rescue ",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Helicopter Rescue Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Helicopter Rescue is a third-person shooter. If you like to play shooting games, then Helicopter Escape 3D you can't miss! Your mission is to rescue the hostages from the enemy and then let them escape on the plane without any problem. Concentrate on your mission and shoot the enemies quickly to avoid them catching up with the hostages. Come join us and start your rescue journey!",
      tag: ["Yad","Shooting","Fighting","Action","3D","RPG","Kids"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Agent Alpha - playcutegames.com",
      name: ["Agent Alpha", "agent-alpha"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/agent-alpha/250x142.png",
      path: ["/game/agent-alpha", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/57c1f7d03843411a938072a75e3295a6/?gd_sdk_referrer_url=https://playcutegames.com/game/agent-alpha",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Agent Alpha Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Agent Alpha is a casual shooting game designed for boys. If you like shooting games, you can't go wrong with Squad Alpha! The gameplay is very simple, slide the screen to move and defeat all the enemies to successfully enter the next level. More cool guns are waiting for you to unlock! Join now, press the trigger wildly and feel the fun of shooting the game you! Have fun!",
      tag: ["Yad","Shooting","Fighting","Action","3D","RPG","Kids"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Dressing Up Rush - playcutegames.com",
      name: ["Dressing Up Rush", "dressing-up-rush"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dressing-up-rush/250x142.png",
      path: ["/game/dressing-up-rush", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/6658a5b1bb434026a76a6c9e3b0d7aa4/?gd_sdk_referrer_url=https://playcutegames.com/game/dressing-up-rush",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dressing Up Rush Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "I'm going to recommend you a new fantastic arcade game. It is named Dress Up Run! You won't choose your own style or clothes anymore. In this game, you need to dress up the predetermined clothes to match at the end. The better the match, the easier it is to win. So you have to be careful when dressing up or collecting. I would say that you will like this game! Try it out now! Enjoy it!",
      tag: ["Yad","Girl games","Dress up","Running","Fashion","3D","Relaxing","Collecting"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Couple Rich Rush - playcutegames.com",
      name: ["Couple Rich Rush", "couple-rich-rush"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/couple-rich-rush/250x142.png",
      path: ["/game/couple-rich-rush", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/06b590c358c9443d99bc1e8daf1c674a/?gd_sdk_referrer_url=https://playcutegames.com/game/couple-rich-rush",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Couple Rich Rush Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Couple Rich Rush is a couple of parkour arcade games. Your task is to play as the couple and help them make money together and increase their wealth. The couple can pass money to each other and increase their money through the green and front doors. Don't spend your money and don't eat or drink a lot. The money you save can be used to furnish the house and dress up your house in a gorgeous way!",
      tag: ["Yad","Girl games","Kids","Running","Fashion","3D","Relaxing","Collecting"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Catwalk Girl Challenge - playcutegames.com",
      name: ["Catwalk Girl Challenge", "catwalk-girl-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/catwalk-girl-challenge/250x142.png",
      path: ["/game/catwalk-girl-challenge", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/c8d558321b454bc79f32e8ec30580daf/?gd_sdk_referrer_url=https://playcutegames.com/game/catwalk-girl-challenge",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Catwalk Girl Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Catwalk Girl Challenge is a unique fashion walk casual game. Do you usually like watching fashion shows? Come and join us to experience the fun of the catwalk! Choose your clothes according to the theme of each level and test your reflexes and aesthetic skills. In the end, the judges will score and the party with the highest score will win. Enjoy the glory of being in the spotlight!",
      tag: ["Yad","Girl games","Skill","Running","Fashion","3D","challenge","Collecting"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "GD",
     
      orientation: 1,
      title: "Bridge Water Rush - playcutegames.com",
      name: ["Bridge Water Rush", "bridge-water-rush"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bridge-water-rush/250x142.png",
      path: ["/game/bridge-water-rush", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/6d1c6b3a959b461587ebcac0b4d8fc56/?gd_sdk_referrer_url=https://playcutegames.com/game/bridge-water-rush",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Bridge Water Rush Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Bridge Water Rush is an addictive water bridge-building race casual entertainment game. Build your own bridge on the water by competing with others! Collect the purple wooden strips and transport them to the lanes to build automatically. The fastest player wins. Can you finish first with your speed? Take the challenge! Good luck!",
      tag: ["Yad","Kids","Skill","Running","Ball","3D","Relaxing","Collecting"],
      hint: `Slide to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "Yad",
     
      orientation: 1,
      title: "Going Balls - playcutegames.com",
      name: ["Going Balls", "going-balls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/going-balls/250x142.png",
      path: ["/game/going-balls", "https://playcutegames.com"],
      iframe:
        "https://www.yad.com/games/Going-Balls/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Going Balls Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Going Balls is a fun army combat game. As a great army commander, it's time to lead your army into battle! Build turrets, unlock soldiers and defend against the enemy invasion. Collect as much gold as possible to unlock weapons and soldiers. Gather your army and defend your base well. Don't forget to raise your rank, expelling the enemy is your only goal. Good luck!",
      tag: ["Yad","Kids","Skill","Running","Ball","3D","Relaxing"],
      hint: `Drag to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-12-05",
      brand: "Yad",
     
      orientation: 1,
      title: "Army Defender - playcutegames.com",
      name: ["Army Defender", "army-defender"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/army-defender/250x142.png",
      path: ["/game/army-defender", "https://playcutegames.com"],
      iframe:
        "https://www.yad.com/games/Army-Defender/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Army Defender Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Army Defender is a fun army combat game. As a great army commander, it's time to lead your army into battle! Build turrets, unlock soldiers and defend against the enemy invasion. Collect as much gold as possible to unlock weapons and soldiers. Gather your army and defend your base well. Don't forget to raise your rank, expelling the enemy is your only goal. Good luck!",
      tag: ["Yad","Fighting","Action","Strategy","RPG"],
      hint: `Drag to move`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-11-01",
      brand: "GD",
     
      orientation: 0,
      title: "Dino Rush Hypercasual Runner - playcutegames.com",
      name: ["Dino Rush Hypercasual Runner", "dino-rush-hypercasual-runner"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dino-rush-hypercasual-runner/250x142.png",
      path: ["/game/dino-rush-hypercasual-runner", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/e8d75668b9e74d578537e073f78168bf/?gd_sdk_referrer_url=https://playcutegames.com/game/dino-rush-hypercasual-runner",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Dino Rush Hypercasual Runner Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "The world of Red Dead Redemption is embodied in a hypercasual Dinosaur game. Escape from the police, enjoy the world of the Wild West. Dino rush as ARK will only allow you to play light and relax while making races.",
      tag: ["Arcade","Running","Hypercasual","Kids","Action","Adventure","Poki"],
      hint: `Click left mouse buttone to play Swerve to move Run, run, run`,
      rating:[4.9,350,18],
    },
    
    {
      date: "2022-10-27",
      brand: "Gamesnacks",
     
      orientation: 1,
      title: "Aliens Gone Wild - playcutegames.com",
      name: ["Aliens Gone Wild", "aliens-gone-wild"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/aliens-gone-wild/250x142.png",
      path: ["/game/aliens-gone-wild", "https://playcutegames.com"],
      iframe:
        "https://cdn.gamesnacks.com/aliensgonewild1/gameCode/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Aliens Gone Wild Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Aliens Gone Wild. Aliens have gone wild and invaded the Wild West. Play as a cowboy out to take revenge and destroy all the alien invaders in his land. But Aliens will not let you come either! They are in position and ready to shoot and defend the wild west. Dodge their shots and aim your auto-shooting guns at them. Grab power-ups to improve your firepower! and heart to increase your health and, allies to help you combat the aliens!",
      tag: ["Shooting","Skill","Arcade","Action","Poki"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-27",
      brand: "GD",
     
      orientation: 1,
      title: "Rabbids Wild Race - playcutegames.com",
      name: ["Rabbids Wild Race", "rabbids-wild-race"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rabbids-wild-race/250x142.png",
      path: ["/game/rabbids-wild-race", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/1a353bd608a84d7598621034b3e94f50/?gd_sdk_referrer_url=https://playcutegames.com/game/rabbids-wild-race/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Rabbids Wild Race Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Rabbids Wild Race. Fly as far as you can and collect stars before the other naughty Rabbids! Dress-up and ready your Jetpack, it’s time to Race! Challenge your friends to a wacky race! Avoid enemies and obstacles! Collect coins and unlock crazy outfits! Use naughty tricks to annoy other players!",
      tag: ["Multiplayer","Io","Kids","Action","Racing","Crazy games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-12",
      brand: "cutedressup",
     
      orientation: 0,
      title: "Half And Half Celebrity Style - playcutegames.com",
      name: ["Half And Half Celebrity Style", "half-and-half-celebrity-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/half-and-half-celebrity-style/250x142.png",
      path: ["/game/half-and-half-celebrity-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/half-and-half-celebrity-style/",
      Walkthrough: "https://www.youtube.com/embed/kmW84YTKi9c",
      s_dis:
      "Half And Half Celebrity Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Half And Half Celebrity Style. Celebrity wants to try brand new half & half-style. It’s now popular all over social media. Get them ready for a night out and mix and match the clothes to create unique half & half looks. Use your fashion skill and help the celebrity for this new fashion.",
        tag: ["Celebrity","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-12",
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFFs Welcome Fall Look - playcutegames.com",
      name: ["BFFs Welcome Fall Look", "bffs-welcome-fall-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-welcome-fall-look/250x142.png",
      path: ["/game/bffs-welcome-fall-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/partner/bffs-welcome-fall-look/",
      Walkthrough: "https://www.youtube.com/embed/I7vVzKwdABQ",
      s_dis:
      "BFFs Welcome Fall Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFFs Welcome Fall Look. Autumn is a beautiful season. Bffs is going to throw a party in the forest. They invited other friends to the party. Open the closet and dress up for the Bffs and her friends. An accessory is a must for a great look. Enjoy the game called BFFs Welcome Fall Look!",
        tag: ["Autumn","Princess","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-11",
      brand: "bestgamespot",
     
      orientation: 1,
      title: "Spiny maze puzzle - playcutegames.com",
      name: ["Spiny maze puzzle", "spiny-maze-puzzle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/spiny-maze-puzzle/250x142.png",
      path: ["/game/spiny-maze-puzzle", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/spiny-maze-puzzle/",
      Walkthrough: "https://www.youtube.com/embed/dYvj9QbPvhE",
      s_dis:
      "Spiny maze puzzle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Spiny maze puzzle. In this game, you have to put the tiny ball into the hole. But it's not easy you can rotate the circle maze using an Arrow key or touch the screen left half screen to rotate the left side and the right half screen to the rotate right side. The spiny maze puzzle contains 30 challenging levels. play and have fun.",
      tag: ["Skill","Kids","Arcade","Exclusive"],
      hint: `Rotate the circle maze using an Arrow key or touch the screen left half screen to rotate the left side and the right half screen to the rotate right side.`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-11",
      brand: "Gamesnacks",
     
      orientation: 1,
      title: "Panda Pizza Parlor - playcutegames.com",
      name: ["Panda Pizza Parlor", "panda-pizza-parlor"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/panda-pizza-parlor/250x142.png",
      path: ["/game/panda-pizza-parlor", "https://playcutegames.com"],
      iframe:
        "https://cdn.gamesnacks.com/pandapizzaparlor/gameCode/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Panda Pizza Parlor Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Panda Pizza Parlor. This cute panda is now ready to manage a pizza business and serve yummy pizza to its customers! Move like a busy bee and prepare pizza dough, and sauce and cook it before serving it hot to your hungry customers! Help the panda serve as many customers as he can for the day. The pizza menu has new offers as the level progresses. Have fun playing this fun pizza management game here! Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup. Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Cutedressup has a cute collection of dress-up and makeup games for girls. Play them and have fun!",
      tag: ["Puzzle","Skill","Kids","Arcade","Exclusive"],
      hint: ``,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-06",
      brand: "cutedressup",
     
      orientation: 1  ,
      title: "Stylish Fashion Challenge - playcutegames.com",
      name: ["Stylish Fashion Challenge", "stylish-fashion-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/stylish-fashion-challenge/250x142.png",
      path: ["/game/stylish-fashion-challenge", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/stylish-fashion-challenge/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Stylish Fashion Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Stylish Fashion Challenge.  A stylish Fashionista plans to show her fashion skill to her opponent. Join and play as a fashion designer for her. Help her to pick a nice costume for each event and compare her costumes with other players. Browse her wardrobe and pick outfits and costumes which is suitable for the events. Play and have fun!",
        tag: ["Multiplayer","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-06",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Funny Rescue Sumo - playcutegames.com",
      name: ["Funny Rescue Sumo", "funny-rescue-sumo"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/funny-rescue-sumo/250x142.png",
      path: ["/game/funny-rescue-sumo", "https://playcutegames.com"],
      iframe:
        "https://www.crazygames.com/embed/funny-rescue-sumo",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Funny Rescue Sumo Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Funny Rescue Sumo is a fun game where you must take care of the sumo that looks messy after his battle. Rescue him immediately. Help him recover from the wounds, and don't forget to give him some makeovers.",
      tag: ["Doctor","Skill","Kids","Crazy games"],
      hint: `Use the left mouse button to interact with the in-game UI.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-10-06",
      brand: "Crazy games",
     
      orientation: 1,
      title: "Flip The Box - playcutegames.com",
      name: ["Flip The Box", "flip-the-box"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/flip-the-box/250x142.png",
      path: ["/game/flip-the-box", "https://playcutegames.com"],
      iframe:
        "https://www.crazygames.com/embed/flip-the-box",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Flip The Box Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Flip The Box is a puzzle game where you must paint all the squares on the floor with colors using a block. This game comes with different and unique mechanics. Embark on this challenging game!",
      tag: ["Puzzle","Skill","Kids","Crazy games"],
      hint: `Use the WASD / arrow keys / hold and slide the left mouse button to move the box.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-10-06",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Scary Horror Escape Room - playcutegames.com",
      name: ["Scary Horror Escape Room", "scary-horror-escape-room"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/scary-horror-escape-room/250x142.png",
      path: ["/game/scary-horror-escape-room", "https://playcutegames.com"],
      iframe:
        "https://www.crazygames.com/embed/scary-horror-escape-room-games",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Scary Horror Escape Room Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Scary Horror Escape Room is a creepy escape room game set in a mysterious place. You need to solve tricky puzzles and escape the place alive!",
      tag: ["Puzzle","Skill","Kids","Crazy games"],
      hint: `Use the left mouse button to interact with the objects and follow the in-game tutorial.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-10-06",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Color Galaxy - playcutegames.com",
      name: ["Color Galaxy", "color-galaxy"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/color-galaxy/250x142.png",
      path: ["/game/color-galaxy", "https://playcutegames.com"],
      iframe:
        "https://www.crazygames.com/embed/color-galaxy",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Color Galaxy Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Color Galaxy is a fun arcade-style .io game where you must create a territory as big as possible. Choose the coolest car, a pattern of your territory, and also a cute sticker to make your appearance even more interesting! Have fun driving!",
      tag: ["Arcade","3D","Skill","Kids","Multiplayer","Io","Girl games","Crazy games"],
      hint: `Drag the left mouse button to move the car.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-10-01",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Super Girls Fall Fashion trends - playcutegames.com",
      name: ["Super Girls Fall Fashion trends", "super-girls-fall-fashion-trends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-fall-fashion-trends/250x142.png",
      path: ["/game/super-girls-fall-fashion-trends", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/super-girls-fall-fashion-trends/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Super Girls Fall Fashion trends Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Super Girls Fall Fashion trends. If you want to stay trendy this autumn play this new game called Super Girls Fall Fashion trends to dress up the super girls. These cute girls are real fashion addicts and they have just renewed their wardrobe. The girls are ready for autumn so hurry up and explore their wardrobe to see what kind of fashionable clothing pieces they hide there.",
        tag: ["Winx","Dress up","Bff","Autumn","Fall","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Ready For Christmas - playcutegames.com",
      name: ["Princess Ready For Christmas", "princess-ready-for-christmas"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-ready-for-christmas/250x142.png",
      path: ["/game/princess-ready-for-christmas", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_ready_for_christmas/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute game Princess Ready For Christmas, Princess Anna, and Elsa get ready for their Christmas celebrations. The excitement always is at a peak in the palace.",
      l_dis:
        `In this cute game Princess Ready For Christmas, Princess Anna, and Elsa get ready for their Christmas celebrations. The excitement always is at a peak in the palace. The celebrations are round the corner with everyone gearing up towards funny dances, cute dress up, and long party nights. Being a princess is even more fun.Let’s help the princess wear a cool party outfit for Christmas Eve and then you will need to help her with the dresses because this is the biggest event in the Palace. Last but not least, help her decorate her Christmas tree with a majestic look.At first, help princess Elsa to choose cute Christmas costumes with cute hairstyles and accessories. Now its a turn for Princess Anna, Help her to choose outfit, hairstyles and accessories.Finally it time to decorate their Christmas tree. Decorate with cute colors and decorative things.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Christmas",
        "Decoration",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-10-01",
      brand: "cutedressup",
     
      orientation: 1,
      title: "Cat Girl Fashion Challenge - playcutegames.com",
      name: ["Cat Girl Fashion Challenge", "cat-girl-fashion-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cat-girl-fashion-challenge/230x131.png",
      path: ["/game/cat-girl-fashion-challenge", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/cat-girl-fashion-challenge/",
      Walkthrough: "https://www.youtube.com/embed/WkiQ7kEdKc4",
      s_dis:
      "Cat Girl Fashion Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Cat Girl Fashion Challenge. Cat Girl is more conscious of her fashion. She plans to compete with all other fashionistas. Join and play as a fashion designer for cat girl. Help her to pick a nice costume for each event and compare her costumes with other players. Browse her wardrobe and pick outfits and costumes which is suitable for the events. Play and have fun!",
        tag: ["Angela","Dress up","Multiplayer","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-01",
      brand: "Y8",
     
      orientation: 1,
      title: "Happy Filled Glass 2 - playcutegames.com",
      name: ["Happy Filled Glass 2", "happy-filled-glass-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/happy-filled-glass-2/250x142.png",
      path: ["/game/happy-filled-glass-2", "https://playcutegames.com"],
      iframe:
        "https://storage.y8.com/y8-studio/html5/madpuffers/Football_2019_new/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Happy Filled Glass 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Happy Filled Glass 2. Happy Filled Glass 2 is a game in which you need to draw with a pencil the optimal way to move water to fill a glass, on your way you will encounter various obstacles such as burning platforms, water-accelerating platforms, as well as rotating platforms, and much more.",
      tag: ["Puzzle","Sports","Multiplayer","Skill","Arcade","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-10-01",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Chibi Sup Color - playcutegames.com",
      name: ["Chibi Sup Color", "chibi-sup-color"],
      thumb:"https://cdn.cutedressup.in/games/cutedressup_new/chibi-sup-color/250x142.png",
      path: ["/game/chibi-sup-color", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1664471631/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Chibi Sup Color Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Chibi Sup Color. Hello, young artist. The mysterious Halloween holiday is approaching. Take the opportunity to create a unique greeting card with your favorite characters for your friends. Cute chibi superheroes and funny animals will not leave anyone unconcerned. Take a screenshot and share the result with your friends!",
      tag: ["Skill","Kids","Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-10-01",
      brand: "GD",
     
      orientation: 0,
      title: "Aquapark IO - playcutegames.com",
      name: ["Aquapark IO", "aquapark-io"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/aquapark-io/250x142.png",
      path: ["/game/aquapark-io", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/ce67b95f0db44d66b8b0dc48ade7ce1a/?gd_sdk_referrer_url=https://playcutegames.com/game/aquaparkio",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Aquapark IO Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Aquapark IO. AquaPark.io is a crazy water slide racing game with fun mechanics. Overtake other players by jumping off the track and landing further down or simply kill them by pushing them off. Aquapark.io is an exciting and competitive io-type casual game. In a giant water slide, many different characters have to glide forward and compete against each other. Play and have fun with this new IO game.",
      tag: ["Multiplayer","Io","Kids","Poki"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
  {
    date: "2022-09-30",
    brand: "GD",
   
    orientation: 1,
    title: "Love Pins - playcutegames.com",
    name: ["Love Pins", "love-pins"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/love-pins/250x142.png",
    path: ["/game/love-pins", "https://playcutegames.com"],
    iframe:
      "https://html5.gamedistribution.com/14be4a167d814f8f8ad8e8b3e72e835d/?gd_sdk_referrer_url=https://playcutegames.com/game/love-pins",
    Walkthrough: "https://www.youtube.com/embed/WkiQ7kEdKc4",
    s_dis:
    "Love Pins Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to Love Pins. This should be a happy day because this couple of lovers will have a date. But there is a problem now, so many roadblocks on their road. Use the tools to kill bad guys and help them to meet their lover. Use your brain to solve these problems. Can you help them to meet each other? Have a try in the Love Pins!",
    tag: ["crazyGames","Skill","Puzzle","Kids","Girl games"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
  },
  {
    date: "2022-09-22",
    brand: "playcutegames",
   
    orientation: 0,
    title: "Celebrity Valentino Pink Collections - playcutegames.com",
    name: ["Celebrity Valentino Pink Collections", "celebrity-valentino-pink-collections"],
    thumb:
      "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-valentino-pink-collections/250x142.png",
    path: ["/game/celebrity-valentino-pink-collections", "https://playcutegames.com"],
    iframe:
      "https://games.cutedressup.net/celebrity-valentino-pink-collections/",
    Walkthrough: "https://www.youtube.com/embed/gX3DRC9m1qY",
    s_dis:
    "Celebrity Valentino Pink Collections Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
    l_dis:
      "Welcome to Celebrity Valentino Pink Collections. Celebrity girls are more famous for their fashion trends. Now they planned to add a new collection to this series. Yes! It’s the Valentino Pink collection. They already filled the collection in their wardrobe. Browse and pick a suitable costume for each girl. Join and help the girls in their fashion preparation. Have fun!",
    tag: ["Celebrity","Fashion","Valentino","Dress up","Kids","Girl games","Exclusive"],
    hint: `Use your left mouse button and keyboard`,
    rating:[4.4,1210,114],
  },
    {
      date: "2022-09-22",
      brand: "bestgamespot",
     
      orientation: 1,
      title: "Crazy Egg Catch - playcutegames.com",
      name: ["Crazy Egg Catch", "crazy-egg-catch"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/crazy-egg-catch/250x142.png",
      path: ["/game/crazy-egg-catch", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/crazy-egg-catch/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Crazy Egg Catch Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Crazy Egg Catch Endless. In this game, you have to transport the egg into the correct conveyor belt. If you waste the egg. The chicken will have angry with you and note the egg color to change the portal color. play and have fun.",
      tag: ["Puzzle","Clicker","Skill","Kids","Arcade","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-09-22",
      brand: "Y8",
     
      orientation: 1,
      title: "Football Legends - playcutegames.com",
      name: ["Football Legends", "football-legends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/football-legends/250x142.png",
      path: ["/game/football-legends", "https://playcutegames.com"],
      iframe:
        "https://storage.y8.com/y8-studio/html5/madpuffers/Football_2019_new/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Football Legends Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Football Legends. Choose your favorite team and join a tournament where only the best fight for the title. Control your favorite player! You can play solo against AI opponents or play with or against your friend.",
      tag: ["Puzzle","Sports","Multiplayer","Skill","Arcade","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-22",
      brand: "GD",
     
      orientation: 1,
      title: "Fish Love - playcutegames.com",
      name: ["Fish Love", "fish-love"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fish-love/250x142.png",
      path: ["/game/fish-love", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/714caa9b970d47c78729c961faa998f8/?gd_sdk_referrer_url=https://playcutegames.com/game/fish-love",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Fish Love Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Fish Love Game. Save fish from danger, bombs, lava, and crab, and give the fish just love. They cannot live without each other. Beautiful and pleasant music, sound effects, and bright and colorful graphics accompany you through all the puzzles of this game. Start your adventure now. Clear all the puzzles and let two fishes finally meet each other for love. Good luck in love !!!",
      tag: ["Puzzle","Skill","Arcade","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-22",
      brand: "GD",
     
      orientation: 0,
      title: "Snail Bob 5: Love Story - playcutegames.com",
      name: ["Snail Bob 5: Love Story", "snail-bob-5-love-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/snail-bob-5-love-story/250x142.png",
      path: ["/game/snail-bob-5-love-story", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/7e2e56736eef49f78220605798903354/?gd_sdk_referrer_url=https://playcutegames.com/game/snail-bob-5-html5",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Snail Bob 5: Love Story Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Snail Bob 5: Love Story. This is a story about love! The famous snail is setting out on another adventure. You need to help Bob the snail going to meet his girlfriend, but it seems that the way is far from his house. Help him to avoid a trap and reach the next door.",
      tag: ["Puzzle","Skill","Arcade","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-22",
      brand: "Y8",
     
      orientation: 0,
      title: "Noughts and Crosses - playcutegames.com",
      name: ["Noughts and Crosses", "noughts-and-crosses"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/noughts-and-crosses/250x142.png",
      path: ["/game/noughts-and-crosses", "https://playcutegames.com"],
      iframe:
        "https://storage.y8.com/y8-studio/html5/akeemywka/noughts_and_crosses_v2/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Noughts and Crosses Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Noughts and Crosses. A simple game of ‘tic tac toe’ on a chalkboard theme. You can play alone or with a friend in two-player modes. This is a classic game that is easily playable with paper. Now you can play using a computer.",
      tag: ["Puzzle","Skill","Arcade","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-22",
      brand: "GD",
     
      orientation: 0,
      title: "Moto X3M - playcutegames.com",
      name: ["Moto X3M", "moto-x3m"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/moto-x3m/250x142.png",
      path: ["/game/moto-x3m", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/5b0abd4c0faa4f5eb190a9a16d5a1b4c/?gd_sdk_referrer_url=https://playcutegames.com/game/moto-x3m-bike-race-game",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Moto X3M Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Moto X3M. Moto X3M is a cool motocross stunt game. With 22 challenging, yet addicting levels. Put on your helmets, revv your motorcycle and take off to beat the time on all the amazing stages. Perform tricks, like flips, to decrease your time.",
      tag: ["Racing","Action","Crazy games","Kids"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-15",
      brand: "cutedressup",
     
      orientation: 0,
      title: "PFW-The Big Shoulder Couture - playcutegames.com",
      name: ["PFW-The Big Shoulder Couture", "pfw-the-big-shoulder-couture"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pfw-the-big-shoulder-couture/250x142.png",
      path: ["/game/pfw-the-big-shoulder-couture", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/pfw-the-big-shoulder-couture/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "PFW-The Big Shoulder Couture Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to PFW-The Big Shoulder Couture. Celebrity girls are getting ready for another action. Yes! it’s Paris Fashion Week! They are excited to try big Shoulder Couture outfits. Browse their wardrobe and pick suitable outfits and accessories. Don’t forget to give unique hairstyles. Join them as fashion expert and help the girls to show their stunning fashion looks!",
      tag: ["Celebrity","Fashion","PFW","Dress up","Kids","Girl games","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-09-15",
      brand: "GD",
     
      orientation: 1,
      title: "Idle Fashion Shop - playcutegames.com",
      name: ["Idle Fashion Shop", "idle-fashion-shop"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/idle-fashion-shop/250x142.png",
      path: ["/game/idle-fashion-shop", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/76495354bb344d3f925e1ebfd4b55cc3/?gd_sdk_referrer_url=https://gamedistribution.com/game/idle-fashion-shop",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Idle Fashion Shop Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Idle Fashion Shop is a casual entertainment game with fashion as its theme. Congratulations! You are now a clothing store owner and you need to invest in unlocking pits to make more money. Of course, initially, you don't have a lot of money, you need to organize your own clothes and checkout, when you have the money you can hire cashiers and sales clerks. You can also open shoe stores, lingerie stores, etc. Become a millionaire just around the corner!",
      tag: ["Clicker","Arcade","Crazy games","Girl games","Kids"],
      hint: `Slide to move`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-15",
      brand: "GD",
      orientation: 1,
      title: "Nine Blocks: Block Puzzle Game - playcutegames.com",
      name: ["Nine Blocks: Block Puzzle Game", "nine-blocks-block-puzzle-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/nine-blocks-block-puzzle-game/250x142.png",
      path: ["/game/nine-blocks-block-puzzle-game", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/50513f5fb5594ab99f62a2912abaec84/?gd_sdk_referrer_url=https://playcutegames.com/game/nine-blocks-block-puzzle-game",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Nine Blocks: Block Puzzle Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "It’s a simple yet challenging free block puzzle you won’t be able to put down. Merge cube blocks on the 9x9 grid, which should be familiar to all sudoku fans, to build lines and squares. Strategically stack sudoku blocks made up of cubes on the board to destroy them and keep the board clean.",
      tag: ["Puzzle","Arcade","Crazy games","Matching","Kids"],
      hint: `Match blocks to remove them by completing lines and cubes. Keep the board clean and beat your high score in this block puzzle!`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-15",
      brand: "GD",
     
      orientation: 1,
      title: "SmileyWorld Bubble Shooter - playcutegames.com",
      name: ["SmileyWorld Bubble Shooter", "smileyworld-bubble-shooter"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/smileyworld-bubble-shooter/250x142.png",
      path: ["/game/smileyworld-bubble-shooter", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/f0ecdfa546ca4fc39d52b1af0f08af3f/?gd_sdk_referrer_url=https://playcutegames.com/game/smileyworld-bubble-shooter",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "SmileyWorld Bubble Shooter Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Pop cute smiley bubbles and travel across the SmileyWorld® Bubble Shooter game. The smiley bubbles are just waiting for you to have fun and smile with them. Collect all the little animals and characters and explore hundreds of levels. Match at least three bubbles and pop them, but don't run out of them. Use the extra booster smiley faces to pop any three smiley balls.",
      tag: ["Puzzle","Arcade","Crazy games","Bubble","Kids"],
      hint: `How to play SmileyWorld® Bubble Shooter game. - Tap/click and hold to aim, then release to shoot and pop the smiley bubble. - Try to group 3 or more bubbles to pop them. - Clear all the bubbles on the screen to level up. Try to get 3 stars on each level. - Collect extra characters by popping all the bubbles around them. - Use the Extra Booster smileys to complete the levels.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-14",
      brand: "bestgamespot",
     
      orientation: 1,
      title: "Catch The Thief - playcutegames.com",
      name: ["Catch The Thief", "catch-the-thief"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/catch-the-thief/250x142.png",
      path: ["/game/catch-the-thief", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/catch-the-thief",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Catch The Thief Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Catch The Thief is a very addictive game. The task is very simple need to collide the police and the thief. But is not easy to keep police and thief inside the game layout and a lot of obstacles also there if an obstacle collides with the police or thief you fail. Also, catch the thief alive.",
      tag: ["Puzzle","Clicker","Skill","Kids","Arcade","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-09-13",
      brand: "GD",
     
      orientation: 1,
      title: "Kitten Hide And Seek - playcutegames.com",
      name: ["Kitten Hide And Seek", "kitten-hide-and-seek"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/kitten-hide-and-seek/250x142.png",
      path: ["/game/kitten-hide-and-seek", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/b78553bf5db34812972452aab68f88c0/?gd_sdk_referrer_url=https://playcutegames.com/game/kitten-hide-and-seek",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Kitten Hide And Seek Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Kitten Hide And Seek is a puzzle online game of hide-and-seek for boys for free. In this game, your goal is to help the little girl escape successfully and get the props she needs, while avoiding being found by the kitten's search. If you love Tamagotchi or green light red light, this game is totally for you. You also can decorate your room with props won by beating the level. Have fun with it!",
      tag: ["Puzzle","Skill","Kids","Yiv"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-13",
      brand: "GD",
     
      orientation: 1,
      title: "Match 20 Challenge - playcutegames.com",
      name: ["Match 20 Challenge", "match-20-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/match-20-challenge/250x142.png",
      path: ["/game/match-20-challenge", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/a065b77e1f29400dac2cdd4e0e84928c/?gd_sdk_referrer_url=https://playcutegames.com/game/match-20-challenge",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Match 20 Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Match 20 Challenge is a free colorful puzzle online game for boys. The game starts at 5, looks easy until 10, gets fiendish at 15, and challenges you to get to 20. Anyone can learn it, but you'll have to concentrate to make it all the way. TIt is a very good skill-based game that tests both reaction time and dexterity. Can you get to Twenty?",
      tag: ["Puzzle","Match3","Kids"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-13",
      brand: "GD",
     
      orientation: 0,
      title: "Sumo.io - playcutegames.com",
      name: ["Sumo.io", "sumo.io"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/sumo-io/250x142.png",
      path: ["/game/sumo.io", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/5ad56ded64df44118b4e7dc922606492/?gd_sdk_referrer_url=https://playcutegames.com/game/sumo.io",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Sumo.io Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Push your opponents out of the playing field and don't let yourself be pushed out! Develop your character and buy useful gear! Different skins and arenas are waiting for you. Become the King of the Hill!",
      tag: ["Puzzle","Action","Io","Kids","Multiplayer","Yiv"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-09",
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFFs E Girl Vs Soft Girl - playcutegames.com",
      name: ["BFFs E Girl Vs Soft Girl", "bffs-e-girl-vs-soft-girl"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-e-girl-vs-soft-girl/250x142.png",
      path: ["/game/bffs-e-girl-vs-soft-girl", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-e-girl-vs-soft-girl/",
      Walkthrough: "https://www.youtube.com/embed/3ETVdmS5o64",
      s_dis:
      "BFFs E Girl Vs Soft Girl Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to BFFs E Girl Vs Soft Girl. Ellie and her friends love to try a new fashions. Everyone is talking about the Soft Girl and the E-girl style nowadays, which are the trending fashion styles preferred by teens worldwide. Both are trendy and have opposite aesthetics. The Soft Girl style embraces the girly girl look, sweet and cute, wearing lots of pinks. Ellie and her friends will explore both styles and you must help them out by creating their Soft Girl versus E-girl look!",
      tag: ["Barbie","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.7,210,20],
    },
    {
      date: "2022-09-07",
      brand: "bestgamespot",
     
      orientation: 1,
      title: "Bouncy King - playcutegames.com",
      name: ["Bouncy King", "bouncy-king"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bouncy-king/250x142.png",
      path: ["/game/bouncy-king", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/bouncy-king/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Bouncy King Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Bounce king. In this game, you will enjoy tricky puzzles of 30 levels. the game goal is very simple need put the ball into the hole. But it's a bit difficult to do that because you need to arrange some wood and other things too.",
      tag: ["Puzzle","Clicker","Skill","Kids","Arcade","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-09-06",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bffs Fall Fashion Trends - playcutegames.com",
      name: ["Bffs Fall Fashion Trends", "bffs-fall-fashion-trends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-fall-fashion-trends/250x142.png",
      path: ["/game/bffs-fall-fashion-trends", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-fall-fashion-trends/",
      Walkthrough: "https://www.youtube.com/embed/3ETVdmS5o64",
      s_dis:
      "Bffs Fall Fashion Trends Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Bffs Fall Fashion Trends. Fall is quite an exciting season when it comes to clothing and fashion, primarily because the harsher weather allows for more layering and combinations of clothes, which you can’t really do in the summer because it is too hot. Your favorite LOL girls want to rock looks inspired by the fall season, so you are here to give them this makeover they really need and have fun!",
      tag: ["LOL Surprise","Dress up","Bff","Autumn","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.7,210,20],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 0,
      title: "Raymans Incrediballs Dodge - playcutegames.com",
      name: ["Raymans Incrediballs Dodge", "raymans-incrediballs-dodge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/raymans-incrediballs-dodge/250x142.png",
      path: ["/game/raymans-incrediballs-dodge", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/cce037d8ac83427e870620b66f51ed3d/?gd_sdk_referrer_url=https://playcutegames.com/game/raymans-incrediballs-dodge",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Raymans Incrediballs Dodge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Raymans Incrediballs Dodge. Anarchy in the Jungle! Choose one of more than 20 hilarious Incrediballs and take advantage of their unique properties to escape the hazards of the jungle for as long as you can. Dodge left, dodge right, and push other players around, it’s the law of the jungle! Play and have fun with this new puzzle game for kids!",
      tag: ["Puzzle","Action","Action","Kids","Skill","Multiplayer","Poki"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      id: 202111265,
      brand: "Prinxy",
     
      orientation: 0,
      title: "TikTok Braided Hairstyles - playcutegames.com",
      name: ["TikTok Braided Hairstyles", "tiktok-braided-hairstyles"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-braided-hairstyles/250x142.png",
      path: ["/game/tiktok-braided-hairstyles", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/a4cfdc35-2fdb-5074-a667-932c2b324f92/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In the TikTok Braided Hairstyles, we featured all your favorite games from 2021. It’s been a great year for fashion, and in it, you are going to find a bit of everything.",
      l_dis:
        `Engage in styling beautiful braids, experiment with elegant patterns and try to put on interesting accessories to create great images. If you’re aiming for a casual outfit or something more formal, do not worry because there are lots of looks to try out. Try new braid combinations, discover new looks and show off your hair dressing skills. Grow and style fabulous braided looks and enjoy them with friends in this fashionable and entertaining braiding game!`,
      tag: ["Hair", "Tiktok", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Fashion Box: Glam Diva - playcutegames.com",
      name: ["Fashion Box: Glam Diva", "fashion-box-glam-diva"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fashion-box-glam-diva/250x142.png",
      path: ["/game/fashion-box-glam-diva", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/a4cfdc35-2fdb-5074-a667-932c2b324f92/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In the Fashion Box: Glam Diva, we featured all your favorite games from 2021. It’s been a great year for fashion, and in it, you are going to find a bit of everything.",
      l_dis:
        "Bonnie is one of the first influencers to try the life in plastic fashion challenge, and she could use your help to nail the look. She's going to go for a glam diva look, and she needs your help to look stunning. Are you ladies ready for a one-kind dress-up game? Then let's get started with the Fashion Box: Glam Diva dress-up game for girls and see what chic look you can put together for our beautiful fashionista.",
      tag: ["Makeup", "Dress up", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 0,
      title: "Basketball King - playcutegames.com",
      name: ["Basketball King", "basketball-king"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/basketball-king/250x142.png",
      path: ["/game/basketball-king", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/d57292e2177f4ec19fed58b0d21553e5/?gd_sdk_referrer_url=https://playcutegames.com/game/basketball-king",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Basketball King Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Basketball King. Most realistic physics provides you with the best and most realistic ball shooting experience. Train yourself anywhere and anytime. Beat all records and all opponents. Play with your friends in be the Basketball King! Gameplay is very simple, Swipe to throw the ball! sounds fun! right? Play and have fun with this new puzzle game for kids!",
      tag: ["Puzzle","Kids","Skill","Crazy games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 1,
      title: "Merge Block Raising - playcutegames.com",
      name: ["Merge Block Raising", "merge-block-raising"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/merge-block-raising/250x142.png",
      path: ["/game/merge-block-raising", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/5fc965b3a1534a4aaa1181b98acf7238/?gd_sdk_referrer_url=https://playcutegames.com/game/merge-block-raising",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Merge Block Raising Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Merge Block Raising. Merge Wood: Block Puzzle is a brand new puzzle game! A new type of brain training puzzle, entertain your brain for minutes or hours. Just drag the block onto another one with the same number! Merge them into a bigger number!! ",
      tag: ["Puzzle","Kids","Skill","Crazy games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 0,
      title: "Emergency Ambulance Simulator - playcutegames.com",
      name: ["Emergency Ambulance Simulator", "emergency-ambulance-simulator"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/emergency-ambulance-simulator/250x142.png",
      path: ["/game/emergency-ambulance-simulator", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/8de6c56bcafb40cf841069f4defd722f/?gd_sdk_referrer_url=https://playcutegames.com/game/emergency-ambulance-simulator",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Emergency Ambulance Simulator Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Emergency Ambulance Simulator. Here is a typical ambulance driving rescue simulation game with 3D game art animations. All levels have accidents, your mission is to transport the injured people from the accident area to the hospital as fast as you can. Keep calm and step on the gas! Play and have fun to play this new simulator game.",
      tag: ["Racing","Puzzle","Kids","Skill","Crazy games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 0,
      title: "Laser Cannon - playcutegames.com",
      name: ["Laser Cannon", "laser-cannon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/laser-cannon/250x142.png",
      path: ["/game/laser-cannon", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/b02d2e71e5ea45aca38dc93960acdc04/?gd_sdk_referrer_url=https://playcutegames.com/game/laser-cannon",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Laser Cannon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Laser Cannon. The new game in the “Laser Cannon” series. New locations, new monsters, new gear. Kill’em all! Laser Cannon 3 is finally back and now the funny physics-based shooting game goes into the third round. It’s up to you to save our planet one more time against a bunch of annoying alien creatures. Clear each stage using your mighty cannon and try to shoot them all with as less shots as possible.",
      tag: ["Arcade","Action","Shooting","Puzzle","Casual","Kids","Skill","Crazy games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 1,
      title: "Draw Love Story - playcutegames.com",
      name: ["Draw Love Story", "draw-love-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/draw-love-story/250x142.png",
      path: ["/game/draw-love-story", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/b863a161456243f1804b24406bd8c2cf/?gd_sdk_referrer_url=https://playcutegames.com/game/draw-love-story",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Draw Love Story Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Draw Love Story. Draw Love Story is a drawing puzzle online game for boys for free, which brings you a lot of fun. You need to deduce the meaning that the author wants to express through the scene and draw to complete the task. Draw different elements in the game to end the love story and create a happy ending for the couple. There are hundreds of different levels waiting for you, Have a great time!",
      tag: ["Arcade","Crazy games","Puzzle","Casual","Kids","Skill","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-06",
      brand: "GD",
     
      orientation: 0,
      title: "Cursed Treasure - playcutegames.com",
      name: ["Cursed Treasure", "cursed-treasure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cursed-treasure-1/250x142.png",
      path: ["/game/cursed-treasure", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/5d0c9c0ceeb54f4d9c238e82d35dea96/?gd_sdk_referrer_url=https://playcutegames.com/game/cursed-treasure-1",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Cursed Treasure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Cursed Treasure 1. Once again the gems of the evil forces are in danger! After a decade of peace, the good heroes come again to steal the last 3 gems carefully saved by the evil Overlord. Your duty is to defend what was granted by centuries of nefarious deeds. Build and upgrade towers, cast powerful spells, and learn useful skills to protect the gems and smash all these ninjas, angels, and lizard riders to pieces!",
      tag: ["Arcade","Puzzle","Casual","Kids","Skill","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-02",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bffs Kidcore Outfits - playcutegames.com",
      name: ["Bffs Kidcore Outfits", "bffs-kidcore-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-kidcore-outfits/250x142.png",
      path: ["/game/bffs-kidcore-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-kidcore-outfits/",
      Walkthrough: "https://www.youtube.com/embed/YVviaWqMxJs",
      s_dis:
      "Bffs Kidcore Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Bffs Kidcore Outfits. Kidcore is an aesthetic that centers around bright colors, icons from the 90s, and kiddy themes. These young girls are very excited to try this fashion. Join as a fashion expert and browse the wardrobe for cute outfits. Pick a suitable one for each girl and give a unique hairstyle to them.",
      tag: ["Barbie","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.7,210,20],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 0,
      title: "Bubble Shooter Candy 2 - playcutegames.com",
      name: ["Bubble Shooter Candy 2", "bubble-shooter-candy-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bubble-shooter-candy-2/250x142.png",
      path: ["/game/bubble-shooter-candy-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/8587b3c7418e485ea1b5d6d8f2e72c56/?gd_sdk_referrer_url=https://playcutegames.com/game/bubble-shooter-candy-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Bubble Shooter Candy 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Bubble Shooter Candy 2 is the long-awaited second instalment of the legendary Bubble Shooter game. The unique combination of the best Bubble Shooter game with the nicest and cutest candies will make you fall in love with this game from the first pop! What is the highest score you can achieve? Enjoy Bubble Shooter Candy 2 now and make the candies pop!",
      tag: ["Match3","Puzzle","Casual","Kids","Girl games","Poki"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 0,
      title: "Forest Match 2 - playcutegames.com",
      name: ["Forest Match 2", "forest-match-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/forest-match-2/250x142.png",
      path: ["/game/forest-match-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/be2946e7efea47b8bf3feb542369251c/?gd_sdk_referrer_url=https://playcutegames.com/game/forest-match-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Forest Match 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Forest Match 2 is one of greatest Match 3 games of all time! Because of the huge popularity of its predecessor, the players simply asked us for more. Forest Match 2 features incredible 3000+ levels. The biggest adventure in a beautiful magical forest there has ever been: more fruits to collect, more chests of coins to discover and a saga map packed with surprises for you to enjoy!",
      tag: ["Match3","Puzzle","Casual","Kids","Girl games","Poki"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
      orientation: 0,
      title: "Daily Solitaire Blue - playcutegames.com",
      name: ["Daily Solitaire Blue", "daily-solitaire-blue"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/daily-solitaire-blue/250x142.png",
      path: ["/game/daily-solitaire-blue", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/e28872a3009d42d7b5580c95a71192fe/?gd_sdk_referrer_url=https://playcutegames.com/game/daily-solitaire-blue",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Daily Solitaire Blue Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Daily Solitaire Blue brings you a new klondike solitaire game every day! Enjoy different challenges and difficulty levels depending on the day of the week. Practice makes masters! Play every day and increase your chance to earn monthly rewards! Combined with the highly polished graphics that Softgames Solitaire Games are known for, Daily Solitaire Blue guarantees fun and brain teasing for hours to come! Enjoy this card game masterpiece!",
      tag: ["Card","Puzzle","Casual","Poki"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 0,
      title: "The Mergest Kingdom - playcutegames.com",
      name: ["The Mergest Kingdom", "the-mergest-kingdom"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/the-mergest-kingdom/250x142.png",
      path: ["/game/the-mergest-kingdom", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/b5203bfef51242c39690c761321d769f/?gd_sdk_referrer_url=https://playcutegames.com/game/the-mergest-kingdom",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "The Mergest Kingdom Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Do you believe in fairytales? How else would you explain the story that is about to unfold!Merge together hundreds of objects in a land full of challenging quests, collect and harvest resources, design your own island by making it look the way YOU want it! Find the best ways to match and merge various pieces to erect the biggest buildings and cultivate the most fruitful plants! Help rebuild the Mergest Kingdom and make it the most magical one in the Seventh Realm!What is The Mergest Kindom like?YOUR WORLD! Only here can you find the most amazing objects that you will ever merge! YOUR CHOICE! You can merge dragons, trees, gems, and virtually anything you find on your journey of discovery! YOUR RULES! The islands full of merge magic and merge craft enabling you to make the biggest merges the way YOU want!Quests and challengesTake part in DAILY QUESTS to collect coins and gems, Mine various RESOURCES to build, Explore vast MAGIC territories full of mysterious creatures, bedazzling characters, and captivating objects populating this infinite world!If you love merge games this mergical experience is going to be a perfect fit for you!",
      tag: ["Match3","Puzzle","Casual","Kids","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GM",
     
      orientation: 0,
      title: "Island Puzzle - playcutegames.com",
      name: ["Island Puzzle", "island-puzzle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/island-puzzle/250x142.png",
      path: ["/game/island-puzzle", "https://playcutegames.com"],
      iframe:
        "https://html5.gamemonetize.co/ono1u6bmtkk11a03q9ulcy4hjzdcpxc6/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Island Puzzle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Island Puzzle. Start an exciting adventure in the cutest jungle puzzle matching game! Help the brave pilot and charming cat Tom survive on a desert island. Restore and decorate different areas on the island, get to the bottom of the secrets it holds, and enjoy the company of amusing in-game characters. What are you waiting for? Indulge yourself and become the hero of a brand new story. Build your dream island for free! Search for rare and unique artifacts by playing with curious pets, connecting them together in lines as long as possible. Play with little Tom cat, get powerful boosters, and help collect precious items!",
      tag: ["Match3","Puzzle","Casual","Kids","Skill","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 1,
      title: "Gold Diggers - playcutegames.com",
      name: ["Gold Diggers", "gold-diggers"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/gold-diggers/250x142.png",
      path: ["/game/gold-diggers", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/2d98c70ec8af4702a7b7ce59a618877f/?gd_sdk_referrer_url=https://playcutegames.com/game/gold-diggers",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Gold Diggers Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Dig your way to GOLD! Avoid obstacles as you try to collect as much precious gold as possible. Can you get enough gold to unlock all 9 carts? Let's find out!",
      tag: ["Match3","Puzzle","Casual","Kids","Girl games","Poki"],
      hint: `Click or tap on the sand to dig.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 1,
      title: "Tasty Match - playcutegames.com",
      name: ["Tasty Match", "tasty-match"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tasty-match/250x142.png",
      path: ["/game/tasty-match", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/224cba30a91a42debb44da41b7ff1866/?gd_sdk_referrer_url=https://playcutegames.com/game/tasty-match",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Tasty Match Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "A colorful and addictive game to relax and unwind! The goal of the game is to connect pairs of identical tiles. You need to have time to clear the entire field before the time runs out. Connect more distant tiles to get more stars. For a certain number of stars, a reward is given - a chest. Over time, new beautiful tiles are unlocked and level configurations become more complicated.",
      tag: ["Match3","Puzzle","Casual","Kids","Girl games"],
      hint: `The goal of the game is to connect pairs of identical tiles. You need to have time to clear the entire field of how the time runs out. Connect more distant tiles to get more stars. For a certain number of stars, a reward is given - a chest.`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 1,
      title: "Hide and Seek | The Original HNS Stickman Game - playcutegames.com",
      name: ["Hide and Seek | The Original HNS Stickman Game", "hide-and-seek-or-the-original-hns-stickman-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/hide-and-seek-or-the-original-hns-stickman-game/250x142.png",
      path: ["/game/hide-and-seek-or-the-original-hns-stickman-game", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/239942ce1a1349f6bcc9d312b32c5c3b/?gd_sdk_referrer_url=https://playcutegames.com/game/hide-and-seek-or-the-original-hns-stickman-game",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Hide and Seek | The Original HNS Stickman Game Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Find or be found. Up to you. The good old classic hide & seek with stickman. Play either as a seeker or as a hider stick and build your shelters from cars or office desks, hide in the water, in the hay pile, in the cornfield, in the boss' office and most importantly, push others in the seeker's vision field. Try to be kind though. Very addictive and easy to play hyper casual game Hide 'n seek - the original hns game",
      tag: ["Arcade","Puzzle","Casual","Kids","Poki"],
      hint: `Swipe across the screen or use WASD to move Find other stickman or hide from them`,
      rating:[4.9,252,19],
    },
    {
      date: "2022-09-01",
      brand: "GD",
     
      orientation: 1,
      title: "Human Wheel - playcutegames.com",
      name: ["Human Wheel", "human-wheel"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/human-wheel/250x142.png",
      path: ["/game/human-wheel", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/a9917a0fabf748bea8615c2aa4589e4a/?gd_sdk_referrer_url=https://playcutegames.com/game/people-wheel",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Human Wheel Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "GATHER THE BIGGEST HUMAN WHEEL Start running alone and gather people on your way to collect a massive wheel. Lead your team through all sorts of moving, rotating, and expanding obstacles. Calculate your moves on the run and save as many members of the wheel as possible.",
      tag: ["Arcade","Puzzle","Casual","Kids","Poki"],
      hint: `Calculate your moves on the run and save as many members of the wheel as possible.`,
      rating:[4.5,202,10],
    },
    {
      date: "2022-08-26",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Couple Red Carpet Fashion - playcutegames.com",
      name: ["Celebrity Couple Red Carpet Fashion", "celebrity-couple-red-carpet-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-couple-red-carpet-fashion/250x142.png",
      path: ["/game/celebrity-couple-red-carpet-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-couple-red-carpet-fashion/",
      Walkthrough: "https://www.youtube.com/embed/Nd2NI3wmxPI",
      s_dis:
      "Celebrity Couple Red Carpet Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Celebrity Couple Red Carpet Fashion. A Celebrity couple was invited to a Hollywood award function. They are excited to join this event. At this event, all celebrities are welcomed on a traditional red carpet, so they planned to wear the best outfits and show themself as perfect couples. Join and play this new game! Have fun!",
      tag: ["Celebrity","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.7,210,20],
    },
    {
      date: "2022-08-26",
      brand: "playcutegames",
     
      orientation: 0,
      title: "FNF Girlfriend Multiverse Fashion - playcutegames.com",
      name: ["FNF Girlfriend Multiverse Fashion", "fnf-girlfriend-multiverse-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fnf-girlfriend-multiverse-fashion/250x142.png",
      path: ["/game/fnf-girlfriend-multiverse-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/fnf-girlfriend-multiverse-fashion/",
      Walkthrough: "https://www.youtube.com/embed/egQcNjiRH8c",
      s_dis:
      "FNF Girlfriend Multiverse Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to FNF Girlfriend Multiverse Fashion. Friday Night Funkin’ Girl seems bored of trying music. She has a plan for something different other than music. Yes, she wants to try multiverse fashion! Sounds fun! right? Join and pick a different fashion outfit. At each level, her wardrobe is filled with different costumes. Help them to choose the best one!",
      tag: ["FNF","Dress up","Bff","Kids","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.7,210,20],
    },
    {
      date: "2022-08-25",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Snow Patrol - playcutegames.com",
      name: ["Snow Patrol", "snow-patrol"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/snow-patrol/250x142.png",
      path: ["/game/snow-patrol", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/snow-patrol/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Snow Patrol Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Snow Patrol is an arcade game where you must pick as many objects as possible to cover the hole in front of you and pass by the road. There are hundreds of levels available with dozens of different challenges, cities, and objects to collect. This game is fun and satisfying!",
      tag: ["Arcade","3D","Skill","Kids","Girl games","Crazy games"],
      hint: `Hold and drag the left mouse button to collect objects.`,
      rating:[4.9,350,18],
    },
    
    {
      date: "2022-08-25",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Layers Roll - playcutegames.com",
      name: ["Layers Roll", "layers-roll"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/layers-roll/250x142.png",
      path: ["/game/layers-roll", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/layers-roll/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Layers Roll Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Layers Roll is an arcade game where you must roll over on strips of the same color as your wheel. Make the wheel grow and become bigger to achieve a higher score. Don't forget to collect coins along your way and use the coins to buy new characters.",
      tag: ["Arcade","3D","Skill","Kids","Girl games","Crazy games"],
      hint: `Drag the left mouse button to the left and right to move.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-08-25",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Funny Zoo Emergency - playcutegames.com",
      name: ["Funny Zoo Emergency", "funny-zoo-emergency"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/funny-zoo-emergency/250x142.png",
      path: ["/game/funny-zoo-emergency", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/funny-zoo-emergency/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Funny Zoo Emergency Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Funny Zoo Emergency is a beauty game where you must take care of these baby animals. Cure their sickness, treat injured animals, clean all the dirt, dress them up, and make them perfect again.",
      tag: ["Dress up","Bff","Kids","Doctor","Girl games","Crazy games"],
      hint: `Use the left mouse button to interact with the objects.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-08-20",
      brand: "playcutegames",
     
      orientation: 0,
      title: "Friday Night Funkin First Date - playcutegames.com",
      name: ["Friday Night Funkin First Date", "friday-night-funkin-first-date"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/friday-night-funkin-first-date/250x142.png",
      path: ["/game/friday-night-funkin-first-date", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/friday-night-funkin-first-date/",
      Walkthrough: "https://www.youtube.com/embed/NcY7JGu75PA",
      s_dis:
      "Friday Night Funkin First Date Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Friday Night Funkin First Date. FNF girl and his boyfriend plan for a date. They want to be perfect in their looks. Pick up suitable night party outfits for them. Their wardrobe has already been filled with a new collection. Don’t forget to pick unique hairstyles and different costumes. Join and help them to make this date a more memorable one in their life.",
      tag: ["FNF","Dress up","Bff","Kids","Dating","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.9,350,18],
    },
    {
      date: "2022-08-20",
      brand: "Y8",
     
      orientation: 0,
      title: "Catapultz IO - playcutegames.com",
      name: ["Catapultz IO", "catapultz-io"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/catapultz-io/250x142.png",
      path: ["/game/catapultz-io", "https://playcutegames.com"],
      iframe:
        "https://storage.y8.com/y8-studio/html5/akeemywka/catapultz_io_v2/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Catapultz IO Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Catapultz IO. Drag, aim, and release! Launch your catapult at your opponents. The first to reach 20 kills wins the round. A fun IO game, guaranteed to keep players coming back for more. Features: – Simple tutorial to quickly learn how to play – Fast game-play – Choose your own avatar and username – Clever bots to balance the game-play.",
      tag: ["Arcade","Multiplayer","Kids","Io","Girl games","Crazy games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-20",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Blondie Reload - playcutegames.com",
      name: ["Blondie Reload", "blondie-reload"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/blondie-reloaded/250x142.png",
      path: ["/game/blondie-reload", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1660943844/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Blondie Reload Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Blondie Reload. Look who’s back! This is the charming Blondie and her boyfriend Kenny. They are a great stylish couple. Just take a look at their wonderful outfits inspired by the fashion of the 80s. Charge yourself with the radiant energy of Blondie and Kenny, and plunge into the world of high fashion with your lovers on the sunny coast and among the lights of the night city. Dance, have fun, and roller-skate through the streets of the metropolis or along the promenade.",
      tag: ["Dress up","Fashion","Kids","Dl-Girls","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-17",
      brand: "cutedressup",
     
      orientation: 0,
      title: "Super Girls Ready To Adventure - playcutegames.com",
      name: ["Super Girls Ready To Adventure", "super-girls-ready-to-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-girls-ready-to-adventure/250x142.png",
      path: ["/game/super-girls-ready-to-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/super-girls-ready-to-adventure/",
      Walkthrough: "https://www.youtube.com/embed/oFl6ruDP9QE",
      s_dis:
      "Super Girls Ready To Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      `Welcome to Super Girls Ready To Adventure. Winx Girls loves magical and adventurous fashion. They planned to participate in superheroes cosplay events. Help them to pick some interesting Outfits. Browse their wardrobe for cute outfits and pick a suitable outfit for each one. Don’t forget try unique hairstyles and accessories. Play and have fun!
      <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Winx","Dress up","Bff","Kids","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,1110,118],
    },
    {
      date: "2022-08-17",
      brand: "GD",
     
      orientation: 1, 
      title: "Save The Girl 2 - playcutegames.com",
      name: ["Save The Girl 2", "save-the-girl-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/save-the-girl-2/250x142.png",
      path: ["/game/save-the-girl-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/3594e310e0ee4be7ba5705cdd6032c25/?gd_sdk_referrer_url=https://playcutegames.com/game/save-the-girl-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Save The Girl 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Save The Girl 2. Save the girl 2 is a sequel to Save the girl, it is funnier than the last one. It still adopts the quiz play mode. But this time the bad guy is, an alien! It is so horrible, we can not let her be caught. Choose the right answer to save our cute girl! She will love you! Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup. Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Cutedressup has a cute collection of dress-up and makeup games for girls. Play them and have fun!",
      tag: ["Kids","Puzzle","Skill","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-17",
      brand: "GD",
     
      orientation: 1, 
      title: "Save The Girl - playcutegames.com",
      name: ["Save The Girl", "save-the-girl"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/save-the-girl/250x142.png",
      path: ["/game/save-the-girl", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/ecfd8df63b2146d4861294e92cdf00fa/?gd_sdk_referrer_url=https://playcutegames.com/game/save-the-girl",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Save The Girl Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Save The Girl. A girl will encounter various kinds of dangers in the game Save The Girl. You need some props or articles to help the little girl to carry out a task of escape and survival. The system will let the player choose one of several props given. You can’t give it wrong, or she will be caught by bad guys.",
      tag: ["Kids","Puzzle","Skill","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-17",
      brand: "Winx",
     
      orientation: 1, 
      title: "Winx Club Magic Match - playcutegames.com",
      name: ["Winx Club Magic Match", "winx-club-magic-match"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/winx-club-magic-match/250x142.png",
      path: ["/game/winx-club-magic-match", "https://playcutegames.com"],
      iframe:
        "https://www.winxclub.com//sites/default/games/html5/36/Game.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Winx Club Magic Match Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Winx Club Magic Match. The Winx Club Magic Match game is a match 3 game and it has a tricky puzzle level. To get more fun complete all levels  ",
      tag: ["Match3","Kids","Winx","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-17",
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Rise Minions Maker - playcutegames.com",
      name: ["Rise Minions Maker", "rise-minions-maker"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rise-minions-maker/250x142.png",
      path: ["/game/rise-minions-maker", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1660327027/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Rise Minions Maker Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to Rise Minions Maker. A minion is a devoted servant of his master. Since ancient times, minions have been looking for a ruler worthy of their service. Create your own minions and become their master! Choose hairstyles, clothes, and accessories to your liking. Experiment with colors and accessories, you are only limited by your imagination. Which of the minions is worthy to take a place in your great corporation? You decide!",
      tag: ["Dress up","Fashion","Kids","Dl-Girls","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.5,1110,118],
    },
    {
      date: "2022-08-12",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Colorful Denim Days - playcutegames.com",
      name: ["My Colorful Denim Days", "my-colorful-denim-days"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-colorful-denim-days/250x142.png",
      path: ["/game/my-colorful-denim-days", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-colorful-denim-days/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Colorful Denim Days Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Welcome to My Colorful Denim Days. Hey Girls! Are you ready for the coolest outfits? The style for today is #denim! Barbie and his friends are curious to know which style suits them best. Mix and match the clothes and create awesome outfits for the girls. Browse and pick suitable costumes for them. Play and Have fun!",
      tag: ["Barbie","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,1110,118],
    },
    {
      date: "2022-08-09",
      brand: "GD",
     
      orientation: 1,
      title: "Zen Cube 3D - playcutegames.com",
      name: ["Zen Cube 3D", "zen-cube-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/zen-cube-3d/250x142.png",
      path: ["/game/zen-cube-3d", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/2a1b01b50c6b45a69350ec6ee1ff9ad3/?gd_sdk_referrer_url=https://playcutegames.com/game/zen-cube-3d",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Zen Cube 3D Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Zen Cube 3D is a relaxation match 3d puzzle game. Not like classic match games, Zen Triple 3D not only a time-killing game but also is a zen & relaxation matching puzzle game which is easy for everyone to play.",
      tag: ["Crazy games","Match3","Kids","Puzzle","Challenge","Skill","Online games"],
      hint: `
      <ul> 
      <li>You need to choose 3 same 3D cubes.Use props at the right time to help you go more smoothly.</li>
      <li>Swipe to rotate the 3D cube, you can find more matching tile pairs.</li><li>Pay attention to the collecting bar, do not fill it up.</li>
      <li>Use props at the right time to help you go more smoothly.</li><li>Focus on your senses, clear all tiles within limited time.</li>
      </ul>`,
      rating:[4.5,110,7],
    },
    {
      date: "2022-08-09",
      brand: "GD",
     
      orientation: 1,
      title: "Match Tile 3D - playcutegames.com",
      name: ["Match Tile 3D", "match-tile-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/match-tile-3d/250x142.png",
      path: ["/game/match-tile-3d", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/e020c9ffd5f24d8b9383392418def0f2/?gd_sdk_referrer_url=https://playcutegames.com/game/match-tile-3d",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Match Tile 3D Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Match Tile 3D is an addictive puzzle, not only connect your brain to logical thinking but also is a fun triple matching game which is easy for everyone to play!",
      tag: ["Crazy games","Match3","Kids","Puzzle","Challenge","Skill","Online games"],
      hint: `
      <ul> 
      <li>A huge amount of candy crush, cute animals, cool toys, exciting emoji’s, appetizing food, yummy fruits and etc..</li>
      <li>Catchy sounds and vivid 3D visual effects</li><li>Pause it whenever you want</li><li>Well-designed trainer to power your brain up</li>
      </ul>`,
      rating:[4.7,150,5],
    },
    {
      date: "2022-08-09",
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity Dark Gothic Fashion - playcutegames.com",
      name: ["Celebrity Dark Gothic Fashion", "celebrity-dark-gothic-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-dark-gothic-fashion/250x142.png",
      path: ["/game/celebrity-dark-gothic-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-dark-gothic-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Celebrity Dark Gothic Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      `Welcome to Celebrity Dark Gothic Fashion. Celebrity girls plan something unique in fashion. They heard about Dark gothic fashion and were excited to try it. But they have some confusion to choose the best outfit. Browse the wardrobe and pick suitable costumes and outfits for girls. Join with girls and so your creative skill.
      <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Celebrity","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.2,110,18],
    },
    {
      date: "2022-08-05",
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Sweet Lolita Dress - playcutegames.com",
      name: ["My Sweet Lolita Dress", "my-sweet-lolita-dress"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-sweet-lolita-dress/250x142.png",
      path: ["/game/my-sweet-lolita-dress", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-sweet-lolita-dress/",
      Walkthrough: "https://www.youtube.com/embed/uWbgeiIUmFQ",
      s_dis:
      "My Sweet Lolita Dress Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      " Welcome toMy Sweet Lolita Dress. LOL Surprise girls plan to try their sweet lolita fashion. Lolita fashion is a cute stylish way to dress up in puffy skirts, lacy blouses, and accessories, perfect to create your own colorful trendy fashion. Mix classical, sweet, and gothic lolita styles for a unique spin on the Japanese trend. Browse and find cute outfits for our girls. Join as a fashion expert and show your skill. ",
      tag: ["LOL Surprise","Dress up","Bff","Summer","Exclusive","Girl games"],
      hint: `Use your left mouse button to play.`,
      rating:[4.8,1110,18],
    },
    {
      date: "2022-08-05",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Like a King - playcutegames.com",
      name: ["Like a King", "like-a-king"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/like-a-king/250x142.png",
      path: ["/game/like-a-king", "https://playcutegames.com"],
      iframe:
        "https://ext.minijuegosgratis.com/like-a-king/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Like a King Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      "Like a King is a tower defense and deck-building game created by LAK Games. You are a king who must battle other kings using and combining the units in your collection of cards. Each card is a unique unit you can use on the battlefield. Some of them attack the enemy, but some of them mine gold and even lay out traps for the enemy unit. Complete quests, build a strong deck, and most importantly, strategize wisely to be the one true king that stands!",
      tag: ["Crazy games","Multiplayer","Strategy","Tower Defense","Challenge","Online games"],
      hint: `
      Tap on the empty tile to spawn a unit. Draw a line from the unit to the enemy unit you want to attack. Revise your tactics and destroy all enemy units before your units are destroyed`,
      rating:[4.8,1110,18],
    },
    {
      date: "2022-08-04",
      brand: "Crazy games",
     
      orientation: 0,
      title: "Gold Digger FRVR - playcutegames.com",
      name: ["Gold Digger FRVR", "gold-digger-frvr"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/gold-digger-frvr/250x142.png",
      path: ["/game/gold-digger-frvr", "https://playcutegames.com"],
      iframe:
        "https://golddigger.frvr.com/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Gold Digger FRVR Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Gold Digger FRVR is a 2D adventure game by FRVR Games. You are a miner with a shovel and you are discovering the underground world. Shovelling your way through mud, earth and rock, you can collect money to improve your skills and buy better tools. The game is an ongoing adventure in which you can improve your character step by step.",
      tag: ["Crazy games","Digger games","Arcade","Adventure"],
      hint: "Walk around with the miner to collect as much gold as you can.",
      rating:[4.2,110,14],
    },
    {
      date: "2022-08-03",
      brand: "playcutegames",
     
      orientation: 0, 
      title: "Ellie And Ben Insta Fashion - playcutegames.com",
      name: ["Ellie And Ben Insta Fashion", "ellie-and-ben-insta-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-ben-insta-fashion/250x142.png",
      path: ["/game/ellie-and-ben-insta-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-and-ben-insta-fashion/",
      Walkthrough: "https://www.youtube.com/embed/74ZZ-u_QNxE",
      s_dis:
        "Ellie And Ben Insta Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Ellie And Ben Insta Fashion. Help Ellie and her boyfriend Ben to choose an outstanding outfit to show their fashion skill on Instagram. Browse their wardrobe and pick costumes based on their moods. You can make their looks better with the help of other accessories. Join and play with your favorite couples. Have fun!",
        tag: ["Barbie","Dress up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      date: "2022-08-04",
      brand: "GD",
     
      orientation: 1,
      title: "Draw Car Road - playcutegames.com",
      name: ["Draw Car Road", "draw-car-road"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/draw-car-road/250x142.png",
      path: ["/game/draw-car-road", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/3240a82dceb24b8f8f61fcfe2c5fb209/?gd_sdk_referrer_url=https://playcutegames.com/games/draw-car-road",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Draw Car Road Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "This game is actually not a car avoiding arcade game but a bridge drawing puzzle game with 3D cartoon game art animations. It is managed for you to draw a bridge for the yellow car to overpass all kinds of terrains and the red flag on the platform track at each level. Glad you will complete all levels and have fun!",
      tag: ["Puzzle","Clicker","Car games","Arcade","Yiv"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.2,110,14],
    },
    {
      date: "2022-08-03",
      brand: "bestgamespot",
     
      orientation: 0,
      title: "Super Hoops Basketball - playcutegames.com",
      name: ["Super Hoops Basketball", "super-hoops-basketball"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-hoops-basketball/250x142.png",
      path: ["/game/super-hoops-basketball", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bestgamespot/super-hoops-basketball/",
      Walkthrough: "https://www.youtube.com/embed/o6qwEccxtqc",
      s_dis:
      "Super Hoops Basketball Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Super Hoops Basketball is a puzzle game. In this game, you have to deliver a Basketball to that Hoop. Collect the stars along the way but be careful not to turn the platforms too much or else it’ll be game over! ",
      tag: ["Puzzle","Clicker","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
    {
      date: "2022-07-25",
      brand: "crazy Games",
     
      orientation: 0,
      title: "Slap Castle - playcutegames.com",
      name: ["Slap Castle", "slap-castle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/slap-castle/250x142.png",
      path: ["/game/slap-castle", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/slap-castle/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Slap Castle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Slap Castle is a funny arcade game to slap all the enemies and the king to conquer the kingdom!",
      tag: ["Crazy games","Action","Fighting","Skill"],
      hint: `Use your left mouse button and keyboard`,
      rating:[4.4,1210,114],
    },
   
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 1,
      title: "Celebrity Fashion Battle - playcutegames.com",
      name: ["Celebrity Fashion Battle", "celebrity-fashion-battle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-fashion-battle/250x142.png",
      path: ["/game/celebrity-fashion-battle", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-fashion-battle/",
      Walkthrough: "https://www.youtube.com/embed/7nrf-_0vVvo",
      s_dis:
        "Celebrity Fashion Battle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Fashion Battle. Celebrities always follow the latest trends in the fashion world. Meet the new fashion collection Spring-Summer 2022! The trend is bright flashy colors and a crazy combination of shades that will not leave anyone indifferent. Every celebrity wants to be inimitable. Which girl is ready to concede in this fashionable battle? Or will they shine until the last accessory? Don’t give your opponents a chance. Time for bold decisions and new experiences. Try on all these dresses, suits, skirts, pants, tops, shorts, and shoes! Show your creative nature and prove to everyone that you are a cool stylist.",
        tag: ["Celebrity","Dress up","Multiplayer","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 1,
      title: "Galaxzynos - playcutegames.com",
      name: ["Galaxzynos", "galaxzynos"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/galaxzynos/250x142.png",
      path: ["/game/galaxzynos", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/b22155b2751b420f8c3c1f86cb1ff8e2/?gd_sdk_referrer_url=https://playcutegames.com/games/galaxzynos",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Galaxzynos Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Galaxzynos is a space shooter game, make your way through the galaxy, fight with different types of enemy spaceships, grab different types of power ups by exploding the asteroids such as bullet shield, laser beam, health gain and spaceship upgrade.",
      tag: ["Action","Arcade","Shooting","Kids","Poki"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
      
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 1,
      title: "Erase One Part - playcutegames.com",
      name: ["Erase One Part", "erase-one-part"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/erase-one-part/250x142.png",
      path: ["/game/erase-one-part", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/af219f4790cb4dc2868e9925a41e904b/?gd_sdk_referrer_url=https://playcutegames.com/games/erase-one-part",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Erase One Part Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "What will you discover and what will you remove in Erase One Part? Grab a magical eraser and get started in this truly creative game. Your new eraser has some pretty amazing powers. It can erase stuff on paper, needless to say, but that's not all! It can also clean magic lamps, activate enchanted mirrors, and even help baby chicks get out of their eggs! Find out what else it can do in this puzzle game that's great for players of all ages.",
        tag: ["Puzzle","Skill","Multiplayer","Kids","Yad"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Perfect Weekend Outfits - playcutegames.com",
      name: ["My Perfect Weekend Outfits", "my-perfect-weekend-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-weekend-outfits/250x142.png",
      path: ["/game/my-perfect-weekend-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/my-perfect-weekend-outfits/",
      Walkthrough: "https://www.youtube.com/embed/5mjB5xH4HqM",
      s_dis:
        "My Perfect Weekend Outfits Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to My Perfect Weekend Outfits. Are you ready for a crazy weekend with these Rainbow Girls? Do you know what they have planned? Play this game and help these girls get ready for a weekend full of adventures, but most importantly, help them get ready for it. They need to look absolutely fabulous so you need to help them find the perfect outfit and accessories, as well as the hairstyle!",
        tag: ["Rainbow High","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "K-Pop Stars Inspired Look - playcutegames.com",
      name: ["K-Pop Stars Inspired Look", "k-pop-stars-inspired-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/k-pop-stars-inspired-look/250x142.png",
      path: ["/game/k-pop-stars-inspired-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/k-pop-stars-inspired-look/",
      Walkthrough: "https://www.youtube.com/embed/vVVXtVptilk",
      s_dis:
        "K-Pop Stars Inspired Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to K-Pop Stars Inspired Look. These girls love Korean pop music. They absolutely adore the world of k-pop, and they’re going to a nightclub where a DJ will be playing music by their favorite groups all night long! Help them choose awesome outfits and fast! You can also lend them a hand while they do their makeup in this beauty game.",
        tag: ["Winx","Dress up","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Aquapark Shark - playcutegames.com",
      name: ["Aquapark Shark", "aquapark-shark"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/aquapark-shark/250x142.png",
      path: ["/game/aquapark-shark", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/bf2f6bcba5f84f28b8d92ccf5da654f2/?gd_sdk_referrer_url=https://playcutegames.com/game/aquapark-shark",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Aquapark Shark Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the fun in Aquapark Shark water slide pool racing adventure where dreams are born and people come to beat the summer heat! Have fun at the aqua slides, uphill rush crazy rides adventure park racing games! Play all kinds of water pool race activities in an uphill rush water park racing game to make this summer the best fun-filled summer with water splash fun aqua racing adventure!",
        tag: ["Skill","Arcade","Summer","Poki"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Best Friend DIY - playcutegames.com",
      name: ["Best Friend DIY", "best-friend-diy"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/best-friend-diy/250x142.png",
      path: ["/game/best-friend-diy", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1657210355/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Best Friend DIY Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Best Friend DIY. Galya is a very modest girl. It is difficult for her to meet new people and make friends. One day she decided to create her own best friend with her own hands! Galya works as a scientist in a laboratory. Join her in her research and help make the discovery of the century. Choose your ingredients wisely, otherwise, you might end up with a monster instead of a best friend!",
      tag: ["Puzzle Games","Skill","Bff Games","Summer","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Summer Aesthetic - playcutegames.com",
      name: ["BFFs Summer Aesthetic", "bffs-summer-aesthetic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-summer-aesthetic/250x142.png",
      path: ["/game/bffs-summer-aesthetic", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-summer-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/8Cgd1PEAiTM",
      s_dis:
        "BFFs Summer Aesthetic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the BFFs Summer Aesthetic. Summer is here, and these 6 BFFs are already enjoying it in style with amazing trips around the world! This year, each of them picked a unique destination for the summer holiday, but they keep themselves updated by sending each other lots of pictures. Join them in, and get ready to discover the most beautiful colors of summer.",
        tag: ["Winx","Dress up","Bff","Summer","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedresssup",
     
      orientation: 0,
      title: "Celebrity E-Girl Fashion - playcutegames.com",
      name: ["Celebrity E-Girl Fashion", "celebrity-e-girl-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-e-girl-fashion/250x142.png",
      path: ["/game/celebrity-e-girl-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-e-girl-fashion/",
      Walkthrough: "https://www.youtube.com/embed/ekZmVhGP75M",
      s_dis:
        "Celebrity E-Girl Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Celebrity E-Girl Fashion. These Celebrities were definitely among the very first online fashionistas to fall into this highly popularized trend, so get ready to be amazed by their edgy fashion style and awesome makeup. Today our e-girls have decided to upgrade their looks with something new and for that, they are going to need your expert advice. Join and help the girls!",
        tag: ["Celebrity","Dress up","E-girl","Bff","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Bff Summer Shine Look - playcutegames.com",
      name: ["Bff Summer Shine Look", "bff-summer-shine-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-summer-shine-look/250x142.png",
      path: ["/game/bff-summer-shine-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bff-summer-shine-look/",
      Walkthrough: "https://www.youtube.com/embed/MThaX0FR0io",
      s_dis:
        "Bff Summer Shine Look Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFF Summer Shine Look. Bffs are planning for the weekend summer party at the resort. They are bored of trying old fashion summer costumes. Browse their wardrobe for a new collection of outfits and pick a suitable one for each one. Choose matching accessories and unique hairstyles for them. Help them and make this summer party a more memorable one. ",
        tag: ["Barbie","Dress up","Make Up","Summer","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Jetpack Joyride Online - playcutegames.com",
      name: ["Jetpack Joyride Online", "jetpack-joyride-online"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/jetpack-joyride-online/250x142.png",
      path: ["/game/jetpack-joyride-online", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/fa2ecd1d2d18473b96b9103e564117ec/?gd_sdk_referrer_url=https://playcutegames.com/game/jetpack-rusher",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Jetpack Joyride Online Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Jetpack Rusher. Strap on a bullet-powered jetpack to dodge laser’s electric fields and all the missiles that come your way Get a boost along the way Collect coins and buy new suits for Clarry. Join play this new game for kids. Have fun!",
      tag: ["Crazy Games","Skill","Kids","Poki"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Tom And Angela Insta Fashion - playcutegames.com",
      name: ["Tom And Angela Insta Fashion", "tom-and-angela-insta-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tom-and-angela-insta-fashion/250x142.png",
      path: ["/game/tom-and-angela-insta-fashion", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/197c2e8d3c59467e96730a0128e279f2/?gd_sdk_referrer_url=https://playcutegames.com/game/tom-and-angela-insta-fashion",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Tom And Angela Insta Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Tom And Angela Insta Fashion. Help Tom & Angela to become Instagram famous. Buy and choose clothes, hairstyles, or accessories by following their moods. Make them look stunning on every occasion, take beautiful pictures to post on Instagram, and receive lots of likes. Get their insta-ready outfits and have fun!",
      tag: ["Angela","Tom","Kids","Dress up"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Super Friday Night Funkin - playcutegames.com",
      name: ["Super Friday Night Funkin", "super-friday-night-funkin"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-friday-night-funkin/250x142.png",
      path: ["/game/super-friday-night-funkin", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/800958c9ee814a389b0bdc01392c9bc5/?gd_sdk_referrer_url=https://playcutegames.com/game/super-friday-night-funki",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Super Friday Night Funkin Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Have fun with this new game Super Friday Night Funkin’ a new game in this fabulous saga !! Play the ritual of each music, press the game buttons at the right time and accumulate the points to show that you are the best.",
      tag: ["Friday Night Funkin","FNF","Skill","Kids","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedresssup",
     
      orientation: 0,
      title: "Ocean Kids Back To school - playcutegames.com",
      name: ["Ocean Kids Back To school", "ocean-kids-back-to-school"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ocean-kids-back-to-school/250x142.png",
      path: ["/game/ocean-kids-back-to-school", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ocean-kidz-back-to-school/",
      Walkthrough: "https://www.youtube.com/embed/cATCCaxiEHw",
      s_dis:
        "Ocean Kids Back To school Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Ocean Kids Back to school. It’s back to school time for these Kids. Can you help them get ready for their first day of classes? They’ll need to pick some cool outfits, new hairstyles, accessories, and school supplies too in this online dress-up and design game for girls. After you’re all done, help them to repair their vespa.",
        tag: ["Barbie","Dress up","Summer","Bff","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Choose My summer Style - playcutegames.com",
      name: ["Choose My summer Style", "choose-my-summer-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/choose-my-summer-style/250x142.png",
      path: ["/game/choose-my-summer-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/choose-my-summer-style/",
      Walkthrough: "https://www.youtube.com/embed/04EQx-8mkEQ",
      s_dis:
        "Choose My summer Style Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Choose My summer Style game. Summer is just seconds away. These girls can’t wait for sun tan lines, longer days, and good times with friends during vacation. It’s time to freshen up their wardrobe for vacation. They’re excited to finally get out and enjoy the sun. Help them to choose their style for this summer.",
      tag: ["Princess","Dress up","Make Up","Summer","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Beach Rainbow Season - playcutegames.com",
      name: ["Beach Rainbow Season", "beach-rainbow-season"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/beach-rainbow-season/250x142.png",
      path: ["/game/beach-rainbow-season", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1655919910/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Beach Rainbow Season Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Beach Rainbow Season. It’s time to open the summer beach season with your BFF. Time to swim, sunbathe, surf, and just have fun. Help these trendy influencers choose the perfect beach party outfits! Girls prefer bright swimsuits and tops, and a sports outfit or shorts are suitable for a guy. Don’t forget to complete your look with top accessories from well-known brands!",
      tag: ["Rainbow High","Dress up","Make Up","Summer","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Kiz10",
     
      orientation: 1,
      title: "Super Friday Night vs Neon - playcutegames.com",
      name: ["Super Friday Night vs Neon", "super-friday-night-vs-neon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/super-friday-night-vs-neon/250x142.png",
      path: ["/game/super-friday-night-vs-neon", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/d0a85081f8df45f5b33931f1521d3b9f/?gd_sdk_referrer_url=https://playcutegames.com/game/super-friday-night-vs-neon",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Super Friday Night vs Neon Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Super Friday Night vs Neon. Have fun with this new game Super Friday Night Funkin vs Neon a new game in this fabulous saga !! Play the ritual of each music, with a new 3D animated background improvement, press the game buttons at the right time and accumulate the points to show that you are the best. All your favorite characters are here Huggy, Sonik and many others are waiting for you. Enjoy the new 3D backgrounds and music with new characters and more levels from easy to hard.",
      tag: ["Friday Night Funkin","FNF","Skill","Kids","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity First Date Adventure - playcutegames.com",
      name: ["Celebrity First Date Adventure", "celebrity-first-date-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-first-date-adventure/250x142.png",
      path: ["/game/celebrity-first-date-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-first-date-adventure/",
      Walkthrough: "https://www.youtube.com/embed/l6TYgm8ev8Y",
      s_dis:
        "Celebrity First Date Adventure Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Celebrity First Date Adventure. Our favorite Celebrity couple planned for an adventure date. Both are excited and want to be more attractive. They need some expert advice. Browse their wardrobe and pick a cute outfit for them. Help the couple and make their date the most memorable one!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
        tag: ["Celebrity","Dress up","Make Up","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFF Art Hoe Fashion - playcutegames.com",
      name: ["BFF Art Hoe Fashion", "bff-art-hoe-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-art-hoe-fashion/250x142.png",
      path: ["/game/bff-art-hoe-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bff-art-hoe-fashion/",
      Walkthrough: "https://www.youtube.com/embed/4e1FBScw-AQ",
      s_dis:
        "BFF Art Hoe Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to BFF Art Hoe Fashion. Ellie and her friends are weary of trying the same fashion. Today they planned to try something new. Yes! It’s Art Hoe fashion!. You join as a fashion expert and give a company to these girls in choosing their perfect outfit. Browse their wardrobe and pick a suitable one. Don’t forget to help them with their makeover. Play and have fun!",
      tag: ["Barbie","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Jump Ball - playcutegames.com",
      name: ["Jump Ball", "jump-ball"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/jump-ball/250x142.png",
      path: ["/game/jump-ball", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/4de31de2e65644139a577c8b1e95ff2e/?gd_sdk_referrer_url=https://playcutegames.com/game/jump-ball",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Jump Ball Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Om Nom Bounce. Remember ‘Om Nom’ from the Cut the Rope mobile games? Our little green friend rolled into a new adventure in ‘On Nom Bounce.’ He faces some fierce enemies and bosses which can be eliminated by shooting candy right at them! The aim, shoot and bounce your way through 40 challenging levels and 4 different environments. Upgrade your abilities along the way to defeat even the toughest enemies!
       Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup.
       Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Cutedressup has a cute collection of dress-up and makeup games for girls. Play them and have fun!`,
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Om Nom Bounce - playcutegames.com",
      name: ["Om Nom Bounce", "om-nom-bounce"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/om-nom-bounce/250x142.png",
      path: ["/game/om-nom-bounce", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/c60593d7d3434def9ac80eed7f315208/?gd_sdk_referrer_url=https://playcutegames.com/game/om-nom-bounce",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "om-nom-bounce Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Om Nom Bounce. Remember ‘Om Nom’ from the Cut the Rope mobile games? Our little green friend rolled into a new adventure in ‘On Nom Bounce.’ He faces some fierce enemies and bosses which can be eliminated by shooting candy right at them! The aim, shoot and bounce your way through 40 challenging levels and 4 different environments. Upgrade your abilities along the way to defeat even the toughest enemies!
       Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup.
       Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Cutedressup has a cute collection of dress-up and makeup games for girls. Play them and have fun!`,
      tag: ["Puzzle","Girl games","Poki"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "crazygames",
     
      orientation: 0,
      title: "Friday Night Funkin game - playcutegames.com",
      name: ["Friday Night Funkin", "friday-night-funkin"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/friday-night-funkin/250x142.png",
      path: ["/game/friday-night-funkin", "https://playcutegames.com"],
      iframe:
        "https://v6p9d9t4.ssl.hwcdn.net/html/2876359-359162/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Friday Night Funkin Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Friday Night Funkin’ is a musical rhythm game where you compete in freestyle music battles. Press the arrow keys in time with the music to outdo your opponents and enjoy the cool beats!",
      tag: ["Friday Night Funkin","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"pc",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFFs Grunge Minimalist Fashion - playcutegames.com",
      name: ["BFFs Grunge Minimalist Fashion", "bffs-grunge-minimalist-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-grunge-minimalist-fashion/250x142.png",
      path: ["/game/bffs-grunge-minimalist-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-grunge-minimalist-fashion/",
      Walkthrough: "https://www.youtube.com/embed/mwqvKFzf9GE",
      s_dis:
        "BFFs Grunge Minimalist Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to BFFs Grunge Minimalist Fashion. The grunge look is a style based on the grunge music scene and these BFFs absolutely love it! The girls are always ready for a new fashion challenge and today they are exploring the grunge style! Are you ready to join in and help them find the perfect outfit?
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,    tag: ["LOL Surprise","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Fruit Ninja - playcutegames.com",
      name: ["Fruit Ninja", "fruit-ninja"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fruit-ninja/250x142.png",
      path: ["/game/fruit-ninja", "https://playcutegames.com"],
      iframe:
        "https://html5.distributegames.com/lrllu2q2wli15xal5rlmnkiguxqxj6s5/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Fruit Ninja Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Fruit Ninja. Fruit Ninja is an addictive arcade game where you slash fruit to reach a high score. Make sure you avoid slicing bombs, though! This classic game was originally a mobile app and VR title. You’ll be slashing your way through watermelons, pineapples, and more as they fly through the air. Unfortunately, there’s also a few bombs that have been tossed into the mix! Avoid those while you focus on the fruit and try to earn a high-score`,
      tag: ["Action","Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Sand Balls Online - playcutegames.com",
      name: ["Sand Balls Online", "sand-balls-online"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/sand-balls/250x142.png",
      path: ["/game/sand-balls-online", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/4e41d5619e4b490a9f7d12b83b90490d/?gd_sdk_referrer_url=https://playcutegames.com/game/sand-balls-online/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Sand Balls Online Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Sand Balls Online. Make a path for your balls by moving your finger. Avoid and crash obstacles. Get as many balls to the finish line as you can. Use your creativity to dig through the floor, making water reach the underground flower to see her smile again. Try to find the best way to complete each level using your own solution, so be creative and dont be afraid to think out of the box!`,
      tag: ["Action","Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "YAD",
     
      orientation: 1,
      title: "Line Color 3D - playcutegames.com",
      name: ["Line Color 3D", "line-color-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/line-color-3D/250x142.png",
      path: ["/game/line-color-3d", "https://playcutegames.com"],
      iframe:
        "https://www.yad.com/games/Line-Color-3d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Line Color 3D Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Line Color 3D. This is an online Kids game, it’s playable on all smartphones or tablets, such as iPhone, iPad, Samsung, and other Apple and android systems. Line Color 3D is an interesting arcade game. It can test your reflection and help you kill your time. It’s a hard thing to pass all levels, but I believe you can do it! Avoid those obstacles on your road and try to complete more challenges.
         Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup.
         Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Cutedressup has a cute collection of dress-up and makeup games for girls. Play them and have fun!`,
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Undine Match The Pic - playcutegames.com",
      name: ["Undine Match The Pic", "undine-match-the-pic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/undine-match-the-pic/250x142.png",
      path: ["/game/undine-match-the-pic", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1654878306/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Undine Match The Pic Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Undine Match The Pic. In the summer you want to go to the sea, swim and sunbathe! And it’s time to play another fun game. You need to find differences in two almost identical pictures. You will meet cute little mermaids and sea creatures, and train your mindfulness. Go ahead, hurry up before the time runs out. If you can’t find all the differences the first time, don’t be discouraged, try again, and you will definitely succeed!
         Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup.
         Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Cutedressup has a cute collection of dress-up and makeup games for girls. Play them and have fun!`,
      tag: ["Puzzle","Mermaid","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Core Fashion Battle - playcutegames.com",
      name: ["Celebrity Core Fashion Battle", "celebrity-core-fashion-battle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-core-fashion-battle/250x142.png",
      path: ["/game/celebrity-core-fashion-battle", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-core-fashion-battle/",
      Walkthrough: "https://www.youtube.com/embed/WpSXmkgQhhw",
      s_dis:
        "Celebrity Core Fashion Battle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Celebrity Core Fashion Battle. These celebrities want to try a core fashion. Each one of them wants to try a different core style. Help them to select a unique style for each one of them by choosing a fashion card. Then browse the wardrobe and pick cute outfits which shoe their fashion style.",
      tag: ["Celebrity","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Beauty Run Run - playcutegames.com",
      name: ["Beauty Run Run", "beauty-run-run"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/beauty-run-run/250x142.png",
      path: ["/game/beauty-run-run", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/60767897915e4e339f07e769f35a7cb9/?gd_sdk_referrer_url=https://playcutegames.com/game/beauty-run-run",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Beauty Run Run Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to Beauty Run Run. There is no standard for beauty, everyone can be beautiful! In this game, you have the opportunity to try out various professions! Beauty Race is a fun casual game where you can choose the activity you are interested in. You can become a model or a firefighter. Or maybe you want to go skiing or to a water park?
        Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup.
        Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Cutedressup has a cute collection of dress-up and makeup games for girls. Play them and have fun!`,
      tag: ["Puzzle","Girl games","Poki"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Drink Master - playcutegames.com",
      name: ["Drink Master", "drink-master"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/drink-master/250x142.png",
      path: ["/game/drink-master", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/0093d9f049a941c0b9eb7e96146aebf5/?gd_sdk_referrer_url=https://playcutegames.com/games/drink-master",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Drink Master Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Have you always dreamt of working as a bartender? Do you consider perfectly poured cocktails and drinks to be your life goal? Do you always pour beer perfectly into a glass? Then Drink Master is the game for you! Become a Drink Master. Pour the liquor and show everyone that you can create the perfect cocktail! Don't forget to add an ice cube.",
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Aqua Blitz 2 - playcutegames.com",
      name: ["Aqua Blitz 2", "aquablitz-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/aquablitz-2/250x142.png",
      path: ["/game/aquablitz-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/ddfa9c1d2b474b7ab5d0ca26322bb9fd/?gd_sdk_referrer_url=https://playcutegames.com/games/aquablitz-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Aqua Blitz 2 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Aquablitz 2 - the newest iteration of the beloved underwater match-3 game that comes with fresh new graphics, a catchy tune and lots of improvements! Meet your new hostess, Pearl, the little mermaid. She will be pleased to guide you on your adventure through more than 700 splashy levels and teach you all the tricks you need to know to solve even the most complicated puzzles. Complete Daily Missions to earn extra coins and Boosters, or play the Daily Challenge to earn extra stars that might help you unlock treasure chests and gates to new levels. Make sure to come back and play every day to receive your Daily Rewards. Take part in regular Treasure Hunt events to collect sunken pirate treasures. Play Aquablitz 2 now for free and enjoy tons of addictive match-3 challenges.",
      tag: ["Puzzle","Girl games","Mermaid"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Influencers Aesthetic Fashion Challenge - playcutegames.com",
      name: ["Influencers Aesthetic Fashion Challenge", "influencers-aesthetic-fashion-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/influencers-aesthetic-fashion-challenge/250x142.png",
      path: ["/game/influencers-aesthetic-fashion-challenge", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/influencers-aesthetic-fashion-challenge/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Influencers Aesthetic Fashion Challenge Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to the Influencers Aesthetic Fashion Challenge. Omg, the BFFs are going to participate in a new Influencer challenge. Complete the Look is the latest trend and you will have to pick cute makeup and a fabulous outfit for them. Pick a unique costume for all the girls. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Barbie","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Jewels Blitz 5 - playcutegames.com",
      name: ["Jewels Blitz 5", "jewels-blitz-5"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/jewels-blitz-5/250x142.png",
      path: ["/game/jewels-blitz-5", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/0dd92ae2a7214f289608049167f2ebd4/?gd_sdk_referrer_url=https://playcutegames.com/games/jewels-blitz-5",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Jewels Blitz 5 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Jewels Blitz 5, the long-awaited 5th installment of the legendary Match 3 game that takes you deep into the mystery of ancient cultures of Central America. You will explore Mayan Temples and try to solve their secrets and mysteries. Enjoy hundreds of levels, Daily Missions and Weekly Challenges. Earn gold and magical items!",
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Pinafore Fashion - playcutegames.com",
      name: ["BFFs Pinafore Fashion", "bffs-pinafore-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-pinafore-fashion/250x142.png",
      path: ["/game/bffs-pinafore-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-pinafore-fashion/",
      Walkthrough: "https://www.youtube.com/embed/1RuyAuKAfWc",
      s_dis:
        "BFFs Pinafore Fashion Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the BFFs Pinafore Fashion. Bffs planned to try new fashion with their old collection in their wardrobe. Yes! its Pinafore. Help them to put together a cute pinafore dress by first choosing a shape for the dress. Then decide if the dress should be in a single color or in one of the pre-made prints. Then you have to choose if the fabric of the dress should have a texture and add-ons like a zipper or buttons. After you’ve designed the dress, it’s time to dress up and style the model!",
      tag: ["LOL Surprise","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Crazy Games",
     
      orientation: 0,
      title: " Cups Water Sort Puzzle - playcutegames.com",
      name: [" Cups Water Sort Puzzle", "cups-water-sort-puzzle"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cups-water-sort-puzzle/250x142.png",
      path: ["/game/cups-water-sort-puzzle", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/cups---water-sort-puzzle/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Cups Water Sort Puzzle Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Cups – Water Sort Puzzle is a fun puzzle game where you sort colored liquids into glass cups. Sort all the colors neatly together to move to the next level! Move the colors from one container to the next until each glass is filled to the top with one color. You can only pour liquid into another glass if it is the same color.",
      tag: ["Puzzle","Crazy Games","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Crazy Games",
     
      orientation: 0,
      title: "Funny Heroes Emergency - playcutegames.com",
      name: ["Funny Heroes Emergency", "funny-heroes-emergency"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/funny-heroes-emergency/250x142.png",
      path: ["/game/funny-heroes-emergency", "https://playcutegames.com"],
      iframe:
        "https://games.crazygames.com/en_US/funny-heroes-emergency/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Funny Heroes Emergency Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Funny Heroes Emergency is a dress-up game to makeover the heroes after their fight to save the world. Take care of all of their injuries to get them back into fighting! Many heroes after returning home after fights with criminals look very bad. Today in the game Funny Heroes Emergency you will have to help them clean up.",
      tag: ["Doctor","Crazy Games","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Influencers Girly Vs Tomboy - playcutegames.com",
      name: ["Influencers Girly Vs Tomboy", "influencers-girly-vs-tomboy"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/influencers-girly-vs-tomboy/250x142.png",
      path: ["/game/influencers-girly-vs-tomboy", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/influencers-girly-vs-tomboy/",
      Walkthrough: "https://www.youtube.com/embed/J7kEGLn0yr0",
      s_dis:
        "Influencers Girly Vs Tomboy Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to the Influencers Girly Vs Tomboy. What should these celebrities choose today? A cute, girly-girl outfit or maybe a tomboy one? It’s going to be your task to help them out decide on that! Join them in getting this amazing dress-up game started, run your fingers through their amazing wardrobe, and help them to decide on the style that best suits their mood today.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Celebrity","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Yummy Donut Factory - playcutegames.com",
      name: ["Yummy Donut Factory", "yummy-donut-factory"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/yummy-donut-factory/250x142.png",
      path: ["/game/yummy-donut-factory", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/d2d01e50efef4117895d07f85785bcd5/?gd_sdk_referrer_url=https://playcutegames.com/games/yummy-donut-factory",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Yummy Donut Factory Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Yummy Donut Factory. Let’s be a donut chef! Mix all ingredients by following the receipt to make the dough. Create different shapes and bake the donuts. Decorate and throw on some sweet goodies, such as candies, sprinkles, frosting, and many more. You’ll be the best donut baker ever!",
      tag: ["Cooking","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 1,
      title: "BFFs Flowers Inspired Fashion - playcutegames.com",
      name: ["BFFs Flowers Inspired Fashion", "bffs-flowers-inspired-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-flowers-inspired-fashion/250x142.png",
      path: ["/game/bffs-flowers-inspired-fashion", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-flowers-inspired-fashion/",
      Walkthrough: "https://www.youtube.com/embed/zUnsVCiPiho",
      s_dis:
        "Welcome to the BFFs Flowers Inspired Fashion. hese Bffs are true trendsetters, who love to enjoy the latest fashion. They love nature and natural styles in fashion.",
      l_dis:
        "Welcome to the BFFs Flowers Inspired Fashion. These Bffs are true trendsetters, who love to enjoy the latest fashion. They love nature and natural styles in fashion. They are obsessed with floral designs. Browse their wardrobe and select their favorite one. Then pick suitable costumes and hairstyles which match their outfits.",
      tag: ["Princess","Dress up","Make Up","Fashion","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Escape Out - playcutegames.com",
      name: ["Escape Out", "escape-out"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/escape-out/250x142.png",
      path: ["/game/escape-out", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/1a8a9cbaed0f4fd58cbaf8a68656b6fa/?gd_sdk_referrer_url=https://playcutegames.com/game/escape-out/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Escape Out Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to Escape Out. The escape adventures have never been so fun. Save yourself and your mates from unexpected traps and tricky obstacles. Dig the right way out and enjoy your freedom. He’s determined to sneak out of his cell and start digging a tunnel while the guard is distracted. He’ll need to work fast in order to get to his escape vehicle in this prison adventure game.",
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      id: 202203001,
      brand: "Y8",
     
      orientation: 1,
      title: "Boat Hitting Out - playcutegames.com",
      name: ["Boat Hitting Out", "boat-hitting-out"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/boat-hitting-out/250x142.png",
      path: ["/game/boat-hitting-out", "https://playcutegames.com"],
      iframe:
        "https://download-cdn21.vigoo.com/boathittingout-download/index.html?key=y8&value=default",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Boat Hitting Out Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Welcome to the Boat Hitting Out. You are on the wooden raft on a sea beach, and the main task is to rescue the stickmen in the water on your raft. In addition to your boat, there will be others of different shapes and sizes who are your rivals. Bring them down, and then collect all those who fell into the water on their raft. The task is to score points due to the number of saved passengers. Do not miss the bonus umbrella.",
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity Social Media Adventure - playcutegames.com",
      name: ["Celebrity Social Media Adventure", "celebrity-social-media-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-social-media-adventure/250x142.png",
      path: ["/game/celebrity-social-media-adventure", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-social-media-adventure-game/",
      Walkthrough: "https://www.youtube.com/embed/Py6zUnrUyu8",
      s_dis:
        "Welcome to the Celebrity Social Media Adventure. Celebrity girls planned to try some adventure fashion over social media. Each one tries different fashions and posts them",
      l_dis:
        `Welcome to the Celebrity Social Media Adventure. Celebrity girls planned to try some adventure fashion over social media. Each one tries different fashions and posts them on social media and finds who trends the most. Sounds fun! right? Help the girls by browsing their wardrobes and picking the suitable costumes in each fashion. And then pick cute accessories and hairstyles.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Celebrity","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Wood Block Puzzle 2 - playcutegames.com",
      name: ["Wood Block Puzzle 2", "wood-block-puzzle-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/wood-block-puzzle-2/250x142.png",
      path: ["/game/wood-block-puzzle-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/cc846d5943ad4f769f22e87f7d00b14d/?gd_sdk_referrer_url=https://playcutegames.com/game/funny-kitty-care",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "The second sequel to the most addictive puzzle game of all-time! This puzzle game will exercise your brain for hours, days and months to come. It boasts a beautiful",
      l_dis:
        "The second sequel to the most addictive puzzle game of all-time! This puzzle game will exercise your brain for hours, days and months to come. It boasts a beautiful wooden design with carefully crafted puzzle pieces and a game board. Are you ready to become a master?",
      tag: ["Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Funny Kitty Care - playcutegames.com",
      name: ["Funny Kitty Care", "funny-kitty-care"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/funny-kitty-care/250x142.png",
      path: ["/game/funny-kitty-care", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/d4736455671a43afae19e231ac08332e/?gd_sdk_referrer_url=https://playcutegames.com/game/funny-kitty-care",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Meow! Let's take care of an adorable stray kitty! You play as the rescuer who save a cute little kitty. Clean its dirty fur and treat the wounds until the kitty",
      l_dis:
        "Meow! Let's take care of an adorable stray kitty! You play as the rescuer who save a cute little kitty. Clean its dirty fur and treat the wounds until the kitty is completely healthy. Try dressing your kitty with various cute clothes and accessories. Enjoy Funny Kitty Care!",
      tag: ["Doctor","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 1,
      title: "Pop It Master - playcutegames.com",
      name: ["Pop It Master", "pop-it-master"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pop-it-master/250x142.png",
      path: ["/game/pop-it-master", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/pop-it-master/",
      Walkthrough: "https://www.youtube.com/embed/xWBH7Diyw2Y",
      s_dis:
        "Pop It Master is a relaxing online puzzle game. The game is based on the famous Pop It fidget toys. In this game, your objective is to press the popits to pop them and unlock",
      l_dis:
        "Pop It Master is a relaxing online puzzle game. The game is based on the famous Pop It fidget toys. In this game, your objective is to press the popits to pop them and unlock a colorful toy. Pay attention to pop every bubble and leave nothing behind! Play and have fun!",
      tag: ["Puzzle","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    // Add here 
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "My Dolphin Show 9 - playcutegames.com",
      name: ["My Dolphin Show 9", "my-dolphin-show-9"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-9/250x142.png",
      path: ["/game/my-dolphin-show-9", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/a06295d0201144198cc53faf85505e52/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-9",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 9 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `
        This gorgeous facility was inspired by the art and culture of several Asian nations. Team up with your dolphin and wow the crowd with some really impressive flips and other tricks in this installment of the popular My Dolphin Show series. You’ll earn tons of coins you can trade in for waterproof kimonos, mawashis, and other really cool outfits!`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `PRESS THE LEFT AND RIGHT ARROWS to swim. PRESS THE UP ARROW to rise or leap. PRESS THE DOWN ARROW to dive.`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "My Dolphin Show 8 - playcutegames.com",
      name: ["My Dolphin Show 8", "my-dolphin-show-8"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-8/250x142.png",
      path: ["/game/my-dolphin-show-8", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/e610689a14a64646b4189abe3fc76e3c/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-8",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 8 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Welcome to a paradise of aquatic sea animals! This ever popular show is unstoppable and offers a splashing 126 levels to play in 6 different worlds. Perform amazing tricks like doughnut jumping, splashing the audience and even score a soccer goal! Play various mini-games to keep your dolphin motivated. Are you ready to jump in and dive into a world of fun with your cute dolphin? Endless splashing`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `PRESS THE LEFT AND RIGHT ARROWS to swim. PRESS THE UP ARROW to rise or leap. PRESS THE DOWN ARROW to dive.`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "My Dolphin Show 7 - playcutegames.com",
      name: ["My Dolphin Show 7", "my-dolphin-show-7"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-7/250x142.png",
      path: ["/game/my-dolphin-show-7", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/c0fca7ba3ffe4139afdb9f5ee8bbd584/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-7",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 7 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `The dolphin trainer and her dolphin are ready to impress the audience with a specular show. This is not a small aquarium, but a big show like you would see in Sea World or any other water park. New Jungle World with 18 levels.`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `please use your mouse to play`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "My Dolphin Show 6 - playcutegames.com",
      name: ["My Dolphin Show 6", "my-dolphin-show-6"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-6/250x142.png",
      path: ["/game/my-dolphin-show-6", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/fed6eed89207405d93880eec4c547dbd/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-6",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 6 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `If you are into animal simulation games then try My Dolphin Show 4 available at Play Cute Games where you teach dolphins some impressive tricks to impress an awaiting crowd. Make special performances and travel through bright and beautiful underwater worlds to chase the limelight and demonstrate mind-blowing tricks. From jumping through hoops to splashing in style, every show is your opportunity to entertain. It is also possible to have a change of costume for the dolphins, making it an extra thrill for the crowds. There is always something new to do and explore, and the creativity available will keep you coming back for the fun and enjoyment. Assemble your guests, get your dolphin ready for the performance, and leap into a world of happiness and laughter with My Dolphin Show 4!`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `please use your mouse to play`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "My Dolphin Show 5 - playcutegames.com",
      name: ["My Dolphin Show 5", "my-dolphin-show-5"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-5/250x142.png",
      path: ["/game/my-dolphin-show-5", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/02d11195e64540dcb743722c313fe739/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-5",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 5 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Play cute games has the fifth part of My Dolphin Show where you can actually teach dolphins to perform stunts and entertain the happy audience. Get into the water as you are able to make your dolphins perform other stunts and dress them up in trendy apparels that suits their performances. Every performance is an opportunity to produce special moments on stage, and to ‘wow’ the audience with jumps, flips and dives. Choose from a range of spectacular and colourful stages and underwater worlds, all of which presents varied and entertaining obstacles. The fact is that the more frequently the dancer performs, the more inventive he/she can be with the choreography, thus having a different show every time. The graphics of My Dolphin Show 5 are bright and childish, the gameplay is funny and entertaining, and that is why you should enjoy the Dolphin Show. Prepare yourself to have countless of hours of fun as you work hand in hand with your dolphins to be performers!`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `Arrow to move`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "My Dolphin Show 4 - playcutegames.com",
      name: ["My Dolphin Show 4", "my-dolphin-show-4"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/my-dolphin-show-4/250x142.png",
      path: ["/game/my-dolphin-show-4", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/ff1e3ccf2733433fa5815ab9bb5b4354/?gd_sdk_referrer_url=https://playcutegames.com/game/my-dolphin-show-4",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "My Dolphin Show 4 Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Play My Dolphin Show 4 on Cute Games: teach your dolphins the most spectacular stunts so that the viewers would demand more! Create your own performances that shows off your dolphins style while at the same time incorporating the latest trinkets to make them appear fashionable. Every time there is a new performance, new problems are created and new possibilities to pull out new spectacular stunts. As if the underwater environment is alive and changing, it becomes easier to come up with more different ideas. Perform for the energetic audience as they respond to your dolphin’s stunts making each performance unique entertainment. Welcome to our underwater world, an amazing journey in which you can freely explore and dream!`,
      tag: ["My dolphin show","Animal","Dolphin",],
      hint: `Arrow to move`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Shopaholic Wedding Models - playcutegames.com",
      name: ["Shopaholic: Wedding Models", "shopaholic-wedding-models"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/shopaholic-wedding-models/250x142.png",
      path: ["/game/shopaholic-wedding-models", "https://playcutegames.com"],
      iframe:
        "https://games.cdn.spilcloud.com/1559569463_Shopaholic_Wedding_Models_html5_20150127-https/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Shopaholic Wedding Models Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Get ready to hit the streets of Rio for a great vacation filled with shopping and parties galore!`,
      tag: ["Shopaholic","Dress up","Make Up","Wedding","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Shopaholic: Rio - playcutegames.com",
      name: ["Shopaholic: Rio", "shopaholic-rio"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/shopaholic-rio/250x142.png",
      path: ["/game/shopaholic-rio", "https://playcutegames.com"],
      iframe:
        "https://games.cdn.spilcloud.com/1559567225_shopaholic_rio_mario-https/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Shopaholic: Rio Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `The big wedding is only a few days away. Check out these adorable boutiques and shop for the perfect dress.`,
      tag: ["Shopaholic","Dress up","Wedding","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Shopaholic: Tokyo - playcutegames.com",
      name: ["Shopaholic: Tokyo", "shopaholic-tokyo"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/shopaholic-tokyo/250x142.png",
      path: ["/game/shopaholic-tokyo", "https://playcutegames.com"],
      iframe:
        "https://games.cdn.spilcloud.com/1559568820_Shopaholic_Tokyo_08-04-https/S-T/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Shopaholic: Tokyo Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Hit the streets of Tokyo for an awesome shopping spree but don’t forget about your budget!`,
      tag: ["Shopaholic","Dress up","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Shopaholic: Beach Models - playcutegames.com",
      name: ["Shopaholic: Beach Models", "shopaholic-beach-models"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/shopaholic-beach-models/250x142.png",
      path: ["/game/shopaholic-beach-models", "https://playcutegames.com"],
      iframe:
        "https://www8.agame.com/mirror/html5/s/Shopaholic+Beach+Models/en_US/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
      "Shopaholic: Beach Models Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        `Sun, sand and shops! It’s all here in this fun shopping game...time to find that perfect dress!`,
      tag: ["Shopaholic","Dress up","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
  
  
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Frozen Sisters Dream Wedding - playcutegames.com",
      name: ["Frozen Sisters Dream Wedding", "frozen-sisters-dream-wedding"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/frozen-sisters-dream-wedding/250x142.png",
      path: ["/game/frozen-sisters-dream-wedding", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/frozen-sisters-dream-wedding/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Frozen Sisters Dream Wedding. Princesses are more excited about their dream day with their beloved ones. Yes, It’s their wedding. Help them to get ready for this occasion",
      l_dis:
        `Welcome to the Frozen Sisters Dream Wedding. Princesses are more excited about their dream day with their beloved ones. Yes, It’s their wedding. Help them to get ready for this occasion. And make this occasion a more memorable one in her life! Browse their wardrobe and pick suitable outfits and costumes. Help them to decorate the cake and invitation card. Join them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess","Dress up","Make Up","Wedding","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Hawaii Match 3 - playcutegames.com",
      name: ["Hawaii Match 3", "hawaii-match-3"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/hawaii-match-3/250x142.png",
      path: ["/game/hawaii-match-3", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/24529a72b4d34329befd83c4352c6ddc/?gd_sdk_referrer_url=https://playcutegames.com/game/hawaii-match-3",
      Walkthrough: "https://www.youtube.com/embed/S4C1vaL1BmY",
      s_dis:
        "This beautiful Match 3 Game takes you on a journey through the beautiful nature of Hawaii. Combine and collect exotic fruits and flowers in over 2000+ Exciting Levels!",
      l_dis:
        "This beautiful Match 3 Game takes you on a journey through the beautiful nature of Hawaii. Combine and collect exotic fruits and flowers in over 2000+ Exciting Levels! Complete the Daily Missions and Daily Challenges to earn additional coins and other gifts!",
      tag: ["Princess","Dress up","Make Up","Wedding","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity Foodie Style - playcutegames.com",
      name: ["Celebrity Foodie Style", "celebrity-foodie-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-foodie-style/250x142.png",
      path: ["/game/celebrity-foodie-style", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-foodie-style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Celebrity Foodie Style. These Celebrity girls are more obsessed with both food and fashion. They planned to try this food and fashion in new trends.",
      l_dis:
        `Welcome to the Celebrity Foodie Style. These Celebrity girls are more obsessed with both food and fashion. They planned to try this food and fashion in new trends. Yes! It’s a foodie fashion. Browse the wardrobe and pick a suitable costume for each girl. Give a unique costume for them and prepare them to show their stunning look.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Celebrity","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Bubble Shooter Pro 2 - playcutegames.com",
      name: ["Bubble Shooter Pro 2", "bubble-shooter-pro-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bubble-shooter-pro-2/250x142.png",
      path: ["/game/bubble-shooter-pro-2", "https://playcutegames.com"],
      iframe:"https://html5.gamedistribution.com/4083ecb5efb64504a7904361c7def2ab/?gd_sdk_referrer_url=https://playcutegames.com/game/bubble-shooter-pro-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Bubble Shooter Pro 2 is the highly anticipated sequel to one of the most successful casual games of all time!! More bubbles, more boosters, brand new design and endless",
      l_dis:
        "Bubble Shooter Pro 2 is the highly anticipated sequel to one of the most successful casual games of all time!! More bubbles, more boosters, brand new design and endless hours of fun!",
      tag: ["Shooting","Puzzle","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Beautician Princess - playcutegames.com",
      name: ["Beautician Princess", "beautician-princess"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/beautician-princess/250x142.png",
      path: ["/game/beautician-princess", "https://playcutegames.com"],
      iframe:
        "https://www.dl-girls.com/content/folder_1651246142/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Beautician Princess game. Modern technologies and materials make our lives better. Test yourself as a beautician.",
      l_dis:
        "Welcome to the Beautician Princess game. Modern technologies and materials make our lives better. Test yourself as a beautician. Help our beautiful princess to become even more beautiful. Get rid of dark circles under the eyes with hyaluronic acid. Straighten brow line. Make a tattoo of arrows near the eyes. In addition, make your lips a little bigger. Complete the look with light makeup and accessories!",
      tag: ["Princess","Dress up","Fashion","Dl-Girls","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Burger Mania - playcutegames.com",
      name: ["Burger Mania", "burger-mania"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/burger-mania/250x142.png",
      path: ["/game/burger-mania", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/burger-mania/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Burger Mania. Are you ready to build an incredibly tasty burger? Can you make tasty meals for your customers? They’ll have high expectations",
      l_dis:
        "Welcome to the Burger Mania. Are you ready to build an incredibly tasty burger? Can you make tasty meals for your customers? They’ll have high expectations in this restaurant game. Keep piling the toppings as high as they’ll go until it collapses under the weight of that mighty burger party!",
      tag: ["Celebrity","Cooking","Exclusive",""],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Sisters Ice Vs Flame - playcutegames.com",
      name: ["Sisters Ice Vs Flame", "sisters-ice-vs-flame"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/sisters-ice-vs-flame/250x142.png",
      path: ["/game/sisters-ice-vs-flame", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/sisters-ice-vs-flame/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Sisters Ice Vs Flame. Princesses have a debate on Ice Vs Flames fashion trends. Help the girls and have fun!",
      l_dis:
        `Welcome to the Sisters Ice Vs Flame. Princesses have a debate on Ice Vs Flames fashion trends. So they planned to try both and compete with each other with their stunning look. Help the princesses by browsing their wardrobes and picking a suitable outfit for each one of them. Try different hairstyles and accessories. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess","Dress up","Make Up","Fashion","Exclusive","Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Spring Nail Art - playcutegames.com",
      name: ["Spring Nail Art", "spring-nail-art"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/spring-nail-art/250x142.png",
      path: ["/game/spring-nail-art", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1650642171/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Spring Nail Art. The long-awaited spring has arrived! Time to freshen up your look. Use the latest fashion trends and make a fabulous manicure. You can use",
      l_dis:
        "Welcome to Spring Nail Art. The long-awaited spring has arrived! Time to freshen up your look. Use the latest fashion trends and make a fabulous manicure. You can use a solid color or a fun spring pattern. Apply Mehendi to your hand and decorate it with rings and bracelets! Have fun!",
      tag: ["LOL Surprise","Decoration","Spring","Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Ellie Fashion Fever - playcutegames.com",
      name: ["Ellie Fashion Fever", "ellie-fashion-fever"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-fashion-fever/250x142.png",
      path: ["/game/ellie-fashion-fever", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/ellie-fashion-fever/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Ellie Fashion Fever. Ellie is a supermodel who is inspired more by the latest fashion trends. She tired of trying old fashion and plan try something unique.",
      l_dis:
        "Welcome to Ellie Fashion Fever. Ellie is a supermodel who is inspired more by the latest fashion trends. She tired of trying old fashion and plan try something unique. Help her to try different trends by browsing her wardrobe and picking cute outfits in each fashion style. And don’t forget to try different hairstyles in each fashion.",
      tag: ["Barbie","Dress up","Make Up","Fashion","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Funny Kitty Haircut - playcutegames.com",
      name: ["Funny Kitty Haircut", "funny-kitty-haircut"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/funny-kitty-haircut/250x142.png",
      path: ["/game/funny-kitty-haircut", "https://playcutegames.com"],
      iframe: "https://html5.gamedistribution.com/79bd31d3b0124a7ca4ade79058f82d40/?gd_sdk_referrer_url=https://playcutegames.com/game/funny-kitty-haircut",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Funny Kitty Haircut. Have you ever dreamed of becoming the best hairdresser in your own hair salon? Help the kitties in this new haircut game! Run the best",
      l_dis:
        "Welcome to Funny Kitty Haircut. Have you ever dreamed of becoming the best hairdresser in your own hair salon? Help the kitties in this new haircut game! Run the best kitty’s salon and take care of your virtual kitties. Wash, color, and brush their hair, then create beautiful hairstyles. Don’t forget to dress up and accessorize them to become more fashionable!",
      tag: ["Girl games","Make Up","Hairstyle"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Design With Me SuperHero Tutu Outfits - playcutegames.com",
      name: ["Design With Me SuperHero Tutu Outfits", "design-with-me-superhero-tutu-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/design-with-me-superhero-tutu-outfits/250x142.png",
      path: ["/game/design-with-me-superhero-tutu-outfits", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/design-with-me-superhero-tutu-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Design With Me SuperHero Tutu Outfits. We all love superheroes very much! Likewise, our favorite princesses are inspired more by superhero tutu fashion.",
      l_dis:
        `Welcome to Design With Me SuperHero Tutu Outfits. We all love superheroes very much! Likewise, our favorite princesses are inspired more by superhero tutu fashion. They planned to organize the fashion events under this theme. Join and help each princess to pick a suitable superhero tutu outfit. Play them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess","Dress up","Make Up","Fashion","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Yiv",
     
      orientation: 1,
      title: "Tiles Hop - playcutegames.com",
      name: ["Tiles Hop", "tiles-hop"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/tiles-hop/250x142.png",
      path: ["/game/tiles-hop", "https://playcutegames.com"],
      iframe: "https://www.yiv.com/games/Tiles-Hop/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Tiles Hop Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
      `Tiles Hop
      Tiles Hop is an online HTML5 game presented by playcutegames.com, it's playable in browsers such as safari and chrome. You can play the game on smartphone and tablet (iPhone, iPad, Samsung, Android devices and Windows Phone). Heard About Tiles Hop? With Tiles Hop, you can play various types of music, from beautiful Piano, Guitar songs to Rock and EDM masterpieces. Tiles Hop will be your best EDM game to relax.`,
      tag: ["Clicker","Arcade","Puzzle","Skill","Crazy games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Roller Ski Queen - playcutegames.com",
      name: ["Roller Ski Queen", "roller-ski-queen"],
      thumb: "https://cdn.cutedressup.in/games/cutedressup_new/roller-ski-queen/250x142.png",
      path: ["/game/roller-ski-queen", "https://playcutegames.com"],
      iframe: "https://html5.gamedistribution.com/df68abf5b8fa4a26942a9035e8cb5668/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Roller Ski Queen Want to play? Play this game online for free on playcutegames in fullscreen. Lots of fun to play when bored at home or at school. Play and have fun!",
      l_dis:
        "Become the most dazzling skating queen! Roller Ski Queen is an excellent ice skating game. Collect sharp blades to destroy obstacles and use coins to buy nice ski clothes! Are you ready to become the brightest star on the field? Come and try it out! Good luck and have fun!",
      tag: ["Clicker","Girl games","Arcade","Running","Hypercasual"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Enchantment - playcutegames.com",
      name: ["Enchantment", "enchantment"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/enchantment/250x142.png",
      path: ["/game/enchantment", "https://playcutegames.com"],
      iframe:
        "https://dl-girls.com/content/folder_1650039357/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Enchantment. Help Mira put her face in order. Eliminate dark circles under the eyes, even out the tone of the face and the line of the eyebrows.Play and have fun",
      l_dis:
        "Welcome to Enchantment. Help Mira put her face in order. Eliminate dark circles under the eyes, even out the tone of the face and the line of the eyebrows. Pick a cute hairstyle and accessories. Then choose a bright traditional or modern outfit for Mira. Now our heroine is charm itself! Hurry up and play this wonderful game.",
      tag: ["LOL Surprise","Dress up","Make Up","Fashion","Spring","Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "LOL Surprise Fresh Spring Look - playcutegames.com",
      name: ["LOL Surprise Fresh Spring Look", "lol-surprise-fresh-spring-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lol-surprise-fresh-spring-look/250x142.png",
      path: ["/game/lol-surprise-fresh-spring-look", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/lol-surprise-fresh-spring-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the LOL Surprise Fresh Spring Look.  LOL Suprise Girls have some plans for welcoming their favorite season spring. They already filled their cupboard",
      l_dis:
        `Welcome to the LOL Surprise Fresh Spring Look.  LOL Suprise Girls have some plans for welcoming their favorite season spring. They already filled their cupboard with new spring fashion. But they have a dilemma is to choose the best outfits. Browse the wardrobe and pick the suitable outfits for each one.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["LOL Surprise","Dress up","Make Up","Fashion","Spring","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Princess Villains - playcutegames.com",
      name: ["Princess Villains", "princess-villains"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-villains/250x142.png",
      path: ["/game/princess-villains", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1649431796/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Villains. Let’s fantasize a little and imagine our favorite princesses in the form of fabulous villains. Evil queens love dark colors.",
      l_dis:
        "Welcome to the Princess Villains. Let’s fantasize a little and imagine our favorite princesses in the form of fabulous villains. Evil queens love dark colors. Pick up luxurious outfits with gold and red decorations for the princesses. Makeup villains are bright and dark! Create the image of a vamp lady. Do not forget about the main accessories of the villains – magic staves, potions, magic wands, wings, and a cloak. Look at the usual things from a different angle, playing the game",
      tag: ["Princess","Dress up", "Make Up", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Princess Girls Steampunk Rivalry - playcutegames.com",
      name: ["Princess Girls Steampunk Rivalry", "princess-girls-steampunk-rivalry"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-girls-steampunk-rivalry/250x142.png",
      path: ["/game/princess-girls-steampunk-rivalry", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/princess-girls-steampunk-rivalry/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Girls Steampunk Rivalry. Princess Anna, Elsa, Jasmine, Ariel, Moana, and Snow White are getting ready to attend a fun-themed party. Play and have fun!",
      l_dis:
        "Welcome to the Princess Girls Steampunk Rivalry. Princess Anna, Elsa, Jasmine, Ariel, Moana, and Snow White are getting ready to attend a fun-themed party and they need your expert helping hand to properly get ready for it. The theme is steampunk and only you can help them slay some statement looks – are you ready to begin the fun? Come and join the Disney girls and have fun!",
      tag: ["Princess","Dress up","Make Up","Fashion","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Happy Easter - playcutegames.com",
      name: ["Princess Happy Easter", "princess-happy-easter"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-happy-easter/250x142.png",
      path: ["/game/princess-happy-easter", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_happy_easter/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Happy Easter game. Princess Elsa, Anna, and Ariel are best friends. After a long time, they met each other and planned to organize a get-together party for Easter.",
      l_dis:
        `Welcome to the Princess Happy Easter game. Princess Elsa, Anna, and Ariel are best friends. After a long time, they met each other and planned to organize a get-together party for Easter. They plan to try cute outfits and decorate the cookies with their own style to make this easter most memorable one. Join and help the princess with your ideas. Start with Elsa’s makeup, Help her pick cute pink shades of lipsticks and blushes. And give some purple or pink color of eyeshadows. Then browse her wardrobe and pick cute outfits and hairstyles with matching accessories. Do the same for other princesses. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Easter",
        "Anna",
        "Elsa",
        "Ariel",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Easter Sunday - playcutegames.com",
      name: ["Princess Easter Sunday", "princess-easter-sunday"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-easter-sunday/250x142.png",
      path: ["/game/princess-easter-sunday", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_easter_sunday/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Princess Easter Sunday is the most awaited celebration weekend in spring. The princesses have planned to build some awesome easter eggs and decorate them to look great.  And of course, we will have easter special dress up and style to excite the princesses & make them look great.",
      l_dis:
        `Princess Easter Sunday is the most awaited celebration weekend in spring. The princesses have planned to build some awesome easter eggs and decorate them to look great.  And of course, we will have easter special dress up and style to excite the princesses & make them look great. Hope you have a lot of fun this season with the princesses.Before start decorating their eggs. They need to choose the perfect costume for this celebration. Help each princess with the dress-up part, try some cool outfits and accessories for each princess. And try different hairstyles for each one.Finally, help them to decorate their eggs on unique styles. In egg decoration part we can color for an egg, add some pattern. And also we add some hats and art in their eggs. So please help the princess and make this easter the most memorable one in their life.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Easter",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bffs Spring Break Fashionista - playcutegames.com",
      name: ["Bffs Spring Break Fashionista", "bffs-spring-break-fashionista"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-spring-break-fashionista/250x142.png",
      path: ["/game/bffs-spring-break-fashionista", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/bffs-spring-break-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bffs Spring Break Fashionista. Spring break is here. Barbie and their friends are can’t wait to have some fun together. Spring break since the beginning",
      l_dis:
        `Welcome to the Bffs Spring Break Fashionista. Spring break is here. Barbie and their friends are can’t wait to have some fun together. They’ve been waiting for the spring break since the beginning of the year. Help them prepare their spring break outfits! They can finally wear skirts and dresses since it’s really warm outside now, so make sure to create a cute look! Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Barbie","Dress up","Make Up","Fashion","Spring","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Gala Prep - playcutegames.com",
      name: ["Celebrity Gala Prep", "celebrity-gala-prep"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-gala-prep/250x142.png",
      path: ["/game/celebrity-gala-prep", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/celebrity-gala-prep/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Celebrity Gala Prep game. Ariana, Selena, Rihanna, Kendel Jenner, and Taylor Swift are planning a grand party. Did you have any idea of the theme?",
      l_dis:
        "Welcome to the Celebrity Gala Prep game. Ariana, Selena, Rihanna, Kendel Jenner, and Taylor Swift are planning a grand party. Did you have any idea of the theme? Yes, it’s a Gala party! Join and help the girls to choose the best outfit and accessories. Browse their wardrobe and pick a suitable one. Play and have fun!",
      tag: ["Celebrity","Dress up","Make Up","Fashion","Spring","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Disney Girls Spring Blossoms - playcutegames.com",
      name: ["Disney Girls Spring Blossoms", "disney-girls-spring-blossoms"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/disney-girls-spring-blossoms/250x142.png",
      path: ["/game/disney-girls-spring-blossoms", "https://playcutegames.com"],
      iframe:
        "https://games.cutedressup.net/disney-girls-spring-blossoms/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Disney Girls Spring Blossoms. The Season of blossom has just begun. Princesses Anna. Elsa, Rapunzel, and Ariel are eagerly waiting for this season so long.",
      l_dis:
        `Welcome to the Disney Girls Spring Blossoms. The Season of blossom has just begun. Princesses Anna. Elsa, Rapunzel, and Ariel are eagerly waiting for this season so long. They have already renewed their spring wardrobe with cherry blossom collections. Browse the wardrobe and pick the best one for them. Help the girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess","Dress up","Make Up","Fashion","Spring","Exclusive",
        ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Wonderful High Heels 3D - playcutegames.com",
      name: ["Wonderful High Heels 3D", "wonderful-high-heels-3d"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/wonderful-high-heels-3d/250x142.png",
      path: ["/game/wonderful-high-heels-3d", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/2e1b78022f93454793c1980302bd35c6/?gd_sdk_referrer_url=https://playcutegames.com/games/wonderful-high-heels-3d",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "There are so many levels that the difficulty of the game increases as the level goes on, and it is still fun to play and relatively challenging.",
      l_dis:
        "There are so many levels that the difficulty of the game increases as the level goes on, and it is still fun to play and relatively challenging. 2. Break the shackles and conquer more unknown places in the world, enjoy the fun of opening up more mechanisms waiting for you to crack, inside the track will change. 3. When you successfully complete a level, you get a partial reward here, which is great for buying various items.",
      tag: ['Addictive','3D',"GD"],
      hint: 2,
      platform:"both",  },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Merge Dreams - playcutegames.com",
      name: ["Merge Dreams", "merge-dreams"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/merge-dreams/250x142.png",
      path: ["/game/merge-dreams", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/f2aa0eeb79bc46e48a9c67a1b01a0d3e/?gd_sdk_referrer_url=https://playcutegames.com/games/merge-dreams",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Merge & Build a wonderland full of interesting classic characters and stories! You can combine everything into better and more powerful items for your journey. Build & upgrade all kinds of buildings to produce all kinds of useful products.",
      l_dis:
        "Merge & Build a wonderland full of interesting classic characters and stories! You can combine everything into better and more powerful items for your journey. Build & upgrade all kinds of buildings to produce all kinds of useful products. Use these products to complete the orders to earn more Gold and get more experience points. The more experience points, the higher the level you are, and you can unlock more interesting characters, stories, lands, etc.",
      tag: ["Hypercasual",'Merge',"GD"],
      hint: 2,
      platform:"both",  },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 0,
      title: "Icy Purple Head 3 - playcutegames.com",
      name: ["Icy Purple Head 3", "icy-purple-head-3"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/icy-purple-head-3/250x142.png",
      path: ["/game/icy-purple-head-3", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/7514d8c628d14e7287f880a37f3a9ea4/?gd_sdk_referrer_url=https://playcutegames.com/games/icy-purple-head-3",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Icy Purple Head is back. Help post man to deliver parcels in time. Run jump and slide through levels. Unlock fun hats to make Icy loo even cooler! Be careful with the post birds and match the right color to hit them.",
      l_dis:
        "Icy Purple Head is back. Help post man to deliver parcels in time. Run jump and slide through levels. Unlock fun hats to make Icy loo even cooler! Be careful with the post birds and match the right color to hit them.",
      tag: ["Addictive", "Physics", "GD"],
      hint: 2,
      platform:"both",  },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Yummy Tales 2 - playcutegames.com",
      name: ["Yummy Tales 2", "yummy-tales-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/yummy-tales-2/250x142.png",
      path: ["/game/yummy-tales-2", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/629855c7b1d94998885d5af49942fc3c/?gd_sdk_referrer_url=https://playcutegames.com/games/yummy-tales-2",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Yummy Tales 2 is here with its new and amazing match 3 puzzles! Meet Oscar, your best friend and a clever little dog! He'll help you in feeding and caring for your farm animals.",
      l_dis:
        "Yummy Tales 2 is here with its new and amazing match 3 puzzles! Meet Oscar, your best friend and a clever little dog! He'll help you in feeding and caring for your farm animals. To remove them from the board, you must match three or more fruits or vegetables of the same kind. Continue mixing and matching fruits and vegetables until your animals are completely saturated. If you don't finish the level before you run out of moves, you'll have to start over! Play through over 900 different levels of puzzles. Complete Daily Missions and Challenges to earn fantastic rewards and gifts such as coins and boosters to help you beat even the hardest levels. Stop wasting time and play Yummy Tales 2 for free right now!",
      tag: ["Match-3", "Puzzles", "GD","Poki"],
      hint: 2,
      platform:"both",  },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Bubble Fight IO - playcutegames.com",
      name: ["Bubble Fight IO", "bubble-fight-io"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bubble-fight-io/250x142.png",
      path: ["/game/bubble-fight-io", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/1af7162fcd7347c580350c8c34ea5f7b/?gd_sdk_referrer_url=https://playcutegames.com/games/bubble-fight-io",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Bubble Fight IO is an online multiplayer Bubble Shooter game that you can play ANYTIME, ANYWHERE! The bubbles are stacked at the center of the arena and you take turn shooting them down.",
      l_dis:
        "Bubble Fight IO is an online multiplayer Bubble Shooter game that you can play ANYTIME, ANYWHERE! The bubbles are stacked at the center of the arena and you take turn shooting them down. The more bubbles you shoot down, the more damage you inflict to your opponent! Are you our next Bubble Fight IO champion?",
      tag: ["Multiplayer", ".IO", "GD", "Bubbleshooter"],
      hint: 2,
      platform:"both",  },
    {
      id: 202203001,
      brand: "GD",
     
      orientation: 1,
      title: "Sky Roller - playcutegames.com",
      name: ["Sky Roller", "sky-roller-1"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/sky-roller-1/250x142.png",
      path: ["/game/sky-roller-1", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/1f207255de1443d1ade622ad7fb184c3/?gd_sdk_referrer_url=https://playcutegames.com/games/sky-roller-1",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Your goal: to spread your legs so as not to crash into obstacles. Be agile to complete all the levels Get on the ROLLER right now!",
      l_dis:
        "Your goal: to spread your legs so as not to crash into obstacles. Be agile to complete all the levels Get on the ROLLER right now! Swipe your finger left-right to avoid obstacles and reach the finish line quickly! Can you NEVER crash? Is there enough skill? Unique controls - Control your legs and push them apart to choose the right path. Be agile and reach the BONUS level.",
      tag: ["Runner", "GD", "Hypercasual"],
      hint: 1,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Angela Design With Me Winter Sweater - playcutegames.com",
      name: [
        "Angela Design With Me Winter Sweater",
        "angela-design-with-me-winter-sweater",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-design-with-me-winter-sweater/250x142.png",
      path: [
        "/game/angela-design-with-me-winter-sweater",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/angela-design-with-me-winter-sweater",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Angela Design With Me Winter Sweater. At the beginning of the winter, Angela bought and renewed their wardrobe with cute sweaters. Now she tried everything and plan to design her own.",
      l_dis:
        "Welcome to Angela Design With Me Winter Sweater. At the beginning of the winter, Angela bought and renewed their wardrobe with cute sweaters. Now she tried everything and plan to design her own. Join and help Angela to design the better one. First, start choosing the shape of the sweater and then decorate this with beautiful color and pattern. Play and have fun!",
      tag: [
        "Angela",
        "Dress up",
        "Make Up",
        "Winter",
        "Decoration",
        "playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFFs Travelling Vibes - playcutegames.com",
      name: ["BFFs Travelling Vibes", "bffs-travelling-vibes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-travelling-vibes/250x142.png",
      path: ["/game/bffs-travelling-vibes", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-travelling-vibes",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the BFFs Travelling Vibes. Princesses Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are in a travelling mood. But they have a dilemma to choose the destination. Help the girls to find out the perfect location for each one of them.",
      l_dis:
        `Welcome to the BFFs Travelling Vibes. Princesses Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are in a travelling mood. But they have a dilemma to choose the destination. Help the girls to find out the perfect location for each one of them. It’s simple! Just spin the wheel to find the destination. Sounds fun right! Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Homecoming Party - playcutegames.com",
      name: ["BFFs Homecoming Party", "bffs-homecoming-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-homecoming-party/250x142.png",
      path: ["/game/bffs-homecoming-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-homecoming-party",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the BFFs Homecoming Party. After college days, Barbie and their friends are living from different locations. They planned to gather for a gettogether party.",
      l_dis:
        "Welcome to the BFFs Homecoming Party. After college days, Barbie and their friends are living from different locations. They planned to gather for a gettogether party. Help the girls to get ready with stunning outfits and matching accessories. And give some pleasant makeover for the girls. Play and have fun!",
      tag: [
        "Barbie",
        "Dress up",
        "Make Up",
        "party",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202203001,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Perfect Tokyo Street Style - playcutegames.com",
      name: ["Perfect Tokyo Street Style", "perfect-tokyo-street-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/perfect-tokyo-street-style/250x142.png",
      path: ["/game/perfect-tokyo-street-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/perfect-tokyo-street-style",
      Walkthrough: "https://www.youtube.com/embed/dq7qLktSQho",
      s_dis:
        "Welcome to the Perfect Tokyo Street Style. Princess Elsa, Anna, Moana, Ariel, Snow White, and Jasmine are the best friends. They planned to throw a surprise weekend fashion party.",
      l_dis:
        `Welcome to the Perfect Tokyo Street Style. Princess Elsa, Anna, Moana, Ariel, Snow White, and Jasmine are the best friends. They planned to throw a surprise weekend fashion party. Everyone agreed to try Tokyo street style Fashion. join and browse the princesses wardrobe for the best collection of outfits and costumes. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs City Chic Fashion - playcutegames.com",
      name: ["BFFs City Chic Fashion", "bffs-city-chic-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-city-chic-fashion/250x142.png",
      path: ["/game/bffs-city-chic-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-city-chic-fashion",
      Walkthrough: "https://www.youtube.com/embed/iXxD-fenMtk",
      s_dis:
        "Welcome to the BFFs City Chic Fashion. Rainbow Girls Sunny, Skyler, Ruby, and Violet have an idea to try Plus-sized clothing. Yes! Its city chic fashion.",
      l_dis:
        "Welcome to the BFFs City Chic Fashion. Rainbow Girls Sunny, Skyler, Ruby, and Violet have an idea to try Plus-sized clothing. Yes! Its city chic fashion. Browse the wardrobe and find a cute set of outfits for the girls. Join and play these new dress-up games for girls. Have fun!",
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "BFFs Kimono Fashion - playcutegames.com",
      name: ["BFFs Kimono Fashion", "bffs-kimono-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-kimono-fashion/250x142.png",
      path: ["/game/bffs-kimono-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-kimono-fashion",
      Walkthrough: "https://www.youtube.com/embed/6ARcIoGSLr0",
      s_dis:
        "Welcome to BFFs Kimono Fashion. Princesses Anna, Elsa, Moana, Ariel, Snow White, and Jasmine are planning to try Japanese traditional fashion.",
      l_dis:
        "Welcome to BFFs Kimono Fashion. Princesses Anna, Elsa, Moana, Ariel, Snow White, and Jasmine are planning to try Japanese traditional fashion. Yes, it’s Kimino fashion. Girls are so excited to try it out to show their stunning look. Help the girls by browsing their wardrobe and picking suitable costumes for each one. Play and have fun!",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "March Coloring Book - playcutegames.com",
      name: ["March Coloring Book", "march-coloring-book"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/march-coloring-book/250x142.png",
      path: ["/game/march-coloring-book", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1646578242/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the March Coloring Book. Hello, young artist. The best season of the year is approaching - spring.",
      l_dis:
        "Welcome to the March Coloring Book. Hello, young artist. The best season of the year is approaching – spring. This coloring game is dedicated to the first month of spring – March. Flowers and cute animals will not leave anyone indifferent! Enjoy playing this new collection of games with your friends. Have fun!",
      tag: ["Coloring Games", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "LOL Surprise Preppy Fashion - playcutegames.com",
      name: ["LOL Surprise Preppy Fashion", "lol-surprise-preppy-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lol-surprise-preppy-fashion/250x142.png",
      path: ["/game/lol-surprise-preppy-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lol-surprise-preppy-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to LOL Surprise Preppy Fashion. LOL Suprise doll plans to try a new stylish fashion.",
      l_dis:
        "Welcome to LOL Surprise Preppy Fashion. LOL Suprise doll plans to try a new stylish fashion. Yes! It’s preppy fashion. Browse and choose the best set of outfits for them. Show your fashion skill and give them a stunning look.",
      tag: ["LOL Surprise", "Dress up", "Fashion", "PlaycuteGames", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "St Patrick's Day Princess Challenge - playcutegames.com",
      name: [
        "St Patrick's Day Princess Challenge",
        "st-patricks-day-princess-challenge",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/st-patricks-day-princess-challenge/250x142.png",
      path: [
        "/game/st-patricks-day-princess-challenge",
        "https://playcutegames.com",
      ],
      iframe: "https://dressupwho.net/html5/St_Patricks_Day_Princess_Challenge/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the St Patrick’s Day Princess Challenge. St. Patrick’s Day symbolizes the adoption of Christianity in Ireland and is also a celebration of the cultural heritage of Ireland as a whole.",
      l_dis:
        "Welcome to the St Patrick’s Day Princess Challenge. St. Patrick’s Day symbolizes the adoption of Christianity in Ireland and is also a celebration of the cultural heritage of Ireland as a whole. Celebrations on St. Patrick’s Day usually include parades and festivals, performing Kelli dances, and wearing green robes or shamrocks. Three princesses – Tiana, Ariel, and Harley Quinn decided to dress up properly for this holiday and arrange a small challenge. Harley Quinn invites you to learn holiday makeup, Ariel opens a hair salon and Tiana is ready to share a recipe for a festive cocktail.",
      tag: ["Princess", "Dress up", "Fashion", "Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Princess Gypsy Woodstock - playcutegames.com",
      name: ["Princess Gypsy Woodstock", "princess-gypsy-woodstock"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-gypsy-woodstock/250x142.png",
      path: ["/game/princess-gypsy-woodstock", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/princess-gypsy-woodstock/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Gypsy Woodstock. Gypsy Princess Woodstock invited Disney Princess Elsa and her friends to enjoy the beautiful scenery of Latin American countries.",
      l_dis:
        "Welcome to the Princess Gypsy Woodstock. Gypsy Princess Woodstock invited Disney Princess Elsa and her friends to enjoy the beautiful scenery of Latin American countries. They need to prepare some local style clothing. Because of the very hotness near the equator, they chose to have a bohemian and subtropical style. In the style of the long dress, Princess Elsa also brought an acoustic guitar. She hopes to spread Disney’s music to Latin America. Come and choose the right clothes and accessories for the princesses. Don’t forget to bring that acoustic guitar!",
      tag: ["LOL Surprise", "Dress up", "Fashion", "PlaycuteGames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity Love Candy Outfits - playcutegames.com",
      name: ["Celebrity Love Candy Outfits", "celebrity-love-candy-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-love-candy-outfits/250x142.png",
      path: ["/game/celebrity-love-candy-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-love-candy-outfits",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Celebrity Love Candy Outfits. Celebrity girls Ariana Grande, Rihanna, Selena Gomez, Taylor Swift, and Kendell Jenner are best friends.",
      l_dis:
        `Welcome to Celebrity Love Candy Outfits. Celebrity girls Ariana Grande, Rihanna, Selena Gomez, Taylor Swift, and Kendell Jenner are best friends. They usually admire new trends. Now they plan to try candy fashion. Browse the wardrobe for each girl and pick a cute outfit for each one. Make sure everything is unique and different from other ones. Help the girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Celebrity",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Prinxy Dress Up Rewind - playcutegames.com",
      name: ["Prinxy Dress Up Rewind", "prinxy-dress-up-rewind"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/prinxy-dress-up-rewind/250x142.png",
      path: ["/game/prinxy-dress-up-rewind", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/af32f294-b25d-5d25-b4fe-1938b7e80d7d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In the Prinxy Dress Up Rewind, we featured all your favorite games from 2021. It’s been a great year for fashion, and in it, you are going to find a bit of everything.",
      l_dis:
        "In the Prinxy Dress Up Rewind, we featured all your favorite games from 2021. It’s been a great year for fashion, and in it, you are going to find a bit of everything. From the titles inspired by the trending TikTok fashion to holiday-themed games, our exclusive fashion game is going to keep you entertained for hours. Now let’s get it started!",
      tag: ["Barbie", "Dress up", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Princess Runway Fashion Look - playcutegames.com",
      name: ["Princess Runway Fashion Look", "princess-runway-fashion-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-runway-fashion-look/250x142.png",
      path: ["/game/princess-runway-fashion-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-runway-fashion-look",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Runway Fashion Look.  Princesses Anna, Elsa, Moana, Ariel, Snow White, and Jasmine are eagerly waiting for an upcoming fashion event.",
      l_dis:
        "Welcome to the Princess Runway Fashion Look.  Princesses Anna, Elsa, Moana, Ariel, Snow White, and Jasmine are eagerly waiting for an upcoming fashion event. They planned to show their fashion skill with their stunning look to attract everyone. Help the princess browse the wardrobe and pick a perfect outfit for each one of them.",
      tag: ["Princess", "Dress up", "Make Up", "Fashion", "Playcutegames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Bonnie Gucci Vs Prada - playcutegames.com",
      name: ["Bonnie Gucci Vs Prada", "bonnie-gucci-vs-prada"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bonnie-gucci-vs-prada/250x142.png",
      path: ["/game/bonnie-gucci-vs-prada", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/bonnie-gucci-prada/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bonnie Gucci Vs Prada. All you fashionistas gather up! Your sister here is facing a huge fashion dilemma:",
      l_dis:
        "Welcome to the Bonnie Gucci Vs Prada. All you fashionistas gather up! Your sister here is facing a huge fashion dilemma: she really can’t decide which label better fits her style and she could use your expert advice! So come and join her in getting this brand new Bonnie Game for girls started, check out your options and help her decide. The Italians Gucci and Prada are the two luxury brands causing her a hard time, so which one will you pick for her? Before deciding on that, you first have to do her makeup and then you get to browse through Prada’s latest collection and see which items you could use to dress up cute Bonnie with.",
      tag: ["Barbie", "Dress up", "Fashion", "Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Dotted Girl New Era - playcutegames.com",
      name: ["Dotted Girl New Era", "dotted-girl-new-era"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dotted-girl-new-era/250x142.png",
      path: ["/game/dotted-girl-new-era", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1645568804/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Dotted Girl New Era. Meet the SuperHeroine in a new look! Today she is an ordinary girl who lives an everyday life, trying on modern outfits and dresses.",
      l_dis:
        "Welcome to the Dotted Girl New Era. Meet the SuperHeroine in a new look! Today she is an ordinary girl who lives an everyday life, trying on modern outfits and dresses. Tomorrow she is a Superhero in a stylish jumpsuit and mask. Use a combination of different costume elements to create not only a unique hero but also a SuperVillain! In addition, in this wonderful game, you can choose and design a cute helper – kwami. Enjoy the new adventures of Dotted Girl by playing this gameś.",
      tag: ["Marinette", "Dress up", "Fashion", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Celebrity Future Fashion - playcutegames.com",
      name: ["Celebrity Future Fashion", "celebrity-future-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-future-fashion/250x142.png",
      path: ["/game/celebrity-future-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-future-fashion",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Celebrity Future Fashion. Taylor Swift, Kendell Jenner, Rihanna, Ariana Grande, and Selena Gomez are the celebrity plans to try something new fashion.",
      l_dis:
        `Welcome to the Celebrity Future Fashion. Taylor Swift, Kendell Jenner, Rihanna, Ariana Grande, and Selena Gomez are the celebrity plans to try something new fashion. They got an idea to try future fashion. Yes! it’s an upcoming fashion will trend over the fashion industry. But they need some expert advice to pick the right one for them. Play and help the girl!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Celebrity", "Dress up", "Make Up", "Fashion", "Cutedressup"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Tactical Princess - playcutegames.com",
      name: ["Tactical Princess", "tactical-princess"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tactical-princess/250x142.png",
      path: ["/game/tactical-princess", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1645116636/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Tactical Princess. A holiday dedicated to the defenders of the Fatherland is approaching. The princesses decided to celebrate it with a paintball match.",
      l_dis:
        "Welcome to the Tactical Princess. A holiday dedicated to the defenders of the Fatherland is approaching. The princesses decided to celebrate it with a paintball match. Train your memory and help the princesses collect equipment for the tournament. Which of the girls will become the most stylish tactical princess?",
      tag: ["Princess", "Dress up", "Fashion", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "playcutegames",
     
      orientation: 0,
      title: "LOL Surprise Insta Party Divas - playcutegames.com",
      name: ["LOL Surprise Insta Party Divas", "lol-surprise-insta-party-divas"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lol-surprise-insta-party-divas/250x142.png",
      path: [
        "/game/lol-surprise-insta-party-divas",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/lol-surprise-insta-party-divas",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the LOL Surprise Insta Party Divas. LOL girls heard about the surprise Insta party. They planned to try some new trends.",
      l_dis:
        "Welcome to the LOL Surprise Insta Party Divas. LOL girls heard about the surprise Insta party. They planned to try some new trends. But they need some hands to be perfect. Browse the girl’s wardrobe for perfect outfits and costumes. Before that pick some cute makeover for the girls. Play and have fun! ",
      tag: ["LOL Surprise", "Dress up", "Make Up", "Fashion", "Playcutegames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFFs Trendy Squad Fashion - playcutegames.com",
      name: ["BFFs Trendy Squad Fashion", "bffs-trendy-squad-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-trendy-squad-fashion/250x142.png",
      path: ["/game/bffs-trendy-squad-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-trendy-squad-fashion",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the BFFs Trendy Squad Fashion.  Barbie and her friends have a discussion on the weekend plan. And they come up with an idea to get ready for an upcoming fashion event.",
      l_dis:
        `Welcome to the BFFs Trendy Squad Fashion.  Barbie and her friends have a discussion on the weekend plan. And they come up with an idea to get ready for an upcoming fashion event. It’s a “Squad Fashion”. They all get excited to be show their fashion skill. Join and help the girls in preparation. Have fun! 
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Barbie", "Dress up", "Make Up", "Fashion", "Cutedressup"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Your Favorite Royal Couple - playcutegames.com",
      name: ["Your Favorite Royal Couple", "your-favorite-royal-couple"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/your-favorite-royal-couple/250x142.png",
      path: ["/game/your-favorite-royal-couple", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/YourFavoriteRoyalCouple/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Your Favorite Royal Couple Choose your pair of favorites for Valentine’s Day! Who will it be? Maybe a wonderful pair of wizards – Princess Eliza and Jack Frost?",
      l_dis:
        "Your Favorite Royal Couple Choose your pair of favorites for Valentine’s Day! Who will it be? Maybe a wonderful pair of wizards – Princess Eliza and Jack Frost? Or the enamored from distant Agroba – an oriental princess and Prince Ali? Or maybe the rulers of great sea power – Prince Eric and the Little Mermaid? Rather, prepare the participants for the fashion show. Choose luxurious dresses for princesses and gorgeous outfits for princes. Makeup, hair, and accessories complete the look. Join with Your Favorite Royal Couple and have fun!",
      tag: ["Dress up", "Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Romantic Valentine Story - playcutegames.com",
      name: ["My Romantic Valentine Story", "my-romantic-valentine-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-romantic-valentine-story/250x142.png",
      path: ["/game/my-romantic-valentine-story", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-romantic-valentine-story",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Romantic Valentine Story game. Barbie’s family went out for a marriage function and they will return after two days. She was alone at home.",
      l_dis:
        "Welcome to the My Romantic Valentine Story game. Barbie’s family went out for a marriage function and they will return after two days. She was alone at home. So she invited Ken to her house and have a surprise plan for him. But the barbie house looks so messy. She needs some hands to clean before Ken arrives. Play and have fun!",
      tag: [
        "Barbie",
        "Dress up",
        "Make Up",
        "Cleaning",
        "Decoration",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Angela Perfect Valentine - playcutegames.com",
      name: ["Angela Perfect Valentine", "angela-perfect-valentine"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-perfect-valentine/230x131.png",
      path: ["/game/angela-perfect-valentine", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/angela-perfect-valentine/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Angela Perfect Valentine game. Angela and Tom love to spend time together on this valentine. They plan to wear new clothes and post them on social media.",
      l_dis:
        `Welcome to the Angela Perfect Valentine game. Angela and Tom love to spend time together on this valentine. They plan to wear new clothes and post them on social media. Tom has a surprise plan for Angela. Browse their wardrobe for perfect clothes. Play and help the couples. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Angela", "Dress up", "Decoration", "cutedressup","Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Marie Prepares Treat for Valentine's Day - playcutegames.com",
      name: [
        "Marie Prepares Treat for Valentine's Day",
        "marie-prepares-treat-for-valentines-day",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/marie-prepares-treat-for-valentines-day/250x142.png",
      path: [
        "/game/marie-prepares-treat-for-valentines-day",
        "https://playcutegames.com",
      ],
      iframe: "https://dl-girls.com/content/folder_1644512126/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Marie Prepares Treat for Valentine’s Day. Princess Marie loves to cook. Holidays are approaching and the girl decided to cook her boyfriend a delicious surprise.",
      l_dis:
        "Welcome to the Marie Prepares Treat for Valentine’s Day. Princess Marie loves to cook. Holidays are approaching and the girl decided to cook her boyfriend a delicious surprise. Join us and find out the recipe for delicious handmade chocolates. Use different types of chocolate: dark, white, and milk. Add nuts and a lot of culinary decorations: sprinkles, fruit, lollipops, and much more! Then comes the turn of baking. Bake cupcakes. Decorate them with cream, fruit, and sprinkles. It remains to choose a festive dress and a cocktail. The threat is ready!",
      tag: ["Dress up", "Cooking", "Decoration", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Fairies Heart Style - playcutegames.com",
      name: ["Fairies Heart Style", "fairies-heart-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fairies-heart-style/250x142.png",
      path: ["/game/fairies-heart-style", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1643819167/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Fairies Heart Style. Valentine’s Day is coming! Two BFF’s decided to have a festive challenge and dress up as cupid fairies.",
      l_dis:
        "Welcome to Fairies Heart Style. Valentine’s Day is coming! Two BFF’s decided to have a festive challenge and dress up as cupid fairies. Join the girls and help them choose light, airy outfits, luxurious fabulous dresses, heart-themed accessories, incredible hairstyles and charming makeup. A wide variety of cosmetics and outfits will not leave anyone indifferent.",
      tag: ["Dress up", "Make Up", "Fashion", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "playcutegames",
     
      orientation: 0,
      title: "Angela Trendy Fashionista - playcutegames.com",
      name: ["Angela Trendy Fashionista", "angela-trendy-fashionista"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-trendy-fashionista/250x142.png",
      path: ["/game/angela-trendy-fashionista", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/angela-trendy-fashionista/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Angela Trendy Fashionista. Angela is more conscious of her fashion. She plans to prepare herself for different events.",
      l_dis:
        "Welcome to Angela Trendy Fashionista. Angela is more conscious of her fashion. She plans to prepare herself for different events. She already has a to-do list. Join and help the angela to be perfect on each occasion. Browse her wardrobe and pick outfits and costumes which is suitable for the events. Play and have fun! ",
      tag: ["Angela", "Dress up", "Fashion", "playcutegames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "DressUpWho",
     
      orientation: 0,
      title: "Bonnie and BFFs Valentine Day Party - playcutegames.com",
      name: [
        "Bonnie and BFFs Valentine Day Party",
        "bonnie-and-bffs-valentine-day-party",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bonnie-and-bffs-valentine-day-party/250x142.png",
      path: [
        "/game/bonnie-and-bffs-valentine-day-party",
        "https://playcutegames.com",
      ],
      iframe: "https://dressupwho.net/html5/Bonnie_and_BFFs_Valentine_Day_Party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Bonnie and BFFs Valentine Day Party. Bonnie and her BFFs are getting ready for Valentine’s Day party. This is one of the most romantic holidays of the year, it is celebrated on February 14 in many countries of the world.",
      l_dis:
        "Welcome to Bonnie and BFFs Valentine Day Party. Bonnie and her BFFs are getting ready for Valentine’s Day party. This is one of the most romantic holidays of the year, it is celebrated on February 14 in many countries of the world. Immerse yourself in the magical world of pink colors! Choose from a variety of luxurious Valentine’s Day special edition outfits. Hearts, sparkles, and floral textures will not leave anyone indifferent! Use pink, red, gold tones for festive make-up, style girls’ hair in cute romantic hairstyles using holiday paraphernalia. Get in the holiday spirit with Bonnie by playing this awesome Bonnie and BFFs Valentine Day Party game on playcutegame.com.",
      tag: ["Dress up", "Make Up", "Valentine", "DressUpWho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Soft Grunge Looks - playcutegames.com",
      name: ["Princess Soft Grunge Looks", "princess-soft-grunge-looks"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-soft-grunge-looks/250x142.png",
      path: ["/game/princess-soft-grunge-looks", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-soft-grunge-looks/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Soft Grunge Looks. Bffs Ariel, Moana, Snow White, and Jasmine are more fashioned princesses planning to try some unique fashion ideas.",
      l_dis:
        `Welcome to the Princess Soft Grunge Looks. Bffs Ariel, Moana, Snow White, and Jasmine are more fashioned princesses planning to try some unique fashion ideas. Princess Moana comes up with an idea to try Soft Grunge fashion. They all get excited and look forward to trying it out. Join and help the girls to pick a suitable costume. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess", "Dress up", "Fashion", "Cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Prinxy Winterella - playcutegames.com",
      name: ["Prinxy Winterella", "prinxy-winterella"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/prinxy-winterella/250x142.png",
      path: ["/game/prinxy-winterella", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/d1791122-0c90-5114-b709-b3a43f91b476/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Prinxy Winterella. If you’re still seeking inspiration for your upcoming winter ball, then look no further Our social media influencers bring a selection of jaw-dropping makeup looks that are going to help",
      l_dis:
        "Welcome to Prinxy Winterella. If you’re still seeking inspiration for your upcoming winter ball, then look no further Our social media influencers bring a selection of jaw-dropping makeup looks that are going to help you steal the spotlights during the most glamorous night of the year. In the Prinxy Winterella game for girls, you can discover the secrets of a sophisticated fantasy makeup look in icy cold colors. Play today and have fun!",
      tag: ["Dress up", "Make Up", "Winter", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "My Best FF Outfits - playcutegames.com",
      name: ["My Best FF Outfits", "my-best-ff-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-best-ff-outfits/250x142.png",
      path: ["/game/my-best-ff-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-best-ff-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Best FF Outfits. Princesses Anna, Elsa, Snowwhite, and Ariel are more font FF game. They always love to wear the FF costumes and accessories.",
      l_dis:
        "Welcome to the My Best FF Outfits. Princesses Anna, Elsa, Snowwhite, and Ariel are more font FF game. They always love to wear the FF costumes and accessories. So they planned to try FF outfits. Join and help the girls to pick a suitable outfit for each one of them. Try to choose unique costumes and hairstyles. Play and have fun!",
      tag: ["Princess", "Dress up", "Fashion", "Playcutegames", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "DressUpWho",
     
      orientation: 0,
      title: "Bonnie Rocker Chick - playcutegames.com",
      name: ["Bonnie Rocker Chick", "bonnie-rocker-chick"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bonnie-rocker-chick/250x142.png",
      path: ["/game/bonnie-rocker-chick", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/barbie-rocker-chick/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bonnie Rocker Chick. Bonnie and her friends have recently discovered rock music, so dress them all up in lovely outfits and bring out their edgy side.",
      l_dis:
        "Welcome to the Bonnie Rocker Chick. Bonnie and her friends have recently discovered rock music, so dress them all up in lovely outfits and bring out their edgy side. Start with Bonnie and add some black striped in her hair, dress her up with a pink crop top with white skull prints, a black and pink striped sports jacket, a ruffled black tulle skirt over a pair of pink leggings, black and pink boots, a bow in her hair with a skull badge and silver and black jewelry. Have a great time playing this exciting game!",
      tag: ["Barbie", "Dress up", "Make Up", "DressUpWho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Space Core Aesthetic - playcutegames.com",
      name: [
        "Rainbow Girls Space Core Aesthetic",
        "rainbow-girls-space-core-aesthetic",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-space-core-aesthetic/250x142.png",
      path: [
        "/game/rainbow-girls-space-core-aesthetic",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/rainbow-girls-space-core-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Rainbow Girls Space Core Aesthetic. Who loves space adventure? Everyone right! Rainbow Girls Skyler, Sunny, Ruby, and Violet are adventurer high school girls planning to try space aesthetic outfits.",
      l_dis:
        `Welcome to the Rainbow Girls Space Core Aesthetic. Who loves space adventure? Everyone right! Rainbow Girls Skyler, Sunny, Ruby, and Violet are adventurer high school girls planning to try space aesthetic outfits. Browse their wardrobe and pick a cute and suitable outfit for them. Join with the girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow high",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Crazy BFF Party - playcutegames.com",
      name: ["Crazy BFF Party", "crazy-bff-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/crazy-bff-party/250x142.png",
      path: ["/game/crazy-bff-party", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1642699140/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Crazy BFF Party. Two best friends – Princess Harlequin and Princess Jen decided to have a party of the century.",
      l_dis:
        "Welcome to the Crazy BFF Party. Two best friends – Princess Harlequin and Princess Jen decided to have a party of the century. These two girls are absolutely reckless funny brutes. They love daring informal clothing, bright colors, fishnet tights, tattoos, and unusual fashion combinations. Join the unbridled fun of the princesses, experiment with makeup and hairstyles. Choose incredible accessories for the girl’s original costumes. Time to go wild with the game.",
      tag: ["Dress up", "Make Up", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Princesses As Ancient Warriors - playcutegames.com",
      name: ["Princesses As Ancient Warriors", "princesses-as-ancient-warriors"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-as-ancient-warriors/250x142.png",
      path: [
        "/game/princesses-as-ancient-warriors",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess-as-ancient-warriors/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princesses As Ancient Warriors. Princesses Anna, Elsa, Ariel, and Moana are the best friends, they love to be ancient warriors in feature.",
      l_dis:
        "Welcome to the Princesses As Ancient Warriors. Princesses Anna, Elsa, Ariel, and Moana are the best friends, they love to be ancient warriors in feature. But these girls don’t want to wait as long. They planned to try cool warrior costumes and be like them now. Help the girls to browse their wardrobe. Join and have fun!",
      tag: ["Princess", "Dress up", "Fashion", "Playcutegames", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "LOL Soft Girls Aesthetic - playcutegames.com",
      name: ["LOL Soft Girls Aesthetic", "lol-soft-girls-aesthetic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lol-soft-girls-aesthetic/250x142.png",
      path: ["/game/lol-soft-girls-aesthetic", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/lol-soft-girls/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the LOL Soft Girls Aesthetic. As you may already know, the LOL Dolls are committed to trying all the fashion trends from TikTok and today they are about to discover the #softgirlaesthetic style.",
      l_dis:
        "Welcome to the LOL Soft Girls Aesthetic. As you may already know, the LOL Dolls are committed to trying all the fashion trends from TikTok and today they are about to discover the #softgirlaesthetic style. Join with these three best friends. Help them to pick lovely pastel outfits and girly-girl accessories. Have fun!",
      tag: ["LOL Surprise", "Dress up", "Make Up", "Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Winter Knit Fashion - playcutegames.com",
      name: ["My Winter Knit Fashion", "my-winter-knit-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-winter-knit-fashion/250x142.png",
      path: ["/game/my-winter-knit-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-winter-knit-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Winter Knit Fashion. Winter is here! Hairdorable girls Dee dee, Willow, and Noah are more adorable in fashion. They planned to try knit fashion.",
      l_dis:
        `Welcome to the My Winter Knit Fashion. Winter is here! Hairdorable girls Dee dee, Willow, and Noah are more adorable in fashion. They planned to try knit fashion. Browse the wardrobe and help the girls to pick cute winter knit outfits. Join and play with the girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables doll",
        "Dress up",
        "Make Up",
        "Winter",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Korean Supermodel Makeup - playcutegames.com",
      name: ["Korean Supermodel Makeup", "korean-supermodel-makeup"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/korean-supermodel-makeup/250x142.png",
      path: ["/game/korean-supermodel-makeup", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1642249015/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Korean Supermodel Makeup. The South Korean model became the star of the catwalks and won the love of millions of compatriots.",
      l_dis:
        "Welcome to Korean Supermodel Makeup. The South Korean model became the star of the catwalks and won the love of millions of compatriots. Deciding to learn a new profession, Jeong made her debut on the silver screen and gained worldwide popularity and many times more fans. The model opened her professional biography on the catwalk at the age of 16. In those years, the aspiring model did not even have an agent, however, when she appeared at Seoul Fashion Week, the girl impressed the representatives of the fashion industry with her fiery red hairdo, cheeky smile, and strong-willed character. Let’s learn the role of makeup artist and stylist for a young model. Pick up trendy makeup, hairstyle and accessories for Jeong. Take a photo for Instagram and get likes!",
      tag: ["Dress up", "Make Up", "Fashion", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Soft Girls Winter Aesthetics - playcutegames.com",
      name: ["Soft Girls Winter Aesthetics", "soft-girls-winter-aesthetics"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/soft-girls-winter-aesthetics/250x142.png",
      path: ["/game/soft-girls-winter-aesthetics", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/ba47d3b8-fac7-5019-a6bd-a4ada498352e/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Soft Girls Winter Aesthetics. Hop on a plane and spend your winter vacation traveling around the world together with your favorite Disney princesses.",
      l_dis:
        "Welcome to the Soft Girls Winter Aesthetics. Hop on a plane and spend your winter vacation traveling around the world together with your favorite Disney princesses. Discover fun winter activities and practice your fashion advisor skills while helping them choose their outfits of the day in the Soft Girls Winter Aesthetics for girls! Have a blast!",
      tag: ["Princess", "Dress up", "Make Up", "Winter", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Princess Look Like A Supermodel - playcutegames.com",
      name: [
        "Princess Look Like A Supermodel",
        "princess-look-like-a-supermodel",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-look-like-a-supermodel/250x142.png",
      path: [
        "/game/princess-look-like-a-supermodel",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess-look-like-a-supermodel/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Look Like A Supermodel. Princess Anna, Elsa, Moana, and Snow White are the BFFs who admired more on modeling.",
      l_dis:
        "Welcome to the Princess Look Like A Supermodel. Princess Anna, Elsa, Moana, and Snow White are the BFFs who admired more on modeling. They planned to be a supermodel in upcoming fashion events. Browse their wardrobe and help the princesses to be a supermodel.",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Cute Tiger Cub Care - playcutegames.com",
      name: ["Cute Tiger Cub Care", "cute-tiger-cub-care"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-tiger-cub-care/250x142.png",
      path: ["/game/cute-tiger-cub-care", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1641837336/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Cute Tiger Cub Care. Everyone knows that the symbol of this year is the Blue Water Tiger! The blue color symbolizes impermanence, water is moving, and the tiger is one of the most unpredictable and energetic animals.",
      l_dis:
        "Welcome to the Cute Tiger Cub Care. Everyone knows that the symbol of this year is the Blue Water Tiger! The blue color symbolizes impermanence, water is moving, and the tiger is one of the most unpredictable and energetic animals. Therefore, those people who are not afraid of changes, lead an active lifestyle, and like to be constantly on the move will be on the horse. In traditional Chinese astrology, the Water Tiger is a big fan of purposeful and hardworking people. Let’s help this year’s mascot – a cute tiger cub. He’s a little sick. We need to cure him and prepare him for the new active year! Choose cute outfits and accessories for the tiger cub so that he pleases his fans all year round!",
      tag: ["Doctor", "Dress up", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Perfect Winter Outfits - playcutegames.com",
      name: [
        "Rainbow Girls Perfect Winter Outfits",
        "rainbow-girls-perfect-winter-outfits",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-perfect-winter-outfits/250x142.png",
      path: [
        "/game/rainbow-girls-perfect-winter-outfits",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/rainbow-girls-perfect-winter-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Rainbow Girls Perfect Winter Outfits. Winter is not a season, It’s a celebration. Enjoy this winter with these cute rainbow girls.",
      l_dis:
        `Welcome to Rainbow Girls Perfect Winter Outfits. Winter is not a season, It’s a celebration. Enjoy this winter with these cute rainbow girls. These rainbow girls want to try something special this winter. Join and help the girls to pick cute outfits and accessories. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Winter",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Celebrity Signature Styles - playcutegames.com",
      name: ["Celebrity Signature Styles", "celebrity-signature-styles"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/celebrity-signature-styles/250x142.png",
      path: ["/game/celebrity-signature-styles", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/celebrity-signature-styles/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Celebrity Signature Styles. Our favorite celebrities Selena Gomez, Taylor Swift, Rihanna, Ariana Grande, and Kendel Jenner are gathering together for celebrity events.",
      l_dis:
        "Welcome to the Celebrity Signature Styles. Our favorite celebrities Selena Gomez, Taylor Swift, Rihanna, Ariana Grande, and Kendel Jenner are gathering together for celebrity events. They planned to appear in their signature style. Join and help your favorite celebrities with your fashion skill. Pick a suitable outfit for the event. Have fun!",
      tag: [
        "Celebrity",
        "Dress up",
        "Make Up",
        "Fashion",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "GM",
     
      orientation: 0,
      title: "Princess Little Mermaid - playcutegames.com",
      name: ["Princess Little Mermaid", "princess-little-mermaid"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-little-mermaid/250x142.png",
      path: ["/game/princess-little-mermaid", "https://playcutegames.com"],
      iframe: "https://html5.gamemonetize.co/uhikvkzq4anzzaejqkh83fjy4x6xrn7y/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Princess Little Mermaid is a very addictive and cute makeup game. Princess little mermaid lives in the kingdom deep down the ocean.",
      l_dis:
        "Princess Little Mermaid is a very addictive and cute makeup game. Princess little mermaid lives in the kingdom deep down the ocean. She likes beautiful dresses and pretty makeup just like you. She has a best friend. They always stay together and put on makeup and enjoy their beautiful outfits. Do you want to experience her makeup process? In this game, there are many carefully selected pretty makeups for you to pick. And many lovely dresses, hairstyles and beautiful shoes for your to choose from. Come and play this game customized just for you!",
      tag: ["Princess", "Mermaid","Dress up", "Make Up"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Tiktok Musical Fest - playcutegames.com",
      name: ["Tiktok Musical Fest", "tiktok-musical-fest"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-musical-fest/250x142.png",
      path: ["/game/tiktok-musical-fest", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/tiktok-musical-fest/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Tiktok Musical Fest. Rainbow Girls Skyler and Sunny planned to participate in the TikTok musical fest along with their friends Ruby and Violet.",
      l_dis:
        `Welcome to the Tiktok Musical Fest. Rainbow Girls Skyler and Sunny planned to participate in the TikTok musical fest along with their friends Ruby and Violet. Join and help the girls with preparation. Pick a suitable costume and outfit for the music fest. Don’t forget to choose the best musical instrument. Join and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "GM",
     
      orientation: 0,
      title: "Diana City Fashion and beauty - playcutegames.com",
      name: ["Diana City Fashion and beauty", "diana-city-fashion-beauty"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/diana-city-fashion-beauty/250x142.png",
      path: ["/game/diana-city-fashion-beauty", "https://playcutegames.com"],
      iframe: "https://html5.gamemonetize.co/0xg5wnh53nprv6f4lejfnf2nrs91bph8/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Diana City Fashion & beauty. Love, Diana Dress-up Mobile Game. In the new room, Diana has nothing the little girl needs. She is upset.",
      l_dis:
        "Welcome to Diana City Fashion & beauty. Love, Diana Dress-up Mobile Game. In the new room, Diana has nothing the little girl needs. She is upset. Dad and Roma brings Diana dresses, jewelry and makeup toys. Pick a nice costumes and outfits for Diana and also select cute pet for her! Have fun!",
      tag: ["Dress up", "Fashion"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
  
      brand: "playcutegames",
     
      orientation: 0,
      title: "Rainbow Girls Neon Fashion - playcutegames.com",
      name: ["Rainbow Girls Neon Fashion", "rainbow-girls-neon-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-neon-fashion/250x142.png",
      path: ["/game/rainbow-girls-neon-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/rainbow-girls-neon-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Rainbow Girls Neon Fashion. Girls! Neon is always special and fun to wear! These Rainbow high girls are more fashionable in the latest trends.",
      l_dis:
        "Welcome to the Rainbow Girls Neon Fashion. Girls! Neon is always special and fun to wear! These Rainbow high girls are more fashionable in the latest trends. They planned to try this neon fashion. Join and help the girls with your creative ideas. Browse each wardrobe and try unique costumes for each girl! Have fun!",
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "Prinxy",
     
      orientation: 0,
      title: "K Pop New Year's Concert 2 - playcutegames.com",
      name: ["K Pop New Year's Concert 2", "k-pop-new-years-concert-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/k-pop-new-years-concert-2/250x142.png",
      path: ["/game/k-pop-new-years-concert-2", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/61f4128e-3907-5802-b771-83e28a45340a/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the K-Pop New Year’s Concert 2. Right after midnight, the most famous K-pop band of the moment is going to entertain the gathering with their cool songs, and these best friends girls can’t wait to see the BlackPink girls performing.",
      l_dis:
        "Welcome to the K-Pop New Year’s Concert 2. Right after midnight, the most famous K-pop band of the moment is going to entertain the gathering with their cool songs, and these best friends girls can’t wait to see the BlackPink girls performing. They’ve got the tickets, and they just need the right looks, and here is where you can help them out! Get ready to prove your stylist skills, ladies, and get them the right BlackPink inspired looks while playing the K-Pop New Year’s Concert 2dress up game for girls online!",
      tag: ["Dress up", "Make Up", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Get Ready For Winter - playcutegames.com",
      name: ["Princess Get Ready For Winter", "princess-get-ready-for-winter"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-get-ready-for-winter/250x142.png",
      path: ["/game/princess-get-ready-for-winter", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-get-ready-for-winter/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Get Ready For Winter. Winter is almost there! Princess Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are the best friends.",
      l_dis:
        `Welcome to the Princess Get Ready For Winter. Winter is almost there! Princess Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are the best friends. They are more passionate about the latest trends. But these princesses still have a dilemma in welcoming the most lovable season. Join and help the princesses to pick cute outfits and costumes. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        ,
        "Winter",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
  
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "New Year's Kigurumi - playcutegames.com",
      name: ["New Year's Kigurumi", "new-years-kigurumi"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/new-years-kigurumi/250x142.png",
      path: ["/game/new-years-kigurumi", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1640627705/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to New Year’s Kigurumi. What does it take to make this New Year the coziest holiday? Everything is very simple: instead of a tedious choice of outfit, you can dress in funny home pajamas",
      l_dis:
        "Welcome to New Year’s Kigurumi. What does it take to make this New Year the coziest holiday? Everything is very simple: instead of a tedious choice of outfit, you can dress in funny home pajamas – Kigurumi! It will be a real New Years’ pajama party! Join princesses Eliza and Jacqueline, do some fun New Year’s makeup, and choose your favorite pajamas. It can be a Kigurumi-tiger, a Christmas tree, or Santa Claus, or maybe you want to be a funny unicorn or a dragon? Put on your pajamas, cute slippers, and pour some hot chocolate! Celebrate the New Year comfortably by playing the game New Year’s Kigurumi.",
      tag: ["Princess", "Dress up", "Make Up", "New year", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "Kawaii",
     
      orientation: 0,
      title: "Kawaii Witch School Dress Up Game - playcutegames.com",
      name: [
        "Kawaii Witch School Dress Up Game",
        "kawaii-witch-school-dress-up-game",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/kawaii-witch-school-dress-up-game/250x142.png",
      path: [
        "/game/kawaii-witch-school-dress-up-game",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.kawaiigames.net/jogos/kawaii-witch-school-dress-up-game/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Kawaii Witch School Dress Up Game. Dress up your anime-style witch however you want. She will start at magic school and needs to be in the look that matches her personality!",
      l_dis:
        "Welcome to Kawaii Witch School Dress Up Game. Dress up your anime-style witch however you want. She will start at magic school and needs to be in the look that matches her personality! Choose from different types of hair and bangs, from short to long hairstyles. You can choose fantastic dresses or create interesting combinations with blouses, skirts, shorts, and pants for a more laid-back look. You can select from cute sneakers with laces and charming boots. Put on glasses to see beyond the reach and wear your magic hat, a must-have for every witch. And a powerful sorceress is accompanied by extraordinary animals. ",
      tag: ["Dress up", "Fashion", "Kawaii"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111265,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Sweet Candy Outfits - playcutegames.com",
      name: ["My Sweet Candy Outfits", "my-sweet-candy-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-sweet-candy-outfits/250x142.png",
      path: ["/game/my-sweet-candy-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-sweet-candy-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Sweet Candy Outfits. Rainbow high girls Skyler, Sunny, Ruby, and Violet are planning a weekend party. But a party without a theme is not fun!",
      l_dis:
        `Welcome to the My Sweet Candy Outfits. Rainbow high girls Skyler, Sunny, Ruby, and Violet are planning a weekend party. But a party without a theme is not fun! So they planned to be in Sweet candy themes. Help the girls to browse the wardrobe and pick the cute candy outfit for them. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Rainbow High", "Dress up", "Make Up", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "dressupwho",
     
      orientation: 0,
      title: "New Year Party Challenge - playcutegames.com",
      name: ["New Year Party Challenge", "new-year-party-challenge"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/new-year-party-challenge/250x142.png",
      path: ["/game/new-year-party-challenge", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/New%20Year%20Party%20Challenge/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the New Year Party Challenge game. Be the best dentist at this Superhero clinic! All our patient is Superhero, who is dedicated to fighting crime, protecting the public,",
      l_dis:
        " Challenge New Year Party Challenge New Year Party Challenge New Year is truly an international holiday, but in different countries it is celebrated in its own way. This is one of those rare holidays that are unanimously celebrated by all seven billion people on Earth. The princesses decided to arrange a New Year's challenge, decorating the palace and choosing outfits in the style of their kingdom. In Eliza's kingdom, the main symbol of the New Year is the festive tree. Gifts are placed under the tree, and sweets and treats are placed on the table. And, of course, festive garlands are hung up! Sparkling gold and silver outfits, stars and snowflakes are in vogue this year. According to Chinese traditions, celebrating the New Year is one of the most important and significant family traditions. On the first day of the new year, the Chinese launch fireworks, and also burn incense sticks, which, in their opinion, can scare away evil spirits and drive them out of the house. Many people buy new clothes (they must be in bright colors - gold or red) and send congratulations to each other. The Chinese princess follows these traditions without forgetting about modern fashion! Let's compare such different and such similar holidays! Have fun!",
      tag: ["Princess", "Dress up", "Make Up", "New year", "DressupWho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "Yiv",
     
      orientation: 0,
      title: "Superhero Dentist - playcutegames.com",
      name: ["Superhero Dentist", "superhero-dentist"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/superhero-dentist/250x142.png",
      path: ["/game/superhero-dentist", "https://playcutegames.com"],
      iframe: "https://www.yiv.com/games/Superhero-Dentist-1/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Superhero Dentist game.  Be the best dentist at this Superhero clinic! All our patient is Superhero, who is dedicated to fighting crime, protecting the public, and usually battling supervillains, so their whereabouts are to be kept secret.",
      l_dis:
        "Welcome to the Superhero Dentist game.  Be the best dentist at this Superhero clinic! All our patient is Superhero, who is dedicated to fighting crime, protecting the public, and usually battling supervillains, so their whereabouts are to be kept secret. Treat our patients with crazy tools like mouth spray, dental tweezers, and braces!",
      tag: ["Doctor", "Yiv","Dentist"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      id: 20211164,
  
      brand: "Yiv",
     
      orientation: 0,
      title: "Makeup Artist Fashion Salon - playcutegames.com",
      name: ["Makeup Artist Fashion Salon", "makeup-artist-fashion-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/makeup-artist-fashion-salon/250x142.png",
      path: ["/game/makeup-artist-fashion-salon", "https://playcutegames.com"],
      iframe: "https://html5.gamemonetize.co/zc22nrlaspi1nv9ikvypvex7uhohezop/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Makeup Artist Fashion Salon. Why not become a makeup artist? Makeup with your style and pick your favorite match. There are many types of cosmetics that can be used here. It’s very interesting.",
      l_dis:
        "Welcome to Makeup Artist Fashion Salon. Why not become a makeup artist? Makeup with your style and pick your favorite match. There are many types of cosmetics that can be used here. It’s very interesting. You can use foundation, eye makeup, lipstick, hairstyle, necklace, and earrings to dress up. Tons of different designs for you to discover, cloud, butterfly, rainbow, Christmas, and Halloween, everything in trend can be an inspiration for your style. Come and challenge your fashion skill now!",
      tag: ["Make Up", "Yiv"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 202111263,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Winter Ice Skating Outfits - playcutegames.com",
      name: [
        "Princess Winter Ice Skating Outfits",
        "princess-winter-ice-skating-outfits",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-winter-ice-skating-outfits/250x142.png",
      path: [
        "/game/princess-winter-ice-skating-outfits",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/princess-winter-ice-skating-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Winter Ice Skating Outfits. Girls enjoy this winter with your favorite princess. Princesses Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are planning to go for Ice skating.",
      l_dis:
        `Welcome to Princess Winter Ice Skating Outfits. Girls enjoy this winter with your favorite princess. Princesses Anna, Elsa, Moana, Ariel, Jasmine, and Snow White are planning to go for Ice skating. But before that, they need perfect outfits. Help the princesses with their outfits with matching boots. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Winter",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "cutedressup",
     
      orientation: 0,
      title: "TikTok Inspired Outfits - playcutegames.com",
      name: ["TikTok Inspired Outfits", "tiktok-inspired-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-inspired-outfits/250x142.png",
      path: ["/game/tiktok-inspired-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/tiktok-inspired-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the TikTok Inspired Outfits. Princesses Elsa, Anna, Moana, and Jasmine are big fans of Tiktok fashion. They always fill their wardrobe with new TikTok collections.",
      l_dis:
        `Welcome to the TikTok Inspired Outfits. Princesses Elsa, Anna, Moana, and Jasmine are big fans of Tiktok fashion. They always fill their wardrobe with new TikTok collections. Join and help the princess to pick the fabulous outfit from their collections. Before that try some crazy makeup to show a gorgeous look. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "TikTok",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211125,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Frozen Princess New year's Eve - playcutegames.com",
      name: ["Frozen Princess New year's Eve", "frozen-princess-new-years-eve"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/frozen-princess-new-years-eve/250x142.png",
      path: ["/game/frozen-princess-new-years-eve", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/frozen-princess-new-years-eve/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Frozen Princess on New year’s Eve. Princess Elsa and Anna arrange a party at Arendelle. They invited other princesses Ariel, Moana, Snow White, and Jasmine for the party.",
      l_dis:
        `Welcome to the Frozen Princess on New year’s Eve. Princess Elsa and Anna arrange a party at Arendelle. They invited other princesses Ariel, Moana, Snow White, and Jasmine for the party. They all are so excited to visit Arendelle on New Year. Help the princesses to get ready for the New Year celebration.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "New year",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211210,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Eliza Winter Coronation - playcutegames.com",
      name: ["Eliza Winter Coronation", "eliza-winter-coronation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/eliza-winter-coronation/250x142.png",
      path: ["/game/eliza-winter-coronation", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1639673873/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Eliza Winter Coronation. Princess Elisa assumes the throne this winter. She's getting ready to be queen. This is a very significant event for a girl.",
      l_dis:
        "Welcome to Eliza Winter Coronation. Princess Elisa assumes the throne this winter. She's getting ready to be queen. This is a very significant event for a girl. A princess must be irresistible at her coronation. Eliza's favorite season is winter, so the holiday will take place with the onset of cold weather. Let's travel to Eliza's magical ice kingdom and help her prepare for her coronation. ",
      tag: ["Princess", "Dress up", "Make up", "Winter", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211125,
      brand: "playcutegames",
     
      orientation: 0,
      title: "Perfect New Year's Eve Party Look - playcutegames.com",
      name: [
        "Perfect New Year's Eve Party Look",
        "perfect-new-years-eve-party-look",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/perfect-new-years-eve-party-look/250x142.png",
      path: [
        "/game/perfect-new-years-eve-party-look",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/perfect-new-years-eve-party-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Perfect New Year’s Eve Party Look. Rainbow High girls Skyler, Sunny, Ruby, and Violet get ready for the New year’s eve party.",
      l_dis:
        "Welcome to Perfect New Year’s Eve Party Look. Rainbow High girls Skyler, Sunny, Ruby, and Violet get ready for the New year’s eve party. Browse their wardrobe and choose the best New year outfit. And add some cute makeup to each one. Surprise them with your skill and make this New year the most memorable one. Join and help them. Have fun!",
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "New year",
        "Playcutegames",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211210,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Baby Princesses Wonderful Christmas - playcutegames.com",
      name: [
        "Baby Princesses Wonderful Christmas",
        "baby-princesses-wonderful-christmas",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/baby-princesses-wonderful-christmas/250x142.png",
      path: [
        "/game/baby-princesses-wonderful-christmas",
        "https://playcutegames.com",
      ],
      iframe: "https://dressupwho.net/html5/Baby_Princesses_Wonderful_Christmas/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Baby Princesses Wonderful Christmas Little princesses Eliza, Jasmin, Tiara, Rosehip, Cinderella, Mermaid getting ready for the most wonderful holiday of the year - Christmas!",
      l_dis:
        "Baby Princesses Wonderful Christmas Little princesses Eliza, Jasmin, Tiara, Rosehip, Cinderella, Mermaid getting ready for the most wonderful holiday of the year - Christmas! Let's help the little ones choose cute trendy outfits for the Christmas ball. Just look how many sweetie dresses, skirts, shoes, and blouses the princesses have in their wardrobe. All outfits sparkle and shimmer in the light of festive garlands. Don't forget to choose an evening hairstyle for each princess. Have a lot of fun playing the game Baby Princesses Wonderful Christmas.",
      tag: ["Princess", "Dress up", "Christmas", "DressupWho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211125,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Angela Insta Fashion Stories - playcutegames.com",
      name: ["Angela Insta Fashion Stories", "angela-insta-fashion-stories"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-insta-fashion-stories/250x142.png",
      path: ["/game/angela-insta-fashion-stories", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/angela-insta-fashion-stories/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Angela Insta Fashion Stories. Angela feels about her popularity on social media. She has plans to attract the people on her side.",
      l_dis:
        `Welcome to the Angela Insta Fashion Stories. Angela feels about her popularity on social media. She has plans to attract the people on her side. She decided to share posts and stories among the popular hashtags. But she needs some experts like you. Come and join with Angela and make an attractive post. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Angela", "Dress up", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211124,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Kiki's Pink Christmas - playcutegames.com",
      name: ["Kiki's Pink Christmas", "kikis-pink-christmas"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/kikis-pink-christmas/250x142.png",
      path: ["/game/kikis-pink-christmas", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/54c1ac04-ec82-543f-b7de-4dc86f9a835c/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Kiki’s Pink Christmas. Come and help gorgeous Kiki get her holiday outfits ready in time for Christmas Day.",
      l_dis:
        "Welcome to Kiki’s Pink Christmas. Come and help gorgeous Kiki get her holiday outfits ready in time for Christmas Day. She needs four statement looks and you are invited to help her out. In this brand-new Christmas dress-up game for girls called Kiki’s Pink Christmas you dress up our girl in comfy chic homeware, fashionable kitchen outfits, glam festive outfits, and colorful outdoor outfits. Go through all the options and create four unique Christmas looks for cute Kiki. Don’t forget to also deal with her makeup look. Have a blast!",
      tag: ["Princess", "Dress up", "Christmas", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    
    {
      id: 20211124,
  
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Angelcore Princess - playcutegames.com",
      name: ["Angelcore Princess", "angelcore-princess"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angelcore-princess/250x142.png",
      path: ["/game/angelcore-princess", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1638849435/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Angelcore Princess. Angelcore is a fashion inspired by images of angels. This style is intended to depict the same unearthly beauty with which European angels",
      l_dis:
        "Welcome to the Angelcore Princess. Angelcore is a fashion inspired by images of angels. This style is intended to depict the same unearthly beauty with which European angels are described and portrayed, regardless of whether it is done using modern or old methods of depiction. Angelcore is based on the tenderness, gentleness, and purity of angels in modern culture. Pastel colors and a nostalgic vintage vibe are also hallmarks of this style. Join princesses Eliza and Tiara to experience this amazing style of Angelcore together. Choose a bow from a variety of delicate, airy outfits – dresses, blouses, skirts. Try one of two hairstyles for each princess, as well as a choice of makeup. This unearthly style will not leave anyone indifferent!",
      tag: ["Princess", "Dress up", "Make Up", "Decoration", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211123,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Christmas Outfits - playcutegames.com",
      name: [
        "Rainbow Girls Christmas Outfits",
        "rainbow-girls-christmas-outfits",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-christmas-outfits/250x142.png",
      path: [
        "/game/rainbow-girls-christmas-outfits",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/rainbow-girls-christmas-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Rainbow Girls Christmas Outfits. Rainbow girls Skyler, Sunny, Ruby, and Violet get ready for Christmas celebrations.",
      l_dis:
        `Welcome to the Rainbow Girls Christmas Outfits. Rainbow girls Skyler, Sunny, Ruby, and Violet get ready for Christmas celebrations. They want to try cute Christmas outfits with crazy makeovers. Browse their wardrobe for cute outfits and pick some matching accessories. Join with these girls and enjoy this Christmas a most memorable one. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Christmas",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211122,
  
      brand: "GM",
     
      orientation: 0,
      title: "Foot Doctor 3D Game - playcutegames.com",
      name: ["Foot Doctor 3D Game", "foot-doctor-3d-game"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/foot-doctor-3d-game/250x142.png",
      path: ["/game/foot-doctor-3d-game", "https://playcutegames.com"],
      iframe: "https://html5.gamemonetize.co/yzekeqiukej2cjdfb3ed029npxtk9cz2/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Foot Doctor 3D Game. HELP! Doctor, I hurt my feet, ouch! It is so painful! Could you please help me to cure my feet? I know you have the best medical tools here! Foot Doctor features:",
      l_dis:
        "Welcome to the Foot Doctor 3D Game. HELP! Doctor, I hurt my feet, ouch! It is so painful! Could you please help me to cure my feet? I know you have the best medical tools here! Foot Doctor features: ♥ Select adorable patients. ♥ Lots of Free medical tools designed. ♥ Placate patients with free gifts.",
      tag: ["Doctor"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211202,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Cute Couple Ariana - playcutegames.com",
      name: ["Cute Couple Ariana", "cute-couple-ariana"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-couple-ariana/250x142.png",
      path: ["/game/cute-couple-ariana", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/cute-couple-ariana/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Cute Couple Ariana. Ariana Grande and Dalton Gomez together in the Cute Couple dress-up routine! Go with them to a party, school, the dance studio, outside and others.",
      l_dis:
        "Welcome to the Cute Couple Ariana. Ariana Grande and Dalton Gomez together in the Cute Couple dress-up routine! Go with them to a party, school, the dance studio, outside and others. Enjoy playing these new dress up games for girls. Join and help Ariana and Dalton with their makeover and have fun!",
      tag: ["Celebrity", "Dress up", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211201,
      brand: "babygames",
     
      orientation: 0,
      title: "Dress Up Unicorn - playcutegames.com",
      name: ["Dress Up Unicorn", "dress-up-unicorn"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dress-up-unicorn/250x142.png",
      path: ["/game/dress-up-unicorn", "https://playcutegames.com"],
      iframe: "https://www.babygames.com/games/Dress-Up-Unicorn/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Dress Up Unicorn. Unicorns are magical horses with a horn on their head. Have you ever dreamed to own a pet unicorn? In this game, you can create a huge number of outfits for four kinds of unicorns.",
      l_dis:
        "Welcome to Dress Up Unicorn. Unicorns are magical horses with a horn on their head. Have you ever dreamed to own a pet unicorn? In this game, you can create a huge number of outfits for four kinds of unicorns. Let’s dress up and take care of the unicorn. Charming style starts",
      tag: ["Dress up", "Make Up", "Decoration", "babygames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211121,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bffs Rainbow Fashion Addict - playcutegames.com",
      name: ["Bffs Rainbow Fashion Addict", "bffs-rainbow-fashion-addict"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-rainbow-fashion-addict/250x142.png",
      path: ["/game/bffs-rainbow-fashion-addict", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bffs-rainbow-fashion-addict/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bffs Rainbow Fashion Addict. Bffs Skyler, Sunny, Ruby, and Violet are more interested to try rainbow fashion outfits.",
      l_dis:
        `Welcome to the Bffs Rainbow Fashion Addict. Bffs Skyler, Sunny, Ruby, and Violet are more interested to try rainbow fashion outfits. They are more attractive, elegant, and beautiful to wear. These girls already fulfilled their wardrobe with the latest collection, but they have been confused to choose the better one. Join as a design expert and help the girls to give their stunning look. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211120,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Riddles of Squid - playcutegames.com",
      name: ["Riddles of Squid", "riddles-of-squid"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/riddles-of-squid/250x142.png",
      path: ["/game/riddles-of-squid", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1638164149/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Riddles of Squid. Introducing an exciting new game based on the acclaimed Korean TV series. You have to play as a brave girl who wants to win the main prize.",
      l_dis:
        "Welcome to Riddles of Squid. Introducing an exciting new game based on the acclaimed Korean TV series. You have to play as a brave girl who wants to win the main prize. Use your luck and skills to win. And at the end you will receive a reward – you can choose cool outfits, hairstyles and makeup for your heroine. Hurry up – play this addicting game and solve all the Riddles Of Squid!",
      tag: ["Dress up", "Make Up", "Celebrity", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211119,
      brand: "dressupwho",
     
      orientation: 0,
      title: "So Different Zendaya - playcutegames.com",
      name: ["So Different Zendaya", "so-different-zendaya"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/so-different-zendaya/250x142.png",
      path: ["/game/so-different-zendaya", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/So_Different_Zendaya/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to So Different Zendaya game. We have often seen Zendaya on the red carpet, where she showcases form-fitting outfits from famous designers and shows her perfect figure.",
      l_dis:
        "Welcome to So Different Zendaya game. We have often seen Zendaya on the red carpet, where she showcases form-fitting outfits from famous designers and shows her perfect figure. But, in fact, she is a very versatile girl. Zendaya has been dancing since childhood. At the age of 8, she was a member of the hip-hop troupe of the California Theater, where her mother worked. Zendaya began to go to strength training when contracts for films appeared, where you need to perform physically difficult stunts. The actress goes to the gym 3-4 times a week and loves to make classes a joy. And, of course, she, like any girl, loves to walk and go shopping. Let’s help Zendaya get ready for any daily routine.Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup.Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Cutedressup has a cute collection of dress-up and makeup games for girls. Play them and have fun!",
      tag: ["Dress up", "Make Up", "Celebrity", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211118,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF Lovely Kawaii Outfits - playcutegames.com",
      name: ["BFF Lovely Kawaii Outfits", "bff-lovely-kawaii-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-lovely-kawaii-outfits/250x142.png",
      path: ["/game/bff-lovely-kawaii-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff-lovely-kawaii-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to BFF Lovely Kawaii Outfits game. Princesses Elsa, Anna, Moana, and Jasmine are more fascinated about Kawaii Outfits.",
      l_dis:
        `Welcome to BFF Lovely Kawaii Outfits game. Princesses Elsa, Anna, Moana, and Jasmine are more fascinated about Kawaii Outfits. They are very eager to browse the new collection of Kawaii outfits. Start helping with each princess for their outfits and matching costumes by browse their wardrobe. Play these cute girl games and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Kawaii",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211117,
      brand: "dressupwho",
     
      orientation: 0,
      title: "LOL Surprise Coachella - playcutegames.com",
      name: ["LOL Surprise Coachella", "lol-surprise-coachella"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lol-surprise-coachella/250x142.png",
      path: ["/game/lol-surprise-coachella", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/lol-surprise-coachella/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to LOL Surprise Coachella. Our LOL Surprise fashionistas are getting ready for their own Coachella festival in this brand-new dress-up game",
      l_dis:
        "Welcome to LOL Surprise Coachella. Our LOL Surprise fashionistas are getting ready for their own Coachella festival in this brand-new dress-up game for girls called LOL Surprise Coachella! It’s a fun warmup session for the real gig which will take place in October this year but that doesn’t mean they won’t pay great attention to the outfits they are going to wear. Join and have fun!",
      tag: ["Dress up", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211116,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Black Friday Collections - playcutegames.com",
      name: [
        "Princess Black Friday Collections",
        "princess-black-friday-collections",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-black-friday-collections/250x142.png",
      path: [
        "/game/princess-black-friday-collections",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess-black-friday-collections/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Black Friday Collections. Princess Elsa, Anna, Jasmine, Snow White, Moana, and Ariel are ready for shopping! Is anything special, all the princesses are going together? Yes,",
      l_dis:
        `Welcome to Princess Black Friday Collections. Princess Elsa, Anna, Jasmine, Snow White, Moana, and Ariel are ready for shopping! Is anything special, all the princesses are going together? Yes, It’s Black Friday. They are all excited like us for this day. Join and help the princesses with your skill. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Festival",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211115,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Blondy in Pink - playcutegames.com",
      name: ["Blondy in Pink", "blondy-in-pink"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/blondy-in-pink/250x142.png",
      path: ["/game/blondy-in-pink", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1637507149/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Blondy in Pink. Meet everyone’s favorite – Blondy! She is a real fashionista and a very sweet girl. And, like all girls, Blondy loves pink! Let’s spend one day with this adorable blonde.",
      l_dis:
        "Welcome to Blondy in Pink. Meet everyone’s favorite – Blondy! She is a real fashionista and a very sweet girl. And, like all girls, Blondy loves pink! Let’s spend one day with this adorable blonde. First, let’s devote a few minutes to facial treatment. We need to apply cream, get rid of acne and dark circles under the eyes. Then we will choose bright stylish makeup for Blondy with all kinds of shades of pink. After the make-up is the turn of the great hairstyle and sparkly jewelry. Finally, choose from a variety of sparkling, colorful outfits for our girl. Don’t forget to complement your clothes with fashionable accessories. Now, take your pet with you and go for a walk with the Blondy.",
      tag: ["Barbie", "Dress up", "Make Up", "Festival", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211114,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Princess Black Friday Chaos - playcutegames.com",
      name: ["Princess Black Friday Chaos", "princess-black-friday-chaos"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-black-friday-chaos/250x142.png",
      path: ["/game/princess-black-friday-chaos", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/princess-black-friday/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Black Friday Chaos. It is the day after thanksgiving and the princesses need some retail therapy!",
      l_dis:
        "Welcome to Princess Black Friday Chaos. It is the day after thanksgiving and the princesses need some retail therapy! They need some new clothes for the new year! Help Elsa, Moana, and Sleeping Beauty grab all the sale items. This Black Friday is going to be insane so you need to have quick hands. Let us see who can grab the most items! Have fun and tell us your scores in the comments!Start with princess dress up, choose a pair of cute tops and bottom or perfect outfits. Pick a long hairstyle and sparkling accessories. Do the same for all the princesses with different costumes. Play and have fun!",
      tag: ["Princess", "Dress up", "Make Up", "Festival", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211113,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Cute Puppies Puzzle - playcutegames.com",
      name: [
        "Cute Puppies Puzzle",
        "cute-puppies-puzzle",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-puppies-puzzle/250x142.png",
      path: [
        "/game/cute-puppies-puzzle",
        "https://playcutegames.com",
      ],
      iframe: "https://dl-girls.com/content/folder_1652459191/",
      Walkthrough: "https://www.youtube.com/embed/bv5L7eMkFFQ",
      s_dis:
        "Welcome to Cute Puppies Puzzle. We can not always afford to buy expensive goods. Black Friday is a real holiday or a day of fulfillment of wishes,",
      l_dis:
        "Welcome to Cute Puppies Puzzle. It’s time for puzzles! Just look at these cute and funny puppies. They love to eat delicious food, fly into space and love ice cream. Test your wits. Drag and Drop the puzzle pieces in the right place to complete the levels. Collect all the pictures with these wonderful puppies and have fun!",
      tag: ["Puzzle", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211113,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Marinette Freaky Black Friday Sale - playcutegames.com",
      name: [
        "Marinette Freaky Black Friday Sale",
        "marinette-freaky-black-friday-sale",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/marinette-freaky-black-friday-sale/250x142.png",
      path: [
        "/game/marinette-freaky-black-friday-sale",
        "https://playcutegames.com",
      ],
      iframe: "https://dl-girls.com/content/folder_1606411483/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Marinette Freaky Black Friday Sale. We can not always afford to buy expensive goods. Black Friday is a real holiday or a day of fulfillment of wishes,",
      l_dis:
        "Welcome to Marinette Freaky Black Friday Sale. We can not always afford to buy expensive goods. Black Friday is a real holiday or a day of fulfillment of wishes, when clothes, equipment, and other types of goods can be purchased at a pleasantly low price. Let’s go shopping with Marinet in Paris. This fashion center is a paradise for girls. Choose bright outfits, shoes, accessories, and branded cosmetics! And gifts are waiting for you!",
      tag: ["Princess", "Dress up", "Make Up", "Festival", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211111,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Harley and BFF PJ Party - playcutegames.com",
      name: ["Harley and BFF PJ Party", "harley-and-bff-pj-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/harley-and-bff-pj-party/250x142.png",
      path: ["/game/harley-and-bff-pj-party", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/harley-and-bff-pj-party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Harley and BFF PJ Party. Harley and her friends Mel and Cruella decided to have a pajama party. Hooligan girls also love casual entertainment.",
      l_dis:
        "Welcome to Harley and BFF PJ Party. Harley and her friends Mel and Cruella decided to have a pajama party. Hooligan girls also love casual entertainment. What could be better than getting together with your friends, doing each other’s makeup, fooling around, having a pillow fight, and watching your favorite movie together? Choose the right pajamas or dressing gown for each character. Have fun with the girl-hooligans at Harley’s Pajama Party",
      tag: ["Harley", "Dress up", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211110,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bff Weekend Style - playcutegames.com",
      name: ["Bff Weekend Style", "bff-weekend-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-weekend-style/250x142.png",
      path: ["/game/bff-weekend-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff-weekend-style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bff Weekend Style. Hairdorable Girls Dee dee, Noah, and Willow decided to visit some new places on weekend. They planned to try a new style of outfits and costumes to attract everyone.",
      l_dis:
        `Welcome to the Bff Weekend Style. Hairdorable Girls Dee dee, Noah, and Willow decided to visit some new places on weekend. They planned to try a new style of outfits and costumes to attract everyone. Help the girls to pick an outfit from the wardrobe and make them more pretty as you like. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables doll",
        "Bff",
        "Dress up",
        "Make Up",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211109,
      brand: "Prinxy",
     
      orientation: 0,
      title: "BFFs Black Friday Shopping - playcutegames.com",
      name: ["BFFs Black Friday Shopping", "bffs-black-friday-shopping"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bffs-black-friday-shopping/250x142.png",
      path: ["/game/bffs-black-friday-shopping", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/df74e08c-fa18-59e8-8992-0dfe6afa832a/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this online game for girls called BFFs Black Friday Shopping, you help five friends get their looks ready to hit the mall and shop till they drop.",
      l_dis:
        "In this online game for girls called BFFs Black Friday Shopping, you help five friends get their looks ready to hit the mall and shop till they drop. Brian and the girls are heading to the mall for a fun shopping spree on Black Friday, and you girls should join the shopaholics. Get started with this online dress-up game for girls and see what stunning looks you can find for each of them. Don’t forget to accessorize their final looks with designer purses and sparkly jewelry! Have fun!",
      tag: ["Bff", "Dress up", "Shopping", "Festival", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211107,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Burning Man Stay at Home - playcutegames.com",
      name: ["Burning Man Stay at Home", "burning-man-stay-at-home"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/burning-man-stay-at-home/250x142.png",
      path: ["/game/burning-man-stay-at-home", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/burning-man-stay-home/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Burning Man Stay at Home. The famous Burning Man festival is approaching, but it is canceled due to quarantine. What should young fashionistas do who are missing entertainment and want to show everyone",
      l_dis:
        "Welcome to the Burning Man Stay at Home. The famous Burning Man festival is approaching, but it is canceled due to quarantine. What should young fashionistas do who are missing entertainment and want to show everyone their costumes? Princesses Elsa, Jasmine, and Merida take matters into their own hands. They had a great idea – to put on a fashion show online. They gathered all their colorful outfits and put on a real festival without leaving their homes! Using famous social media, the girls show off their costumes for Burning Man.",
      tag: ["Princess", "Dress up", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211106,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Festival Dia de Muertos - playcutegames.com",
      name: ["Festival Dia de Muertos", "festival-dia-de-muertos"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/festival-dia-de-muertos/250x142.png",
      path: ["/game/festival-dia-de-muertos", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1636743257/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Festival Dia de Muertos. The Dia de Muertos is a joyful celebration of life: love, memory, and reverence are the main characters of the event.",
      l_dis:
        "Welcome to the Festival Dia de Muertos. The Dia de Muertos is a joyful celebration of life: love, memory, and reverence are the main characters of the event. Dia de Los Muertos is the acceptance of death without mourning and the real glory of life. The Day of the Dead is celebrated in Mexico and in the United States, in places where large Mexican communities live. The holiday lasts for three days, starting on October 31. On this day, women clean houses and cook food. Men build altars out of clay. This year, princesses the Little Mermaid, Lisa, and Jacqueline are also participating in the festival. Help the girls make impressive makeup for the Day of the Dead, create a luxurious hairstyle with braids, and, of course, choose decent outfits for such a holiday. ",
      tag: ["Princess", "Dress up", "Make Up", "Festival", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211106,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Raya Multiverse Fashion - playcutegames.com",
      name: ["Raya Multiverse Fashion", "raya-multiverse-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/raya-multiverse-fashion/250x142.png",
      path: ["/game/raya-multiverse-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/raya-multiverse-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Raya Multiverse Fashion. Princess Raya had a dream about a multiverse. There she is in a different trendy style.",
      l_dis:
        `Welcome to the Raya Multiverse Fashion. Princess Raya had a dream about a multiverse. There she is in a different trendy style. She loves to be the same in real life also. So she plans to try multiverse fashion with her own style. Join Raya and help her to pick a cute trendy outfit. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Fashion",
        "Raya",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211106,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Kim K Busy Day - playcutegames.com",
      name: ["Kim K Busy Day", "kim-k-busy-day"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/kim-k-busy-day/250x142.png",
      path: ["/game/kim-k-busy-day", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/kim-k-busy-day/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Kim K Busy Day. Ever wanted to know what it’s like to be Kim Kardashian’s personal fashion adviser?",
      l_dis:
        "Welcome to the Kim K Busy Day. Ever wanted to know what it’s like to be Kim Kardashian’s personal fashion adviser? Well, you get to prove your amazing skills today as this famous socialite has four major events on her agenda and she needs one jaw-dropping look for each of them. Feel free to join her in getting started this brand-new celebrity dress-up game for girls and see if you can keep up with all the fashion challenges she has prepared for her assistant today.",
      tag: ["Celebrity", "Dress up", "Make Up", "Supermodel", "Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211106,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Strike Force Heroine RPG - playcutegames.com",
      name: ["Strike Force Heroine RPG", "strike-force-heroine-rpg"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/strike-force-heroine-rpg/250x142.png",
      path: ["/game/strike-force-heroine-rpg", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/strike-force-heroine-rpg/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Strike Force Heroine RPG. Do you love online games? Then this game is especially for you! Enjoy special magical Genshin-themed costumes.",
      l_dis:
        "Welcome to the Strike Force Heroine RPG. Do you love online games? Then this game is especially for you! Enjoy special magical Genshin-themed costumes. Create your own beautiful warrior or powerful sorceress. Magnificent airy dresses and original costumes will not leave you indifferent. Lots of hairstyles and accessories will help create an absolutely unique heroine. Don’t forget about cute ears and wings! Create a whole fighting squad of three girls. Have fun",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Kawaii",
        "Harley Quinn",
        "Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211105,
      brand: "Dressupwho",
     
      orientation: 0,
      title: "Kawaii Princess At Comic Con - playcutegames.com",
      name: ["Kawaii Princess At Comic Con", "kawaii-princess-at-comic-con"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/kawaii-princess-at-comic-con/250x142.png",
      path: ["/game/kawaii-princess-at-comic-con", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/kawaii-princess-at-comic-con/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Kawaii Princess At Comic Con. Our favorite princesses – Harley, Eliza, and Jasmina – are going to the biggest cosplay event this fall",
      l_dis:
        "Welcome to Kawaii Princess At Comic Con. Our favorite princesses – Harley, Eliza, and Jasmina – are going to the biggest cosplay event this fall – Comic Con. ComicCon is a new event that will showcase all the best from the world of films, TV series, computer, and board games, entertainment literature, and, of course, popular comics, anime, and manga. This is a great holiday for everyone who is not alien to modern pop culture in any of its manifestations. Choose incredible kawaii hairstyles and princess makeup. Don’t forget about cute pastel-colored costumes and tons of accessories! Have fun!",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Kawaii",
        "Harley Quinn",
        "Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Turned Into Mermaid - playcutegames.com",
      name: ["Princess Turned Into Mermaid", "princess-turned-into-mermaid"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-turned-into-mermaid/250x142.png",
      path: ["/game/princess-turned-into-mermaid", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-turned-into-mermaid/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Turned Into Mermaid. Princess Ariel throws a party at her place. She invited her friends Princess Elsa, Moana, and Jasmine.",
      l_dis:
        `Welcome to Princess Turned Into Mermaid. Princess Ariel throws a party at her place. She invited her friends Princess Elsa, Moana, and Jasmine. They are all happy to visit Ariel’s place and excited to be a mermaid. But they need perfect costumes for the party. Browse Ariel’s wardrobe to help other princesses with mermaid outfits. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess", "Dress up", "Mermaid","Fashion", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211104,
      brand: "Prinxy",
     
      orientation: 0,
      title: "TikTok Fall Fashion - playcutegames.com",
      name: ["TikTok Fall Fashion", "tiktok-fall-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-fall-fashion/250x142.png",
      path: ["/game/tiktok-fall-fashion", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/019008a0-5961-528c-9a74-804e7ec132e5/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this online game for girls called TikTok Fall Fashion, you help three beautiful best friends get their daily looks updated to the latest fashion trends.",
      l_dis:
        "In this online game for girls called TikTok Fall Fashion, you help three beautiful best friends get their daily looks updated to the latest fashion trends. Try bold colors for their makeup looks, and then mix and match sparkly sequins with cozy knits into jaw-dropping outfits. Don’t forget to accessorize their final looks with designer purses and sparkly jewelry! Have fun!",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Fall",
        "Autumn",
        "Prinxy Games"
      ]
  ,
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211103,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Princess Sweet Kawaii Fashion - playcutegames.com",
      name: ["Princess Sweet Kawaii Fashion", "princess-sweet-kawaii-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-sweet-kawaii-fashion/250x142.png",
      path: ["/game/princess-sweet-kawaii-fashion", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1636127432/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Princess Sweet Kawaii Fashion. Princess Eliza travels to Japan to study teenage kawaii style.",
      l_dis:
        "Welcome to the Princess Sweet Kawaii Fashion. Princess Eliza travels to Japan to study teenage kawaii style. All kinds of bows, lace, frills are used as decorations, which mothers loved to supply their princesses within childhood. The kawaii style also has an unusual hair color. And makeup is limited only by your imagination. There is no place for boring shades, only gentle tones and sparkles! Create your own unique kawaii look with Princess Eliza! Have fun!",
      tag: ["Princess", "Dress up", "Make Up", "Fashion", "Kawaii", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Magical Fairy Fashion Look - playcutegames.com",
      name: ["Magical Fairy Fashion Look", "magical-fairy-fashion-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/magical-fairy-fashion-look/250x142.png",
      path: ["/game/magical-fairy-fashion-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/magical-fairy-fashion-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Magical Fairy Fashion Look. Skyler and Sunny love things about fairies. Fairies always look pretty, wings and magical powers.",
      l_dis:
        `Welcome to the Magical Fairy Fashion Look. Skyler and Sunny love things about fairies. Fairies always look pretty, wings and magical powers. They love to look like them. But they have dilemma to choose perfect costumes. Help them to pick a cute outfit, pretty accessories, and wings. Play and have fun with our cute games.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "dressupwho",
     
      orientation: 0,
      title: "Harajuku Princess - playcutegames.com",
      name: ["Harajuku Princess", "harajuku-princess"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/harajuku-princess/250x142.png",
      path: ["/game/harajuku-princess", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/harajuku-princess/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Harajuku Princess. Are you ready for another cute-themed dress-up game? Today you are invited to help Jasmine, Tiana, and Elsa discover the colorful",
      l_dis:
        "Welcome to Harajuku Princess. Are you ready for another cute-themed dress-up game? Today you are invited to help Jasmine, Tiana, and Elsa discover the colorful Harajuku fashion style. Harajuku fashion is a mix of all the well-known Japanese sub-styles. There are no rules to follow while creating a Harajuku outfit… so feel free to cherry pick whatever you fancy the most. And guess what? Your selected items do not have to match but the more utrageous, the better. Have a blast! Play and have fun!",
      tag: ["Princess", "Dress up", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Design With Me Fall Sweater - playcutegames.com",
      name: ["Design With Me Fall Sweater", "design-with-me-fall-sweater"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/design-with-me-fall-sweater/250x142.png",
      path: ["/game/design-with-me-fall-sweater", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/design-with-me-fall-sweater/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Design With Me Fall Sweater. Princess Elsa, Anna, and Ariel are the best friends who love more to do creative.",
      l_dis:
        `Welcome to Design With Me Fall Sweater. Princess Elsa, Anna, and Ariel are the best friends who love more to do creative. For this Autumn they planning to create and design their own fall sweater. Help the girls to pick and design a perfect sweater. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Decoration",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "VSCO Girl Aesthetic - playcutegames.com",
      name: ["VSCO Girl Aesthetic", "vsco-girl-aesthetic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/vsco-girl-aesthetic/250x142.png",
      path: ["/game/vsco-girl-aesthetic", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/vsco-girl-aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the VSCO Girl Aesthetic. Rainbow High school girls Sunny, Skyler, Ruby, and Violet are the trendy fashion girls.",
      l_dis:
        `Welcome to the VSCO Girl Aesthetic. Rainbow High school girls Sunny, Skyler, Ruby, and Violet are the trendy fashion girls. They planned to try VSCO fashion which is trending all over social media. Join and browse their wardrobe to pick some suitable outfits for the girls. Play and Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Aesthetic",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Matryoshka Maker - playcutegames.com",
      name: ["Matryoshka Maker", "matryoshka-maker"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/matryoshka-maker/250x142.png",
      path: ["/game/matryoshka-maker", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1635354705/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Matryoshka Maker. Matryoshka is popular all over the world. It has a fascinating history. These toys are produced in huge quantities",
      l_dis:
        "Welcome to Matryoshka Maker. Matryoshka is popular all over the world. It has a fascinating history. These toys are produced in huge quantities, and in some cities, there are entire museums dedicated to this unusual doll.  Let’s create our own unique nesting doll! Choose colors and patterns for different parts of the matryoshka – body, apron, stand, shirt, and scarf.",
      tag: ["Dress up", "Make Up", "Decoration", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "dressupwho",
     
      orientation: 0,
      title: "Black and White Halloween - playcutegames.com",
      name: ["Black and White Halloween", "black-and-white-halloween"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/black-and-white-halloween/250x142.png",
      path: ["/game/black-and-white-halloween", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/black-and-white-halloween/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Black and White Halloween. Black and white colors are in trend today. And still very close to everyone’s favorite holiday – Halloween.",
      l_dis:
        "Welcome to Black and White Halloween. Black and white colors are in trend today. And still very close to everyone’s favorite holiday – Halloween. Let’s mix these two fashion trends! Meet your favorite princesses – Mulan, Elsa, and Ariel in black and white witch outfits. Black and white colors have always been a model of style, their simplicity and elegance can hardly be overestimated. Create a unique look of stylish witches this Halloween!",
      tag: ["Princess", "Dress up", "Make Up", "Halloween", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Secret College Crush - playcutegames.com",
      name: ["My Secret College Crush", "my-secret-college-crush"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-secret-college-crush/250x142.png",
      path: ["/game/my-secret-college-crush", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-secret-college-crush/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Secret College Crush. Ellie started College with Ben. She has a crush on Ben. But Ben wouldn’t even look at Ellie.",
      l_dis:
        `Welcome to the My Secret College Crush. Ellie started College with Ben. She has a crush on Ben. But Ben wouldn’t even look at Ellie.  She decided to start with the simplest: a little change of style is always a good idea! Can you help her with new looks? And in case they are lucky enough to be asked out for a date, you are going to help her with choosing outing outfits, too!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "College",
        "Ellie",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Autumn Bright Outfits - playcutegames.com",
      name: ["My Autumn Bright Outfits", "my-autumn-bright-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-autumn-bright-outfits/250x142.png",
      path: ["/game/my-autumn-bright-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-autumn-bright-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Autumn Bright Outfits game. Dee dee, Willow, and Noah are the best friends who admire more on fashion trends.",
      l_dis:
        `Welcome to the My Autumn Bright Outfits game. Dee dee, Willow, and Noah are the best friends who admire more on fashion trends. They heard about autumn is near. So they planned to welcome the most beautiful season with their latest fashion trends. Join and help them to pick a cute outfits. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "hairdorables doll",
        "Autumn",
        "Season",
        "Willow",
        "Dee dee",
        "Noah",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Raya Back To Kumandra - playcutegames.com",
      name: ["Raya Back To Kumandra", "raya-back-to-kumandra"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/raya-back-to-kumandra/250x142.png",
      path: ["/game/raya-back-to-kumandra", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/raya-back-to-kumandra/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Raya Back To Kumandra game. Raya and Namaari are close friends who moved to cities for their high school.",
      l_dis:
        `Welcome to Raya Back To Kumandra game. Raya and Namaari are close friends who moved to cities for their high school. Now they got an invite to their native Kumandra. They are more excited to back to Kumandra and planned to be there in a more traditional way. Come and join them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "Princess", "Namari", "Raya"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Cute Witch Princess - playcutegames.com",
      name: ["Cute Witch Princess", "cute-witch-princess"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-witch-princess/250x142.png",
      path: ["/game/cute-witch-princess", "https://playcutegames.com"],
      iframe: "https://www.dl-girls.com/content/folder_1633580503/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Cute Witch Princess. Everyone’s favorite holiday is approaching – Halloween. What will the funny princesses come up with this time? The girls decided to change their traditional outfits",
      l_dis:
        "Welcome to Cute Witch Princess. Everyone’s favorite holiday is approaching – Halloween. What will the funny princesses come up with this time? The girls decided to change their traditional outfits, turning them into a witch dress! Do you want to know what your favorite princess will look like in the form of a witch? Then rather play this wonderful game called Cute Witch Princess.",
      tag: ["Dress up", "Make Up", "Princess", "Witch", "Halloween", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Ellie And Ben Fall Date - playcutegames.com",
      name: ["Ellie And Ben Fall Date", "ellie-and-ben-fall-date"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-and-ben-fall-date/250x142.png",
      path: ["/game/ellie-and-ben-fall-date", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/ellie-and-ben-fall-date/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Ellie And Ben Fall Date. Ben asked for a date for Ellie. She is so excited and she wants to be more attractive.",
      l_dis:
        `Welcome to the Ellie And Ben Fall Date. Ben asked for a date for Ellie. She is so excited and she wants to be more attractive. She needs some expert advice. Browse her wardrobe and pick a cute outfit for her. Join and help her to choose perfect outfits.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Barbie",
        "Ellie",
        "Date",
        "fall",
        "autumn",
        "couple",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "TikTok VSCO Girls - playcutegames.com",
      name: ["TikTok VSCO Girls", "tiktok-vsco-girls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-vsco-girls/250x142.png",
      path: ["/game/tiktok-vsco-girls", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/d58eb532-88df-5444-aa90-6ef9a6e18e5c/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to TikTok VSCO Girls. Play this fun dress-up game for girls called TikTok VSCO Girls, and help the two BFFs discover the VSCO aesthetics as you dress them up.",
      l_dis:
        "Welcome to TikTok VSCO Girls. Play this fun dress-up game for girls called TikTok VSCO Girls, and help the two BFFs discover the VSCO aesthetics as you dress them up. Then do their makeup, change their hairstyles, and accessorize their final looks with hydro flasks and girly-girl photo cameras. Have a great time playing our online TikTok Game for girls!",
      tag: ["Dress up", "Make Up", "Tiktok Trends", "VSCO Girls", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Baby Boss Back In Business - playcutegames.com",
      name: ["Baby Boss Back In Business", "baby-boss-back-in-business"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/baby-boss-back-in-business/250x142.png",
      path: ["/game/baby-boss-back-in-business", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/baby-boss-back-in-business/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Baby Boss Back In Business game. After a small vacation Baby Boss is back into his business.",
      l_dis:
        `Welcome to the Baby Boss Back In Business game. After a small vacation Baby Boss is back into his business. It’s gonna be a fresh start after a small gap. He needs to be perfect in look. Pick a fabulous suit for him. Join and help the boss. Have fun! Enjoy playing these new cute games for girls.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Decoration", "Baby Boss", "Kids"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Iconic Celebrity Look - playcutegames.com",
      name: ["Iconic Celebrity Look", "iconic-celebrity-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/iconic-celebrity-look/250x142.png",
      path: ["/game/iconic-celebrity-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/iconic-celebrity-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Iconic celebrity Look. These princesses sure have their favorite celebs and they just want to dress up like them. In this game, you will discover the most iconic celebrity outfits!",
      l_dis:
        `Welcome to the Iconic celebrity Look. These princesses sure have their favorite celebs and they just want to dress up like them. In this game, you will discover the most iconic celebrity outfits! You will have the chance to dress up these princesses as your favorite celebs. Play them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Rainbow girls",
        "Celebrity",
        "Violet",
        "Skyler",
        "Sunny",
        "Ruby",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Trendy Plaid Outfits - playcutegames.com",
      name: ["My Trendy Plaid Outfits", "my-trendy-plaid-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-trendy-plaid-outfits/250x142.png",
      path: ["/game/my-trendy-plaid-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-trendy-plaid-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Trendy Plaid Outfits. Plaid is back on the top list when it comes to fashion trends! And who else should know more about Plaid outfit",
      l_dis:
        `Welcome to the My Trendy Plaid Outfits. Plaid is back on the top list when it comes to fashion trends! And who else should know more about Plaid outfit if not Princess Ariel, Moana and Jasmine? This game is all about wearing the Plaid outfit the right way! There are many great ways to wear the timeless Plaid print, but you need to know how to match it with the rest of the outfit to avoid a fashion disaster. Are you ready to explore different outfits with us?
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Princess",
        "Jasmine",
        "Moana",
        "Ariel",
        "Plaid outfits",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Insta Girls Fruity Fashion - playcutegames.com",
      name: ["Insta Girls Fruity Fashion", "insta-girls-fruity-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/insta-girls-fruity-fashion/250x142.png",
      path: ["/game/insta-girls-fruity-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/insta-girls-fruity-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Insta Girls Fruity Fashion. Dee Dee arranges a party at her home. Yes! It’s a fruity fashion Party.",
      l_dis:
        `Welcome to the Insta Girls Fruity Fashion. Dee Dee arranges a party at her home. Yes! It’s a fruity fashion Party. She invites her friends Willow and Noah to the party. Help them to browse their wardrobe to pick the best outfit. Come and Join the girls and have fun! Enjoy playing these new cute games for girls. Have a great time with our cute games. If you are looking for the best makeup games, then look no further. Playcutegames.com has a cute collection of games for makeup. Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "Instagram", "hairdorables doll"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Zentangle Coloring Book - playcutegames.com",
      name: ["Zentangle Coloring Book", "zentangle-coloring-book"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/zentangle-coloring-book/250x142.png",
      path: ["/game/zentangle-coloring-book", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1632759992/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Zentangle Coloring Book. All children love to draw and color. It develops the brain and calms the nerves. Meet the original coloring in the Zentagle",
      l_dis:
        "Welcome to Zentangle Coloring Book. All children love to draw and color. It develops the brain and calms the nerves. Meet the original coloring in the Zentagle technique! This style was invented in 2006. Drawings in this style usually consist of some kind of repeating pattern. Many patterns can be combined in one drawing, and the technique itself is quite free and intuitive. Play and color on Playcutegames.com. Take a screenshot and share the result with your friends!",
      tag: ["Kids", "Dl-Girls", "Coloring Games", "Girl games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Highschool Mean Girls 2 - playcutegames.com",
      name: ["Highschool Mean Girls 2", "highschool-mean-girls-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/highschool-mean-girls-2/250x142.png",
      path: ["/game/highschool-mean-girls-2", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/29be8b59-6722-5fb5-9d9c-eb1bad17f4cd/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Highschool Mean Girls 2. The mean girls are back to school, this time in a brand-new formula. You can join them in our online dress-up game for girls called Highschool Mean Girls 2",
      l_dis:
        "Welcome to Highschool Mean Girls 2. The mean girls are back to school, this time in a brand-new formula. You can join them in our online dress-up game for girls called Highschool Mean Girls 2 and help them style up their looks for the first day of school. Get it started, and have a great time creating jaw-dropping outfits for these 2 groups of BFFs competing in the high school fashion challenge. Only one group will be the next fashion queen, but who will be our winners? The supermodels or the singers?",
      tag: [
        "Dress up",
        "Celebrity",
        "Fashion",
        "Highschool",
        "Prinxy Games",
        "Selena Gomez",
        "Kendall Jenner",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Popular 80's Fashion Trends - playcutegames.com",
      name: ["Popular 80's Fashion Trends", "popular-80s-fashion-trends"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/popular-80s-fashion-trends/250x142.png",
      path: ["/game/popular-80s-fashion-trends", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/popular-80s-fashion-trends/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Popular 80’s Fashion Trends. Rainbow high school girls are in love with the 80’s style. And they are going to dress up accordingly.",
      l_dis:
        `Welcome to the Popular 80’s Fashion Trends. Rainbow high school girls are in love with the 80’s style. And they are going to dress up accordingly. Who else loves it? Have you ever created an 80’s style outfit? Here is your chance to create four different ones, one for each girl. The wardrobe is stacked with tones of clothes waiting for you, so have fun dressing them up!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Anna",
        "Elsa",
        "Snow White",
        "Jasmine",
        "Moana",
        "Ariel",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Incredible Princess Eye Art 2 - playcutegames.com",
      name: ["Incredible Princess Eye Art 2", "incredible-princess-eye-art-2"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/incredible-princess-eye-art-2/250x142.png",
      path: ["/game/incredible-princess-eye-art-2", "https://playcutegames.com"],
      iframe: "https://www.dl-girls.com/content/folder_1631636380/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to an Incredible Princess Eye Art 2 Game. This is a tutorial from a fabulous makeup artist. Try your hand at makeup on the beautiful eyes of cute princesses.",
      l_dis:
        "Welcome to an Incredible Princess Eye Art 2 Game. This is a tutorial from a fabulous makeup artist. Try your hand at makeup on the beautiful eyes of cute princesses. Use extraordinary artistic solutions to create a striking and non-standard look. Use new trends: angel makeup, rhinestones, animal prints, neon effects. Grab your makeup brush and get started! Try out new art options for the eyes.",
      tag: ["Dress up", "Make Up", "Decoration", "Faceart", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Love Pinky Outfits - playcutegames.com",
      name: ["Princess Love Pinky Outfits", "princess-love-pinky-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-love-pinky-outfits/250x142.png",
      path: ["/game/princess-love-pinky-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-love-pinky-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Love Pinky Outfits. Princess Anna, Elsa, Moana, Jasmine, Snow White, and Ariel met after a long time. They are Planned to throw a weekend party with their beloved theme called Pinky Outfits.",
      l_dis:
        `Welcome to Princess Love Pinky Outfits. Princess Anna, Elsa, Moana, Jasmine, Snow White, and Ariel met after a long time. They are Planned to throw a weekend party with their beloved theme called Pinky Outfits. Browse the wardrobe for each princess and pick cute pinky outfits. Join with girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Anna",
        "Elsa",
        "Snow White",
        "Jasmine",
        "Moana",
        "Ariel",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Blondy Extra - playcutegames.com",
      name: ["Blondy Extra", "blondy-extra"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/blondy-extra/250x142.png",
      path: ["/game/blondy-extra", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1631034618/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Blondy Extra game. Blondie Extra’s style is bold fashion and bright colors, as well as a loud statement! Each girl has her own unique playful",
      l_dis:
        "Welcome to Blondy Extra game. Blondie Extra’s style is bold fashion and bright colors, as well as a loud statement! Each girl has her own unique playful and cool style. And their pets – all different and all adorable – also have a subtle personality! A fun and playful look showcase their confident style – take a look at these pink denim shorts with teddy bear prints paired with a matching jacket with super fluffy sleeves. And here’s a ruffle top in lavender with stars paired with frayed-edged shorts and a neon faux fur coat. Blondie’s accessories – cell phone wallet, sunglasses, jewelry, green socks, and brown shoes – add personality!",
      tag: ["Dress up", "Make Up", "Fashion", "Blondie", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Stylish Ball Gown - playcutegames.com",
      name: ["My Stylish Ball Gown", "my-stylish-ball-gown"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-stylish-ball-gown/250x142.png",
      path: ["/game/my-stylish-ball-gown", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-stylish-ball-gown/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Stylish Ball Gown game. Adorable girls Dee dee, Willow, and Noah are BFFs planned for a get-together party. Yesss! it’s a stylish ball gown party.",
      l_dis:
        `Welcome to the My Stylish Ball Gown game. Adorable girls Dee dee, Willow, and Noah are BFFs planned for a get-together party. Yesss! it’s a stylish ball gown party. Help the girls to pick a perfect gown and costumes for the party. Join with a girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "hairdorables doll",
        "Willow",
        "Dee dee",
        "Noah",
        "Ball Gown",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Summer Festivals Fashion - playcutegames.com",
      name: ["Summer Festivals Fashion", "summer-festivals-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/summer-festivals-fashion/250x142.png",
      path: ["/game/summer-festivals-fashion", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/b09741c1-e218-5170-87b1-329e10286d49/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Summer Festivals Fashion. This summer, festivals are back in trend, and you are going to have a blast attending 3 famous parties in our dress-up game for girls called",
      l_dis:
        "Welcome to Summer Festivals Fashion. This summer, festivals are back in trend, and you are going to have a blast attending 3 famous parties in our dress-up game for girls called Summer Festivals Fashion. Get it started, and have a great time creating jaw-dropping #ootd for these 6 BFFs getting ready to dance, dance, dance while looking in style. Have a blast!",
      tag: [
        "Dress up",
        "Prinxy Games",
        "Princess",
        "Festival",
        "Summer",
        "Celebrity",
        "Ariana",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Cyberpunk City Fashion - playcutegames.com",
      name: ["Cyberpunk City Fashion", "cyberpunk-city-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cyberpunk-city-fashion/250x142.png",
      path: ["/game/cyberpunk-city-fashion", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/f0229414-aa9d-5652-97d3-e3442fe4e606/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Cyberpunk City Fashion game. Belle, Ariel, Moana, and Elsa are willing to give up their stunning princess dress and have the full cyberpunk experience in this Prinxy",
      l_dis:
        "Welcome to the Cyberpunk City Fashion game. Belle, Ariel, Moana, and Elsa are willing to give up their stunning princess dress and have the full cyberpunk experience in this Prinxy.app game for girls. Surrounded by futuristic neon lights, the royal BFFs wait for you to join the fun and help them decide on the winning looks. Are you ready to join them in the Cyberpunk City Fashion game?",
      tag: [
        "Dress up",
        "Prinxy Games",
        "Fashion",
        "Princess",
        "Elsa",
        "Belle",
        "Moana",
        "Ariel",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Insta Makeup: Bride - playcutegames.com",
      name: ["Insta Makeup: Bride", "insta-makeup-bride"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/insta-makeup-bride/250x142.png",
      path: ["/game/insta-makeup-bride", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/577755f1-d6ca-54d1-bf32-2aa8091a99e6/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Insta Makeup: Bride. Sophia’s wedding day is almost here, but luckily she still has some time left to experiment with her bridal makeup look.",
      l_dis:
        "Welcome to the Insta Makeup: Bride. Sophia’s wedding day is almost here, but luckily she still has some time left to experiment with her bridal makeup look. In the Insta Makeup: Bride, you play the role of a super talented makeup artist in charge of our gorgeous girl’s wedding makeup look. Get it started, select your favorite shades, and create three unique makeup looks for every kind of bride. Natural, bold, or glam? Which bridal makeup suits her best?",
      tag: ["Dress up", "Instagram", "Make Up", "Wedding", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Fabulous Vintage Look - playcutegames.com",
      name: ["My Fabulous Vintage Look", "my-fabulous-vintage-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-fabulous-vintage-look/250x142.png",
      path: ["/game/my-fabulous-vintage-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-fabulous-vintage-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to My Fabulous Vintage Look. Princess Anna, Elsa, and Moana are planned for the weekend Fashion. They decided to try vintage fashion outfits.",
      l_dis:
        `Welcome to My Fabulous Vintage Look. Princess Anna, Elsa, and Moana are planned for the weekend Fashion. They decided to try vintage fashion outfits. Play as a fashion designer and help them to pick a unique costume for each one. Enjoy playing this new game and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "vintage", "Fashion", "Princess"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Monster Popsy Dolls - playcutegames.com",
      name: ["Monster Popsy Dolls", "monster-popsy-dolls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/monster-popsy-dolls/250x142.png",
      path: ["/game/monster-popsy-dolls", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1630514497/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Monster Popsy Dolls game. Meet incredible monster-popsy. These toys are both beautiful and terrible! Choose from a variety of hairstyles and outfits",
      l_dis:
        "Welcome to Monster Popsy Dolls game. Meet incredible monster-popsy. These toys are both beautiful and terrible! Choose from a variety of hairstyles and outfits to create your own unique doll. Use your makeup artist skills to choose the right makeup for your popsy. Try on dresses, tops, leather jackets, boots, and fun jewelry. Have a lot of fun playing Monster Popsy Dolls!",
      tag: ["Dress up", "Make Up", "Dl-Girls", "monster high"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "TikTok Trends: Color Block - playcutegames.com",
      name: ["TikTok Trends: Color Block", "tiktok-trends-color-block"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-trends-color-block/250x142.png",
      path: ["/game/tiktok-trends-color-block", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/213692ab-2021-5312-8b8d-9e58aeb690dc/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to TikTok Trends: Color Block game. In this online dress-up game for girls called TikTok Trends: Color Block, you help our beautiful fashionista create bold outfits",
      l_dis:
        "Welcome to TikTok Trends: Color Block game. In this online dress-up game for girls called TikTok Trends: Color Block, you help our beautiful fashionista create bold outfits for the seven days of color block fashion challenge. She has a busy agenda this week, and sometimes you’ll find it challenging to follow the rules of this fashion challenge. Are you ready to get it started?",
      tag: ["Decoration", "Prinxy Games", "Trends", "Tiktok"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Summer Floral Prints - playcutegames.com",
      name: ["Summer Floral Prints", "summer-floral-prints"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/summer-floral-prints/250x142.png",
      path: ["/game/summer-floral-prints", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/28abdc6b-62bf-5912-b8e5-6392e6f3f2c2/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Welcome to Summer Floral Prints game. This summer, floral prints are back in trends, and you are going to have a blast discovering this feminine and playful",
      l_dis:
        "Welcome to Summer Floral Prints game. This summer, floral prints are back in trends, and you are going to have a blast discovering this feminine and playful fashion trend in our dress-up game for girls called Summer Floral Prints. Get it started, and have fun creating jaw-dropping #ootd for these 5 BFFs getting ready to conquer the crowdest boulevard looking in style. Have a blast!",
      tag: ["Dress up", "Princess", "Make Up", "Summer", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "babygames",
     
      orientation: 0,
      title: "Princess First Aid In Mermaid Kingdom - playcutegames.com",
      name: [
        "Princess First Aid In Mermaid Kingdom",
        "princess-first-aid-in-mermaid-kingdom",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-first-aid-in-mermaid-kingdom/250x142.png",
      path: [
        "/game/princess-first-aid-in-mermaid-kingdom",
        "https://playcutegames.com",
      ],
      iframe:
        "https://www.babygames.com/games/Princess-First-Aid-In-Mermaid-Kingdom/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess First Aid In Mermaid Kingdom. Sunny days are good days to go out to the sea. Elsa and Belle think so too. They went out to sea in good weather to enjoy the holiday.",
      l_dis:
        "Welcome to Princess First Aid In Mermaid Kingdom. Sunny days are good days to go out to the sea. Elsa and Belle think so too. They went out to sea in good weather to enjoy the holiday. Suddenly there was a storm. Elsa and Belle fell into the bottom of the sea, in a very dangerous situation. Please go call Ariel to save her two friends. Can Elsa and Belle be out of danger? Have fun in this game!",
      tag: ["Dress up", "Princess", "Mermaid", "Doctor","Mermaid", "babygames"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Cute Unicorn Fashion Dress Up - playcutegames.com",
      name: [
        "My Cute Unicorn Fashion Dress Up",
        "my-cute-unicorn-fashion-dress-up",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-cute-unicorn-fashion-dress-up/250x142.png",
      path: [
        "/game/my-cute-unicorn-fashion-dress-up",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/my-cute-unicorn-fashion-dress-up/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Cute Unicorn Fashion Dress Up. Skyler, Sunny, Ruby, and Violet are the Rainbow high school girls who have planned for a weekend party.",
      l_dis:
        `Welcome to the My Cute Unicorn Fashion Dress Up. Skyler, Sunny, Ruby, and Violet are the Rainbow high school girls who have planned for a weekend party. They decided to throw a party in Unicorn fashion. Join as a design expert and help the girls to get ready. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Rainbow High",
        "Unicorn",
        "Fashion",
        "babygames",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Ibiza Pool Party - playcutegames.com",
      name: ["Ibiza Pool Party", "ibiza-pool-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ibiza-pool-party/250x142.png",
      path: ["/game/ibiza-pool-party", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/f176a50f-c881-5939-9bcd-cecdb4eb8eaf/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Ibiza Pool Party. In this online makeover game, Elsa and Ariel have prepared some girly-girl summer activities that you are going to love.",
      l_dis:
        "Welcome to Ibiza Pool Party. In this online makeover game, Elsa and Ariel have prepared some girly-girl summer activities that you are going to love. Are you ladies ready to embark on a fun trip to Ibiza? Then simply get started with the Ibiza Pool Party, and have a blast together with your favorite Disney princesses while you help them prep their looks for the most-awaited pool party of the year.",
      tag: ["Dress up", "Princess", "Make Up", "Summer", "Prinxy Games", "Party"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Rainbow Insta Girls - playcutegames.com",
      name: ["Rainbow Insta Girls", "rainbow-insta-girls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-insta-girls/250x142.png",
      path: ["/game/rainbow-insta-girls", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1628875523/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Rainbow Insta Girls. Get to know the students of the Rainbow School better. These girls love trendy clothes and bright hairstyles.",
      l_dis:
        "Welcome to Rainbow Insta Girls. Get to know the students of the Rainbow School better. These girls love trendy clothes and bright hairstyles. This helps them become Instagram stars. The friends even managed to draw the guys into this fashion adventure. Join the fun and try on outfits with the girls. An incredible rainbow whirlpool and a colorful extravaganza await you!",
      tag: [
        "Dress up",
        "Princess",
        "Make Up",
        "Instagram",
        "Rainbow",
        "Dl-Girls",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "dressupwho",
     
      orientation: 0,
      title: "School Girl Makeover - playcutegames.com",
      name: ["School Girl Makeover", "school-girl-makeover"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/school-girl-makeover/250x142.png",
      path: ["/game/school-girl-makeover", "https://playcutegames.com"],
      iframe: "https://dressupwho.net/html5/schoolgirl-makeover/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to School Girl Makeover game. At school, you are known as the girl who is always studying and not ever paid attention to.",
      l_dis:
        "Welcome to School Girl Makeover game. At school, you are known as the girl who is always studying and not ever paid attention to. Now, you want to change that by getting the ultimate makeover to soon become the most popular girl in school and impress all the people around you. Join and have fun!",
      tag: ["School", "Make Up", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Get Ready With Me Summer Picnic - playcutegames.com",
      name: [
        "Get Ready With Me Summer Picnic",
        "get-ready-with-me-summer-picnic",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/get-ready-with-me-summer-picnic/250x142.png",
      path: [
        "/game/get-ready-with-me-summer-picnic",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/get-ready-with-me-summer-picnic/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Get Ready With Me Summer Picnic game. Skyler, Sunny, Ruby, and Violet are the Rainbow high school girls who have planned for vacation.",
      l_dis:
        `Welcome to Get Ready With Me Summer Picnic game. Skyler, Sunny, Ruby, and Violet are the Rainbow high school girls who have planned for vacation. They decided to have a picnic to get rid of this summer. They need to get ready to heads out. Join and help them to get ready. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "Rainbow High", "Vacation", "Summer"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Insta Trends: Galaxy Fashion - playcutegames.com",
      name: ["Insta Trends: Galaxy Fashion", "insta-trends-galaxy-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/insta-trends-galaxy-fashion/250x142.png",
      path: ["/game/insta-trends-galaxy-fashion", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/2ccf9dda-e046-5d4d-a092-c8aa7c5b38fb/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Insta Trends: Galaxy Fashion. Embark on a fashionable trip to the center of the Universe together with your favorite Disney Princesses while playing the Insta Trends: Galaxy Fashion dress-up game",
      l_dis:
        "Welcome to Insta Trends: Galaxy Fashion. Embark on a fashionable trip to the center of the Universe together with your favorite Disney Princesses while playing the Insta Trends: Galaxy Fashion dress-up game for girls. Elsa, Anna, Moana, Ariel, and Rapunzel recently found Planet Her, and they are super excited to share their new home with you. Fashion on this amazing planet is galaxy colored and centered around fashionable suits and out-of-this-world accessories. You can discover them all while helping the girls choose their outfits for the day! Have fun!",
      tag: [
        "Dress up",
        "Make Up",
        "Princess",
        "Fashion",
        "Prinxy Games",
        "Anna",
        "Elsa",
        "Rapunzel",
        "Ariel",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Angela All Season Fashion - playcutegames.com",
      name: ["Angela All Season Fashion", "angela-all-season-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/angela-all-season-fashion/250x142.png",
      path: ["/game/angela-all-season-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/angela-all-season-fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Angela All Season Fashion game. Angela feels so creative and wants something more fun! She planned to do seasonal fashion outfits and post on social media to create new trends.",
      l_dis:
        `Welcome to the Angela All Season Fashion game. Angela feels so creative and wants something more fun! She planned to do seasonal fashion outfits and post on social media to create new trends. Help her to pick an cute outfits from the wardrobe for each season. Come and join with Angela. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Fashion",
        "Winter",
        "Summer",
        "Autumn",
        "Season",
        "Angela",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Quarantine Glam Look - playcutegames.com",
      name: ["My Quarantine Glam Look", "my-quarantine-glam-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-quarantine-glam-look/250x142.png",
      path: ["/game/my-quarantine-glam-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-quarantine-glam-look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to My Quarantine Glam Look games. Rainbow Girls Skyler, Sunny, Ruby, and Violet are got bored being home in this Quarantine! They planned to do some funny indoor fashion activities.",
      l_dis:
        `Welcome to My Quarantine Glam Look games. Rainbow Girls Skyler, Sunny, Ruby, and Violet are got bored being home in this Quarantine! They planned to do some funny indoor fashion activities. Come and join with the girls and show your expert skills. Pick cute pink shades of blushes, eyeshades, eye color, and lip colors. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Rainbow High",
        "Quarantine",
        "Cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Summer Aesthetics - playcutegames.com",
      name: ["Summer Aesthetics", "summer-aesthetics"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/summer-aesthetics/250x142.png",
      path: ["/game/summer-aesthetics", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/34db646a-72f3-5988-8880-e19d5e749e32/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Summer Aesthetics game. Hop on a plane and spend your summer vacation traveling around the world together with your favorite Disney princesses.",
      l_dis:
        "Welcome to Summer Aesthetics game. Hop on a plane and spend your summer vacation traveling around the world together with your favorite Disney princesses. Discover beautiful sceneries and practice your fashion advisor skills while helping them choose their outfits of the day in the Summer Aesthetics Dress Up Game for girls! Have a blast!",
      tag: [
        "Dress up",
        "Princess",
        "Summer",
        "Aesthetic",
        "Prinxy Games",
        "Belle",
        "Merida",
        "Aurora",
        "Moana",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "GD",
     
      orientation: 0,
      title: "Rachel Holmes - playcutegames.com",
      name: ["Rachel Holmes", "rachel-holmes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rachel-holmes/250x142.png",
      path: ["/game/rachel-holmes", "https://playcutegames.com"],
      iframe:
        "https://html5.gamedistribution.com/120653a94b0a48cf9050fff4a258f5cf/?gd_sdk_referrer_url=https://playcutegames.com/games/rachel-holmes",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Rachel Holmes. Strange things keep happening all over the world! Someone is taking pictures in different places and challenges people to find the originals as fast as possible.",
      l_dis:
        "Welcome to Rachel Holmes. Strange things keep happening all over the world! Someone is taking pictures in different places and challenges people to find the originals as fast as possible. Rachel Holmes is already on the case but she needs your help! Together you can spot the differences while looking at thousands of pictures and competing with other detectives. Travel the world, find all the differences online, and aid Rachel with solving this mystery. Hurry up! Everyone wants to become the best and get there first. This game is perfect for you: …if you like to look around and appreciate the world in its every detail, …if you prefer to keep your brain active and agile while having fun, …if you live to challenge yourself and compete with other enthusiasts, …if you just want to relax and do something enjoyable. The list goes on but you don’t have time. Rachel Holmes is already waiting for you in the game. Play now!",
      tag: ["Puzzle", "Kids", "Difference", "Find Out","Yad"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Design With Me Trendy Pencil Skirt - playcutegames.com",
      name: [
        "Design With Me Trendy Pencil Skirt",
        "design-with-me-trendy-pencil-skirt",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/design-with-me-trendy-pencil-skirt/250x142.png",
      path: [
        "/game/design-with-me-trendy-pencil-skirt",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/design-with-me-trendy-pencil-skirt/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Design With Me Trendy Pencil Skirt. Hairdorable girls Dee dee, Willow, and Noah are getting ready for a holiday.",
      l_dis:
        `Welcome to Design With Me Trendy Pencil Skirt. Hairdorable girls Dee dee, Willow, and Noah are getting ready for a holiday. They have decided to design their own trendy pencil skirt and get a unique look for this holiday. Help out the girls to choose a perfect skirt model, the color and the pattern of the fabric, add a cute inscription and finally match it with nice tops and accessories. Join and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Decoration",
        "Skirt",
        "Design",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Ariana Wedding Prep - playcutegames.com",
      name: ["Ariana Wedding Prep", "ariana-wedding-prep"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ariana-wedding-prep/250x142.png",
      path: ["/game/ariana-wedding-prep", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/8b6a9c91-68cb-5abf-a76e-664eb2a33b2a/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Ariana Wedding Prep. Join your favorite singer in getting started with this free-to-play celebrity game for girls called Ariana Wedding Prep, and step by step, help her wow her fiancee with a stunning bridal look.",
      l_dis:
        "Welcome to Ariana Wedding Prep. Join your favorite singer in getting started with this free-to-play celebrity game for girls called Ariana Wedding Prep, and step by step, help her wow her fiancee with a stunning bridal look. Choose her wedding dress, style up her hair, and do her makeup as you complete all the steps in the beautifying process. Next, you can help her bridesmaids, Kendall Jenner and Gigi Hadid, choose their wedding looks as well. Have fun!",
      tag: [
        "Wedding",
        "Dress up",
        "Make Up",
        "Bride",
        "Ariana",
        "Kendall Jenner",
        "Design",
        "Prinxy Games"]
  ,
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "dressupwho",
     
      orientation: 0,
      title: "Blondie Summer Friends Fashion Show - playcutegames.com",
      name: [
        "Blondie Summer Friends Fashion Show",
        "blondie-summer-friends-fashion-show",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/blondie-summer-friends-fashion-show/250x142.png",
      path: [
        "/game/blondie-summer-friends-fashion-show",
        "https://playcutegames.com",
      ],
      iframe: "https://dressupwho.net/html5/blondie_friends_summer_fashion_show/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Blondie Summer Friends Fashion Show. Blondie and her BFF decided to have a high fashion week. It is summer and warm weather outside.",
      l_dis:
        "Welcome to Blondie Summer Friends Fashion Show. Blondie and her BFF decided to have a high fashion week. It is summer and warm weather outside. This means that the time has come for long, flying skirts and dresses. Elegant floral prints are all the rage now. Mix and match a variety of blouses, jackets, skirts, and trousers to create a stylish ensemble. Don’t forget to put on trendy makeup and have a summer runway show.",
      tag: ["Princess", "Dress up", "Make Up", "Summer", "Blondie", "dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Royal Wedding - playcutegames.com",
      name: ["Princess Royal Wedding", "princess-royal-wedding"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-royal-wedding/250x142.png",
      path: ["/game/princess-royal-wedding", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess-royal-wedding/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the princess royal wedding game. Princess Anna and Elsa are getting ready for their wedding with Kristoff and Jack.",
      l_dis:
        `Welcome to the princess royal wedding game. Princess Anna and Elsa are getting ready for their wedding with Kristoff and Jack. They waiting so long for this occassion. They need some hand to get ready. Come and join the girls! And make this occassion most beautiful one in their life!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Wedding",
        "Anna",
        "Elsa",
        "Kristoff",
        "Jack Frost",
        "Date",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Lovely Wedding Date - playcutegames.com",
      name: ["Lovely Wedding Date", "lovely-wedding-date"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lovely-wedding-date/250x142.png",
      path: ["/game/lovely-wedding-date", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/lovely-wedding-date/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Hey Girls, Welcome to the Lovely Wedding Date game. Skyler is more excited about her dream day with Collin. Yes, It’s their wedding.",
      l_dis:
        `Hey Girls, Welcome to the Lovely Wedding Date game. Skyler is more excited about her dream day with Collin. Yes, It’s their wedding. Help them to get ready for this occassion. And make this occasion a more memorable one in her life! Join with them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Wedding",
        "Date",
        "Rainbow High",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Sweet Strawberry Outfits - playcutegames.com",
      name: ["My Sweet Strawberry Outfits", "my-sweet-strawberry-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-sweet-strawberry-outfits/250x142.png",
      path: ["/game/my-sweet-strawberry-outfits", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my-sweet-strawberry-outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Hey girl, welcome to the My Sweet Strawberry Outfits game. Rainbow high girls Skyler, Sunny, Ruby, and Violet are planned to do something special on the weekend.",
      l_dis:
        `Hey girl, welcome to the My Sweet Strawberry Outfits game. Rainbow high girls Skyler, Sunny, Ruby, and Violet are planned to do something special on the weekend. They have an idea to design their own strawberry outfit. Help them with the clothes to create a cute outfit, and complete the look with makeup. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Rainbow High", "Dress up", "Make Up", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Pride Rainbow Fashion - playcutegames.com",
      name: ["Pride Rainbow Fashion", "pride-rainbow-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/pride-rainbow-fashion/250x142.png",
      path: ["/game/pride-rainbow-fashion", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/31e09f63-1f81-545b-a953-b97b3278c217/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Pride Rainbow Fashion game. In this free-to-play fashion game for girls called Pride Rainbow Fashion, you help six of your favorite Disney",
      l_dis:
        "Welcome to the Pride Rainbow Fashion game. In this free-to-play fashion game for girls called Pride Rainbow Fashion, you help six of your favorite Disney Princesses get ready to attend the annual Pride Parade looking in style. Each girl’s wardrobe is colored in a rainbow-inspired shade, and to discover what color each princess took, you are challenged to advance from one unit to another. Be ready to discover our impressive selection of summer dresses, flower printed tops, shorts, skirts, and jeans as you dress up each Disney girl in one bright shade inspired by the colors of the rainbow.Mix and match your favorite clothing pieces in jaw-dropping pride outfits. And then accessorize the winning outfit with a summery hairstyle, colorful accessories, floral headbands, and a striped flag. Next, capture a photo of your creation and share it with your friends! Spread the love!",
      tag: [
        "Princess",
        "Dress up",
        "Fashion",
        "Prinxy Games",
        "Rainbow Girls",
        "Anna",
        "Elsa",
        "Elsa",
        "Tiana",
        "Moana",
        "Merida",
        "Rapunzel",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Little Cute Summer Fairies Puzzle - playcutegames.com",
      name: [
        "Little Cute Summer Fairies Puzzle",
        "little-cute-summer-fairies-puzzle",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/little-cute-summer-fairies-puzzle/250x142.png",
      path: [
        "/game/little-cute-summer-fairies-puzzle",
        "https://playcutegames.com",
      ],
      iframe: "https://www.dl-girls.com/content/folder_1623255644/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Little Cute Summer Fairies Puzzle game. It’s time for puzzles! Just look at these lovely and pretty fairies. They are so different, but all charming in their own way.",
      l_dis:
        "Welcome to the Little Cute Summer Fairies Puzzle game. It’s time for puzzles! Just look at these lovely and pretty fairies. They are so different, but all charming in their own way. Try to collect all the puzzles in the shortest possible time; set records and compete with your friends. Collect all the pictures of these wonderful fairies and have fun!",
      tag: ["Princess", "Summer", "Puzzle", "Fairy", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Design With Me Cute Tie Dye Tops - playcutegames.com",
      name: [
        "Design With Me Cute Tie Dye Tops",
        "design-with-me-cute-tie-dye-tops",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/design-with-me-cute-tie-dye-tops/250x142.png",
      path: [
        "/game/design-with-me-cute-tie-dye-tops",
        "https://playcutegames.com",
      ],
      iframe: " https://games.cutedressup.net/design_with_me_cute_tie_dye_tops/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Design With Me Cute Tie Dye Tops. Rainbow girls Sunny, Skyler and Ruby are getting ready for a holiday. They have decided to design their own crop tops and get a unique look for this holiday.",
      l_dis:
        `Welcome to Design With Me Cute Tie Dye Tops. Rainbow girls Sunny, Skyler and Ruby are getting ready for a holiday. They have decided to design their own crop tops and get a unique look for this holiday. Help out the girls to choose a crop top model, the color and the pattern of the fabric, add a cute inscription and finally match it with a nice skirt or shorts. Join and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Decoration",
        "Rainbow High",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "Prinxy",
     
      orientation: 0,
      title: "Graduation Hairstyles - playcutegames.com",
      name: ["Graduation Hairstyles", "graduation-hairstyles"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/graduation-hairstyles/250x142.png",
      path: ["/game/graduation-hairstyles", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/6f2264e1-f61d-57f2-b5f8-a8cd1fa7063d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Graduation Hairstyles game. You help Princess Belle prepare herself for graduation day! This day comes to complete Belle’s beautiful journey through high school, and it’s surely one day she will not forget any time soon.",
      l_dis:
        "Welcome to the Graduation Hairstyles game. You help Princess Belle prepare herself for graduation day! This day comes to complete Belle’s beautiful journey through high school, and it’s surely one day she will not forget any time soon. Surrounded by close friends and family, she will head to the high school for the last time, but not before getting herself pampered by your skillful hands. Let’s start with some hair care! Then, follow the step-by-step instructions to recreate an elegant updo for the day, dress her up in a colorful summer dress or in a chic two pieces outfit, and customize her graduation robe and cap in bright shades and cool stickers. Have fun playing the Graduation Hairstyles game for girls!",
      tag: ["Dress up", "Make Up", "Graduation", "Salon", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
   
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Baddie Billie Evolution - playcutegames.com",
      name: ["Baddie Billie Evolution", "baddie-billie-evolution"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/baddie-billie-evolution/250x142.png",
      path: ["/game/baddie-billie-evolution", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/880e62c9-845a-54f4-918f-4922ffc39685/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Baddie Billie Evolution. Join your favorite singer in getting started with this free-to-play celebrity and have a blast recreating outfits inspired by the fashion Billie, wore while having a certain hair color.",
      l_dis:
        "Welcome to Baddie Billie Evolution. Join your favorite singer in getting started with this free-to-play celebrity and have a blast recreating outfits inspired by the fashion Billie, wore while having a certain hair color. Are you curious to discover the four iconic looks we featured in our game?",
      tag: ["Dress up", "Manicure", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF Summer Vibes - playcutegames.com",
      name: ["BFF Summer Vibes", "bff-summer-vibes"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-summer-vibes/250x142.png",
      path: ["/game/bff-summer-vibes", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_summer_vibes/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the BFF Summer Vibes games. Summer is here and these rainbow high school girls need to get ready for it! How about you, are you ready for summer? These girls need a summer touch when it comes to their wardrobe and it would be nice if you could help them out.",
      l_dis:
        `Welcome to the BFF Summer Vibes games. Summer is here and these rainbow high school girls need to get ready for it! How about you, are you ready for summer? These girls need a summer touch when it comes to their wardrobe and it would be nice if you could help them out. So check out the latest summer trends and then turn these princesses into some real divas! You have a whole wardrobe stacked with dresses, tops, skirts and so many accessories at your disposal. Play and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Bff",
        "Summer",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "My Cottagecore Aesthetic Look - playcutegames.com",
      name: ["My Cottagecore Aesthetic Look", "my-cottagecore-aesthetic-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-cottagecore-aesthetic-look/250x142.png",
      path: ["/game/my-cottagecore-aesthetic-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/my_cottagecore_aesthetic_look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the My Cottagecore Aesthetic Look game. Cottagecore fashion is inspired by farm life, it is very impractical for doing chores around the farm! Princess Elsa, Anna, and Ariel are best friends planning to try something special.",
      l_dis:
        `Welcome to the My Cottagecore Aesthetic Look game. Cottagecore fashion is inspired by farm life, it is very impractical for doing chores around the farm! Princess Elsa, Anna, and Ariel are best friends planning to try something special. Get ready to explore the Cottagecore fashion style along with them to create the cutest looks!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Cottagecore",
        "Aesthetic",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Billie Blonde Transformation - playcutegames.com",
      name: ["Billie Blonde Transformation", "billie-blonde-transformation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/billie-blonde-transformation/250x142.png",
      path: ["/game/billie-blonde-transformation", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/315cad52-4b3f-54d1-8770-e167d8e831d8/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Billie Blonde Transformation game. Billie Eilish’s new look took over the internet during the last weeks, and all the craze around the news inspired us to create this fun makeover game for girls that covers the transformation process.",
      l_dis:
        "Welcome to the Billie Blonde Transformation game. Billie Eilish’s new look took over the internet during the last weeks, and all the craze around the news inspired us to create this fun makeover game for girls that covers the transformation process. In this game, you girls join Billie’s personal hairdresser and help together you transform her neon green roots and black lengths into a creamy ice blonde. Are you ready to get started with Billie’s total transformation process?",
      tag: ["Princess", "Dress up", "Make Up", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Hollywood Story - playcutegames.com",
      name: ["Rainbow Girls Hollywood Story", "rainbow-girls-hollywood-story"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-hollywood-story/250x142.png",
      path: ["/game/rainbow-girls-hollywood-story", "https://playcutegames.com"],
      iframe: " https://games.cutedressup.net/rainbow_girls_hollywood_story/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Hey girls! Welcome to the new Rainbow Girls Hollywood Story game. Rainbow high school girls Skyler, Sunny, Ruby, and Violet are invited for a Hollywood-themed dress-up party.",
      l_dis:
        `Hey girls! Welcome to the new Rainbow Girls Hollywood Story game. Rainbow high school girls Skyler, Sunny, Ruby, and Violet are invited for a Hollywood-themed dress-up party. They are been so excited and planned to be the best at the party. Join the girls and have fun! Start with Skyler’s makeup part, help her to pick a cute pick shade of lip color, eyeshades, and blushes. Then start with dress-up, pick a cute outfit, and sparkly accessories. Do the same thing for the rest of the girls. Join and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Rapunzel",
        "Hollywood",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Incredible Princess Eye Art - playcutegames.com",
      name: ["Incredible Princess Eye Art", "incredible-princess-eye-art"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/incredible-princess-eye-art/250x142.png",
      path: ["/game/incredible-princess-eye-art", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1620318663/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Incredible Princess Eye Art game. Try your hand at makeup on the beautiful eyes of cute princesses.",
      l_dis:
        "Welcome to the Incredible Princess Eye Art game. Try your hand at makeup on the beautiful eyes of cute princesses. Use extraordinary artistic solutions to create a striking and non-standard look. What kind of princess will be today – a cute doll, a vamp-lady or a stylish cyber princess – you decide. Grab your makeup brush and get started! Try all the art for the eyes.",
      tag: ["Princess", "Make Up", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "High Fashion Runway Look - playcutegames.com",
      name: ["High Fashion Runway Look", "high-fashion-runway-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/high-fashion-runway-look/250x142.png",
      path: ["/game/high-fashion-runway-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/high_fashion_runway_look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the High Fashion Runway Look game. Dee dee, Noah, and Willow are the best friends planning to participate in a runway fashion. They have confusion to choose the best outfit in their wardrobes.",
      l_dis:
        `Welcome to the High Fashion Runway Look game. Dee dee, Noah, and Willow are the best friends planning to participate in a runway fashion. They have confusion to choose the best outfit in their wardrobes. Pick a unique style for each one of them. Join and help the girls with your expert skill. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "hairdorables doll",
        "Dress up",
        "Make Up",
        "Fashion",
        "Runway",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Oscar Red Carpet Fashion - playcutegames.com",
      name: ["Oscar Red Carpet Fashion", "oscar-red-carpet-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/oscar-red-carpet-fashion/250x142.png",
      path: ["/game/oscar-red-carpet-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/oscar_red_carpet_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Oscar Red Carpet Fashion game. The red carpet on Oscar fashion has been always the most exciting as everyone shows their gowns for the first time.",
      l_dis:
        `Welcome to the Oscar Red Carpet Fashion game. The red carpet on Oscar fashion has been always the most exciting as everyone shows their gowns for the first time. Bff’s from Rainbow high is preparing for their special weekend contest and they would very much appreciate it if you could take some time to give them some good tips and to make them shine on the red carpet. Would you like to be their personal stylist? Check out the special grows, jewelry, and hairstyles available and choose the ones you like best from all to give them a perfect look.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "TikTok Trends: Boyfriend Fashion - playcutegames.com",
      name: [
        "TikTok Trends: Boyfriend Fashion",
        "tiktok-trends-boyfriend-fashion",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tiktok-trends-boyfriend-fashion/250x142.png",
      path: [
        "/game/tiktok-trends-boyfriend-fashion",
        "https://playcutegames.com",
      ],
      iframe:
        "https://game.digitap.eu/4a455617-7730-5f4c-b08d-236ddc0956a8/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to TikTok Trends: Boyfriend Fashion. In this online dress-up game for girls called TikTok Trends: Boyfriend Fashion, you go shopping with two of your favorite Disney princesses, and you help them pick the coolest boyfriend clothes and accessories available in stores. Are you ready to get started with their fashion transformation",
      l_dis:
        "Welcome to TikTok Trends: Boyfriend Fashion. In this online dress-up game for girls called TikTok Trends: Boyfriend Fashion, you go shopping with two of your favorite Disney princesses, and you help them pick the coolest boyfriend clothes and accessories available in stores. Are you ready to get started with their fashion transformation? The boyfriend trend is all about wearing oversized clothes, and sometimes it involves discreetly removing items from your boyfriend’s wardrobe and including them in your cool everyday outfits. Imagine long sweatshirts paired with cool boots, his white shirts transformed into summer dresses. Your boy’s hoodies paired with your favorite leggings, and his cool varsity jacket paired with your favorite flower printed dress.",
      tag: ["Princess", "Dress up", "Tiktok", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Cute Little Dragon Creator - playcutegames.com",
      name: ["Cute Little Dragon Creator", "cute-little-dragon-creator"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/cute-little-dragon-creator/170x170.jpg",
      path: ["/game/cute-little-dragon-creator", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1618507402/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Cute Little Dragon Creator. Do you like dragons? Then this game is for you! Create your own unique cute little dragon; you can choose any color and many patterns and decorations, as well as eyes, wings, tail, and accessories.",
      l_dis:
        "Welcome to Cute Little Dragon Creator. Do you like dragons? Then this game is for you! Create your own unique cute little dragon; you can choose any color and many patterns and decorations, as well as eyes, wings, tail, and accessories. How did your little dragon turn out? Show the screenshot in the comments.",
      tag: ["Pet", "Dress up", "Kids", "Fashion", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Perfect Stylish Street Look - playcutegames.com",
      name: ["Perfect Stylish Street Look", "perfect-stylish-street-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/perfect-stylish-street-look/250x142.png",
      path: ["/game/perfect-stylish-street-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/perfect_stylish_street_look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Perfect Stylish Street Look game. Dee dee, Willow, and Noah are the best friends who planned to throw a weekend party. They have an idea to make this party more special.",
      l_dis:
        `Welcome to the Perfect Stylish Street Look game. Dee dee, Willow, and Noah are the best friends who planned to throw a weekend party. They have an idea to make this party more special. Noah comes with an idea to try stylish street outfits. But they need and expert help to choose the best outfit. Join the girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables Doll",
        "Dress up",
        "Make Up",
        "Style",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Dragonstone Quest Adventure - playcutegames.com",
      name: ["Dragonstone Quest Adventure", "dragonstone-quest-adventure"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/dragonstone-quest-adventure/250x142.png",
      path: ["/game/dragonstone-quest-adventure", "https://playcutegames.com"],
      iframe: "https://dl-girls.com/content/folder_1617902716/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Dragonstone Quest Adventure. Brave Princess sets off in search of the dragon stone; she has a long journey in which many dangers lie in wait.",
      l_dis:
        "Welcome to Dragonstone Quest Adventure. Brave Princess sets off in search of the dragon stone; she has a long journey in which many dangers lie in wait. Help Princess find all the fragments of the dragon stone, reassemble it and bring peace back to the five kingdoms. And the magic dragon will help Princess in her search.",
      tag: ["Princess", "Dress up", "Raya", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF Happy Spring - playcutegames.com",
      name: ["BFF Happy Spring", "bff-happy-spring"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-happy-spring/250x142.png",
      path: ["/game/bff-happy-spring", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_happy_spring/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bff Happy Spring games. Princesses Belle, Tiana, and Moana are best friends. They planned to organize a party to welcome the most adorable spring season.",
      l_dis:
        `Welcome to the Bff Happy Spring games. Princesses Belle, Tiana, and Moana are best friends. They planned to organize a party to welcome the most adorable spring season. They already filled their wardrobe with a cherry blossom collection of outfits. Being a design expert join and help them to choose the best outfit for them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "BFF",
        "Spring",
        "Belle",
        "Tiana",
        "Moana",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Fresh Spring Style - playcutegames.com",
      name: ["Fresh Spring Style", "fresh-spring-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/fresh-spring-style/250x142.png",
      path: ["/game/fresh-spring-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/fresh_spring_style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Fresh Spring Style game. Rainbow high school girls Sunny, Skyler, Ruby, and Violet are getting ready for the most awaited season who are absolutely mad about this season! They already filling the wardrobe with floral and blossom outfits.",
      l_dis:
        `Welcome to the Fresh Spring Style game. Rainbow high school girls Sunny, Skyler, Ruby, and Violet are getting ready for the most awaited season who are absolutely mad about this season! They already filling the wardrobe with floral and blossom outfits. They need some expert help to look better. Come and join them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Spring",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Maskne Face Care - playcutegames.com",
      name: ["Maskne Face Care", "maskne-face-care"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/maskne-face-care/250x142.png",
      path: ["/game/maskne-face-care", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/e8e228d9-111e-5722-89da-d0627bcef73d/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Maskne Face Care. Caused by a mix of sweat, cosmetics, and contact with a face mask that prevents the skin from breathing, maskne is the new acne, and it is here to complicate our lives even more.",
      l_dis:
        "Welcome to Maskne Face Care. Caused by a mix of sweat, cosmetics, and contact with a face mask that prevents the skin from breathing, maskne is the new acne, and it is here to complicate our lives even more. So if you’re also facing breakouts from wearing a mask, you’re not alone. Gorgeous Ellie has been facing it since last summer, but luckily she learned what beauty products and cosmetics to use and how to keep it under control. Get started with the Ellie: Maskne Face Care beauty game for girls, and step by step discover her  Korean-inspired minimalist skincare routine. Then be prepared to learn some of her makeup tricks too! Because of the coronavirus, she will continue wearing the mask every day. But she knows exactly how to pair her eyeshadow with the face masks she’s wearing. Are you curious to learn more?",
      tag: ["Princess", "Dress up", "Make Up", "Care", "Barbie", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Stylish Tiered Ruffle Addiction - playcutegames.com",
      name: [
        "Stylish Tiered Ruffle Addiction",
        "stylish-tiered-ruffle-addiction",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/stylish-tiered-ruffle-addiction/250x142.png",
      path: [
        "/game/stylish-tiered-ruffle-addiction",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/stylish_tiered_ruffle_addiction/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Hey girls! Welcome to the Stylish Tiered Ruffle Addiction game. Rainbow high school girls Skyler, Sunny, Violet, and Ruby planned to have a fashion meet.",
      l_dis:
        `Hey girls! Welcome to the Stylish Tiered Ruffle Addiction game. Rainbow high school girls Skyler, Sunny, Violet, and Ruby planned to have a fashion meet. Nothing will be fun when this will not align with a particular topic. Ruby suggested having a fashion meet with Ruffles design. Each girl needs expert help to make themself better. Join and help them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Style",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bff Trendy K Fashion - playcutegames.com",
      name: ["Bff Trendy K Fashion", "bff-trendy-k-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-trendy-k-fashion/250x142.png",
      path: ["/game/bff-trendy-k-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_trendy_k_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Bff Trendy K-Fashion game. Princess Anna, Elsa, and Ariel are the best friends who met after a long time. They planned to make a challenge between them.",
      l_dis:
        `Welcome to the Bff Trendy K-Fashion game. Princess Anna, Elsa, and Ariel are the best friends who met after a long time. They planned to make a challenge between them. Princess Ariel comes up with the idea of trying Korean fashion trends. On hearing that Elsa and Anna getting more excited to try this. Join and help the princesses with these k-fashion trends. Have fun! Start with princess Elsa’s make-up part, pick cute pink shades of lip color and eyeshades. And pick the cute color of eye color and blushes. Then browse her wardrobe to find the best outfit for her. Do the same for other princesses.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Anna",
        "Elsa",
        "Elsa",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Soft Girl Aesthetic - playcutegames.com",
      name: ["Soft Girl Aesthetic", "soft-girl-aesthetic"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/soft-girl-aesthetic/250x142.png",
      path: ["/game/soft-girl-aesthetic", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/soft_girl_aesthetic/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Soft Girl Aesthetic game. Skyler, Sunny, Ruby, and violet are the rainbow high school girls. They are up to date with the latest trends.",
      l_dis:
        `Welcome to the Soft Girl Aesthetic game. Skyler, Sunny, Ruby, and violet are the rainbow high school girls. They are up to date with the latest trends. Now they hear about the soft girl’s hashtag trending on both Instagram and Tiktok. They love to change themselves with the latest trends. Join and help them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Aesthetic",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Easter Glamping Trip - playcutegames.com",
      name: ["Easter Glamping Trip", "easter-glamping-trip"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/easter-glamping-trip/250x142.png",
      path: ["/game/easter-glamping-trip", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/46674736-e7a3-5f68-9baf-c0c5185ce160/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Easter Glamping Trip. This year, our Frozen sisters are going glamping in the mountains, where they plan to relax under the sun all day long.",
      l_dis:
        "Welcome to the Easter Glamping Trip. This year, our Frozen sisters are going glamping in the mountains, where they plan to relax under the sun all day long. For that, they booked the most Instagrammable glamping place in Norway, and now they need your help to get their outfits ready for the fancy trip. Come and join them in getting started with the Easter Glamping Trip game for girls, and see what jaw-dropping holiday looks you can put together for them. As their fashion adviser, you can choose from an impressive collection of bohemian dresses, embroidered tops, knitted blouses, jeans, shorts, and Aztec printed overtops. Go through all the options available at your disposal, and pick the perfect outfit of the day. Great job, ladies! Are you ready for more? Click next and get ready to discover new challenging tasks as you continue playing this holiday-themed game for girls! Have a blast!",
      tag: ["Princess", "Dress up", "Decoration", "Easter", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Mix And Match Fashion - playcutegames.com",
      name: ["Mix And Match Fashion", "mix-and-match-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/mix-and-match-fashion/250x142.png",
      path: ["/game/mix-and-match-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/mix_and_match_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Mix And Match Fashion Game. Noah, Dee Dee, and Willow are adorable girls who inspired more by the latest fashion trends. They heard about a new upcoming contest called Mix and Match Fashion.",
      l_dis:
        `Welcome to Mix And Match Fashion Game. Noah, Dee Dee, and Willow are adorable girls who inspired more by the latest fashion trends. They heard about a new upcoming contest called Mix and Match Fashion. Their wardrobes are already filled with the latest trends of outfits. But they need help to choose the better one. Join and help these girls. Start with girl’s makeup level, help them to have a cute color of eyeshades, lip and eye colors. Then Mix and Match the best outfits for each girl by browsing their wardrobe. Maybe outfit with a crop top with jeans or a top with a skirt. Join and Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables doll",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Valentine's Matching Outfits - playcutegames.com",
      name: ["Valentine's Matching Outfits", "valentines-matching-outfits"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/valentines-matching-outfits/250x142.png",
      path: ["/game/valentines-matching-outfits", "https://playcutegames.com"],
      iframe: " https://games.cutedressup.net/valentines_matching_outfits/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Valentine’s Matching Outfits. These cute couples from rainbow high are planned to celebrate this Valentine as a memorable one. So they decided to go for an outing and spend a whole day together on this occasion.",
      l_dis:
        `Welcome to Valentine’s Matching Outfits. These cute couples from rainbow high are planned to celebrate this Valentine as a memorable one. So they decided to go for an outing and spend a whole day together on this occasion. They make this even more special by trying the same matching outfits. Join and help these cute pairs from rainbow high. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Valentine",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Mega Dressup Seasons Best - playcutegames.com",
      name: ["Mega Dressup Seasons Best", "mega-dressup-seasons-best"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/mega-dressup-seasons-best/250x142.png",
      path: ["/game/mega-dressup-seasons-best", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/f810782b-9007-569c-be2f-1dcc6dda36a1/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Mega Dressup Seasons Best. Pretty clothes and unique styles, Mega Dressup will definitely bring out the fashionista in you! A vast array of outfits and accessories awaits you in this online dress-up game for girls, each to fit every season of the year",
      l_dis:
        "Welcome to Mega Dressup Seasons Best. Pretty clothes and unique styles, Mega Dressup will definitely bring out the fashionista in you! A vast array of outfits and accessories awaits you in this online dress-up game for girls, each to fit every season of the year, and the princesses are excited to welcome you into their trendy world. Are you ready to join the fun!",
      tag: [
        "Princess",
        "Dress up",
        "Winter",
        "Spring",
        "Spring",
        "Summer",
        "Autumn",
        "Prinxy Games"]
  ,
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Princess Homeland - playcutegames.com",
      name: ["Princess Homeland", "princess-homeland"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-homeland/150X150.jpg",
      path: ["/game/princess-homeland", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/ceafbe0b-8ec7-502c-b12e-02265e96d775/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Travel around the world in this online Cutedressup game for girls called Princess Homeland, and get ready to discover the traditional fashion of the different countries where your favorite Disney princesses live.",
      l_dis:
        "Travel around the world in this online Cutedressup game for girls called Princess Homeland, and get ready to discover the traditional fashion of the different countries where your favorite Disney princesses live. Elsa, Moana, Merida, and Esmeralda can’t wait to show off their stunning wardrobes. Are you curious to discover where you will be going first? Get started with the Princess Homeland Dress Up Game for girls, and be prepared to discover lots of fashionable clothing pieces and accessories as you travel from Scotland to France and from the sunny islands of Polynesia to Noway. Have a blast! ",
      tag: ["Princess", "Dress up", "Fashion", "Prinxy Games","Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Valentine's Crush - playcutegames.com",
      name: ["Princess Valentine's Crush", "princess-valentines-crush"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-valentines-crush/250x142.png",
      path: ["/game/princess-valentines-crush", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_valentines_crush/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Princess Valentine’s Crush game. Princess Elsa has a crush on Jack Frost. She Invited Jack to the valentine’s party. Both have a plan to do some makeovers and enjoy taking some photos to post on social media.",
      l_dis:
        `Welcome to Princess Valentine’s Crush game. Princess Elsa has a crush on Jack Frost. She Invited Jack to the valentine’s party. Both have a plan to do some makeovers and enjoy taking some photos to post on social media. Join and help the beautiful couples. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Valentine",
        "Jack Frost",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Lotta The Otter Rescue - playcutegames.com",
      name: ["Lotta The Otter Rescue", "lotta-the-otter-rescue"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/lotta-the-otter-rescue/250x142.png",
      path: ["/game/lotta-the-otter-rescue", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/f8611bf0-acea-5663-a104-9ef96ea08c0e/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Lotta The Otter Rescue. Discover little Lotta’s story in this free-to-play animal care game for girls called Lotta The Otter Rescue. Take the lost otter to your home, take care of her injuries, and then spoil her with a warm bubble bath.",
      l_dis:
        "Welcome to Lotta The Otter Rescue. Discover little Lotta’s story in this free-to-play animal care game for girls called Lotta The Otter Rescue. Take the lost otter to your home, take care of her injuries, and then spoil her with a warm bubble bath. Next, dress her un in a fashionable outfit, and then help her build a new home. Decorate it with colorful hammocks and lots of green plants and so you get to turn Lotta’s new home into one cozy place.",
      tag: ["Dress up", "Decoration", "Pet", "Prinxy Games","Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Venice Carnival Party - playcutegames.com",
      name: ["Venice Carnival Party", "venice-carnival-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/venice-carnival-party/250x142.png",
      path: ["/game/venice-carnival-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/venice_carnival_party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Venice Carnival Party Game. Skyler planned to have a vacation in Venice. Because It’s Carnival time in Venice now. She eagerly waits for so long for this. Her friend Sunny would like to join with Skyler.",
      l_dis:
        `Welcome to Venice Carnival Party Game. Skyler planned to have a vacation in Venice. Because It’s Carnival time in Venice now. She eagerly waits for so long for this. Her friend Sunny would like to join with Skyler. They need to get ready for the carnival. But they have little confusion on which clothing will suit them perfectly. Come and join them. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Decoration",
        "Carnival",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Winter Aesthetic Look - playcutegames.com",
      name: ["Winter Aesthetic Look", "winter-aesthetic-look"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/winter-aesthetic-look/250x142.png",
      path: ["/game/winter-aesthetic-look", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/winter_aesthetic_look/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Winter Aesthetic Look. On being home rainbow girls feels very bored. They decided to do some real fun activities.",
      l_dis:
        `Welcome to Winter Aesthetic Look. On being home rainbow girls feels very bored. They decided to do some real fun activities. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Winter",
        "Aesthetic",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "Prinxy",
     
      orientation: 0,
      title: "Harley Learns To Love - playcutegames.com",
      name: ["Harley Learns To Love", "harley-learns-to-love"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/harley-learns-to-love/250x142.png",
      path: ["/game/harley-learns-to-love", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/0fd26107-e64a-5107-9a3f-aea283396ab4/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Harley Learns To Love. The most famous villain of all time decided to give up dangerous activities and try a new lifestyle. This Valentine’s Day, Harley Quinn has decided to give dates a try, so she’s going to need your expertise.",
      l_dis:
        "Welcome to Harley Learns To Love. The most famous villain of all time decided to give up dangerous activities and try a new lifestyle. This Valentine’s Day, Harley Quinn has decided to give dates a try, so she’s going to need your expertise. Will you help her make a good impression? Play this new holiday game for girls called Harley Learns To Love, you help Harley Quinn get a total makeover and transform herself into a sweet girl. Replace her bold makeup look with a new one in natural shades, remove the colors in her hair, dress her up in girly-girl outfits, and select her perfect date in this free to play Valentine’s Day game for girls! Have a blast!",
      tag: ["Harley Quinn", "Dress up", "Make Up", "Valentine", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls NYE Fashion - playcutegames.com",
      name: ["Rainbow Girls NYE Fashion", "rainbow-girls-nye-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-nye-fashion/250x142.png",
      path: ["/game/rainbow-girls-nye-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/rainbow_girls_nye_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Rainbow Girls NYE Fashion. Skyler, Sunny, Ruby, and Violet are the best friends. They are planning for the NYE fashion to welcome the upcoming year with their stunning look.",
      l_dis:
        `Welcome to Rainbow Girls NYE Fashion. Skyler, Sunny, Ruby, and Violet are the best friends. They are planning for the NYE fashion to welcome the upcoming year with their stunning look. But they need expert help to do better. Join and help them as a fashion designer for these girls. Have fun! In this first level of this Makeup level, help Skyler to pick cute colors of eyeshades, Blushes, lip, and eye color. Then choose a cute hairstyle which suits her. Then browse her wardrobe and find a perfect Outfit and jewelry for her. Once you did with Skyler, Now it’s time for Sunny. Help her with makeup and hairstyles. Browse something different outfit than Skyler. For Ruby, Pick cute red shades of eyeshades, blushes, and lip color. And give her perfect outfits and accessories which suit her hair color. Finally, it’s time for Violet to choose a cool color of makeup and give a perfect outfit and accessories for her.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Fashion",
        "New year Games",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Popsy Surprise Valentines Day Prank - playcutegames.com",
      name: [
        "Popsy Surprise Valentines Day Prank",
        "popsy-surprise-valentines-day-prank",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/popsy-surprise-valentines-day-prank/250x142.png",
      path: [
        "/game/popsy-surprise-valentines-day-prank",
        "https://playcutegames.com",
      ],
      iframe: "https://dl-girls.com/content/folder_1612975569/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Popsy Surprise Valentines Day Prank. Valentine’s day is coming and the cute popsy-princess is very upset. Her boyfriend left her, he went to another girl on the eve of the holiday.",
      l_dis:
        `Welcome to Popsy Surprise Valentines Day Prank. Valentine’s day is coming and the cute popsy-princess is very upset. Her boyfriend left her, he went to another girl on the eve of the holiday. But don’t be sad! Our popsy is still a mischievous person. She decided to give the newly minted couple an unforgettable Valentine’s Day prank. Help the popsy-princess prepare a prank in the restaurant. Also, don’t forget that a princess should always be gorgeous. For this Valentine’s Day, the image of a devil is perfect for her. Choose a daring outfit, hairstyle, and makeup and be gorgeous with our heroine, no matter what!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "Valentine", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Rainbow Girls Christmas Party - playcutegames.com",
      name: ["Rainbow Girls Christmas Party", "rainbow-girls-christmas-party"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/rainbow-girls-christmas-party/250x142.png",
      path: ["/game/rainbow-girls-christmas-party", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/rainbow_girls_christmas_party/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Rainbow Girls Christmas Party game. Skyler and Sunny are best friends. They decided to attend the Christmas party at Rainbow High.",
      l_dis:
        "Welcome to the Rainbow Girls Christmas Party game. Skyler and Sunny are best friends. They decided to attend the Christmas party at Rainbow High. Both have a secret crush on Collin, who also come to the party. So the girls try to impress him with their stunning look. Join and help them. Have fun! ",
      tag: [
        "Rainbow High",
        "Dress up",
        "Make Up",
        "Christmas",
        "Party",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "Chinese New Year - playcutegames.com",
      name: ["Chinese New Year", "chinese-new-year"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/chinese-new-year/250x142.png",
      path: ["/game/chinese-new-year", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/84a26006-1f48-5bb1-9467-03f84cbb61bc/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Chinese New Year game. Play this awesome dress up game for girls and help princess Mulan celebrate the Chinese New Year in style! 2021 is the year of the Ox",
      l_dis:
        "Welcome to the Chinese New Year game. Play this awesome dress up game for girls and help princess Mulan celebrate the Chinese New Year in style! 2021 is the year of the Ox, and she wants to find herself wrapped in elegant white, silver, and aqua blue garments. Mulan’s fashion challenge for you is to help her look great in these colors during the upcoming sixteen days of celebration. In her wardrobe, you can find one exquisite collection of traditional outfits such as cheongsams and qipaos but with a modern twist. Browse through them all, have Mulan try on the ones you like the most, and then pick the winning one. Next, see how you can accessorize it with elegant shoes, embroidered purses, statement jewelry, and vintage hand fans. Do her makeup look as well. Test your decoration skills as you discover the third stage in this free to play the holiday-themed game for girls! Join and have fun! ",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "New year Games","Dressupwho",
        "Prinxy Games"]
  ,
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "New Lifestyle Minimalism - playcutegames.com",
      name: ["New Lifestyle Minimalism", "new-lifestyle-minimalism"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/new-lifestyle-minimalism/250x142.png",
      path: ["/game/new-lifestyle-minimalism", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/8a14266d-7699-59de-9d22-cfd01cd0fa52/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to New Lifestyle Minimalism. It’s the beginning of a new year, and princess Cinderella has decided to experience a new lifestyle.",
      l_dis:
        "Welcome to New Lifestyle Minimalism. It’s the beginning of a new year, and princess Cinderella has decided to experience a new lifestyle. In 2021 Ella wants to become a minimalist, and you can help her out! In this brand-new cleaning game for girls called New Lifestyle: Minimalism, you help one of your favorite Disney princesses to start a major tidying up session and get rid of the clutter. Are you ready to discover what it takes to become a minimalist?",
      tag: ["Princess", "Dress up", "Make Up", "Decoration", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Frozen Princess Christmas Celebration - playcutegames.com",
      name: [
        "Frozen Princess Christmas Celebration",
        "frozen-princess-christmas-celebration",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/frozen-princess-christmas-celebration/250x142.png",
      path: [
        "/game/frozen-princess-christmas-celebration",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/frozen_princess_christmas_celebration/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Frozen Princess Christmas Celebration. The Christmas season has just started. Princess Anna and Elsa planned to celebrate this Christmas a more memorable one.",
      l_dis:
        `Welcome to the Frozen Princess Christmas Celebration. The Christmas season has just started. Princess Anna and Elsa planned to celebrate this Christmas a more memorable one. They joined with their boyfriend Kristoff and Jack. Anna planned to decorate the snowman and Christmas tree along with Elsa. They already bought a cute collection of Christmas outfits for them and their boyfriends. Join and help them with decorations and choosing the perfect costumes for this Christmas. Have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Jack Frost",
        "Kristoff",
        "Anna",
        "Elsa",
        "Christmas",
        "Decoration",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Dl-Girls",
     
      orientation: 0,
      title: "Marinette Winter Vacation Hot and Cold - playcutegames.com",
      name: [
        "Marinette Winter Vacation Hot and Cold",
        "marinette-winter-vacation-hot-and-cold",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/marinette-winter-vacation-hot-and-cold/250x142.png",
      path: [
        "/game/marinette-winter-vacation-hot-and-cold",
        "https://playcutegames.com",
      ],
      iframe: "https://dl-girls.com/content/folder_1611419175/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Marinette Winter Vacation Hot and Cold. All the villains are defeated and Marinette has a week free from superhero affairs. Where should she go on vacation? Choose a vacation option for Marinet.",
      l_dis:
        "Welcome to Marinette Winter Vacation Hot and Cold. All the villains are defeated and Marinette has a week free from superhero affairs. Where should she go on vacation? Choose a vacation option for Marinet. You can send her to a ski resort to conquer steep slopes. Choose from stylish ski suits, skis or snowboard, hats, and goggles. Don’t forget about makeup and hair – you never know where you will meet your prince. Or maybe you prefer warmth? Then choose a beach holiday for Marinette. In this case, you should choose an elegant swimsuit, comfortable shoes – sneakers or sandals, a hat, sunglasses, and an inflatable ring. Cold or heat – the choice is yours, it won’t be boring!",
      tag: ["Princess", "Dress up", "Make Up", "Winter", "Superhero", "Dl-Girls"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Adorable Girls Valentino Fashion - playcutegames.com",
      name: [
        "Adorable Girls Valentino Fashion",
        "adorable-girls-valentino-fashion",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/adorable-girls-valentino-fashion/250x142.png",
      path: [
        "/game/adorable-girls-valentino-fashion",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/adorable_girls_valentino_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Adorable Girls Valentino Fashion. Willow, Dee Dee, and Noah are the BFFs. They hear about a new contest, so they planned to try something different. Willow got an idea to try Valentino fashion trends.",
      l_dis:
        `Welcome to Adorable Girls Valentino Fashion. Willow, Dee Dee, and Noah are the BFFs. They hear about a new contest, so they planned to try something different. Willow got an idea to try Valentino fashion trends. But they need some fashion tips to win the contest. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables doll",
        "Dress up",
        "Make Up",
        "Fashion",
        "Superhero",
        "cutedressup",
        "Exclusive",
        "Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "Y8",
     
      orientation: 0,
      title: "Baby Cathy Ep 1: Newborn - playcutegames.com",
      name: ["Baby Cathy Ep 1: Newborn", "baby-cathy-ep-1-newborn"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/baby-cathy-ep-1-newborn/250x142.png",
      path: ["/game/baby-cathy-ep-1-newborn", "https://playcutegames.com"],
      iframe: "https://y8.com/embed/baby_cathy_newborn",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Baby Cathy Ep 1: Newborn. Cute Cathy gonna born this Christmas season. Her parents are eagerly waiting for her arrival. So let us help them for preparing all the required items for baby Cathy.",
      l_dis:
        "Welcome to Baby Cathy Ep 1: Newborn. Cute Cathy gonna born this Christmas season. Her parents are eagerly waiting for her arrival. So let us help them for preparing all the required items for baby Cathy. First, they need to prepare Cathy next decorate the room n the meanwhile baby Cathy’s mother has admitted n labor help them getting Cathy out with little surgery and let’s decorate her cute cradle and finally let’s clean her up and dress her in the latest dresses and make her and her parents happy. ",
      tag: ["Dress up", "Cooking", "Doctor"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "My Perfect Year Planner - playcutegames.com",
      name: ["My Perfect Year Planner", "my-perfect-year-planner"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/my-perfect-year-planner/250x142.png",
      path: ["/game/my-perfect-year-planner", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/1eb18483-f922-5ce8-b50f-74727d47c471/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to My Perfect Year Planner. Belle has decided to make 2021 her best year ever, so even from the first days of the new year, she made a shortlist of resolutions.",
      l_dis:
        "Welcome to My Perfect Year Planner. Belle has decided to make 2021 her best year ever, so even from the first days of the new year, she made a shortlist of resolutions. She has a perfect plan for the New Year, but she needs your help to stick to it. Will you help her out? Join her in getting started with My Perfect Year Planner dress up game for girls, and help princess Belle get into her new lifestyle as she discovers 12 unique activities that are listed on her planner. Tap the diary on the planning and wait for the wheel to spin. When it stops, you get to discover one of Belle’s resolutions and a wardrobe filled with all the clothes, jewelry, and accessories you might need to help her look great while learning a new skill. Then, browse through her wardrobe, mix and match the clothing pieces you like the most with the right accessories, and dress her up for the given activity. Don’t forget to style up her hair for a completely new look. Return to the wheel, spin again, and create another stunning #ootd for our beautiful princess. Don’t stop until you’ve managed to complete all the twelve looks in this online dress-up challenge! ",
      tag: ["Princess", "Dress up", "Make Up", "Fashion", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "black-friday-shopping-spree - playcutegames.com",
      name: ["Black Friday Shopping Spree", "black-friday-shopping-spree"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/black-friday-shopping-spree/250x142.png",
      path: ["/game/black-friday-shopping-spree", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/black_friday_shopping_spree/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to the Black Friday shopping spree. The Black Friday sale is here. Adorable Princess gets more excited about shopping on this day. She planned to purchase new brands of prom, urban and sport wears.",
      l_dis:
        `Welcome to the Black Friday shopping spree. The Black Friday sale is here. Adorable Princess gets more excited about shopping on this day. She planned to purchase new brands of prom, urban and sport wears. But she is in confusion about which suits her perfectly. Help her and have fun!She is in a shopping mall. She needs your help. The first shop is a prom, pick a nice prom dresses for her and also find pairs of shoes, necklace and cute pairs of earrings. Then you can find an urban shop. She has a lot of collections of urban dresses in their wardrobe, but they are in old trends. So she plans to buy a new one. Then it is a sports shop where you can find sports wears. Buy cute sports outfits and pairs of shoes. on the fourth shop is a hair salon, where you can find long lengthy hairs or short ones. Help her to choose the best one for her. Last but not least you find a photoshop. Help her to take pictures of the items she purchased in the rest of the shops.Create your unique styles of outfits and costumes with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Our free online games for girls can be made much exciting with your comments and encouraging words.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Hairdorables doll",
        "Dress up",
        "Make Up",
        "Shopping",
        "cutedressup",
        "Exclusive",
        "Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Sorority Fall Fashion - playcutegames.com",
      name: ["Sorority Fall Fashion", "sorority-fall-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/sorority-fall-fashion/250x142.png",
      path: ["/game/sorority-fall-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/sorority_fall_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Sorority fall fashion. Princess Ariel, Cinderella, and Merida are the Best friends who admired for the latest fashion trends. They planned to welcome Autumn with their fashion style.",
      l_dis:
        `Welcome to Sorority fall fashion. Princess Ariel, Cinderella, and Merida are the Best friends who admired for the latest fashion trends. They planned to welcome Autumn with their fashion style. Join and help them.Start with Ariel makeup, pick cute eye lenses, eyeshadows, lipsticks, and blushes. Then browse her wardrobe to choose a cute outfit for her. And also pick nice accessories like chains and earrings. Don’t forget to try unique hairstyles. Do the same for Merida and Cinderella. Mix and match the outfits along with accessories that suit them. Finally, group all of them and welcome the autumn with this style. Play these new cute dress up games for girls and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Autumn",
        "cutedressup",
        "Exclusive",,"Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "haley-tries-heatless-curls - playcutegames.com",
      name: ["Haley Tries Heatless Curls", "haley-tries-heatless-curls"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/haley-tries-heatless-curls/250x142.png",
      path: ["/game/haley-tries-heatless-curls", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/525e603b-8046-57bc-960b-fac94d195e22/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Haley Tries Heatless Curls. There is a cool beauty hack trending around on TikTok, and Instagram for perfect no-heat curls, and in case you missed it",
      l_dis:
        "Welcome to Haley Tries Heatless Curls. There is a cool beauty hack trending around on TikTok, and Instagram for perfect no-heat curls, and in case you missed it, make sure you don’t skip this brand-new cute dress up game for girls! In the Haley Tries Heatless Curl’s game, we share with you four unique ways to curl your hair overnight without using heat. Four unique techniques featured in it, make sure you discover them all! You have to help her to do Bathrobe tie curls, socks curls, straw curls, and paper towels. These are the different hairstyles you are going to learn and help Haley. Have fun! If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup.",
      tag: ["Princess", "Dress up", "Make Up", "Hairstyles", "Prinxy Games"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bff Witchy Transformation - playcutegames.com",
      name: ["Bff Witchy Transformation", "bff-witchy-transformation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-witchy-transformation/250x142.png",
      path: ["/game/bff-witchy-transformation", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_witchy_transformation/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Bff Witchy Transformation. Princess Ariel, Merida, and Cinderella are discussing their weekend plan. Being a princess they seem bored. So they decided to do something different.",
      l_dis:
        `Welcome to Bff Witchy Transformation. Princess Ariel, Merida, and Cinderella are discussing their weekend plan. Being a princess they seem bored. So they decided to do something different. They planned to try some evil or witchy costumes. Join them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Decoration",
        "Manicure",
        "cutedressup",
        "Exclusive",,"Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "Prinxy",
     
      orientation: 0,
      title: "K pop New Years Concert - playcutegames.com",
      name: ["K pop New Years Concert", "k-pop-new-years-concert"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/k-pop-new-years-concert/250x142.png",
      path: ["/game/k-pop-new-years-concert", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/62f6892c-868c-5aac-9f3c-5d636ef1a8af/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to K-pop New Years Concert. Right after midnight, the most famous K-pop band of the moment is going to entertain the gathering with their cool songs, and these girls can’t wait to see the BTS boys performing.",
      l_dis:
        "Welcome to K-pop New Years Concert. Right after midnight, the most famous K-pop band of the moment is going to entertain the gathering with their cool songs, and these girls can’t wait to see the BTS boys performing. They’ve got the tickets, and they just need the right looks, and here is where you can help them out! Get ready to prove your stylist skills, ladies, while playing the K-pop New Years Concert dress up game for girls online! Hit play, and meet the lucky girls who are attending the BTS concert on New Year’s! Two of your favorite Disney princesses and two famous villains are willing to attend the concert. But there isn’t enough room for two powerful forces to be in the same place.  We believe that on New Year’s Eve, villains and princesses can be friends. And we imagined this cool holiday game where they welcome the New Year together. And what a better way than to bond at the concert of beloved K-pop sensation, BTS?",
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        " New year Games",
        "Prinxy Games","Dressupwho"
      ]
  ,
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Hello Halloween - playcutegames.com",
      name: ["Princess Hello Halloween", "princess-hello-halloween"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-hello-halloween/250x142.png",
      path: ["/game/princess-hello-halloween", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_hello_halloween/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to a new Halloween game called Princess Hello Halloween. Princess Anna and Elsa planned to welcome this Halloween with something special. So they decided to apply some Halloween paintings to their face and pick up the clothes which suit their Makeup.",
      l_dis:
        `Welcome to a new Halloween game called Princess Hello Halloween. Princess Anna and Elsa planned to welcome this Halloween with something special. So they decided to apply some Halloween paintings to their face and pick up the clothes which suit their Makeup. Finally, they decided to decorate the pumpkin with beautiful colors, patterns, and Hat and facial expressions. Play and have fun! Start with Elsa Halloween face art, Pick the best one which suits Elsa and match with cute Halloween outfits. Browse the perfect one in the wardrobe collection. Do the same for Anna, but select differently from Elsa. And last but not least decorate the pumpkin with different colors and patterns. And also you can customize the facial expressions of the pumpkin and Hat. If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Decoration",
        "Halloween",
        "Elsa",
        "Anna",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "Prinxy",
     
      orientation: 0,
      title: "Ellie: New Year's Eve - playcutegames.com",
      name: ["Ellie: New Year's Eve", "ellie-new-years-eve"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/ellie-new-years-eve/250x142.png",
      path: ["/game/ellie-new-years-eve", "https://playcutegames.com"],
      iframe:
        "https://game.digitap.eu/b3ac9eaa-c35f-575e-95f3-48abfd801958/index.html",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to Ellie: New Year’s Eve game. Get into the winter holidays together cute Ellie. She is in charge of entertaining you this new year’s eve and surely she has some great ideas in mind. Like many of us, she’s also spending New Year’s at home.",
      l_dis:
        "Welcome to Ellie: New Year’s Eve game. Get into the winter holidays together cute Ellie. She is in charge of entertaining you this new year’s eve and surely she has some great ideas in mind. Like many of us, she’s also spending New Year’s at home. So she came up with a busy agenda for the 31st of December. Join her in getting started with New Year’s Eve, and learn from our girl how to rock New Year’s at home, looking glamorous in stunning dresses and the right makeup look! If you are looking for the best makeup games, then look no further. Cutedressup.com has a cute collection of games for makeup.",
      tag: [
        "Ellie",
        "Dress up",
        "Make Up",
        "New Year games",
        "Decoration",
        "Prinxy Games","Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BigMax Happy Halloween - playcutegames.com",
      name: ["BigMax Happy Halloween", "bigmax-happy-halloween"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bigmax-happy-halloween/250x142.png",
      path: ["/game/bigmax-happy-halloween", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bigmax_happy_halloween/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to BigMax Happy Halloween Party. Tomago and Hiro planned to celebrate this Halloween a more memorable one. So they decided to try some crazy outfits for them and the BigMax. They arrange a party with Halloween decorations.",
      l_dis:
        `Welcome to BigMax Happy Halloween Party. Tomago and Hiro planned to celebrate this Halloween a more memorable one. So they decided to try some crazy outfits for them and the BigMax. They arrange a party with Halloween decorations. Help them and have fun! Start with Tomago, browse some cute outfits, and pick the perfect one for her. Choose some cool hairstyles and accessories. Don’t forget to choose a perfect hat that makes the Outfit suits for Halloween. Then start with Hiro, Do the same, and make him more different than Tomago. Finally, it’s time for Bigmax. Browse a wardrobe that has a collection of outfits like Pikachu, Zombie, Honey Bee and Big pumpkin, etc,…
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Halloween",
        "Decoration",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Ready For Adventure's Date - playcutegames.com",
      name: [
        "Princess Ready For Adventure's Date",
        "princess-ready-for-adventures-date",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-ready-for-adventures-date/250x142.png",
      path: [
        "/game/princess-ready-for-adventures-date",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess_ready_for_adventures_date/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Welcome to our new game Princess Ready For Adventure’s Date. Princess Moana is so excited. Maui invited her for a date at Te fiti. She needs your help to be prepare and to impress Maui with her stunning look.",
      l_dis:
        `Welcome to our new game Princess Ready For Adventure’s Date. Princess Moana is so excited. Maui invited her for a date at Te fiti. She needs your help to be prepare and to impress Maui with her stunning look. Join and help her. Start with boat ore decoration, help Moana to decorate her ore with beautiful colors and patterns. Then help her to choose a cute outfit for her date. Pick up beautiful accessories. Maui secretly planned to impress Moana in her way. Help Maui to decorate her hook with different colors and patterns. Then choose a nice outfit and costumes. Once you did, then help them to decorate a boat which they use to go to Te fiti. Choose cute colors and designs. Help Moana and Maui from this cute game, and make this date a more memorable one for both of them.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Make Up",
        "Decoration",
        "Summer",
        "Moana",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bff Attractive Autumn Style - playcutegames.com",
      name: ["Bff Attractive Autumn Style", "bff-attractive-autumn-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-attractive-autumn-style/250x142.png",
      path: ["/game/bff-attractive-autumn-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_attractive_autumn_style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this new game Bff Attractive Autumn Style, Princess Tiana, Belle, and Moana are the Best friends who inspired more in the latest fashion trends. Now they decided to welcome the upcoming season autumn with their fashion skill.",
      l_dis:
        `In this new game Bff Attractive Autumn Style, Princess Tiana, Belle, and Moana are the Best friends who inspired more in the latest fashion trends. Now they decided to welcome the upcoming season autumn with their fashion skill. Come and join them with the latest cute dress up games for girls. Even though they are experts in fashion they need some tips to fulfill them.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Make Up",
        "Decoration",
        "Autumn",
        "Belle",
        "Tiana",
        "Moana",
        "cutedressup",
        "Exclusive","Dressupwho"
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Makeover Salon - playcutegames.com",
      name: ["Princess Makeover Salon", "princess-makeover-salon"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-makeover-salon/250x142.png",
      path: ["/game/princess-makeover-salon", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_makeover_salon/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute game Princess Makeover Salon, Princess Anna, and Elsa seem very sad. Because of this hot summer, their face looks dirty and infected with pimples. They planned to do some makeover to bring their skin back.",
      l_dis:
        `In this cute game Princess Makeover Salon, Princess Anna, and Elsa seem very sad. Because of this hot summer, their face looks dirty and infected with pimples. They planned to do some makeover to bring their skin back. But they need some expert help. Join and help them to recover.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess", "Make Up", "cutedressup", "Exclusive","Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Besties Summer Vacation - playcutegames.com",
      name: ["Besties Summer Vacation", "besties-summer-vacation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/besties-summer-vacation/250x142.png",
      path: ["/game/besties-summer-vacation", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/besties_summer_vacation/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute game Besties Summer Vacation, Princess is planning for a cute summer vacation. They decided to arrange a trip to the beach with their old van. But they need some expert help to choose a cute makeup and perfect trip outfit.",
      l_dis:
        `In this cute game Besties Summer Vacation, Princess is planning for a cute summer vacation. They decided to arrange a trip to the beach with their old van. But they need some expert help to choose a cute makeup and perfect trip outfit. And also they need some help to modify and decorate their old van. Come and join our princesses and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Princess", "Make Up", "Summer", "cutedressup", "Exclusive","Dressupwho"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Superhero Violet Summer Excursion - playcutegames.com",
      name: [
        "Superhero Violet Summer Excursion",
        "superhero-violet-summer-excursion",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/superhero-violet-summer-excursion/250x142.png",
      path: [
        "/game/superhero-violet-summer-excursion",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/superhero_violet_summer_excursion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Superhero Violet Summer Excursion is just kicking in. Violet couldn’t stop thinking about the sea and beach so she decided to take a break and head to the closest beach she knew.",
      l_dis:
        `Superhero Violet Summer Excursion is just kicking in. Violet couldn’t stop thinking about the sea and beach so she decided to take a break and head to the closest beach she knew. But before anything else, she needs to properly prepare her complexion for the long sunbathing sessions she has in mind – care to help her out? Come and join her in getting started with this amazing makeover game for girls and first of all, take good care of her tiered complexion.Use a delicate cleanser and yummy looking face masks to deep clean her face. Apply fresh cucumber slices on her eyes to help to deal with the dark circles under her eyes, and make sure to also shape her eyebrows. Next, you get to put your designer skills to the test and cover her face in a cool face painting and then check out the hairstyle options as well. Pick a wavy hairstyle for Violet’s vacation at the beach and pair it with an easy breeze beach outfit. Finally, decorate her scooter with girly-girl stickers. Have an amazing time playing this brand-new summer-themed cute game for girls.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Dress up",
        "Make Up",
        "Summer",
        "Superhero",
        "Decoration",
        "Facial",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Delightful Summer - playcutegames.com",
      name: ["Princess Delightful Summer", "princess-delightful-summer"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-delightful-summer/250x142.png",
      path: ["/game/princess-delightful-summer", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_delightful_summer/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Princess Delightful Summer has just begun. Summer brings a lot of warmth and sunshine. Princess Cinderella and Ariel want to enjoy the bright days of summer. The long days with more light and comfort are what they have been waiting all these days.",
      l_dis:
        `Princess Delightful Summer has just begun. Summer brings a lot of warmth and sunshine. Princess Cinderella and Ariel want to enjoy the bright days of summer. The long days with more light and comfort are what they have been waiting all these days. But summer is aggressive this time and the princesses must wear the right dresses and costumes. Help them to choose the right outfits for an awesome summer.Start with princess Ariel, help them to choose a cute outfit for this summer with cool accessories and hairstyles. Accessories like earrings and necklaces.Do the same for princess Cinderella and make sure you try unique styles for each princess. The fun doesn’t end here, they planned to spend this summer on a vacation at the seaside.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Summer",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF In Fairy Style - playcutegames.com",
      name: ["BFF In Fairy Style", "bff-in-fairy-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-in-fairy-style/250x142.png",
      path: ["/game/bff-in-fairy-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_in_fairy_style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute BFF In Fairy Style game, Princess Ariel, Cinderella, and Merida are the Bffst who likely to participate in a fairy cosplay contest. They are so excited about the contest.",
      l_dis:
        `In this cute BFF In Fairy Style game, Princess Ariel, Cinderella, and Merida are the Bffst who likely to participate in a fairy cosplay contest. They are so excited about the contest.But they are in a little bit of confusion to choose perfect outfits. So help the princess with the right outfit.First, start with Ariel by trying some cool collection of fairy dresses, and new hairstyles. And help them with a few fairy costumes. Followed by Ariel other girls Cinderella and Merida are waiting in queue for your help.Help them with your magical skill, And finally, don’t forget to add magical wings to these beautiful princesses which make them a real fairy in look.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Aesthetics",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Tulip Back To Home - playcutegames.com",
      name: ["Tulip Back To Home", "tulip-back-to-home"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/tulip-back-to-home/250x142.png",
      path: ["/game/tulip-back-to-home", "https://playcutegames.com"],
      iframe: " https://games.cutedressup.net/tulip_back_to_home/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "Tulip has had a great time at the factory with storks. Now it’s time for Tulip to get back to her home. With a lot of excitement and enthusiasm, Tulip is preparing herself to start her new journey.",
      l_dis:
        `Tulip has had a great time at the factory with storks. Now it’s time for Tulip to get back to her home. With a lot of excitement and enthusiasm, Tulip is preparing herself to start her new journey. Tulip wants to pack a collection of cool outfits for her home. Casual, Pastel, and denim are her all-time favorite. Help tulip to try different outfits and gather the best of her dresses for a happily ever after the journey.Before starting the journey she wants to try some clothes in a different style. She has more collection in each style. So she confused to choose a perfect one. Help her with your fashion skill.Start with casual and followed by choosing a cute collection in pastel and denim. Don’t forget to try some cool hairstyles and modern accessories.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Dress up", "Make Up", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Makeover Fashion Blog - playcutegames.com",
      name: ["Princess Makeover Fashion Blog", "princess-makeover-fashion-blog"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-makeover-fashion-blog/250x142.png",
      path: [
        "/game/princess-makeover-fashion-blog",
        "https://playcutegames.com",
      ],
      iframe: " https://games.cutedressup.net/princess_makeover_fashion_blog/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this new cute game Princess Makeover Fashion Blog, Princess Anna, and Elsa are decided to create a fashion blog. So they planned to make a photoshoot for their blog.",
      l_dis:
        `In this new cute game Princess Makeover Fashion Blog, Princess Anna, and Elsa are decided to create a fashion blog. So they planned to make a photoshoot for their blog.Face decor with photoshoot can never get more fascinating than this! They planned to try some cute face art with different lip colors. And also they decided to try different hairstyles. Princess confused about choosing a nice variety of butterflies, animals, and superhero face art. They need some expert advice to make a perfect blog.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Cheongsam Shanghai Fashion - playcutegames.com",
      name: [
        "Princess Cheongsam Shanghai Fashion",
        "princess-cheongsam-shanghai-fashion",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-cheongsam-shanghai-fashion/250x142.png",
      path: [
        "/game/princess-cheongsam-shanghai-fashion",
        "https://playcutegames.com",
      ],
      iframe:
        "https://games.cutedressup.net/princess_cheongsam_shanghai_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this new cute game Princess Cheongsam Shanghai Fashion, Princesses want to play and enjoy their time, making cute dress up styles. Being BFFs forever, they have tried all possible outfits.",
      l_dis:
        `In this new cute game Princess Cheongsam Shanghai Fashion, Princesses want to play and enjoy their time, making cute dress up styles. Being BFFs forever, they have tried all possible outfits. Curiosity helps them to think past the current century. As they grace through history, they decide to adopt Cheongsam Shanghai Fashion in Princess style. Anna and Elsa finally land upon interesting dress-up costumes in Qing-style tops, modern Qipao with high-slit cuts & mini dress. Help them choose the right color, dresses, and present them in majestic imperial fashion.Try some cool Cheongsam dresses and accessories for each princess. Don’t forget to checkout unique hairstyles for both princesses. They are so excited to try old-fashioned dresses. So don’t make them disappointed try something unique. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Superhero Violet Fashion Shoot - playcutegames.com",
      name: ["Superhero Violet Fashion Shoot", "superhero-violet-fashion-shoot"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/superhero-violet-fashion-shoot/250x142.png",
      path: [
        "/game/superhero-violet-fashion-shoot",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/superhero_violet_fashion_shoot/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute game Superhero Violet Fashion Shoot, Violet is an incredible teenage girl who is normally very shy and stays away from her peer group of girls.",
      l_dis:
        `In this cute game Superhero Violet Fashion Shoot, Violet is an incredible teenage girl who is normally very shy and stays away from her peer group of girls.So violet decides to surprise her best friends, parents, and boyfriend by putting up an exquisite photoshoot. It’s time to display an adorable fashion parade.Violet wants to share her superhero outfits and princess dress-up with her parents but keep them a secret !! Shhhh!Violet reserves the casual look and the prom makeover for her special friend. Saving the best for last, Violet wants to display extraordinary fashion in anime & social media trends and get a lot of followers.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Superhero",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Baby Boss Photo shoot - playcutegames.com",
      name: ["Baby Boss Photo shoot", "baby-boss-photo-shoot"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/baby-boss-photo-shoot/250x142.png",
      path: ["/game/baby-boss-photo-shoot", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/baby_boss_photo_shoot/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In this cute Baby Boss Photo Shoot game, Boss Baby is thrilled about his photoshoot for upcoming candidacy. He wants to make a show by wearing some cool outfits. Beach baby or skater baby? ",
      l_dis:
        `In this cute Baby Boss Photo Shoot game, Boss Baby is thrilled about his photoshoot for upcoming candidacy. He wants to make a show by wearing some cool outfits. Beach baby or skater baby? Casual baby or a pro-style office baby? Hip-hop baby or a mini superhero? Boss is overwhelmed with choice. Help the baby boss choose and try different dress up and makeover outfits to enjoy delivering a stunning appearance.He needs your help because the little baby does not know entirely how to dress and how to match his clothes. So please guide him with your fashion skill. The game will be very fun and rather easy because dear kids you will just have to use your mouse for anything you want to do.Make sure that in the shortest time, you will manage to match all of his outfits, and even find the Batman and the Spy costumes. Boss Baby is also going to need a chic baby costume, a baby skateboarder costume, and a cool rock costume. That you can pull off using all the clothes that he has prepared in this new challenge.Create your unique styles for the Baby boss in fashion with an excellent collection of dresses and hairstyles. Don’t forget to post them in your comments here or share them with your friends on social media. Our free online games for girls can be made much exciting with your comments and encouraging words
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: ["Boss Baby", "Dress up", "Fashion", "cutedressup", "Exclusive"],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Bff Princess Back To School - playcutegames.com",
      name: ["Bff Princess Back To School", "bff-princess-back-to-school"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-princess-back-to-school/250x142.png",
      path: ["/game/bff-princess-back-to-school", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_princess_back_to_school/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "It’s time for the BFF Princess to go back to school.A new academic year is about to begin!Can you help these Princess get ready for school? Each one wants to create the perfect look for their first day at school.",
      l_dis:
        `It’s time for the BFF Princess to go back to school.A new academic year is about to begin!Can you help these Princess get ready for school? Each one wants to create the perfect look for their first day at school. There are lots of hairstyles, outfits, and accessories for them to choose from, and they’re really in a hurry! They just can’t decide which ones to pick, so they’ll need your help!Being a fashion expert, you have to help each princess with different styles of dresses and costumes. Their wardrobe consists of casual, modern, and uniform outfits. Select the best one for their first-day school. Try some unique hairstyles for each princess. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "School",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess In Colourful Wonderland - playcutegames.com",
      name: [
        "Princess In Colourful Wonderland",
        "princess-in-colourful-wonderland",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-in-colourful-wonderland/250x142.png",
      path: [
        "/game/princess-in-colourful-wonderland",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess_in_colourful_wonderland/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis:
        "In Princess in Colourful Wonderland game, Princesses grant wishes, make fairy play and enjoy themselves. Quickly they realize it’s all in their dreams.",
      l_dis:
        `In Princess in Colourful Wonderland game, Princesses grant wishes, make fairy play and enjoy themselves. Quickly they realize it’s all in their dreams.Princesses now want to make their dream a reality. The princesses are planning to wear some cool fairy outfits, bands, and crowns with a cute collection of fairy feathers. They want to make it happen in reality and grant their fairy wishes to each other. Enjoy this fun-filled experience on wonderland with our fairies! So help the princess have maximum fun!Start with Ariel, Choose the best dresses in their fairy wardrobes and also help him to choose accessories and magical wings. Then help cinderella with different dresses and costumes. Make sure to try with different unique hairstyles for both of them.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
  
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF Candy Fever - playcutegames.com",
      name: ["BFF Candy Fever", "bff-candy-fever"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-candy-fever/250x142.png",
      path: ["/game/bff-candy-fever", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/bff_candy_fever/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princesses are now in BFF Candy fever mood. Princess Ariel, Merida, and Cinderella are BFFs forever. They want to enjoy a cute collection of tasty candy costumes.",
      l_dis:
        `Princesses are now in BFF Candy fever mood. Princess Ariel, Merida, and Cinderella are BFFs forever. They want to enjoy a cute collection of tasty candy costumes. Watch the Princesses travel to a Candy wonderland and experience a variety of candy makeovers and candy cream hair.  Decorate each princess with cute candy styles and help them to enjoy this candy saga.Start with Princess Ariel, try with candy costume like a lollipop and other sweet candies. Add a unique hairstyle for Princess Ariel. To add more fun, try hairstyles with candy style. Do the same for princess Cinderella and Merida. They are also so much excited and waiting for your help. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Pastel Fashion - playcutegames.com",
      name: ["Princess Pastel Fashion", "princess-pastel-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-pastel-fashion/250x142.png",
      path: ["/game/princess-pastel-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_pastel_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princess Pastel Fashion is totally on in this season! That’s why these stylish princess Anna and Elsa have decided to design some awesome outfits that feature tons of pastel colors.",
      l_dis:
        `Princess Pastel Fashion is totally on in this season! That’s why these stylish princess Anna and Elsa have decided to design some awesome outfits that feature tons of pastel colors. They have already decorated their wardrobe with a set of cute pastel dresses. Help the princesses to choose the best.Help them to choose the cute dresses collection from pastel. Try some cool accessories and unique hairstyles for each one of them. They are so excited about this fashion contest. So show your fashion skill and make them happy. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "BFF St. Patricks Day Preparation - playcutegames.com",
      name: [
        "BFF St. Patricks Day Preparation",
        "bff-st-patricks-day-preparation",
      ],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/bff-st-patricks-day-preparation/250x142.png",
      path: [
        "/game/bff-st-patricks-day-preparation",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/bff_st_patricks_day_preparation/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "BFF St Patricks Day Preparation styles are the best. The cute princesses are getting ready for Patrick’s day celebration.",
      l_dis:
        `BFF St Patricks Day Preparation styles are the best. The cute princesses are getting ready for Patrick’s day celebration. So help the princesses to prepare for the carnival by choosing the best matching outfits, hairstyles, and accessories.A very special and very interesting new dress up the challenge is appearing today right here. you will manage to have a great time helping three Disney princesses getting ready for St Patrick’s day.You will have to be very creative because dear kids you will see that Ariel, Anna, and Rapunzel are going to be the main characters. You will manage to help the three girls have cute St Patrick’s outfits to wear.Each of the Disney princesses will have different types of styles. Help the princess to be ready for St Patrick’s day. In this new challenge, you will have to be sure that the Disney princesses will be ready to wear cute hats, cute dresses. And new makeup and new accessories that the princesses can wear and match with their own style.The official color of the St Patrick’s day is green. So you have to help all princesses to wear the cutest and the most beautiful outfits for St Patrick’s day.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Stripes Vs Dots - playcutegames.com",
      name: ["Princess Stripes Vs Dots", "princess-stripe-vs-dots"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-stripes-vs-dots/250x142.png",
      path: ["/game/princess-stripe-vs-dots", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_stripe_vs_dots/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princess Stripes vs Dots Competition is about to begin and make things more exciting. The two little princesses of Cutedressup simply adore the modern world and fashion.",
      l_dis:
        `Princess Stripes vs Dots Competition is about to begin and make things more exciting. The two little princesses of Cutedressup simply adore the modern world and fashion. They are trendsetters and best friends. But sometimes their taste in fashion is not the same. They need some experts to advise them to choose stripes or dots which one is best.Now they could use your expert helping hand to learn how to properly accessorize their new additions. Care to help them out? Come and join the little trendsetters getting started with this brand new cute dress up game for girls called Princess Stripes Vs Dots and see what playful, summery looks you can put together for them.First, you can dress each of them in a striped outfit, and then you can have them try on a polka-dotted combo as well. Next, make sure to accessorize each outfit that you choose with a new hairstyle, cute earrings, and matching necklaces.Have them try on all your favorite clothing pieces and accessories before deciding which fashion style best suits each girl.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Spring Fashion - playcutegames.com",
      name: ["Princess Spring Fashion", "princess-spring-fashion"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-spring-fashion/250x142.png",
      path: ["/game/princess-spring-fashion", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_spring_fashion/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princess Spring Fashion is about to begin! Get ready for the most awaited season to spend with the two cute sisters who are absolutely mad about this season!.",
      l_dis:
        `Princess Spring Fashion is about to begin! Get ready for the most awaited season to spend with the two cute sisters who are absolutely mad about this season!. They have already renewed their spring wardrobe with cherry blossom collections. You should really check out their outfits and accessories for spring. Make sure to pick something chic and pretty. Have fun!.Help them to choose the cute floral dress collection from their wardrobe. Try some cool accessories and unique hairstyles for each one of them. They are so excited about this fashion contest. So show your fashion skill and make them happy. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "Spring",
        "cutedressup",
        "Exclusive","Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Trendy T Shirt - playcutegames.com",
      name: ["Princess Trendy T Shirt", "princess-trendy-t-shirt"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-trendy-t-shirt/250x142.png",
      path: ["/game/princess-trendy-t-shirt", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_trendy_tshirt/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princess Trendy T-Shirt season is on. Princess Anna and Elsa are planning to challenge each other with different styles.",
      l_dis:
        `Princess Trendy T-Shirt season is on. Princess Anna and Elsa are planning to challenge each other with different styles. Dresses are available in plenty, the princesses are now in a state of confusion in choosing the best outfits to win over other princesses. They need some expert help. So please help the princess to choose the best t-shirt and give each one a perfect look with fashionable t-shirts.Start with princess Elsa and followed by Princess Anna. Try from the collections like stripes, Dots, pattern, Casual, retro, and pastel styles of t-shirts. Don’t forget to try some unique hairstyles and accessories. Help them and have fun!
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Fashion",
        "cutedressup",
        "Exclusive",
        "Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
  
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Winter Style - playcutegames.com",
      name: ["Princess Winter Style", "princess-winter-style"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-winter-style/250x142.png",
      path: ["/game/princess-winter-style", "https://playcutegames.com"],
      iframe: "https://games.cutedressup.net/princess_winter_style/",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "Princess Winter Style has just begun. Princesses want to get out in the winter but are in dilemma in choosing the best outfit. Help them get cute with warm outfits and enjoy",
      l_dis:
        `Princess Winter Style has just begun. Princesses want to get out in the winter but are in dilemma in choosing the best outfit. Help them get cute with warm outfits and enjoy spending time with them in this cute new dress up game!At first, help the princess Anna to choose the best winter outfits, chain, earing, and stunning hairstyles. And then its time for Princess Elsa, to do the same. Help Elsa to choose perfect outfits, accessories, and hairstyles.Once you did helping both princesses, we have to decorate their new snowman. They decided to make it funnier, so let’s try some hats, scarves, bow tie and glove. Help them out and bring out the best in you.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Winter",
        "Decoration",
        "cutedressup",
        "Exclusive",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Valentine Preparation - playcutegames.com",
      name: ["Princess Valentine Preparation", "princess-valentine-preparation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-valentine-preparation/250x142.png",
      path: [
        "/game/princess-valentine-preparation",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess_valentine_preparations",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "With valentine’s day around the corner, the Princess Valentine preparation has become exciting and thrilling. To celebrate Valentine’s day, the princess has decided to be in their best!",
      l_dis:
        `With valentine’s day around the corner, the Princess Valentine preparation has become exciting and thrilling. To celebrate Valentine’s day, the princess has decided to be in their best! Let’s check out their closet and look at our options. They have a few dresses in their colors of choice, both long and short, sometimes it’s more fun to mix it up a bit! You have the additional task of decorating their card and make them geared up for the royal date.Let’s start with Princess Elsa, try some cool date outfits and costumes. And then help Princess Anna to choose her outfits and accessories. Don’t forget to try some unique hairstyles for both of them.Once you help with dress-up part, then create unique valentine cards for both Princess. Decorate the card with cute emoji’s and cute valentine quotes.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Valentine",
        "Decoration",
        "cutedressup",
        "Exclusive",
        "Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard`,
      platform:"both",
    },
    {
      id: 20211002,
      brand: "cutedressup",
     
      orientation: 0,
      title: "Princess Valentine Preparation - playcutegames.com",
      name: ["Princess Valentine Preparation", "princess-valentine-preparation"],
      thumb:
        "https://cdn.cutedressup.in/games/cutedressup_new/princess-valentine-preparation/250x142.png",
      path: [
        "/game/princess-valentine-preparation",
        "https://playcutegames.com",
      ],
      iframe: "https://games.cutedressup.net/princess_valentine_preparations",
      Walkthrough: "https://www.youtube.com/embed/",
      s_dis: "With valentine’s day around the corner, the Princess Valentine preparation has become exciting and thrilling. To celebrate Valentine’s day, the princess has decided to be in their best!",
      l_dis:
        `With valentine’s day around the corner, the Princess Valentine preparation has become exciting and thrilling. To celebrate Valentine’s day, the princess has decided to be in their best! Let’s check out their closet and look at our options. They have a few dresses in their colors of choice, both long and short, sometimes it’s more fun to mix it up a bit! You have the additional task of decorating their card and make them geared up for the royal date.Let’s start with Princess Elsa, try some cool date outfits and costumes. And then help Princess Anna to choose her outfits and accessories. Don’t forget to try some unique hairstyles for both of them.Once you help with dress-up part, then create unique valentine cards for both Princess. Decorate the card with cute emoji’s and cute valentine quotes.
        <br><br>Game Developed by <a href="https://cutedressup.com/?utm_campaign=Game_By&amp;utm_source=playcutegames.com&amp;utm_medium=Site_Referral&amp;utm_content=Bottom_Link" target="_blank">cutedressup.com</a>`,
      tag: [
        "Princess",
        "Dress up",
        "Make Up",
        "Valentine",
        "Decoration",
        "cutedressup",
        "Exclusive",
        "Dressupwho",
      ],
      hint: `Use your left mouse button and keyboard
      `,
      platform:"both",
    },
    
  ];
