// components/Spinner.js
import styles from '../../styles/Spinner.module.css';
import Image from 'next/image';
import { Assets } from "../../src/list/Assets";

const Spinner = () => {

  const myImageLoader = ({ src }) => {
    return `${src}?w=100&q=100`;
  };

  return (
    <div className={styles.spinner}>
      <div className={styles.loader}>

      <Image
                loader={myImageLoader}
                src={Assets[0].halloween}
                alt={"Halloween image"}
                layout="fill"
                loading="lazy"
              />

      </div>
    </div>
  );
};

export default Spinner;
